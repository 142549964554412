import axios from "axios";
import CommonHelper from "helpers/CommonHelper";


/**
 * Axios 통신 모듈 Response 공통 처리
 */
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 403 || error?.response?.status === 401) {
      console.log("인증 오류");
      alert('만료되었습니다. 로그인을 다시 해주세요.')
      CommonHelper.clearAuthenticateToken();
       window.location.href = "/sign-in";
    }
    return error;
  }
);

export const apiCall = (url, method = "get", payload = {}, options) => {
  const { timeout, withCredentials, responseType, authorization } =
    options || {};
  const headers = {};

  if (authorization) {
    headers["Authorization"] = `Bearer ${authorization}`;
    headers["Access-Control-Allow-Origin"] = "*";
    headers["Content-Type"] = "application/json";
  }

  return axios({
    url,
    method,
    data:
      (method === "post" || method === "put" || method === "delete") && payload,
    params: method === "get" && payload,
    // timeout: timeout || 60000, // 60초 timeout
    timeout: timeout || 300000, //5분 timeout, TODO: 60초로 변경할 것
    withCredentials: withCredentials || false, // CORS 관련 설정
    responseType: responseType || "json", // response Type default : JSON,
    headers,
  });
};

export const apiCallByFormData = (url, formData, options) => {
  const { timeout, responseType } = options || {};

  return axios({
    url,
    method: options?.method || "post",
    data: formData,
    // timeout: timeout || 60000, // 60초 timeout
    timeout: timeout || 300000, //5분 timeout , TODO: 60초로 변경할 것
    // withCredentials: withCredentials || true, // CORS 관련 설정
    responseType: responseType || "json", // response Type default : JSON,
    // maxContentLength: maxContentLength || 2000,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multipart/form-data",
    },
  });
};


