import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";
import { getAppSiteInfoApi } from "../../../apis/appSite";
import { changePcRollingApi } from "../../../apis/main";
import CommonHelper from "../../../helpers/CommonHelper";
import {
  PCRollingSettingOptions,
  PCRollingNumOptions,
  PCROLLING_EN_KO,
  PCROLLING_KO_EN,
} from "../../../common/constants";

const PCRolling = ({ selected = "조회순" }) => {
  const [siteInfo, setSiteInfo] = useState({});
  const [label, setLabel] = useState("");
  const [rollingWay, setRollingWay] = useState(null);
  const [rollingAmount, setRollingAmount] = useState(null);
  const [addFile, setAddFile] = useState();

  useEffect(() => {
    getSiteInfo();
  }, []);

  useEffect(() => {
    const option = PCRollingSettingOptions.find(
      (option) => option.value === selected && option
    );
    if (option) {
      setLabel(option.label);
    }
  }, [PCRollingSettingOptions, selected]);

  const handleChange = (e) => {
    if (e.target.id === "check-등록기준") {
      setRollingWay(PCROLLING_KO_EN[e.target.value]);
    } else if (e.target.id === "check-등록갯수") {
      setRollingAmount(e.target.value);
    }
  };

  return (
    <>
      <Card>
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          롤링 설정하기
        </CardTitle>
        <CardBody>
          <Container>
            <Row>
              <Col>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label>등록기준</label>
                  <select
                    name="등록기준"
                    id={`check-등록기준`}
                    value={PCROLLING_EN_KO[rollingWay]} // selected로 변동될 수 있게 만들기
                    autoblur="true"
                    onChange={(e) => handleChange(e)}
                    // disabled={selectDisabled}>
                  >
                    {PCRollingSettingOptions.map(({ label, value }, index) => (
                      <option key={index} value={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
              <Col>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label>등록갯수</label>
                  <select
                    name="등록갯수"
                    id={`check-등록갯수`}
                    value={rollingAmount} // selected로 변동될 수 있게 만들기
                    autoblur="true"
                    onChange={(e) => handleChange(e)}
                    // disabled={selectDisabled}>
                  >
                    {PCRollingNumOptions.map(({ label, value }, index) => (
                      <option key={index} value={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
            </Row>
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "40px",
              }}>
              <Button onClick={() => changePcRolling()}>바꾸기</Button>
            </Row>
          </Container>
        </CardBody>
      </Card>
    </>
  );

  function getSiteInfo() {
    getAppSiteInfoApi().then(({ data: { status, detail, data } }) => {
      if (status === "SUCCESS") {
        setSiteInfo(data);
        setRollingAmount(data?.pcRollingInfo?.limit || null);
        setRollingWay(data?.pcRollingInfo?.standard || null);
      } else {
        alert(detail);
      }
    });
  }

  function changePcRolling() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      changePcRollingApi(authToken, rollingWay, rollingAmount).then(
        ({ data: { status, detail, data } }) => {
          if (status === "SUCCESS") {
            alert("성공적으로 적용되었습니다.");
            getSiteInfo();
          } else {
            alert(detail);
          }
        }
      );
    }
  }
};
export default PCRolling;
