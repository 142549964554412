import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";
import DEFAULT_IMAGE from "../../../assets/images/big/default-image.jpg";
import { SERVER_API_HOST } from "../../../common/environment";
import CommonHelper from "../../../helpers/CommonHelper";
import { uploadFile } from "../../../apis/uploadFile";
import { getAppSiteInfoApi, changeAppSiteBanner } from "../../../apis/appSite";

const ServiceStoreBanner = () => {
  const newBannerImgRef = useRef(null);
  const modifyBannerImgRef = useRef(null);
  const [showAddNewBanner, setShowAddNewBanner] = useState(false);
  const [currentBannerList, setCurrentBannerList] = useState([]);
  const [newBannerList, setNewBannerList] = useState([]);
  const [newBannerUrl, setNewBannerUrl] = useState(null);
  const [modifyBannerUrl, setModifyBannerUrl] = useState(null);
  const [showModifyBanner, setShowModifyBanner] = useState(false);
  // const [imgSrc, setImgSrc] = useState(null); //백엔드에 연결하기 전에 테스트src 둘 state
  const [modifyId, setModifyId] = useState();
  const onCLickUpdateNewBannerInput = () => {
    // 파일 입력 폼에 변화가 일어나는지 감시하는 이벤트 리스터 등록
    newBannerImgRef.current.addEventListener(
      "change",
      onChangeNewBannerInput,
      false
    );
    // 파일 입력 폼 열기
    newBannerImgRef.current.click();
  };

  const onClickUpdateModifyBannerInput = () => {
    // 파일 입력 폼에 변화가 일어나는지 감시하는 이벤트 리스터 등록
    modifyBannerImgRef.current.addEventListener(
      "change",
      onChangeModifyBannerInput,
      false
    );
    // 파일 입력 폼 열기
    modifyBannerImgRef.current.click();
  };

  useEffect(() => {
    getAppSiteInfo();
  }, []);

  const openAddNewBanner = () => {
    setShowAddNewBanner(true);
  };
  const closeAddNewBanner = () => {
    setShowAddNewBanner(false);
    setNewBannerUrl(null);
  };

  const openModifyOneBanner = (url, idx) => {
    setShowModifyBanner(true);
    setModifyBannerUrl(url);
    setModifyId(idx);
  };

  const closeModifyOneBanner = () => {
    setShowModifyBanner(false);
  };

  const addNewBannerToCurrentList = () => {
    const copyCurrentList = [...currentBannerList];
    copyCurrentList.push(newBannerUrl);
    setNewBannerList(copyCurrentList);
  };
  return (
    <>
      {showAddNewBanner && (
        <Card>
          <CardTitle className="bg-light border-bottom p-3 mb-0">
            <div className="space-between">
              배너 추가하기
              <button onClick={closeAddNewBanner}>닫기</button>
            </div>
          </CardTitle>
          <CardBody>
            <Container>
              <Row>
                <Col sm="6">
                  <Card>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={onCLickUpdateNewBannerInput}>
                      <CardImg
                        top
                        width="100%"
                        src={newBannerUrl ? newBannerUrl : DEFAULT_IMAGE}
                      />
                      <input
                        type="file"
                        accept="image/*"
                        ref={newBannerImgRef}
                        hidden
                      />
                    </div>
                  </Card>
                </Col>
              </Row>
              <Button onClick={putAppSiteBannerList}>생성하기</Button>
            </Container>
          </CardBody>
        </Card>
      )}
      {showModifyBanner && (
        <Card>
          <CardTitle className="bg-light border-bottom p-3 mb-0">
            <div className="space-between">
              배너 수정하기
              <button onClick={closeModifyOneBanner}>닫기</button>
            </div>
          </CardTitle>
          <CardBody>
            <Container>
              <Row>
                <Col sm="6">
                  <Card>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={onClickUpdateModifyBannerInput}>
                      <CardImg
                        top
                        width="100%"
                        src={modifyBannerUrl ? modifyBannerUrl : DEFAULT_IMAGE}
                      />
                      <input
                        type="file"
                        accept="image/*"
                        ref={modifyBannerImgRef}
                        hidden
                      />
                    </div>
                  </Card>
                </Col>
              </Row>
              <Button onClick={putAppSiteBannerList}>수정하기</Button>
              <Button onClick={deleteAppSiteOneBanner}>삭제하기</Button>
            </Container>
          </CardBody>
        </Card>
      )}
      <Card>
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          <div className="space-between">
            App > 매장소개 > 배너 리스트 관리
            <Button onClick={openAddNewBanner}>배너 추가하기</Button>
          </div>
        </CardTitle>
        <CardBody>
          <Container>
            <Row>
              {currentBannerList.map((banner, idx) => (
                <>
                  <Col xs="12" md="6">
                    <Card>
                      <div>
                        <CardImg
                          top
                          width="100%"
                          src={banner ? banner : DEFAULT_IMAGE}
                        />
                      </div>
                    </Card>
                    <Button onClick={() => openModifyOneBanner(banner, idx)}>
                      수정하기
                    </Button>
                  </Col>
                </>
              ))}
            </Row>
            <Row></Row>
          </Container>
        </CardBody>
      </Card>
    </>
  );

  function getAppSiteInfo() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      getAppSiteInfoApi(authToken).then(({ data: { status, error, data } }) => {
        if (status === "SUCCESS") {
          setCurrentBannerList(data?.bannerUrls || []);
        } else {
          console.log(error);
          alert("문제가 생겼습니다.");
        }
      });
    }
  }

  async function onChangeNewBannerInput(event) {
    // 파일을 선택 한 경우에만 실행
    const file = event.target.files[0];
    // 여러번 등록되는 버그 수정 (https://stackoverflow.com/questions/39484895/how-to-allow-input-type-file-to-select-the-same-file-in-react-component)
    event.target.value = null;
    const authToken = CommonHelper.getAuthToken();
    // 파일 업로드
    let nextPhotoUrl = await uploadFileAndGetUrl(file);
    if (nextPhotoUrl) {
      // nextPhotoUrl = nextPhotoUrl.replace('http://localhost:8080', SERVER_API_HOST); // TODO 임시 코드
      nextPhotoUrl = `${SERVER_API_HOST}${nextPhotoUrl}`;
      setNewBannerUrl(nextPhotoUrl);
      const copyCurrentList = [...currentBannerList];
      copyCurrentList.push(nextPhotoUrl);
      setNewBannerList(copyCurrentList);
    }
  }

  async function onChangeModifyBannerInput(event) {
    // 파일을 선택 한 경우에만 실행
    const file = event.target.files[0];
    // 여러번 등록되는 버그 수정 (https://stackoverflow.com/questions/39484895/how-to-allow-input-type-file-to-select-the-same-file-in-react-component)
    event.target.value = null;
    const authToken = CommonHelper.getAuthToken();
    // 파일 업로드
    let nextPhotoUrl = await uploadFileAndGetUrl(file);
    if (nextPhotoUrl) {
      // nextPhotoUrl = nextPhotoUrl.replace('http://localhost:8080', SERVER_API_HOST); // TODO 임시 코드
      nextPhotoUrl = `${SERVER_API_HOST}${nextPhotoUrl}`;
      setModifyBannerUrl(nextPhotoUrl);
      const copyCurrentList = [...currentBannerList];
      copyCurrentList[modifyId] = nextPhotoUrl;
      setNewBannerList(copyCurrentList);
    }
  }
  // 파일 업로드 후 URL 반환
  async function uploadFileAndGetUrl(file) {
    if (file) {
      const { type: fileType, name: fileName, size: fileSize } = file;
      // 파일 데이터 만들기
      const formData = new FormData();
      formData.append("file", file);

      // 파일 업로드
      const uploadResult = await uploadFile(formData);
      const {
        status,
        data: { url },
      } = uploadResult;
      if (status === 200 && !!url) {
        return url;
      } else {
        alert("파일 업로드 실패");
        return null;
      }
    } else {
      return null;
    }
  }

  function putAppSiteBannerList() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      changeAppSiteBanner(authToken, newBannerList).then(
        ({ data: { status, error, data } }) => {
          if (status === "SUCCESS") {
            alert("성공적으로 적용되었습니다.");
            setShowAddNewBanner(false);
            setShowModifyBanner(false);
            setNewBannerUrl(null);
            getAppSiteInfo();
          }
        }
      );
    }
  }

  function deleteAppSiteOneBanner() {
    const authToken = CommonHelper.getAuthToken();
    const copyNewBannerList = currentBannerList.splice(modifyId, 1);
    if (!!authToken) {
      changeAppSiteBanner(authToken, currentBannerList).then(
        ({ data: { status, error, data } }) => {
          if (status === "SUCCESS") {
            alert("성공적으로 삭제되었습니다.");
            setShowAddNewBanner(false);
            setShowModifyBanner(false);
            setNewBannerUrl(null);
            getAppSiteInfo();
          }
        }
      );
    }
  }
};

export default ServiceStoreBanner;
