import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
//import { createBrowserHistory } from 'history';
import indexRoutes from "./routes/index.jsx";
import SignIn from "./components/SignIn/SignIn"
import MemberOnlyRoute from "./routes/MemberOnlyRoute"

import "./assets/scss/style.css";

//const hist = createBrowserHistory();

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={SignIn} />
      <Route path="/sign-in" component={SignIn} />
      {indexRoutes.map((prop, key) => {
        return (
          <MemberOnlyRoute
            path={prop.path}
            key={key}
            component={prop.component}
          />
        );
      })}
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
