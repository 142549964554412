export default class CommonHelper {
  /**
   * 패스워드 규칙
   * @param email
   * @returns {boolean}
   */
  static emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  /**
   * 영문(대소문자구분X)/숫자/특수문자 -길이제한 : 국문기준 2자~20자
   * @param password
   * @returns {boolean}
   */
  static passwordRules(password) {
    return /^.*(?=^.{2,20}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/.test(
      password
    );
  }

  /**
   * 숫자 10~11자
   * @param phoneNumber
   * @returns {boolean}
   */
  static phoneNumberRules(phoneNumber) {
    return /^\d{3}-\d{3,4}-\d{4}$/.test(phoneNumber);
  }

  /**
   * 이름 2~10자(한글)
   * @param name
   * @returns {boolean}
   */
  static nameRule(name) {
    return /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]{2,10}$/.test(name);
  }

  /**
   * 영문/숫자 -길이제한 : 영문기준 3자~20자
   * @param  id
   */
  static idRule(id) {
    return /^[A-Za-z0-9]{3,20}/g.test(id);
  }

  /**
   * 한글은 2글자 영문은 4글자이상
   * @param nickName
   */
  static nickNameRule(nickName) {
    return /^[가-힣]{2,}|[a-zA-Z]{4,}$/.test(nickName);
  }

  /**
   * 핸드폰 규칙 (010-0000-0000)
   * @param phone
   * @returns {boolean}
   */
  static phoneRules(phone) {
    return /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/.test(
      phone
    );
  }
  /**
   * 인증 토큰 - AuthToken
   */
  static getAuthToken() {
    return window.localStorage.getItem("siganAdminToken");
  }
  /**
   * 인증 토큰 로컬 스토리지 저장
   * @param authToken
   * @param refreshToken
   */
  static setAuthenticateToken(authToken) {
    window.localStorage.setItem("siganAdminToken", authToken);
  }
  /**
   * 인증 토큰 삭제
   */
  static clearAuthenticateToken() {
    window.localStorage.removeItem("siganAdminToken");
  }
}

