import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import CommonHelper from "../../helpers/CommonHelper";
import RecommendKeyWord from "./admin-recommend-components/RecommendKeyWord";
import RecommendPopularBrand from "./admin-recommend-components/RecommendPopularBrand";
import {
  getAppSiteInfoApi,
  sendChangeKeywordsApi,
  sendChangeBrandApi,
} from "../../apis/appSite";
const RECOMMEND_MENU = ["추천 키워드", "추천 인기 브랜드"];

const RecommendSetting = () => {
  const [currMenu, setCurrMenu] = useState(RECOMMEND_MENU[0]);
  const [siteInfo, setSiteInfo] = useState({});

  const changeMenu = (e) => {
    setCurrMenu(e.target.value);
  };

  useEffect(() => {
    getAppSiteInfo();
  }, []);
  return (
    <>
      <Card>
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            {RECOMMEND_MENU.map((menu) => (
              <Button
                // style={
                //   menu === currMenu
                //     ? {
                //         border: "2px solid black",
                //         padding: "6px",
                //         cursor: "pointer",
                //       }
                //     : {
                //         border: "1px solid gray",
                //         padding: "6px",
                //         cursor: "pointer",
                //       }
                // }
                value={menu}
                color="info"
                onClick={(e) => changeMenu(e)}>
                {menu}
              </Button>
            ))}
          </div>
        </CardTitle>
        <CardBody>
          {currMenu === "추천 키워드" && (
            <RecommendKeyWord
              siteInfo={siteInfo}
              sendChangeKeywords={sendChangeKeywords}
            />
          )}
          {currMenu === "추천 인기 브랜드" && (
            <RecommendPopularBrand
              siteInfo={siteInfo}
              sendChangeBrand={sendChangeBrand}
            />
          )}
        </CardBody>
      </Card>
    </>
  );

  function getAppSiteInfo() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      getAppSiteInfoApi(authToken).then(
        ({ data: { status, detail, data } }) => {
          if (status === "SUCCESS") {
            setSiteInfo(data);
          } else {
            alert(detail);
          }
        }
      );
    }
  }

  function sendChangeKeywords(newKeywordsBox) {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      sendChangeKeywordsApi(authToken, newKeywordsBox).then(
        ({ data: { status, detail } }) => {
          if (status === "SUCCESS") {
            alert("성공적으로 수정되었습니다.");
            getAppSiteInfo();
          } else {
            alert(detail);
            getAppSiteInfo();
          }
        }
      );
    }
  }

  function sendChangeBrand(newBrandsBox) {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      sendChangeBrandApi(authToken, newBrandsBox).then(
        ({ data: { status, detail } }) => {
          if (status === "SUCCESS") {
            alert("성공적으로 수정되었습니다.");
            getAppSiteInfo();
          } else {
            alert(detail);
            getAppSiteInfo();
          }
        }
      );
    }
  }
};

export default RecommendSetting;
