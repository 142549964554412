import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";
import DEFAULT_IMAGE from "../../../assets/images/big/default-image.jpg";
import {
  SHOW_OR_NOT,
  DEFAULT_FILE_SIZE_LIMIT,
  DEVICE_TYPE_OPTION,
  POPUP_USE_OPTIONS,
  SHOW_OR_NOT_CHANGE,
} from "../../../common/constants";
import CommonHelper from "../../../helpers/CommonHelper";
import { SERVER_API_HOST } from "../../../common/environment";
import {
  createBannerApi,
  uploadFile,
  getBannerListApi,
  modifyOneBannerApi,
  deleteOneBannerApi,
} from "../../../apis/main.js";
const initBanner = {
  photoUrl: "",
  link: "",
  title: "",
  content: "",
  deviceType: "PC",
};

const PCBanner = () => {
  const [imgSrc, setImgSrc] = useState(null); //백엔드에 연결하기 전에 테스트src 둘 state
  const [showCreateBanner, setShowCreateBanner] = useState(false); //배너 생성 카드 여부
  const [showOneBanner, setShowOneBanner] = useState(false);
  const [newBannerInfo, setNewBannerInfo] = useState(initBanner);
  const [oneBannerDetail, setOneBannerDetail] = useState({});
  const [bannerList, setBannerList] = useState([]);
  const newBannerInputRef = useRef();
  const modifyBannerInputRef = useRef();

  const onClickUpdateNewBannerInput = () => {
    // 파일 입력 폼에 변화가 일어나는지 감시하는 이벤트 리스터 등록
    newBannerInputRef.current.addEventListener(
      "change",
      onChangeNewBannerInput,
      false
    );
    // 파일 입력 폼 열기
    newBannerInputRef.current.click();
  };

  const onClickUpdateModifyBannerInput = () => {
    // 파일 입력 폼에 변화가 일어나는지 감시하는 이벤트 리스터 등록
    modifyBannerInputRef.current.addEventListener(
      "change",
      onChangeModifyBannerInput,
      false
    );
    // 파일 입력 폼 열기
    modifyBannerInputRef.current.click();
  };

  const closeCreateBanner = () => {
    setShowCreateBanner(false);
  };

  const openModifyOneBanner = (oneBanner) => {
    setOneBannerDetail(oneBanner);
    setShowOneBanner(true);
  };

  const closeModifyOneBanner = () => {
    setShowOneBanner(false);
  };

  const openNewBanner = () => {
    if (bannerList.length < 4) {
      setShowCreateBanner(true);
    } else {
      alert("배너는 4개까지 생성할 수 있습니다.");
    }
  };

  const handleNewChange = (e) => {
    const { name, value } = e.target;
    const nextBannerInfo = {
      ...newBannerInfo,
      [name]: value,
    };
    setNewBannerInfo(nextBannerInfo);
  };

  const handleModifyChange = (e) => {
    const { name, value } = e.target;
    const nextBannerInfo = {
      ...oneBannerDetail,
      [name]: value,
    };
    setOneBannerDetail(nextBannerInfo);
  };

  useEffect(() => {
    getBannerList();
  }, []);

  return (
    <>
      {showCreateBanner && (
        <Card>
          <CardTitle className="bg-light border-bottom p-3 mb-0">
            <div className="space-between">
              배너 생성하기
              <button onClick={closeCreateBanner}>닫기</button>
            </div>
          </CardTitle>
          <CardBody>
            <Container>
              <Row>
                <Col sm="6">
                  <div onClick={onClickUpdateNewBannerInput}>
                    <CardImg
                      top
                      width="100%"
                      src={
                        newBannerInfo.photoUrl
                          ? newBannerInfo.photoUrl
                          : DEFAULT_IMAGE
                      }
                    />
                    <input
                      type="file"
                      accept="image/*"
                      ref={newBannerInputRef}
                      hidden
                    />
                  </div>
                  <CardBody>
                    <CardText>
                      <label>링크</label>
                      <input
                        name="link"
                        onChange={handleNewChange}
                        value={newBannerInfo.link || ""}
                        type="text"
                        placeholder="클릭 시 이동할 상품 상세페이지"
                      />
                    </CardText>
                    <CardText>
                      <label>제목</label>
                      <input
                        name="title"
                        onChange={handleNewChange}
                        value={newBannerInfo.title || ""}
                        type="text"
                        placeholder="배너 내용1"
                      />
                    </CardText>
                    <CardText>
                      <label>내용</label>
                      <input
                        name="content"
                        onChange={handleNewChange}
                        value={newBannerInfo.content || ""}
                        type="text"
                        placeholder="배너 내용2"
                      />
                    </CardText>
                    <CardText>
                      <label>기기타입</label>
                      <select
                        name="deviceType"
                        value={newBannerInfo.deviceType || "PC"}
                        autoblur="true"
                        onChange={handleNewChange}>
                        {DEVICE_TYPE_OPTION.map(({ label, value }, index) => (
                          <option key={index} value={value}>
                            {label}
                          </option>
                        ))}
                      </select>
                    </CardText>
                    <Button onClick={createBanner}>배너 추가하기</Button>
                  </CardBody>
                </Col>
              </Row>
            </Container>
          </CardBody>
        </Card>
      )}
      {showOneBanner && (
        <Card>
          <CardTitle className="bg-light border-bottom p-3 mb-0">
            <div className="space-between">
              배너 수정하기
              <button onClick={closeModifyOneBanner}>닫기</button>
            </div>
          </CardTitle>
          <CardBody>
            <Container>
              <Row>
                <Col sm="6">
                  <div onClick={onClickUpdateModifyBannerInput}>
                    <CardImg
                      top
                      width="100%"
                      src={
                        oneBannerDetail.photoUrl
                          ? oneBannerDetail.photoUrl
                          : DEFAULT_IMAGE
                      }
                    />
                    <input
                      type="file"
                      accept="image/*"
                      ref={modifyBannerInputRef}
                      hidden
                    />
                  </div>
                  <CardBody>
                    <CardText>
                      <label>링크</label>
                      <input
                        name="link"
                        onChange={handleModifyChange}
                        value={oneBannerDetail.link || ""}
                        type="text"
                        placeholder="클릭 시 이동할 상품 상세페이지"
                      />
                    </CardText>
                    <CardText>
                      <label>제목</label>
                      <input
                        name="title"
                        onChange={handleModifyChange}
                        value={oneBannerDetail.title || ""}
                        type="text"
                        placeholder="배너 내용1"
                      />
                    </CardText>
                    <CardText>
                      <label>내용</label>
                      <input
                        name="content"
                        onChange={handleModifyChange}
                        value={oneBannerDetail.content || ""}
                        type="text"
                        placeholder="배너 내용2"
                      />
                    </CardText>
                    <CardText>
                      <label>기기타입</label>
                      <select
                        name="deviceType"
                        value={oneBannerDetail.deviceType || "PC"}
                        autoblur="true"
                        onChange={handleModifyChange}>
                        {DEVICE_TYPE_OPTION.map(({ label, value }, index) => (
                          <option key={index} value={value}>
                            {label}
                          </option>
                        ))}
                      </select>
                    </CardText>
                    <Button onClick={modifyOneBanner}>수정하기</Button>
                    <Button onClick={deleteOneBanner}>삭제하기</Button>
                  </CardBody>
                </Col>
              </Row>
            </Container>
          </CardBody>
        </Card>
      )}
      <Card>
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          <div className="space-between">
            PC/MOBILE 배너 리스트
            <button onClick={openNewBanner}>배너 생성하기</button>
          </div>
        </CardTitle>
        <CardBody>
          <Container>
            <Row>리스트 목록</Row>
            <Row>
              {bannerList.map((banner) => (
                <Col xs="12" md="6">
                  <Card>
                    <div>
                      <CardImg
                        top
                        width="100%"
                        src={banner.photoUrl ? banner.photoUrl : DEFAULT_IMAGE}
                      />
                    </div>
                    <CardBody>
                      <CardText>
                        <label>링크(제품상세 링크)</label>
                        <div>{banner.link || "no link"}</div>
                      </CardText>
                      <CardText>
                        <label>내용 1</label>
                        <div>{banner.title || "no title"}</div>
                      </CardText>
                      <CardText>
                        <label>내용 2</label>
                        <div>{banner.content || "no content"}</div>
                      </CardText>
                      <CardText>
                        <label>기기타입</label>
                        <div>{banner.deviceType || "no deviceType"}</div>
                      </CardText>
                      <Button onClick={() => openModifyOneBanner(banner)}>
                        수정하기
                      </Button>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </CardBody>
      </Card>
    </>
  );

  async function onChangeNewBannerInput(event) {
    // 파일을 선택 한 경우에만 실행
    const file = event.target.files[0];
    // 여러번 등록되는 버그 수정 (https://stackoverflow.com/questions/39484895/how-to-allow-input-type-file-to-select-the-same-file-in-react-component)
    event.target.value = null;
    const authToken = CommonHelper.getAuthToken();
    // 파일 업로드
    let nextPhotoUrl = await uploadFileAndGetUrl(file);
    if (nextPhotoUrl) {
      // nextPhotoUrl = nextPhotoUrl.replace('http://localhost:8080', SERVER_API_HOST); // TODO 임시 코드
      nextPhotoUrl = `${SERVER_API_HOST}${nextPhotoUrl}`;
      const fakeInfo = { ...newBannerInfo };
      fakeInfo.photoUrl = nextPhotoUrl;
      setNewBannerInfo(fakeInfo);
    }
  }

  async function onChangeModifyBannerInput(event) {
    // 파일을 선택 한 경우에만 실행
    const file = event.target.files[0];
    // 여러번 등록되는 버그 수정 (https://stackoverflow.com/questions/39484895/how-to-allow-input-type-file-to-select-the-same-file-in-react-component)
    event.target.value = null;
    const authToken = CommonHelper.getAuthToken();
    // 파일 업로드
    let nextPhotoUrl = await uploadFileAndGetUrl(file);
    if (nextPhotoUrl) {
      // nextPhotoUrl = nextPhotoUrl.replace('http://localhost:8080', SERVER_API_HOST); // TODO 임시 코드
      nextPhotoUrl = `${SERVER_API_HOST}${nextPhotoUrl}`;
      const fakeInfo = { ...oneBannerDetail };
      fakeInfo.photoUrl = nextPhotoUrl;
      setOneBannerDetail(fakeInfo);
    }
  }

  // 파일 업로드 후 URL 반환
  async function uploadFileAndGetUrl(file) {
    if (file) {
      const { type: fileType, name: fileName, size: fileSize } = file;
      // 파일 데이터 만들기
      const formData = new FormData();
      formData.append("file", file);

      // 파일 업로드
      const uploadResult = await uploadFile(formData);
      const {
        status,
        data: { url },
      } = uploadResult;
      if (status === 200 && !!url) {
        return url;
      } else {
        alert("파일 업로드 실패");
        return null;
      }
    } else {
      return null;
    }
  }

  function getBannerList() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      getBannerListApi(authToken).then(({ data: { status, error, data } }) => {
        if (status === "SUCCESS") {
          setBannerList(data.content || []);
          console.log(data);
        } else {
          console.log(error);
          alert("문제가 생겼습니다.");
        }
      });
    }
  }

  function createBanner() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      createBannerApi(authToken, newBannerInfo).then(
        ({ data: { status, error, data } }) => {
          if (status === "SUCCESS") {
            alert("성공적으로 생성되었습니다.");
            setShowCreateBanner(false);
            setNewBannerInfo(initBanner);
            getBannerList();
          } else {
            console.log(error);
            alert("문제가 생겼습니다.");
          }
        }
      );
    }
  }

  function modifyOneBanner() {
    console.log(oneBannerDetail);
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      modifyOneBannerApi(authToken, oneBannerDetail).then(
        ({ data: { status, error, data } }) => {
          if (status === "SUCCESS") {
            alert("성공적으로 수정되었습니다.");
            setShowOneBanner(false);
            getBannerList();
          } else {
            console.log(error);
            alert("문제가 생겼습니다.");
          }
        }
      );
    }
  }

  function deleteOneBanner() {
    console.log(oneBannerDetail.id);
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      deleteOneBannerApi(authToken, oneBannerDetail).then(
        ({ data: { status, error } }) => {
          if (status === "SUCCESS") {
            alert("성공적으로 삭제되었습니다.");
            setShowOneBanner(false);
            getBannerList();
          } else {
            console.log(error);
            alert("문제가 생겼습니다.");
          }
        }
      );
    }
  }
};

export default PCBanner;
