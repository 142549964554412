import React, { useState, useEffect } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";
import { sendNewTypeListApi } from "../../../apis/modelDetail";
import CommonHelper from "../../../helpers/CommonHelper";

const TypesComponent = ({ typeList, setTypeList, getSiteInfo }) => {
  const deleteOneType = (type) => {
    const filterType = typeList.filter((el) => el !== type);
    setTypeList(filterType);
  };

  const handleTypesChange = (e) => {
    const fakeBox = [...typeList];
    const { name, value } = e.target;
    fakeBox[name] = value;
    setTypeList(fakeBox);
  };

  const addType = () => {
    const copyTypeList = [...typeList];
    copyTypeList.push("");
    setTypeList(copyTypeList);
  };
  return (
    <>
      <Card>
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          다이얼 타입 리스트 관리
        </CardTitle>
        <CardBody>
          <Container>
            <ul>
              {typeList.map((type, idx) => (
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px",
                  }}>
                  <label style={{ margin: "10px" }}>타입 {idx + 1}</label>
                  <input
                    style={{ margin: "0 10px" }}
                    placeholder={`타입 ${idx + 1}`}
                    name={idx}
                    value={type || null}
                    onChange={handleTypesChange}
                  />
                  <Button onClick={() => deleteOneType(type)}>x</Button>
                </Row>
              ))}
            </ul>
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "10px",
              }}>
              <Button style={{ margin: "0 10px" }} onClick={addType}>
                추가
              </Button>
            </Row>
            <Button onClick={sendNewTypeList}>저장</Button>
          </Container>
        </CardBody>
      </Card>
    </>
  );
  function sendNewTypeList() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      sendNewTypeListApi(typeList, authToken).then(
        ({ data: { status, detail } }) => {
          if (status === "SUCCESS") {
            alert("수정되었습니다.");
            getSiteInfo();
          } else {
            alert(detail);
          }
        }
      );
    }
  }
};

export default TypesComponent;
