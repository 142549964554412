import { apiCall } from "./apiCall";
import { CLIENT_URL, SERVER_API_HOST } from "../common/environment";
import CommonHelper from "helpers/CommonHelper";

export const getUserListApi = (authToken,searchBox, page) => {
  return apiCall(
    `${SERVER_API_HOST}/api/user/list?page=${page}`,
    // `${SERVER_API_HOST}/api/user/list?page=0&offset=5&name=관리자&nickName=admin&role=ADMIN&loginId=admin`,
    //페이지네이션 추가
    "get",
    searchBox,
    {
        authorization: authToken
    }
  )
}

export const getAuctionWaitListApi = (authToken, searchBox, page) => {
  return apiCall(
      `${SERVER_API_HOST}/api/auction/apply/list?page=${page}`,
    //페이지네이션 추가
    "get",
    searchBox,
    {
        authorization: authToken
    }
  )
}

export const agreeAuctionUpgradeApi = (authToken, applyId) => {
  return apiCall(
      `${SERVER_API_HOST}/api/auction/apply/${applyId}/AGREE`,
      'put',
      {},
      {
         authorization: authToken 
      },
  )
}

export const rejectAuctionUpgradeApi = (authToken, applyId) => {
 return apiCall(
      `${SERVER_API_HOST}/api/auction/apply/${applyId}/REJECT`,
      'put',
      {},
      {
        authorization: authToken
      }
  )
}