import React, { useState, useEffect } from "react";
import { Card, CardBody, CardTitle, Button } from "reactstrap";
import DiametersComponent from "./admin-model-detail-components/DiametersComponent";
import ColorsComponent from "./admin-model-detail-components/ColorsComponent";
import MaterialsComponent from "./admin-model-detail-components/MaterialsComponent";
import TypesComponent from "./admin-model-detail-components/TypesComponent";
import { getSiteInfoApi } from "../../apis/modelDetail";
const ADMIN_MENU = [
  "케이스 사이즈 관리",
  "케이스 소재 관리",
  "다이얼 컬러 관리",
  "다이얼 타입 관리",
];

const ModelDetailManage = () => {
  const [currMenu, setCurrMenu] = useState(ADMIN_MENU[0]);
  const [diameterList, setDiameterList] = useState([]); //caseDiameters
  const [materialList, setMaterialList] = useState([]); //caseMaterials
  const [colorList, setColorList] = useState([]); //dialColors
  const [typeList, setTypeList] = useState([]); //dialTypes
  const changeMenu = (e) => {
    setCurrMenu(e.target.value);
  };
  useEffect(() => {
    getSiteInfo();
  }, []);
  return (
    <>
      <Card>
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            {ADMIN_MENU.map((menu) => (
              <Button
                // style={
                //   menu === currMenu
                //     ? {
                //         border: "2px solid black",
                //         padding: "6px",
                //         cursor: "pointer",
                //       }
                //     : {
                //         border: "1px solid gray",
                //         padding: "6px",
                //         cursor: "pointer",
                //       }
                // }
                value={menu}
                color="info"
                onClick={(e) => changeMenu(e)}>
                {menu}
              </Button>
            ))}
          </div>
        </CardTitle>
        <CardBody>
          {currMenu === "케이스 사이즈 관리" && (
            <DiametersComponent
              diameterList={diameterList}
              setDiameterList={setDiameterList}
              getSiteInfo={getSiteInfo}
            />
          )}
          {currMenu === "케이스 소재 관리" && (
            <MaterialsComponent
              materialList={materialList}
              setMaterialList={setMaterialList}
              getSiteInfo={getSiteInfo}
            />
          )}
          {currMenu === "다이얼 컬러 관리" && (
            <ColorsComponent
              colorList={colorList}
              setColorList={setColorList}
              getSiteInfo={getSiteInfo}
            />
          )}
          {currMenu === "다이얼 타입 관리" && (
            <TypesComponent
              typeList={typeList}
              setTypeList={setTypeList}
              getSiteInfo={getSiteInfo}
            />
          )}
        </CardBody>
      </Card>
    </>
  );

  function getSiteInfo() {
    getSiteInfoApi().then(({ data: { data, detail, status } }) => {
      if (status === "SUCCESS") {
        setDiameterList(data?.caseDiameters || []);
        setMaterialList(data?.caseMaterials || []);
        setColorList(data?.dialColors || []);
        setTypeList(data?.dialTypes || []);
      } else {
        alert(detail);
      }
    });
  }
};

export default ModelDetailManage;
