import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Table,
  Input,
  Button,
} from "reactstrap";
import CommonHelper from "helpers/CommonHelper";
import {
  postSignUpApi,
  getAdminListApi,
  putUserDataUpdateApi,
} from "apis/adminAccount";
import PaginationComponent from "../../PaginationComponent";
const fontColor = {
  color: "black",
};
// 회원가입
const pStyle = {
  color: "red",
  fontSize: "12px",
};
const initSignupValue = {
  id: "",
  password: "",
  rePassword: "",
  name: "",
  email: "",
  phone: "",
  nickName: "",
};
const emptySearchBox = {
  name: "",
  nickName: "",
  loginId: "",
};

const AdminCreateAcount = () => {
  const history = useHistory();
  const [adminListData, setAdminListData] = useState({});
  const [searchBox, setSearchBox] = useState(emptySearchBox);
  const [isValid, setIsValid] = useState(false);
  const [modifyValid, setModifyValid] = useState(false);
  const [showUserDetail, setShowUserDetail] = useState(false);
  const [oneUserDetail, setOneUserDetail] = useState({});
  const [agreeValues, setAgreeValues] = useState({
    agreeMail: false,
    agreeSms: false,
  });
  const [signUpInputsValue, setSignUpInputsValue] = useState(initSignupValue);
  const {
    id,
    password,
    rePassword,
    name,
    phone,
    nickName,
    email,
  } = signUpInputsValue;
  const { agreeMail, agreeSms } = agreeValues;
  ////페이지네이션 추기
  const [page, setPage] = useState(0);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(4);
  const LIMIT = 10;

  const { totalPage } = adminListData;

  const pageNumbers = [];
  for (let i = 0; i < totalPage; i++) {
    pageNumbers.push(i + 1);
  }
  const newPageNumbers = pageNumbers.slice(start, end);

  const paginate = (number) => {
    const num = number === 0 ? number : number - 1;
    setPage(num);
  };

  const prevPage = () => {
    if (page + 1 === 1) {
      return alert("첫페이지입니다.");
    }
    if ((page + 1) % 4 === 1) {
      const s = start > 0 ? start - 4 : 0;
      const e = end > 0 ? end - 4 : end;

      setStart(s);
      setEnd(e);
    }
    setPage(page - 1);
  };

  const nextPage = () => {
    if (page + 2 > pageNumbers.length) {
      return alert("마지막페이지입니다.");
    } else {
      setPage(page + 1);
      if ((page + 2) % 4 === 1) {
        const s = start + 4;
        const e = end + 4;
        setStart(s);
        setEnd(e);
      }
    }
  };
  /////페이지네이션 끝

  const handleInputsValue = (e) => {
    const copySignUpInputsValue = { ...signUpInputsValue };
    const { name, value } = e.target;
    if (name === "phone") {
      let deleteAll = value.replaceAll("-", "").replaceAll(/[^0-9]/g, "");
      copySignUpInputsValue[name] = deleteAll;
    } else {
      copySignUpInputsValue[name] = value;
    }
    setSignUpInputsValue(copySignUpInputsValue);
  };

  const checkModifyValidation = () => {
    const authToken = CommonHelper.getAuthToken();
    if (
      CommonHelper.passwordRules(oneUserDetail.password) &&
      oneUserDetail.password === oneUserDetail.rePassword &&
      CommonHelper.nameRule(oneUserDetail.name) &&
      CommonHelper.emailIsValid(oneUserDetail.email) &&
      oneUserDetail.phone.length > 9 &&
      CommonHelper.nickNameRule(oneUserDetail.nickName)
    ) {
      putUserDataUpdateApi(
        oneUserDetail.id,
        oneUserDetail.password,
        oneUserDetail.name,
        oneUserDetail.email,
        oneUserDetail.phone,
        oneUserDetail.nickName,
        oneUserDetail.agreeMail,
        oneUserDetail.agreeSms,
        authToken
      ).then(({ data: { status, detail } }) => {
        if (status === "SUCCESS") {
          alert("수정되었습니다.");
          setModifyValid(false);
          getAdminList();
          //리ㅏ스트 부르기
        } else {
          alert(detail);
        }
      });
    } else {
      setModifyValid(true);
    }
  };

  const handleAgreeVelues = (e) => {
    const copyValues = { ...agreeValues };
    const { value, checked } = e.target;
    copyValues[value] = checked;
    setAgreeValues(copyValues);
  };

  const checkValidation = () => {
    const authToken = CommonHelper.getAuthToken();
    if (
      CommonHelper.idRule(id) &&
      CommonHelper.passwordRules(password) &&
      password === rePassword &&
      CommonHelper.nameRule(name) &&
      CommonHelper.emailIsValid(email) &&
      phone.length > 9 &&
      CommonHelper.nickNameRule(nickName)
    ) {
      postSignUpApi(
        id,
        password,
        email,
        nickName,
        phone,
        name,
        agreeMail,
        agreeSms,
        authToken
      ).then(({ data: { status, detail } }) => {
        if (status === "SUCCESS") {
          alert("회원가입이 완료되었습니다.");
          //  setShowNewAccount(false)
          setSignUpInputsValue(initSignupValue);
          setIsValid(false);
          setAgreeValues({ agreeMail: false, agreeSms: false });
          getAdminList();
          //리ㅏ스트 부르기
        } else {
          alert(detail);
        }
      });
    } else {
      setIsValid(true);
    }
  };
  ///////계정 리스트
  const handleSearchInput = (e) => {
    const { name, value } = e.target;
    const nextInputs = {
      ...searchBox,
      [name]: value,
    };
    setSearchBox(nextInputs);
  };

  useEffect(() => {
    getAdminList();
  }, [page]);
  const openOneDetail = (userInfo) => {
    setShowUserDetail(true);
    setOneUserDetail(userInfo);
  };

  /////수정

  const handleModifyCheckBox = (e) => {
    const copyValues = { ...oneUserDetail };
    const { value, checked } = e.target;
    copyValues[value] = checked;
    setOneUserDetail(copyValues);
  };

  const handleModifyInputs = (e) => {
    const copyOneUserDetail = { ...oneUserDetail };
    const { name, value } = e.target;
    if (name === "phone") {
      let deleteAll = value.replaceAll("-", "").replaceAll(/[^0-9]/g, "");
      copyOneUserDetail[name] = deleteAll;
    } else {
      copyOneUserDetail[name] = value;
    }
    setOneUserDetail(copyOneUserDetail);
  };

  const closeDetail = () => {
    setShowUserDetail(false);
  };
  return (
    <>
      <Card>
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          계정 생성
        </CardTitle>
        <CardBody>
          <div className="form-group">
            <label className="control-label" style={fontColor}>
              아이디
            </label>
            <div className="mb-2">
              <Input
                type="text"
                placeholder="아이디(3자 이상, 영문/숫자만)"
                name="id"
                onChange={(e) => handleInputsValue(e)}
                value={signUpInputsValue?.id || ""}
              />
              {isValid && !CommonHelper.idRule(id) ? (
                <p style={pStyle}>
                  아이디를 입력해주세요. (3자 이상, 영문/숫자만)
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="form-group">
            <label className="control-label" style={fontColor}>
              비밀번호
            </label>
            <div className="mb-2">
              <Input
                type="password"
                placeholder="비밀번호"
                name="password"
                onChange={(e) => handleInputsValue(e)}
                value={signUpInputsValue?.password || ""}
              />
              {isValid && !CommonHelper.passwordRules(password) ? (
                <p style={pStyle}>
                  비밀번호를 입력해주세요. (영문/숫자/특수문자를 포함해
                  2자~20자)
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="form-group">
            <label className="control-label" style={fontColor}>
              비밀번호 확인
            </label>
            <div className="mb-2">
              <Input
                type="password"
                placeholder="비밀번호 확인"
                name="rePassword"
                onChange={(e) => handleInputsValue(e)}
                value={signUpInputsValue?.rePassword || ""}
              />
              {isValid && password !== rePassword ? (
                <p style={pStyle}>비밀번호가 일치하지 않습니다.</p>
              ) : (
                ""
              )}
            </div>
          </div>
        </CardBody>
        <CardBody>
          <CardTitle>개인 정보</CardTitle>
          <div className="form-group">
            <label className="control-label" style={fontColor}>
              이름
            </label>
            <div className="mb-2">
              <Input
                type="text"
                placeholder="이름"
                name="name"
                onChange={(e) => handleInputsValue(e)}
                value={signUpInputsValue?.name || ""}
              />
              {isValid && !CommonHelper.nameRule(name) ? (
                <p style={pStyle}>이름은 국문으로 2자 이상 입력해주세요.</p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="form-group">
            <label className="control-label" style={fontColor}>
              이메일
            </label>
            <div className="mb-2">
              <Input
                type="text"
                placeholder="이메일"
                name="email"
                onChange={(e) => handleInputsValue(e)}
                value={signUpInputsValue?.email || ""}
              />
              {isValid && !CommonHelper.emailIsValid(email) ? (
                <p style={pStyle}>이메일 형식이 올바르지 않습니다.</p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="form-group">
            <label className="control-label" style={fontColor}>
              휴대폰 번호
            </label>
            <div className="mb-2">
              <Input
                type="text"
                placeholder="휴대폰 번호"
                name="phone"
                value={signUpInputsValue?.phone || ""}
                onChange={(e) => handleInputsValue(e)}
              />
              {isValid && phone.length < 10 ? (
                <p style={pStyle}>
                  핸드폰 번호는 - 없이 10~11자를 입력해주세요.
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="form-group">
            <label className="control-label" style={fontColor}>
              닉네임
            </label>
            <div className="mb-2">
              <Input
                type="text"
                placeholder="닉네임 (한글 2자, 영문 4자 이상)"
                name="nickName"
                onChange={(e) => handleInputsValue(e)}
                value={signUpInputsValue?.nickName || ""}
              />
              {isValid && !CommonHelper.nickNameRule(nickName) ? (
                <p style={pStyle}>
                  닉네임은 국문으로 2자 이상 혹은 영문으로 4자 이상
                  입력해주세요.
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
          <input
            type="checkbox"
            value="agreeMail"
            onChange={handleAgreeVelues}
          />
          <span>정보 메일을 받겠습니다.</span>
          <label>
            <input
              type="checkbox"
              value="agreeSms"
              onChange={handleAgreeVelues}
            />
            <span>휴대폰 문자메세지를 받겠습니다.</span>
          </label>
          <div classname="form-group">
            <Button className="btn_join" onClick={checkValidation}>
              회원가입
            </Button>
          </div>
        </CardBody>
      </Card>
      {showUserDetail && (
        <Card>
          <CardTitle className="bg-light border-bottom p-3 mb-0">
            <div className="space-between">
              계정 조회 / 수정
              <Button onClick={closeDetail}>닫기</Button>
            </div>
          </CardTitle>
          <CardBody>
            <div className="form-group">
              <label className="control-label" style={fontColor}>
                아이디
              </label>
              <div className="mb-2">
                <Input
                  type="text"
                  disabled
                  placeholder="아이디(3자 이상, 영문/숫자만)"
                  name="loginId"
                  onChange={(e) => handleModifyInputs(e)}
                  value={oneUserDetail?.loginId || ""}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label" style={fontColor}>
                비밀번호
              </label>
              <div className="mb-2">
                <Input
                  type="password"
                  placeholder="비밀번호"
                  name="password"
                  onChange={(e) => handleModifyInputs(e)}
                  value={oneUserDetail?.password || ""}
                />
                {modifyValid &&
                !CommonHelper.passwordRules(oneUserDetail.password) ? (
                  <p style={pStyle}>
                    비밀번호를 입력해주세요. (영문/숫자/특수문자를 포함해
                    2자~20자)
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="form-group">
              <label className="control-label" style={fontColor}>
                비밀번호 확인
              </label>
              <div className="mb-2">
                <Input
                  type="password"
                  placeholder="비밀번호 확인"
                  name="rePassword"
                  onChange={(e) => handleModifyInputs(e)}
                  value={oneUserDetail?.rePassword || ""}
                />
                {modifyValid &&
                oneUserDetail.password !== oneUserDetail.rePassword ? (
                  <p style={pStyle}>비밀번호가 일치하지 않습니다.</p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </CardBody>
          <CardBody>
            <CardTitle>개인 정보</CardTitle>
            <div className="form-group">
              <label className="control-label" style={fontColor}>
                이름
              </label>
              <div className="mb-2">
                <Input
                  type="text"
                  placeholder="이름"
                  name="name"
                  onChange={(e) => handleModifyInputs(e)}
                  value={oneUserDetail?.name || ""}
                />
                {modifyValid && !CommonHelper.nameRule(oneUserDetail.name) ? (
                  <p style={pStyle}>이름은 국문으로 2자 이상 입력해주세요.</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="form-group">
              <label className="control-label" style={fontColor}>
                이메일
              </label>
              <div className="mb-2">
                <Input
                  type="text"
                  placeholder="이메일"
                  name="email"
                  onChange={(e) => handleModifyInputs(e)}
                  value={oneUserDetail?.email || ""}
                />
                {modifyValid &&
                !CommonHelper.emailIsValid(oneUserDetail.email) ? (
                  <p style={pStyle}>이메일 형식이 올바르지 않습니다.</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="form-group">
              <label className="control-label" style={fontColor}>
                휴대폰 번호
              </label>
              <div className="mb-2">
                <Input
                  type="text"
                  placeholder="휴대폰 번호"
                  name="phone"
                  value={oneUserDetail?.phone || ""}
                  onChange={(e) => handleModifyInputs(e)}
                />
                {modifyValid && oneUserDetail.phone.length < 10 ? (
                  <p style={pStyle}>
                    핸드폰 번호는 - 없이 10~11자를 입력해주세요.
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="form-group">
              <label className="control-label" style={fontColor}>
                닉네임
              </label>
              <div className="mb-2">
                <Input
                  type="text"
                  placeholder="닉네임 (한글 2자, 영문 4자 이상)"
                  name="nickName"
                  onChange={(e) => handleModifyInputs(e)}
                  value={oneUserDetail?.nickName || ""}
                />
                {modifyValid &&
                !CommonHelper.nickNameRule(oneUserDetail.nickName) ? (
                  <p style={pStyle}>
                    닉네임은 국문으로 2자 이상 혹은 영문으로 4자 이상
                    입력해주세요.
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <input
              type="checkbox"
              value="agreeMail"
              onChange={handleModifyCheckBox}
              checked={oneUserDetail.agreeMail}
            />
            <span>정보 메일을 받겠습니다.</span>
            <label>
              <input
                type="checkbox"
                value="agreeSms"
                onChange={handleModifyCheckBox}
                checked={oneUserDetail.agreeSms}
              />
              <span>휴대폰 문자메세지를 받겠습니다.</span>
            </label>
            <div classname="form-group">
              <Button className="btn_join" onClick={checkModifyValidation}>
                수정하기
              </Button>
            </div>
          </CardBody>
        </Card>
      )}
      <Card>
        <CardBody>
          <CardTitle className="bg-light border-bottom p-3 mb-0 space-between">
            회원 리스트
            <div>
              <input
                type="text"
                placeholder="name검색"
                name="name"
                onChange={handleSearchInput}
              />
              <input
                type="text"
                placeholder="nickName검색"
                name="nickName"
                onChange={handleSearchInput}
              />
              <input
                type="text"
                placeholder="loginId 검색"
                name="loginId"
                onChange={handleSearchInput}
              />
              <Button onClick={getAdminList}>조회하기</Button>
            </div>
          </CardTitle>
          <Table className="no-wrap v-middle" responsive>
            <thead>
              <tr className="border-0">
                <th className="border-0">고유 id</th>
                <th className="border-0">login Id</th>
                <th className="border-0">name</th>
                <th className="border-0">nickName</th>
              </tr>
            </thead>
            <tbody>
              {adminListData?.content?.map((el, idx) => (
                <tr key={idx}>
                  <td>{el.id || "-"}</td>
                  <td>{el.loginId || "-"}</td>
                  <td>{el.name || "-"}</td>
                  <td>{el.nickName || "-"}</td>
                  <td>
                    <Button onClick={() => openOneDetail(el)}>상세보기</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <PaginationComponent
            prevPage={prevPage}
            paginate={paginate}
            nextPage={nextPage}
            newPageNumbers={newPageNumbers}
            page={page}
          />
        </CardBody>
      </Card>
    </>
  );
  function getAdminList() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      getAdminListApi(authToken, searchBox, page).then(
        ({ data: { data, status, detail } }) => {
          if (status === "SUCCESS") {
            setAdminListData(data);
          } else {
            alert(detail);
          }
        }
      );
    }
  }
};
export default AdminCreateAcount;
