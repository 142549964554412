import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Button,
  Input,
  Table,
} from "reactstrap";
import CommonHelper from "../../../helpers/CommonHelper";
import PaginationComponent from "../../PaginationComponent";
import useDebounce from "../../../utils/useDebounce";
import {
  getBrandListApi,
  searchWatchModelApi,
  registerNewProductApi,
  sendModifiedProductInfoApi,
  getModelListApi,
  deleteOneModelApi,
} from "../../../apis/product";
import { getSiteInfoApi } from "../../../apis/modelDetail"
const initNewProduct = {
  brand: "",
  model: "",
  serialNo: "",
  caseDiameter: "",
  caseMaterial: "",
  movement: "",
  dialColor: "",
  dialType: "",
  officialPrice: 0,
};
const ulStyle = {
  height: "100px",
  overflow: "scroll",
  border: "1px solid gainsboro",
  borderRadius: "3px",
};

const AddProductComponent = () => {
  ////생성
  const [newProductInfo, setNewProductInfo] = useState(initNewProduct);
  const [currentStoreList, setCurrentStoreList] = useState([]);
  const [showNewProductPopup, setShowNewProductPopup] = useState(false);
  const [searchAuctionStatus, setSearchAuctionStatus] = useState("");
  ////////조회 및 수정state /////////
  const [showModifyOneProduct, setShowModifyOneProduct] = useState(false);
  const [modifyProductInfo, setModifyProductInfo] = useState({});
  const [currentProductList, setCurrentProductList] = useState({});
  //브랜드 검색 및 선택
  const [brandList, setBrandList] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  ////모델 검색 및 선택
  const [modelInput, setModelInput] = useState("");
  const [resultModelArray, setResultModelArray] = useState([]);
  const [resultModelInfo, setResultModelInfo] = useState({});
  //////시계 항목
  const [dialColorList, setDialColorList] = useState([]);
  const [dialTypeList, setDialTypeList] = useState([]);
  const [caseMaterialList, setCaseMaterialsList] = useState([]);
  const [caseDiameterList, setCaseDiametersList] = useState([]);
  /////debounce
  // const debouncedSearchTerm = useDebounce(modelInput, 500);

  // useEffect(() => {
  //   !!showNewProductPopup && getBrandList();
  // }, [showNewProductPopup]);

  ////페이지네이션 시작
  const [page, setPage] = useState(0);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(4);
  const LIMIT = 10;
  useEffect(() => {
    searchWatchModel();
  }, [page]);

  useEffect(() => {
    getSiteInfo();
  }, []);

  const { totalPage } = resultModelInfo;

  const pageNumbers = [];
  for (let i = 0; i < totalPage; i++) {
    pageNumbers.push(i + 1);
  }
  const newPageNumbers = pageNumbers.slice(start, end);

  const paginate = (number) => {
    const num = number === 0 ? number : number - 1;
    setPage(num);
  };

  const prevPage = () => {
    if (page + 1 === 1) {
      return alert("첫페이지입니다.");
    }
    if ((page + 1) % 4 === 1) {
      const s = start > 0 ? start - 4 : 0;
      const e = end > 0 ? end - 4 : end;

      setStart(s);
      setEnd(e);
    }
    setPage(page - 1);
  };

  const nextPage = () => {
    if (page + 2 > pageNumbers.length) {
      return alert("마지막페이지입니다.");
    } else {
      setPage(page + 1);
      if ((page + 2) % 4 === 1) {
        const s = start + 4;
        const e = end + 4;
        setStart(s);
        setEnd(e);
      }
    }
  };

  const handleBrandChange = (e) => {
    setSelectedBrand(e.target.value);
  };

  const handleModelInput = (e) => {
    setModelInput(e.target.value);
  };

  /**
   * handleChange
   * @param {*} e
   */
  const handleNewProduct = (e) => {
    const { name, value } = e.target;
    let copyNewProductInfo = { ...newProductInfo };
    if (name === "officialPrice") {
      let deleteAll = value.toString()
        .replaceAll(" ", "")
        .replaceAll(",", "")
        .replaceAll(/[^0-9]/g, "");

      copyNewProductInfo[name] = Number(deleteAll);
    } else {
      copyNewProductInfo[name] = value;
    }
    setNewProductInfo(copyNewProductInfo);
  };

  const handleModifyProduct = (e) => {
    const { name, value } = e.target;
    let copyProductInfo = { ...modifyProductInfo };
    if (name === "officialPrice") {
      let deleteAll = value.toString()
        .replaceAll(" ", "")
        .replaceAll(",", "")
        .replaceAll(/[^0-9]/g, "");
      copyProductInfo[name] = Number(deleteAll);
    } else {
      copyProductInfo[name] = value;
    }
    setModifyProductInfo(copyProductInfo);
  };

  const openRegisterNewProduct = () => {
    setShowNewProductPopup(true);
  };
  /**
   *닫으면서 초기화
   */
  const closeRegisterNewProduct = () => {
    setShowNewProductPopup(false);
    setSelectedBrand(null);
    setNewProductInfo(initNewProduct);
  };

  /////////조회 및 수정 함수 시작
  const openModifyOneProduct = (oneProduct) => {
    setShowModifyOneProduct(true);
    setModifyProductInfo(oneProduct); //수정할 내용
  };

  const closeModifyProduct = () => {
    setShowModifyOneProduct(false);
  };

  return (
    <>
      {showNewProductPopup && (
        <Card>
          <CardTitle
            className="bg-light border-bottom p-3 mb-0"
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
            }}>
            브랜드/모델 추가
            <Button onClick={closeRegisterNewProduct}>닫기</Button>
          </CardTitle>
          <CardBody style={{ color: "black" }}>
            <Container>
              <Row style={{ marginTop: "40px" }}>
                <Col>
                  브랜드
                  <Input
                    onChange={handleNewProduct}
                    value={newProductInfo.brand || ""}
                    name="brand"
                  />
                </Col>
                <Col>
                  모델
                  <Input
                    onChange={handleNewProduct}
                    name="model"
                    value={newProductInfo.model || ""}
                  />
                </Col>
                <Col>
                  레퍼런스/serialNo
                  <Input
                    onChange={handleNewProduct}
                    value={newProductInfo.serialNo || ""}
                    name="serialNo"
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "40px" }}>
                <Col>
                  케이스 사이즈
                  {/* <Input
                    onChange={handleNewProduct}
                    value={newProductInfo.caseDiameter || ""}
                    name="caseDiameter"
                  /> */}
                  <select
                    value={newProductInfo.caseDiameter || ""}
                    name="caseDiameter"
                    className="form-control"
                    onChange={handleNewProduct}>
                    <option></option>
                    {caseDiameterList?.map((diameter, idx) => (
                      <option key={idx}>{diameter}</option>
                    ))}
                  </select>
                </Col>
                <Col>
                  구동방식
                  {/* <Input
                    onChange={handleNewProduct}
                    value={newProductInfo.movement || ""}
                    name="movement"
                  /> */}
                  <select
                    value={newProductInfo.movement || ""}
                    name="movement"
                    className="form-control"
                    onChange={handleNewProduct}>
                    <option></option>
                    <option>AUTOMATIC</option>
                    <option>PASSIVITY</option>
                    <option>QUARTZ</option>
                  </select>
                </Col>
                <Col>
                  케이스 소재
                  {/* <Input
                    onChange={handleNewProduct}
                    value={newProductInfo.caseMaterial || ""}
                    name="caseMaterial"
                  /> */}
                  <select
                    value={newProductInfo.caseMaterial || ""}
                    name="caseMaterial"
                    className="form-control"
                    onChange={handleNewProduct}>
                    <option></option>
                    {caseMaterialList?.map((material, idx) => (
                      <option key={idx}>{material}</option>
                    ))}
                  </select>
                </Col>
              </Row>
              <Row style={{ marginTop: "40px" }}>
                <Col>
                  다이얼 색상
                  {/* <Input
                    onChange={handleNewProduct}
                    value={newProductInfo.dialColor || ""}
                    name="dialColor"
                  /> */}
                  <select
                    className="form-control"
                    name="dialColor"
                    value={newProductInfo.dialColor || ""}
                    onChange={handleNewProduct}>
                    <option></option>
                    {dialColorList?.map((color, idx) => (
                      <option key={idx}>{color}</option>
                    ))}
                  </select>
                </Col>
                <Col>
                  다이얼 타입
                  <select
                    className="form-control"
                    name="dialType"
                    value={newProductInfo.dialType || ""}
                    onChange={handleNewProduct}>
                    <option></option>
                    {dialTypeList?.map((type, idx) => (
                      <option key={idx}>{type}</option>
                    ))}
                  </select>
                </Col>
                <Col>
                  gender
                  <select
                    className="form-control"
                    name="gender"
                    value={newProductInfo.gender || ""}
                    onChange={handleNewProduct}>
                    <option>MALE</option>
                    <option>FEMALE</option>
                    <option>UNISEX</option>
                  </select>
                </Col>
              </Row>
              <Row style={{ marginTop: "40px" }}>
                <Col>
                  RetailPrice
                  <Input
                    value={
                      newProductInfo.officialPrice
                        ? Number(newProductInfo.officialPrice).toLocaleString()
                        : ""
                    }
                    onChange={handleNewProduct}
                    name="officialPrice"
                  />
                </Col>
                <Col></Col>
                <Col></Col>
              </Row>
            </Container>
          </CardBody>
          <CardBody>
            <Container>
              <Row>
                <Col
                  style={{
                    marginTop: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <Button onClick={registerNewProduct}>모델 등록하기</Button>
                </Col>
              </Row>
            </Container>
          </CardBody>
        </Card>
      )}
      {showModifyOneProduct && (
        <Card>
          <CardTitle
            className="bg-light border-bottom p-3 mb-0"
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
            }}>
            브랜드/모델 수정
            <Button onClick={closeModifyProduct}>닫기</Button>
          </CardTitle>
          <CardBody style={{ color: "black" }}>
            <Container>
              <Row style={{ marginTop: "40px" }}>
                <Col>
                  해당 모델 사용중 Count
                  <Input
                    disabled
                    value={modifyProductInfo?.relationCount || "0"}
                  />
                  <p>
                    *count가 0이면, 해당 모델을 삭제하거나 브랜드, 모델을 수정할
                    수 있습니다.
                  </p>
                </Col>
                <Col>
                  id
                  <Input disabled value={modifyProductInfo?.id || "-"} />
                </Col>
                <Col>
                  RetailPrice
                  <Input
                    value={
                      modifyProductInfo?.officialPrice
                        ? Number(
                            modifyProductInfo?.officialPrice
                          ).toLocaleString()
                        : ""
                    }
                    onChange={handleModifyProduct}
                    name="officialPrice"
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "40px" }}>
                <Col>
                  브랜드
                  <Input
                    onChange={handleModifyProduct}
                    value={modifyProductInfo?.brand || "-"}
                    name="brand"
                    disabled={modifyProductInfo?.relationCount > 0}
                  />
                </Col>
                <Col>
                  모델
                  <Input
                    onChange={handleModifyProduct}
                    name="model"
                    value={modifyProductInfo?.model || ""}
                    disabled={modifyProductInfo?.relationCount > 0}
                  />
                </Col>
                <Col>
                  레퍼런스/serialNo
                  <Input
                    onChange={handleModifyProduct}
                    value={modifyProductInfo?.serialNo || ""}
                    name="serialNo"
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "40px" }}>
                <Col>
                  케이스 사이즈
                  {/* <Input
                    onChange={handleModifyProduct}
                    value={modifyProductInfo.caseDiameter || ""}
                    name="caseDiameter"
                  /> */}
                  <select
                    value={modifyProductInfo?.caseDiameter || ""}
                    name="caseDiameter"
                    className="form-control"
                    onChange={handleModifyProduct}>
                    <option></option>
                    {caseDiameterList?.map((diameter, idx) => (
                      <option key={idx}>{diameter}</option>
                    ))}
                  </select>
                </Col>
                <Col>
                  구동방식
                  {/* <Input
                    onChange={handleModifyProduct}
                    value={modifyProductInfo.movement || ""}
                    name="movement"
                  /> */}
                  <select
                    value={modifyProductInfo?.movement || ""}
                    name="movement"
                    className="form-control"
                    onChange={handleModifyProduct}>
                    <option></option>
                    <option>AUTOMATIC</option>
                    <option>PASSIVITY</option>
                    <option>QUARTZ</option>
                  </select>
                </Col>
                <Col>
                  케이스 소재
                  {/* <Input
                    onChange={handleModifyProduct}
                    value={modifyProductInfo.caseMaterial || ""}
                    name="caseMaterial"
                  /> */}
                  <select
                    value={modifyProductInfo?.caseMaterial || ""}
                    name="caseMaterial"
                    className="form-control"
                    onChange={handleModifyProduct}>
                    <option></option>
                    {caseMaterialList?.map((material, idx) => (
                      <option key={idx}>{material}</option>
                    ))}
                  </select>
                </Col>
              </Row>
              <Row style={{ marginTop: "40px" }}>
                <Col>
                  다이얼 색상
                  {/* <Input
                    onChange={handleModifyProduct}
                    value={modifyProductInfo.dialColor || ""}
                    name="dialColor"
                  /> */}
                  <select
                    className="form-control"
                    name="dialColor"
                    value={modifyProductInfo?.dialColor || ""}
                    onChange={handleModifyProduct}>
                    <option></option>
                    {dialColorList?.map((color, idx) => (
                      <option key={idx}>{color}</option>
                    ))}
                  </select>
                </Col>
                <Col>
                  다이얼 타입
                  <select
                    className="form-control"
                    name="dialType"
                    value={modifyProductInfo?.dialType || ""}
                    onChange={handleModifyProduct}>
                    <option></option>
                    {dialTypeList?.map((type, idx) => (
                      <option key={idx}>{type}</option>
                    ))}
                  </select>
                </Col>
                <Col>
                  gender
                  <select
                    className="form-control"
                    name="gender"
                    value={modifyProductInfo?.gender || ""}
                    onChange={handleModifyProduct}>
                    <option></option>
                    <option>MALE</option>
                    <option>FEMALE</option>
                    <option>UNISEX</option>
                  </select>
                </Col>
              </Row>
            </Container>
          </CardBody>
          <CardBody>
            <Container>
              <Row>
                <Col
                  style={{
                    marginTop: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <Button onClick={modifyOneModel} style={{ margin: "3px" }}>
                    수정하기
                  </Button>

                  <Button
                    disabled={modifyProductInfo.relationCount > 0}
                    onClick={deleteOneModel}
                    style={{ margin: "3px" }}>
                    삭제하기
                  </Button>
                </Col>
              </Row>
            </Container>
          </CardBody>
        </Card>
      )}
      <Card>
        <CardBody>
          <CardTitle className="bg-light border-bottom p-3 mb-0 space-between">
            모델 리스트
            <div>
              <input
                value={modelInput}
                onChange={(e) => setModelInput(e.target.value)}
                placeholder="모델명을 입력해주세요."
              />
              <Button style={{ margin: "0 10px" }} onClick={searchWatchModel}>
                모델 검색하기
              </Button>
              <Button
                style={{ margin: "0 10px" }}
                onClick={openRegisterNewProduct}>
                새 모델 등록
              </Button>
            </div>
          </CardTitle>
          <Table className="no-wrap v-middle" responsive>
            <thead>
              <tr className="border-0">
                <th className="border-0">고유 id</th>
                <th className="border-0">브랜드</th>
                <th className="border-0">모델</th>
                <th className="border-0">레퍼런스</th>
              </tr>
            </thead>
            <tbody>
              {resultModelArray.length > 0 &&
                resultModelArray.map((el, idx) => (
                  <tr key={idx}>
                    <td>{el.id ? el.id : "-"}</td>
                    <td>{el.brand ? el.brand : "-"}</td>
                    <td>{el.model || "-"}</td>
                    <td>{el.serialNo || "-"}</td>
                    <td>
                      <Button
                        onClick={() => openModifyOneProduct(el)}
                        style={{ marginRight: "10px" }}>
                        상세보기
                      </Button>
                    </td>
                  </tr>
                ))}
              <PaginationComponent
                prevPage={prevPage}
                paginate={paginate}
                nextPage={nextPage}
                newPageNumbers={newPageNumbers}
                page={page}
                lastPage={resultModelInfo.lastPage}
              />
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  );

  function getBrandList() {
    // getBrandListApi().then(({ data: { data, status, detail } }) => {
    //   if (status === "SUCCESS") {
    //     setSelectedBrand(data[0]?.brand || null);
    //     setBrandList(data || []);
    //     // console.log(data);
    //   }
    // });
  }

  function getSiteInfo() {
    getSiteInfoApi().then(({ data: { data, detail, status } }) => {
      if (status === "SUCCESS") {
        setDialColorList(data?.dialColors || []);
        setDialTypeList(data?.dialTypes || []);
        setCaseDiametersList(data?.caseDiameters || []);
        setCaseMaterialsList(data?.caseMaterials || []);
      } else {
        alert(detail);
      }
    });
  }

  function registerNewProduct() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      registerNewProductApi(authToken, newProductInfo).then(
        ({ data: { data, status } }) => {
          if (status === "SUCCESS") {
            alert("성공적으로 추가되었습니다.");
            setNewProductInfo(initNewProduct);
            searchWatchModel();
          }
        }
      );
    }
  }

  function searchWatchModel() {
    searchWatchModelApi(selectedBrand, modelInput, page).then(
      ({ data: { data, detail, status } }) => {
        if (status === "SUCCESS") {
          setResultModelArray(data.content || []);
          setResultModelInfo(data || {});
        } else {
          alert(detail);
        }
      }
    );
  }

  function modifyOneModel() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      sendModifiedProductInfoApi(modifyProductInfo, authToken).then(
        ({ data: { status, detail } }) => {
          if (status === "SUCCESS") {
            alert("성공적으로 수정되었습니다.");
            setShowModifyOneProduct(false);
            searchWatchModel();
          } else {
            alert(detail);
          }
        }
      );
    }
  }

  function deleteOneModel() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      deleteOneModelApi(authToken, modifyProductInfo).then(
        ({ data: { detail, status } }) => {
          if (status === "SUCCESS") {
            alert("성공적으로 삭제되었습니다.");
            setShowModifyOneProduct(false);
            searchWatchModel();
          } else {
            alert(detail);
          }
        }
      );
    }
  }
};
export default AddProductComponent;

// {/* <Card>
//   <CardTitle className="bg-light border-bottom p-3 mb-0 space-between">
//     상품 검색
//     <div>
//       {/* <select
//               style={{ marginRight: "20px" }}
//               value={searchAuctionStatus}
//               onChange={(e) => setSearchAuctionStatus(e.target.value)}>
//               <option></option>
//               <option>WAIT</option>
//               <option>RUN</option>
//               <option>DONE</option>
//             </select>
//             <Button style={{ marginRight: "20px" }}>조회하기</Button> */}
//       <Button onClick={openRegisterNewProduct}>상품 정보 등록하기</Button>
//     </div>
//   </CardTitle>
//   <CardBody>
//     <Container>
//       <CardTitle>시계 찾기</CardTitle>
//       <Row>
//         <Col>
//           브랜드 선택
//           <select
//             className="form-control"
//             value={selectedBrand}
//             onChange={handleBrandChange}>
//             {brandList.map((brandObject, idx) => (
//               <option value={brandObject.brand} key={idx}>
//                 {brandObject.brand || "-"} ({brandObject.count || 0})
//               </option>
//             ))}
//           </select>
//         </Col>
//         <Col>
//           모델 검색하기
//           <Input value={modelInput} onChange={handleModelInput} />
//         </Col>
//         <Col>
//           검색 결과
//           <ul style={ulStyle}>
//             {resultModelArray.length === 0 ? (
//               <li>검색 결과가 없습니다.</li>
//             ) : (
//               resultModelArray.map((model, idx) => (
//                 <li
//                   style={{ cursor: "pointer" }}
//                   onClick={() => openModifyOneProduct(model)}
//                   key={idx}>
//                   {model.model || "-"}
//                 </li>
//               ))
//             )}
//           </ul>
//         </Col>
//       </Row>
//     </Container>
//     {showModifyOneProduct && (
//       <Container>
//         <CardBody style={{ color: "black" }}>
//           <CardTitle
//             className="bg-light border-bottom p-3 mb-0"
//             style={{
//               display: "flex",
//               justifyContent: "space-between",
//               padding: "10px",
//             }}>
//             판매 상품 조회 및 수정
//             <Button onClick={closeModifyProduct}>닫기</Button>
//           </CardTitle>
//           <Container>
//             <Row style={{ marginTop: "40px" }}>
//               <Col>
//                 브랜드
//                 <Input
//                   onChange={handleModifyProduct}
//                   value={modifyProductInfo.brand || ""}
//                   name="brand"
//                 />
//               </Col>
//               <Col>
//                 모델
//                 <Input
//                   onChange={handleModifyProduct}
//                   name="model"
//                   value={modifyProductInfo.model || ""}
//                 />
//               </Col>
//               <Col>
//                 레퍼런스/serialNo
//                 <Input
//                   onChange={handleModifyProduct}
//                   value={modifyProductInfo.serialNo || ""}
//                   name="serialNo"
//                 />
//               </Col>
//             </Row>
//             <Row style={{ marginTop: "40px" }}>
//               <Col>
//                 케이스 사이즈
//                 <Input
//                   onChange={handleModifyProduct}
//                   value={modifyProductInfo.caseDiameter || ""}
//                   name="caseDiameter"
//                 />
//               </Col>
//               <Col>
//                 구동방식
//                 <select
//                   value={modifyProductInfo.movement || ""}
//                   name="movement"
//                   className="form-control"
//                   onChange={handleModifyProduct}>
//                   <option></option>
//                   <option>AUTOMATIC</option>
//                   <option>PASSIVITY</option>
//                 </select>
//               </Col>
//               <Col>
//                 케이스 소재
//                 {/* <Input
//                     onChange={handleModifyProduct}
//                     value={modifyProductInfo.caseMaterial || ""}
//                     name="caseMaterial"
//                   /> */}
//                 <select
//                   value={modifyProductInfo.caseMaterial || ""}
//                   name="caseMaterial"
//                   className="form-control"
//                   onChange={handleModifyProduct}>
//                   <option></option>
//                   <option>옐로우골드</option>
//                   <option>스틸</option>
//                 </select>
//               </Col>
//             </Row>
//             <Row style={{ marginTop: "40px" }}>
//               <Col>
//                 다이얼 색상
//                 {/* <Input
//                     onChange={handleModifyProduct}
//                     value={modifyProductInfo.dialColor || ""}
//                     name="dialColor"
//                   /> */}
//                 <select
//                   className="form-control"
//                   name="dialColor"
//                   value={modifyProductInfo.dialColor || ""}
//                   onChange={handleModifyProduct}>
//                   <option></option>
//                   <option>검정색</option>
//                   <option>갈색</option>
//                   <option>금색</option>
//                   <option>추가 색상</option>
//                 </select>
//               </Col>
//               <Col>
//                 다이얼 타입
//                 <select
//                   className="form-control"
//                   name="dialColor"
//                   value={modifyProductInfo.dialType || ""}
//                   onChange={handleModifyProduct}>
//                   <option></option>
//                   <option>바인덱스</option>
//                   <option>로마체</option>
//                   <option>아라비아 숫자</option>
//                   {/* <option>그래픽</option> */}
//                 </select>
//               </Col>
//               <Col>
//                 gender
//                 <select
//                   className="form-control"
//                   name="gender"
//                   value={modifyProductInfo.gender || ""}
//                   onChange={handleModifyProduct}>
//                   <option></option>
//                   <option>MALE</option>
//                   <option>FEMALE</option>
//                 </select>
//               </Col>
//             </Row>
//           </Container>
//         </CardBody>
//         <CardBody>
//           <Container>
//             <Row>
//               <Col
//                 style={{
//                   marginTop: "50px",
//                   display: "flex",
//                   justifyContent: "center",
//                   alignItems: "center",
//                 }}>
//                 <Button onClick={sendModifiedProductInfo}>상품 수정하기</Button>
//               </Col>
//             </Row>
//           </Container>
//         </CardBody>
//       </Container>
//     )}

//   </CardBody>
// </Card>; */}

//    <Table className="no-wrap v-middle" responsive>
//             <thead>
//               <tr className="border-0">
//                 <th className="border-0">고유 id</th>
//                 <th className="border-0">브랜드</th>
//                 <th className="border-0">모델</th>
//                 <th className="border-0">입찰 명수</th>
//                 <th className="border-0">시작날짜</th>
//                 <th className="border-0">마지막 입찰 시간</th>
//               </tr>
//             </thead>
//             <tbody>
//               {currentProductList?.content?.length > 0 &&
//                 currentProductList.content.map((auction, idx) => (
//                   <tr key={idx} onClick={() => openModifyOneProduct(auction)}>
//                     <td>{auction.id || "-"}</td>
//                     <td>{auction.watch.brand || "-"}</td>
//                     <td>{auction.watch.model || "-"}</td>
//                     <td>{auction.hitCount || "-"}</td>
//                     <td>{auction.startDate || "-"}</td>
//                     <td>{auction.updatedAt || "-"}</td>
//                   </tr>
//                 ))}
//             </tbody>
//           </Table>
//           <PaginationComponent
//             prevPage={prevPage}
//             paginate={paginate}
//             nextPage={nextPage}
//             newPageNumbers={newPageNumbers}
//             page={page}
//           />
