import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Table,
  Button,
} from "reactstrap";
import CommonHelper from "../../../helpers/CommonHelper";
import { getUserListApi } from "../../../apis/userList";
import PaginationComponent from "../../PaginationComponent";
const emptySearchBox = {
  name: "",
  nickName: "",
  loginId: "",
};

const UserList = () => {
  const history = useHistory();
  const [siganUserInfo, setSiganUserInfo] = useState({});
  const [showUserDetail, setShowUserDetail] = useState(false);
  const [oneUserDetail, setOneUserDetail] = useState({});
  const [searchBox, setSearchBox] = useState(emptySearchBox);

  ////페이지네이션 추기
  const [page, setPage] = useState(0);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(4);
  const LIMIT = 10;

  const { totalPage } = siganUserInfo;

  const pageNumbers = [];
  for (let i = 0; i < totalPage; i++) {
    pageNumbers.push(i + 1);
  }
  const newPageNumbers = pageNumbers.slice(start, end);

  const paginate = (number) => {
    const num = number === 0 ? number : number - 1;
    setPage(num);
  };

  const prevPage = () => {
    if (page + 1 === 1) {
      return alert("첫페이지입니다.");
    }
    if ((page + 1) % 4 === 1) {
      const s = start > 0 ? start - 4 : 0;
      const e = end > 0 ? end - 4 : end;

      setStart(s);
      setEnd(e);
    }
    setPage(page - 1);
  };

  const nextPage = () => {
    if (page + 2 > pageNumbers.length) {
      return alert("마지막페이지입니다.");
    } else {
      setPage(page + 1);
      if ((page + 2) % 4 === 1) {
        const s = start + 4;
        const e = end + 4;
        setStart(s);
        setEnd(e);
      }
    }
  };
  /////페이지네이션 끝

  useEffect(() => {
    getUserList();
  }, [page]);

  const openOneDetail = (userInfo) => {
    setShowUserDetail(true);
    setOneUserDetail(userInfo);
  };

  const closeDetail = () => {
    setShowUserDetail(false);
  };

  const handleSearchInput = (e) => {
    const { name, value } = e.target;
    const nextInputs = {
      ...searchBox,
      [name]: value,
    };
    setSearchBox(nextInputs);
  };

  return (
    <>
      {showUserDetail && (
        <Card>
          <CardBody>
            <CardTitle>
              <div className="space-between">
                회원상세
                <Button onClick={closeDetail}>닫기</Button>
              </div>
            </CardTitle>
            <Container>
              <Row>
                <div className="half-width">고유 id</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneUserDetail.id || "-"}
                </div>
              </Row>
              <Row>
                <div className="half-width">loginId</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneUserDetail.loginId || "-"}
                </div>
              </Row>
              <Row>
                <div className="half-width">name</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneUserDetail.name || "-"}
                </div>
              </Row>
              <Row>
                <div className="half-width">nickName</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneUserDetail.nickName || "-"}
                </div>
              </Row>
              <Row>
                <div className="half-width">phone</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneUserDetail.phone || "-"}
                </div>
              </Row>
              <Row>
                <div className="half-width">email</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneUserDetail.email || "-"}
                </div>
              </Row>
              <Row>
                <div className="half-width">roles</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneUserDetail.roles.length > 0
                    ? oneUserDetail.roles.map((el) => <div>{el}</div>)
                    : null}
                </div>
              </Row>
            </Container>
          </CardBody>
        </Card>
      )}
      <Card>
        <CardBody>
          <CardTitle className="bg-light border-bottom p-3 mb-0 space-between">
            회원 리스트
            <div>
              <input
                type="text"
                placeholder="name검색"
                name="name"
                onChange={handleSearchInput}
              />
              <input
                type="text"
                placeholder="nickName검색"
                name="nickName"
                onChange={handleSearchInput}
              />
              <input
                type="text"
                placeholder="loginId 검색"
                name="loginId"
                onChange={handleSearchInput}
              />
              <Button onClick={getUserList}>조회하기</Button>
            </div>
          </CardTitle>
          <Table className="no-wrap v-middle" responsive>
            <thead>
              <tr className="border-0">
                <th className="border-0">고유 id</th>
                <th className="border-0">login Id</th>
                <th className="border-0">name</th>
                <th className="border-0">nickName</th>
              </tr>
            </thead>
            <tbody>
              {siganUserInfo?.content?.map((el, idx) => (
                <tr key={idx}>
                  <td>{el.id || "-"}</td>
                  <td>{el.loginId || "-"}</td>
                  <td>{el.name || "-"}</td>
                  <td>{el.nickName || "-"}</td>
                  <td>
                    <Button onClick={() => openOneDetail(el)}>상세보기</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <PaginationComponent
            prevPage={prevPage}
            paginate={paginate}
            nextPage={nextPage}
            newPageNumbers={newPageNumbers}
            page={page}
          />
        </CardBody>
      </Card>
    </>
  );
  function getUserList() {
    const authToken = CommonHelper.getAuthToken();
    if (authToken !== null && authToken !== undefined) {
      getUserListApi(authToken, searchBox, page).then(
        ({ status, data: { status: backStatus, error, data } }) => {
          //  if(status === 403 || status === 401){
          //    CommonHelper.clearAuthToken();
          //    history.push('/sign-in')
          //  } else {
          if (backStatus === "SUCCESS") {
            setSiganUserInfo(data || {});
          } else {
            alert(error);
          }
          // }
        }
      );
    }
  }
};
export default UserList;

//판매 예약
//REGISTERED  -> APPROVED -> VISITED -> SALE_REGISTERED -> SOLD
//고객 등록     ->   승인     ->   방문   -> 판매 등록 -> 판매완료

//판매 예약(옥션)
//고객 등록 -> 승인 -> 방문 -> 옥션등록(WAIT, RUN, DONE)
