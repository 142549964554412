import React, { useState } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";
import PCPopup from "./admin-main-components/PCPopup";
import PCRolling from "./admin-main-components/PCRolling";
import PCBanner from "./admin-main-components/PCBanner";
import MobileBanner from "./admin-main-components/MobileBanner";
import InspectionSetting from "./admin-main-components/InspectionSetting";
import AppNowSetting from "./admin-main-components/AppNowSetting";
import StoreInfo from "./admin-main-components/StoreInfo";

const MAIN_MENU = [
  "점검 화면",
  "팝업 설정", // pc/mobile완료
  "PC 배너 설정", // pc/mobile완료
  "Mobile 배너 설정",
  "매장 정보 설정",
  "PC 롤링 설정",
  "App 실시간 롤링 설정",
  // "App 상단 배너 관리", //삭제하자
  // "지점 관리"
  // TODO : 지점 관리 추가하고 팝업, 배너 pc mobile나누지 않고 한 컴포넌트에서 핸들링하기
];

const MainSetting = () => {
  const [currMenu, setCurrMenu] = useState(MAIN_MENU[3]);
  // const [currMenu, setCurrMenu] = useState("매장 정보 설정");
  // const []

  const changeMenu = (e) => {
    setCurrMenu(e.target.value);
  };
  //   const getDeviceType = () => {
  //   const ua = navigator.userAgent;
  //   if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
  //     return "TABLET";
  //   }
  //   if (
  //     /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
  //       ua
  //     )
  //   ) {
  //     return "MOBILE";
  //   }
  //   return "PC";
  // };
  // console.log(getDeviceType())
  return (
    <>
      <Card>
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {MAIN_MENU.map((menu) => (
              <Button
                // style={
                //   menu === currMenu
                //     ? {
                //         border: "2px solid black",
                //         padding: "6px",
                //         cursor: "pointer",
                //       }
                //     : {
                //         border: "1px solid gray",
                //         padding: "6px",
                //         cursor: "pointer",
                //       }
                // }
                value={menu}
                color="info"
                onClick={(e) => changeMenu(e)}>
                {menu}
              </Button>
            ))}
          </div>
        </CardTitle>
        <CardBody>
          {currMenu === "점검 화면" && <InspectionSetting />}
          {currMenu === "팝업 설정" && <PCPopup />}
          {currMenu === "PC 배너 설정" && <PCBanner />}
          {currMenu === "Mobile 배너 설정" && <MobileBanner />}
          {currMenu === "매장 정보 설정" && <StoreInfo />}
          {currMenu === "PC 롤링 설정" && <PCRolling />}
          {currMenu === "App 실시간 롤링 설정" && <AppNowSetting />}
          {/* {currMenu === "App 상단 배너 관리" && <AppBanner />} */}
        </CardBody>
      </Card>
    </>
  );
};

export default MainSetting;
