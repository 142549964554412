import React, { useState, useEffect } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";
import { sendNewDiameterListApi } from "../../../apis/modelDetail";
import CommonHelper from '../../../helpers/CommonHelper'

const DiameterComponent = ({ diameterList, setDiameterList, getSiteInfo }) => {
  const deleteOneDiameter = (diameter) => {
    const filterDiameter = diameterList.filter((el) => el !== diameter);
    setDiameterList(filterDiameter);
  };

  const handleDiametersChange = (e) => {
    const fakeBox = [...diameterList];
    const { name, value } = e.target;
    fakeBox[name] = value;
    setDiameterList(fakeBox);
  };

  const addDiameter = () => {
    const copyDiameterList = [...diameterList];
    copyDiameterList.push("");
    setDiameterList(copyDiameterList);
  };
  return (
    <>
      <Card>
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          케이스 사이즈 리스트 관리
        </CardTitle>
        <CardBody>
          <Container>
            <ul>
              {diameterList.map((diameter, idx) => (
                // <li
                //   key={idx}
                //   style={{
                //     display: "flex",
                //     margin: "5px",
                //     alignItems: "center",
                //   }}>
                //   <li style={{ marginRight: "10px" }}>{diameter}</li>
                //   <Button>x</Button>
                // </li>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px",
                  }}>
                  <label style={{ margin: "10px" }}>사이즈 {idx + 1}</label>
                  <input
                    style={{ margin: "0 10px" }}
                    placeholder={`사이즈 ${idx + 1}`}
                    name={idx}
                    value={diameter || null}
                    onChange={handleDiametersChange}
                  />
                  <Button onClick={() => deleteOneDiameter(diameter)}>x</Button>
                </Row>
              ))}
            </ul>
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "10px",
              }}>
              <Button style={{ margin: "0 10px" }} onClick={addDiameter}>
                추가
              </Button>
            </Row>
            <Button onClick={sendNewDiemeterList}>저장</Button>
          </Container>
        </CardBody>
      </Card>
    </>
  );
  function sendNewDiemeterList() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      sendNewDiameterListApi(diameterList, authToken).then(
        ({ data: { status, detail } }) => {
          if (status === "SUCCESS") {
            alert("수정되었습니다.");
            getSiteInfo();
          } else {
            alert(detail);
          }
        }
      );
    }
  }
};

export default DiameterComponent;
