import React, { useState } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";
import ReservationBuy from "./admin-reservation-components/ReservationBuy";
import ReservationSell from "./admin-reservation-components/ReservationSell";
import ReservationFind from "./admin-reservation-components/ReservationFind";
const RESERVATION_MENU = ["구매예약", "판매예약", "찾아줘 리스트"];

const ReservationManage = () => {
  // const [currMenu, setCurrMenu] = useState(RESERVATION_MENU[0]);
  const [currMenu, setCurrMenu] = useState("판매예약");
  const changeMenu = (e) => {
    setCurrMenu(e.target.value);
  };
  return (
    <>
      <Card>
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            {RESERVATION_MENU.map((menu, idx) => (
              <Button
                key={idx}
                // style={
                //   menu === currMenu
                //     ? {
                //         border: "2px solid black",
                //         padding: "6px",
                //         cursor: "pointer",
                //       }
                //     : {
                //         border: "1px solid gray",
                //         padding: "6px",
                //         cursor: "pointer",
                //       }
                // }
                color="info"
                value={menu}
                onClick={(e) => changeMenu(e)}>
                {menu}
              </Button>
            ))}
          </div>
        </CardTitle>
        <CardBody>
          {currMenu === "구매예약" && <ReservationBuy />}
          {currMenu === "판매예약" && <ReservationSell />}
          {currMenu === "찾아줘 리스트" && <ReservationFind />}
        </CardBody>
      </Card>
    </>
  );
};

export default ReservationManage;
