import React, { useState, useEffect } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";
import CommonHelper from "../../../helpers/CommonHelper";
import { getAppSiteInfoApi, changeFaqKeywordsApi } from "../../../apis/appSite";

const FaqKeywords = () => {
  const [keywordsBox, setKeywordsBox] = useState([]);
  const [siteInfo, setSiteInfo] = useState();

  const changeKeywordsBox = () => {
    setKeywordsBox(siteInfo?.searchFaqKeywords || []);
  };

  useEffect(() => {
    siteInfo?.searchFaqKeywords && changeKeywordsBox();
  }, [siteInfo]);

  const deleteOneKeyword = (keyword) => {
    const filterKeywords = keywordsBox.filter((el) => el !== keyword);
    setKeywordsBox(filterKeywords);
  };

  const handleKeywordsChange = (e) => {
    const fakeBox = [...keywordsBox];
    const { name, value } = e.target;
    fakeBox[name] = value;
    setKeywordsBox(fakeBox);
  };

  const addKeyworkd = () => {
      const copyKeywords = [...keywordsBox];
      copyKeywords.push("");
      setKeywordsBox(copyKeywords);
  };

  useEffect(() => {
    getAppSiteInfo();
  }, []);

  return (
    <>
      <Card>
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          faq keywords 설정
        </CardTitle>
        <CardBody>
          <Container>
            {keywordsBox.map((keyword, idx) => (
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "10px",
                }}>
                <label style={{ margin: "10px" }}>키워드 {idx + 1}</label>
                <input
                  style={{ margin: "0 10px" }}
                  placeholder={`키워드 ${idx + 1}`}
                  name={idx}
                  value={keyword || null}
                  onChange={handleKeywordsChange}
                />
                <Button onClick={() => deleteOneKeyword(keyword)}>x</Button>
              </Row>
            ))}
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "10px",
              }}>
              <Button style={{ margin: "0 10px" }} onClick={addKeyworkd}>
                추가
              </Button>
            </Row>
            <Button onClick={() => changeFaqKeywords(keywordsBox)}>저장</Button>
          </Container>
        </CardBody>
      </Card>
    </>
  );

  function getAppSiteInfo() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      getAppSiteInfoApi(authToken).then(({ data: { status, detail, data } }) => {
        if (status === "SUCCESS") {
          setSiteInfo(data);
        } else {
          alert(detail);
        }
      });
    }
  }

  function changeFaqKeywords() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      changeFaqKeywordsApi(authToken, keywordsBox).then(({data : {status,detail}}) => {
        if(status === 'SUCCESS'){
            alert('저장되었습니다.')
        } else {
          alert(detail)
        }
      });
    }
  }
};

export default FaqKeywords;
