import React, { createRef, useCallback, useRef, useState } from "react";
import {
  Button,
  Input,
  Card,
  CardBody,
  CardTitle,
  Row
} from "reactstrap";
import styled,{css} from 'styled-components'
// import { commonStyle } from "../../../../assets/js/commonStyle";
import { useHistory } from "react-router-dom";
// import green from "@material-ui/core/colors/green";
// import { addNotice, updateSymbols } from "../../../../helpers/apiList";
import { Editor } from "@toast-ui/react-editor";
import "@toast-ui/editor/dist/toastui-editor.css";
import {
  decodingHtmlTags,
  encodingHtmlTags,
  replaceNewlineWith,
  replaceNewlineWithBrTag,
} from "../../../../helpers/stringHelpers";
import { createNewNoticeApi} from '../../../../apis/customer'
import CommonHelper from "../../../../helpers/CommonHelper";

const NoticeWrite = ({ setShowNewNoticeForm, getNoticeList }) => {
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("공지");
  //   const classes = commonStyle();
  const history = useHistory();
  const noticeEditor = useRef(null);
  return (
    <Card>
      <CardTitle className="bg-light border-bottom p-3 mb-0">
        {`뉴스&이벤트`} 작성
      </CardTitle>
      <CardBody maxWidth="xl">
        <Row>
          <label style={{ marginTop: 10, color: "black", fontWeight: 600 }}>
            제목 *
          </label>
          <Input
            autoFocus
            style={{ marginRight: 0 }}
            fullWidth
            type="text"
            placeholder="글 제목"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Row>
        <Row style={{ width: "100%" }}>
          <label style={{ marginTop: 10, color: "black", fontWeight: 600 }}>
            내용 *
          </label>
          <Wrapper>
            <Editor
              ref={noticeEditor}
              initialValue=""
              previewStyle="vertical"
              height="600px"
              initialEditType="wysiwyg"
              useCommandShortcut={true}
            />
          </Wrapper>
        </Row>
        <Row style={{ display: "flex", justifyContent: "space-between" }}>
          <Button onClick={() => onSave()}>등록하기</Button>
          <Button onClick={() => setShowNewNoticeForm(false)}>닫기</Button>
        </Row>
      </CardBody>
    </Card>
  );

  async function onSave() {
    const authToken = CommonHelper.getAuthToken();
    // const content = editorStateToHtml(noticeEditor.current.getEditorState());
    let content = noticeEditor.current.getInstance().getMarkdown();
    if (!title) {
      alert("제목을 입력해주세요.");
      return;
    }

    if (!content) {
      alert("내용을 입력해주세요.");
      return;
    }
    content = encodingHtmlTags(replaceNewlineWith(content, `<br/>`));

    const params = {
      title,
      content,
      //   category,
      //   attachFiles: [], // 일단 첨부파일은 공지사항에서 없는 것으로 함...
    };
    const response = await createNewNoticeApi(
      authToken,
      params.title,
      params.content
    );
    if (response.data.status === "SUCCESS") {
      alert(`성공적으로 생성되었습니다.`);
      getNoticeList();
      setShowNewNoticeForm(false)
    } else {
      alert(`서버 오류로 글 작성에 실패하였습니다.`);
    }
  }
};

export default NoticeWrite;

const Wrapper = styled.div`
  padding-bottom: 2.8rem;
  font-size: 1.3rem;
  font-family: "Lato";
  width:100%;
  label {
    display: block;
    margin-bottom: 1rem;
  }
  &.bordered {
    .editorWrapper {
      border-color: red;
    }
  }
  > div + p {
    width: 100%;
    padding-top: 12px;
    font-size: 1.2rem;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: -9px;
  }
`;