import React, { useState, useEffect } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";
import { getAppSiteInfoApi } from "../../../apis/appSite";
import { changeAppRollingApi } from "../../../apis/main";
import CommonHelper from "../../../helpers/CommonHelper";
import {
  AppRollingSettingOptions,
  APP_ROLLING_EN_KO,
  APP_ROLLING_KO_EN,
} from "../../../common/constants";

const AppNowSetting = ({ selected = "조회순" }) => {
  const [appRollingWay, setAppRollingWay] = useState(null);
  const handleChange = (e) => {
    if (e.target.id === "check-등록기준") {
      setAppRollingWay(APP_ROLLING_KO_EN[e.target.value]);
    }
  };
  useEffect(() => {
    getSiteInfo();
  }, []);
  return (
    <>
      <Card>
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          App 롤링 기준 설정하기(각 기준의 10개 브랜드)
        </CardTitle>
        <CardBody>
          <Container>
            <Row>
              <Col>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label>등록기준</label>
                  <select
                    name="등록기준"
                    id={`check-등록기준`}
                    value={APP_ROLLING_EN_KO[appRollingWay]} // selected로 변동될 수 있게 만들기
                    autoblur="true"
                    onChange={(e) => handleChange(e)}
                    // disabled={selectDisabled}>
                  >
                    {AppRollingSettingOptions.map(({ label, value }, index) => (
                      <option key={index} value={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "17px",
                }}
                onClick={() => changeAppRolling()}>
                바꾸기
              </Button>
            </Row>
          </Container>
        </CardBody>
      </Card>
    </>
  );

  function getSiteInfo() {
    getAppSiteInfoApi().then(({ data: { data, detail, status } }) => {
      if (status === "SUCCESS") {
        setAppRollingWay(data?.appRollingInfo?.standard || null);
      }
    });
  }

  function changeAppRolling() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      changeAppRollingApi(authToken, appRollingWay).then(
        ({ data: { data, status, detail } }) => {
          if (status === "SUCCESS") {
            alert("성공적으로 적용되었습니다.");
            getSiteInfo();
          } else {
            alert(detail);
          }
        }
      );
    }
  }
};

export default AppNowSetting;
