import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Button,
  Input,
  CardImg,
  Table,
} from "reactstrap";
import { Editor } from "@toast-ui/react-editor";
import styled from "styled-components";
import CommonHelper from "../../../helpers/CommonHelper";
import {
  getAuctionListApi,
  registerNewAuctionApi,
  sendModifyAuctionApi,
  searchWatchModelApi,
  getBrandListApi,
  getOneAuctionApi,
  deleteOneAuctionApi,
  changeAuctionStateApi,
} from "../../../apis/auction";
import PaginationComponent from "../../PaginationComponent";
import AuctionPeopleList from "./AuctionPeopleList";
import { getStoreListApi } from "../../../apis/main";
import { getSiteInfoApi } from "../../../apis/modelDetail";
import useDebounce from "../../../utils/useDebounce";
import DETAULT_IMAGE from "../../../assets/images/big/default-image.jpg";
import { uploadFile } from "../../../apis/uploadFile";
import { SERVER_API_HOST } from "../../../common/environment";
import AuctionModify from "./AuctionModify";
import { handleTime } from "../../../common/common";
import {
  decodingHtmlTags,
  encodingHtmlTags,
  replaceNewlineWith,
  replaceNewlineWithBrTag,
} from "../../../helpers/stringHelpers";
const ulStyle = {
  height: "100px",
  overflow: "scroll",
  border: "1px solid gainsboro",
  borderRadius: "3px",
};

const AuctionTotalList = () => {
  const newAuctionPhotoInputRef = useRef();
  const newAuctionDescription = useRef(null);
  const modifyAuctionDescription = useRef(null);
  const modifyAuctionInputRef = useRef();
  const [oneModel, setOneModel] = useState(null);
  const [currentStoreList, setCurrentStoreList] = useState([]);
  const [showRegisterAuctionForm, setShowRegisterAuctionForm] = useState(false);
  const [searchAuctionStatus, setSearchAuctionStatus] = useState("");
  const [oneDetailId, setOneDetailId] = useState(null);
  ////////조회 및 수정state /////////
  const [showModifyOneAuction, setShowModifyOneAuction] = useState(false);
  const [modifyAuctionInfo, setModifyAuctionInfo] = useState({});
  ///////옥션 생성 추가 정보 state//////////
  const [auctionStartPrice, setAuctionStartPrice] = useState("");
  const [auctionDescription, setAuctionDescription] = useState("");
  const [auctionStore, setAuctionStore] = useState([]);
  const [watchPhotos, setWatchPhotos] = useState([]);
  //브랜드 검색 및 선택
  const [brandList, setBrandList] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  ////모델 검색 및
  const [modelInput, setModelInput] = useState("");
  const [resultModelArray, setResultModelArray] = useState([]);

  const [currentAuctionInfo, setCurrentAuctionInfo] = useState({});
  //////시계 항목
  const [dialColorList, setDialColorList] = useState([]);
  const [dialTypeList, setDialTypeList] = useState([]);
  const [caseMaterialList, setCaseMaterialsList] = useState([]);
  const [caseDiameterList, setCaseDiametersList] = useState([]);
  ////옥션 입찰자 페이징, 입찰자 리스트
  const [auctionPeopleData, setAuctionPeopleData] = useState({});
  //옥션 상태 하나 수정할 id
  const [statusModifyId, setStatusModifyId] = useState();
  const [modifyStatus, setModifyStatus] = useState();

  /////debounce
  const debouncedSearchTerm = useDebounce(modelInput, 500);

  useEffect(() => {
    searchWatchModel();
  }, [debouncedSearchTerm, selectedBrand]);
  useEffect(() => {
    getStoreList();
    getSiteInfo();
  }, []);
  //////date picker 옥션 시작/옥션 종료
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  ////페이지네이션 시작
  const [page, setPage] = useState(0);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(4);
  const LIMIT = 10;

  const { totalPage } = currentAuctionInfo;

  const pageNumbers = [];
  for (let i = 0; i < totalPage; i++) {
    pageNumbers.push(i + 1);
  }
  const newPageNumbers = pageNumbers.slice(start, end);

  const paginate = (number) => {
    const num = number === 0 ? number : number - 1;
    setPage(num);
  };

  const prevPage = () => {
    if (page + 1 === 1) {
      return alert("첫페이지입니다.");
    }
    if ((page + 1) % 4 === 1) {
      const s = start > 0 ? start - 4 : 0;
      const e = end > 0 ? end - 4 : end;

      setStart(s);
      setEnd(e);
    }
    setPage(page - 1);
  };

  const nextPage = () => {
    if (page + 2 > pageNumbers.length) {
      return alert("마지막페이지입니다.");
    } else {
      setPage(page + 1);
      if ((page + 2) % 4 === 1) {
        const s = start + 4;
        const e = end + 4;
        setStart(s);
        setEnd(e);
      }
    }
  };

  const getAuctionList = () => {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      getAuctionListApi(authToken, page, searchAuctionStatus).then(
        ({ data: { data } }) => {
          setCurrentAuctionInfo(data || {});
        }
      );
    }
  };
  useEffect(() => {
    getAuctionList();
  }, [page]);

  /////페이지 네이션 관련 끝

  useEffect(() => {
    getBrandList();
  }, []);

  const handleBrandChange = (e) => {
    setSelectedBrand(e.target.value);
  };

  const handleModelInput = (e) => {
    setModelInput(e.target.value);
  };

  // const onClickUpdateNewAuctionInput = () => {
  //   // 파일 입력 폼에 변화가 일어나는지 감시하는 이벤트 리스터 등록
  //   newAuctionPhotoInputRef.current.addEventListener(
  //     "change",
  //     onChangeNewAuctionInput,
  //     false
  //   );
  //   // 파일 입력 폼 열기
  //   newAuctionPhotoInputRef.current.click();
  // };
  // console.log(oneModel);

  /**
   * handleChange
   * @param {*} e
   */
  const handleNewAuction = (e) => {
    const { name, value } = e.target;
    let copyOneModel = { ...oneModel };

    copyOneModel[name] = value;

    setOneModel(copyOneModel);
  };

  /**
   * 옥션생성-구성품 array에서 추가 혹은 삭제
   * @param {*} e
   */
  const handleNewAuctionSupplements = (e) => {
    const { name, checked } = e.target;
    let copyOneModel = { ...oneModel };
    if (checked) {
      copyOneModel.supplements.push(name);
    } else {
      copyOneModel.supplements = copyOneModel.supplements.filter(
        (el) => el !== name
      );
    }
    setOneModel(copyOneModel);
  };

  /**
   * 옥션생성 - 첨부파일 하나 삭제
   * @param {*} photoUrl
   */
  const deleteOnePhotoUrl = (photoUrl) => {
    let copyPhotos = [...watchPhotos];
    copyPhotos = copyPhotos.filter((url) => url !== photoUrl);
    setWatchPhotos(copyPhotos);
  };

  const onChangeNewAuctionInput = async (event) => {
    // 파일을 선택 한 경우에만 실행
    let file = event.target.files[0];
    // 여러번 등록되는 버그 수정 (https://stackoverflow.com/questions/39484895/how-to-allow-input-type-file-to-select-the-same-file-in-react-component)
    const authToken = CommonHelper.getAuthToken();
    // 파일 업로드
    let nextPhotoUrl = await uploadFileAndGetUrl(file);
    if (nextPhotoUrl) {
      // nextPhotoUrl = nextPhotoUrl.replace('http://localhost:8080', SERVER_API_HOST); // TODO 임시 코드
      nextPhotoUrl = `${SERVER_API_HOST}${nextPhotoUrl}`;
      let fakeArray = [...watchPhotos];
      fakeArray.push(nextPhotoUrl);
      setWatchPhotos(fakeArray);
    }
  };

  const openRegisterAuctionForm = () => {
    setShowRegisterAuctionForm(true);
  };
  /**
   *닫으면서 초기화
   */
  const closeRegisterAuctionForm = () => {
    setShowRegisterAuctionForm(false);
    setOneModel(null);
    setModelInput("");
    setResultModelArray([]);
  };

  useEffect(() => {
    oneDetailId && getOneAuction(oneDetailId);
  }, [oneDetailId]);

  /////////조회 및 수정 함수 시작
  const openModifyAuction = (oneAuction) => {
    setOneDetailId(oneAuction.id);
  };

  const closeModifyOneAuction = () => {
    setShowModifyOneAuction(false);
    setModifyAuctionInfo(null);
    setOneDetailId(null);
  };

  ////////수정쪽 onChange
  const handleModifyAuctionWatch = (e) => {
    const { name, value } = e.target;
    let copyModifyOneAuction = { ...modifyAuctionInfo };
    copyModifyOneAuction.watch[name] = value;
    setModifyAuctionInfo(copyModifyOneAuction);
  };
  const handleModifyAuction = (e) => {
    const { name, value } = e.target;
    let copyModifyOneAuction = { ...modifyAuctionInfo };
    if (name === "price") {
      let deleteAll = value
        .replaceAll(" ", "")
        .replaceAll(",", "")
        .replaceAll(/[^0-9]/g, "");
      copyModifyOneAuction[name] = deleteAll;
    } else {
      copyModifyOneAuction[name] = value;
    }
    setModifyAuctionInfo(copyModifyOneAuction);
  };

  /**
   * 옥션생성-구성품 array에서 추가 혹은 삭제
   * @param {*} e
   */
  const handleModifyAuctionSupplements = (e) => {
    const { name, checked } = e.target;
    let copyModifyOneAuction = { ...modifyAuctionInfo };
    if (checked) {
      copyModifyOneAuction.watch.supplements.push(name);
    } else {
      copyModifyOneAuction.watch.supplements =
        copyModifyOneAuction.watch.supplements.filter((el) => el !== name);
    }
    setOneModel(copyModifyOneAuction);
  };

  const handleModifyStoreInfo = (e, id) => {
    let copyModifyOneAuction = { ...modifyAuctionInfo };
    if (copyModifyOneAuction.storeIds.filter((el) => el === id).length > 0) {
      copyModifyOneAuction.storeIds = copyModifyOneAuction.storeIds.filter(
        (el) => el !== id
      );
    } else {
      copyModifyOneAuction.storeIds = [...copyModifyOneAuction.storeIds, id];
    }
    setModifyAuctionInfo(copyModifyOneAuction);
  };

  const onChangeModifyAuctionInput = async (event) => {
    // 파일을 선택 한 경우에만 실행
    let file = event.target.files[0];
    // 여러번 등록되는 버그 수정 (https://stackoverflow.com/questions/39484895/how-to-allow-input-type-file-to-select-the-same-file-in-react-component)
    const authToken = CommonHelper.getAuthToken();
    // 파일 업로드
    let nextPhotoUrl = await uploadFileAndGetUrl(file);
    if (nextPhotoUrl) {
      // nextPhotoUrl = nextPhotoUrl.replace('http://localhost:8080', SERVER_API_HOST); // TODO 임시 코드
      nextPhotoUrl = `${SERVER_API_HOST}${nextPhotoUrl}`;
      let fakeModifyInfo = { ...modifyAuctionInfo };
      fakeModifyInfo.attachedFiles.push(nextPhotoUrl);
      setModifyAuctionInfo(fakeModifyInfo);
    }
  };

  const deleteOnePhotoUrlModifyAuction = (photoUrl) => {
    let copyPhotos = [...watchPhotos];
    let copyModifyAuction = { ...modifyAuctionInfo };
    copyModifyAuction.attachedFiles = copyModifyAuction.attachedFiles.filter(
      (url) => url !== photoUrl
    );
    setModifyAuctionInfo(copyModifyAuction);
  };

  const handleAuctionStartPrice = (e) => {
    const { value } = e.target;
    let deleteAll = value
      .replaceAll(" ", "")
      .replaceAll(",", "")
      .replaceAll(/[^0-9]/g, "");
    setAuctionStartPrice(deleteAll);
  };

  const handleStoreChange = (id) => {
    let copyAuctionStore = [...auctionStore];
    if (auctionStore.filter((el) => el === id).length > 0) {
      copyAuctionStore = auctionStore.filter((el) => el !== id);
    } else {
      copyAuctionStore.push(id);
    }
    setAuctionStore(copyAuctionStore);
  };

  const changeStatusModifyId = (auction) => {
    setStatusModifyId(auction.id);
    setModifyStatus(auction.auctionStatus);
  };

  const changeOneStatus = (e) => {
    const { value } = e.target;
    setModifyStatus(value);
  };

  return (
    <>
      {showRegisterAuctionForm && (
        <Card>
          <CardTitle
            className="bg-light border-bottom p-3 mb-0"
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
            }}>
            등록 폼 테스트
            <Button onClick={closeRegisterAuctionForm}>닫기</Button>
          </CardTitle>
          <CardBody>
            <Container>
              <CardTitle>시계 찾기</CardTitle>
              <Row>
                <Col>
                  브랜드 선택
                  <select
                    className="form-control"
                    value={selectedBrand}
                    onChange={handleBrandChange}>
                    {brandList.map((brandObject, idx) => (
                      <option value={brandObject.brand} key={idx}>
                        {brandObject.brand || "-"} ({brandObject.count || 0})
                      </option>
                    ))}
                  </select>
                </Col>
                <Col>
                  모델 검색하기
                  <Input value={modelInput} onChange={handleModelInput} />
                </Col>
                <Col>
                  검색 결과
                  <ul style={ulStyle}>
                    {resultModelArray.length === 0 ? (
                      <li>검색 결과가 없습니다.</li>
                    ) : (
                      resultModelArray.map((model, idx) => (
                        <li
                          style={{ cursor: "pointer" }}
                          onClick={() => setOneModel(model)}
                          key={idx}>
                          {model.model || "-"}
                        </li>
                      ))
                    )}
                  </ul>
                </Col>
              </Row>
            </Container>
          </CardBody>
          {oneModel && (
            <>
              <CardBody>
                <Container>
                  <CardTitle>드로우 등록 시계 정보</CardTitle>
                  <Row style={{ marginTop: "40px" }}>
                    <Col>
                      브랜드
                      <Input disabled value={oneModel?.brand || ""} />
                    </Col>
                    <Col>
                      모델
                      <Input disabled value={oneModel?.model || ""} />
                    </Col>
                    <Col>
                      레퍼런스/serialNo
                      <Input
                        onChange={handleNewAuction}
                        value={oneModel?.serialNo || ""}
                        name="serialNo"
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "40px" }}>
                    <Col>
                      케이스 사이즈
                      <select
                        value={oneModel?.caseDiameter || ""}
                        name="caseDiameter"
                        className="form-control"
                        onChange={handleNewAuction}>
                        <option></option>
                        {caseDiameterList?.map((diameter, idx) => (
                          <option key={idx}>{diameter}</option>
                        ))}
                      </select>
                    </Col>
                    <Col>
                      구동방식
                      <select
                        value={oneModel?.movement || ""}
                        name="movement"
                        className="form-control"
                        onChange={handleNewAuction}>
                        <option></option>
                        <option>AUTOMATIC</option>
                        <option>PASSIVITY</option>
                        <option>QUARTZ</option>
                      </select>
                    </Col>
                    <Col>
                      생산 연도
                      <Input
                        onChange={handleNewAuction}
                        type="number"
                        value={oneModel?.producedYear || ""}
                        name="producedYear"
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "40px" }}>
                    <Col>
                      구성품
                      <div>
                        {/* {oneModel.supplements?.map((supplement) => (
                        <div className="custom-radio custom-control">
                          <Input
                            type="radio"
                            className="custom-control-input"
                            name="supplement"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="supplement">
                            {supplement}
                            <div>X</div>
                          </label>
                        </div>
                      )) || "-"}
                      <div className="custom-radio custom-control">
                        <Input />
                        <Button>추가하기</Button>
                      </div> */}
                        <div>
                          <input
                            type="checkbox"
                            onChange={handleNewAuctionSupplements}
                            name="SIGAN_GUARANTEE"
                            checked={oneModel?.supplements?.includes(
                              "SIGAN_GUARANTEE"
                            )}
                          />
                          <label htmlFor="supplement">하이시간보증서</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            onChange={handleNewAuctionSupplements}
                            name="GUARANTEE"
                            checked={oneModel?.supplements?.includes(
                              "GUARANTEE"
                            )}
                          />
                          <label htmlFor="supplement">보증서</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            name="BOX"
                            onChange={handleNewAuctionSupplements}
                            checked={oneModel?.supplements?.includes("BOX")}
                          />
                          <label htmlFor="supplement">박스</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            name="BOOKLET"
                            onChange={handleNewAuctionSupplements}
                            checked={oneModel?.supplements?.includes("BOOKLET")}
                          />
                          <label htmlFor="supplement">북렛</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            name="MEDAL"
                            onChange={handleNewAuctionSupplements}
                            checked={oneModel?.supplements?.includes("MEDAL")}
                          />
                          <label htmlFor="supplement">Medal</label>
                        </div>
                      </div>
                    </Col>
                    <Col>
                      성별
                      <select
                        className="form-control"
                        name="gender"
                        value={oneModel?.gender || ""}
                        onChange={handleNewAuction}>
                        <option>MALE</option>
                        <option>FEMALE</option>
                        <option>UNISEX</option>
                      </select>
                    </Col>
                    <Col>
                      케이스 소재
                      {/* <Input
                        onChange={handleNewAuction}
                        value={oneModel.caseMaterial || ""}
                        name="caseMaterial"
                      /> */}
                      <select
                        value={oneModel?.caseMaterial || ""}
                        name="caseMaterial"
                        className="form-control"
                        onChange={handleNewAuction}>
                        <option></option>
                        {caseMaterialList?.map((material, idx) => (
                          <option key={idx}>{material}</option>
                        ))}
                      </select>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "40px" }}>
                    <Col>
                      다이얼 색상
                      <select
                        className="form-control"
                        name="dialColor"
                        value={oneModel.dialColor || ""}
                        onChange={handleNewAuction}>
                        <option></option>
                        {dialColorList?.map((color, idx) => (
                          <option key={idx}>{color}</option>
                        ))}
                      </select>
                    </Col>
                    <Col>
                      다이얼 타입
                      <select
                        className="form-control"
                        name="dialType"
                        value={oneModel?.dialType || ""}
                        onChange={handleNewAuction}>
                        <option></option>
                        {dialTypeList?.map((type, idx) => (
                          <option key={idx}>{type}</option>
                        ))}
                      </select>
                    </Col>
                    <Col>
                      컨디션 {oneModel?.condition || 0}
                      <Input
                        type="range"
                        onChange={handleNewAuction}
                        value={oneModel?.condition || 0}
                        name="condition"
                        min="0"
                        max="10"
                        step="0.5"
                      />
                    </Col>
                  </Row>
                </Container>
              </CardBody>
              <CardBody>
                <Container>
                  <CardTitle>드로우 정보</CardTitle>
                  <Row style={{ marginTop: "40px" }}>
                    <Col>
                      드로우 시작 시간 : &nbsp;
                      <input
                        type="datetime-local"
                        className="form=control"
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </Col>
                    <Col>
                      드로우 종료 시간 : &nbsp;
                      <input
                        type="datetime-local"
                        className="form=control"
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "40px" }}>
                    <Col>
                      드로우 제품 가격
                      <Input
                        placeholder="드로우 제품 가격"
                        type="text"
                        value={
                          auctionStartPrice
                            ? Number(auctionStartPrice).toLocaleString()
                            : ""
                        }
                        onChange={handleAuctionStartPrice}
                      />
                    </Col>
                    <Col>
                      드로우 신청 링크
                      <Input
                        placeholder="드로우 신청 링크"
                        type="text"
                        value={auctionDescription}
                        onChange={(e) => setAuctionDescription(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "40px" }}>
                    <Col>
                      <div>보유지점</div>
                      {currentStoreList.map((store, idx) => (
                        <div
                          key={idx}
                          // onClick={() => setAuctionStore(store.id)}
                        >
                          <input
                            type="checkbox"
                            onChange={() => handleStoreChange(store.id)}
                            // disabled
                            name={store?.name}
                            checked={
                              auctionStore?.filter((el) => el === store.id)
                                .length > 0
                            }
                          />
                          <label htmlFor="supplement">{store?.name}</label>
                        </div>
                      ))}
                    </Col>
                  </Row>

                  <Row style={{ marginTop: "40px" }}>
                    <Col>
                      사진첨부
                      {/* <div onClick={onClickUpdateNewAuctionInput}> */}
                      {/* <CardImg style={{ width: "50%" }} src={DETAULT_IMAGE} /> */}
                      <input
                        type="file"
                        accept="image/*"
                        ref={newAuctionPhotoInputRef}
                        key={watchPhotos}
                        onChange={onChangeNewAuctionInput}
                        // hidden
                      />
                      {/* </div> */}
                    </Col>
                    <Col>
                      {watchPhotos?.map((photo) => (
                        <>
                          <CardImg style={{ width: "40%" }} src={photo} />
                          <button onClick={() => deleteOnePhotoUrl(photo)}>
                            X
                          </button>
                        </>
                      ))}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "40px" }}>
                    <label>상세 정보(사진 및 추가 설명)</label>
                    <Wrapper>
                      <Editor
                        ref={newAuctionDescription}
                        initialValue=""
                        previewStyle="vertical"
                        height="600px"
                        initialEditType="wysiwyg"
                        useCommandShortcut={true}
                      />
                    </Wrapper>
                  </Row>
                  <Row>
                    <Col
                      style={{
                        marginTop: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      <Button onClick={registerNewAuction}>
                        드로우 등록하기
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </CardBody>
            </>
          )}
        </Card>
      )}
      {showModifyOneAuction && (
        <Card>
          <CardBody>
            <CardTitle
              className="bg-light border-bottom p-3 mb-0"
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px",
              }}>
              고유Id {modifyAuctionInfo?.id || "-"} 드로우 정보 및 수정하기
              <Button onClick={closeModifyOneAuction}>닫기</Button>
            </CardTitle>
            <Container>
              <Row style={{ marginTop: "40px" }}>
                <Col>
                  브랜드
                  <Input
                    disabled
                    value={modifyAuctionInfo?.watch?.brand || "-"}
                  />
                </Col>
                <Col>
                  모델
                  <Input
                    disabled
                    value={modifyAuctionInfo?.watch?.model || "-"}
                  />
                </Col>
                <Col>
                  레퍼런스/serialNo
                  <Input
                    onChange={handleModifyAuctionWatch}
                    value={modifyAuctionInfo?.watch?.serialNo || "-"}
                    name="serialNo"
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "40px" }}>
                <Col>
                  케이스 사이즈 (mm)
                  <select
                    value={modifyAuctionInfo?.watch?.caseDiameter || ""}
                    name="caseDiameter"
                    className="form-control"
                    onChange={handleModifyAuctionWatch}>
                    <option></option>
                    {caseDiameterList?.map((diameter, idx) => (
                      <option key={idx}>{diameter}</option>
                    ))}
                  </select>
                </Col>
                <Col>
                  구동방식 (자동/수동)
                  <select
                    value={modifyAuctionInfo?.watch?.movement || ""}
                    name="movement"
                    className="form-control"
                    onChange={handleModifyAuctionWatch}>
                    <option></option>
                    <option>AUTOMATIC</option>
                    <option>PASSIVITY</option>
                    <option>QUARTZ</option>
                  </select>
                </Col>
                <Col>
                  생산 연도
                  <Input
                    onChange={handleModifyAuctionWatch}
                    type="number"
                    value={modifyAuctionInfo?.watch?.producedYear || "-"}
                    name="producedYear"
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "40px" }}>
                <Col>
                  구성품
                  <div>
                    {/* {oneModel.supplements?.map((supplement) => (
                        <div className="custom-radio custom-control">
                          <Input
                            type="radio"
                            className="custom-control-input"
                            name="supplement"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="supplement">
                            {supplement}
                            <div>X</div>
                          </label>
                        </div>
                      )) || "-"}
                      <div className="custom-radio custom-control">
                        <Input />
                        <Button>추가하기</Button>
                      </div> */}
                    <div>
                      <input
                        type="checkbox"
                        onChange={handleModifyAuctionSupplements}
                        name="SIGAN_GUARANTEE"
                        checked={modifyAuctionInfo?.watch?.supplements?.includes(
                          "SIGAN_GUARANTEE"
                        )}
                      />
                      <label htmlFor="supplement">하이시간보증서</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        onChange={handleModifyAuctionSupplements}
                        name="GUARANTEE"
                        checked={modifyAuctionInfo?.watch?.supplements?.includes(
                          "GUARANTEE"
                        )}
                      />
                      <label htmlFor="supplement">보증서</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        name="BOX"
                        onChange={handleModifyAuctionSupplements}
                        checked={modifyAuctionInfo?.watch?.supplements?.includes(
                          "BOX"
                        )}
                      />
                      <label htmlFor="supplement">박스</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        name="BOOKLET"
                        onChange={handleModifyAuctionSupplements}
                        checked={modifyAuctionInfo?.watch?.supplements?.includes(
                          "BOOKLET"
                        )}
                      />
                      <label htmlFor="supplement">북렛</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        name="MEDAL"
                        onChange={handleModifyAuctionSupplements}
                        checked={modifyAuctionInfo?.watch?.supplements?.includes(
                          "MEDAL"
                        )}
                      />
                      <label htmlFor="supplement">Medal</label>
                    </div>
                  </div>
                </Col>
                <Col>
                  성별
                  <select
                    className="form-control"
                    name="gender"
                    value={modifyAuctionInfo?.watch?.gender || ""}
                    onChange={handleModifyAuctionWatch}>
                    <option>MALE</option>
                    <option>FEMALE</option>
                    <option>UNISEX</option>
                  </select>
                </Col>
                <Col>
                  케이스 소재
                  {/* <Input
                        onChange={handleNewAuction}
                        value={modifyAuctionInfo?.watch?.caseMaterial || ""}
                        name="caseMaterial"
                      /> */}
                  <select
                    className="form-control"
                    name="caseMaterial"
                    value={modifyAuctionInfo?.watch?.caseMaterial || ""}
                    onChange={handleModifyAuctionWatch}>
                    <option></option>
                    {caseMaterialList?.map((material, idx) => (
                      <option key={idx}>{material}</option>
                    ))}
                  </select>
                </Col>
              </Row>
              <Row style={{ marginTop: "40px" }}>
                <Col>
                  다이얼 색상
                  <select
                    className="form-control"
                    name="dialColor"
                    value={modifyAuctionInfo?.watch?.dialColor || ""}
                    onChange={handleModifyAuctionWatch}>
                    <option></option>
                    {dialColorList?.map((color, idx) => (
                      <option key={idx}>{color}</option>
                    ))}
                  </select>
                </Col>
                <Col>
                  다이얼 타입
                  <select
                    className="form-control"
                    name="dialType"
                    value={modifyAuctionInfo?.watch?.dialType || ""}
                    onChange={handleModifyAuctionWatch}>
                    <option></option>
                    {dialTypeList?.map((type, idx) => (
                      <option key={idx}>{type}</option>
                    ))}
                  </select>
                </Col>
                <Col>
                  컨디션 {modifyAuctionInfo?.watch?.condition || 0}
                  <Input
                    type="range"
                    onChange={handleModifyAuctionWatch}
                    value={modifyAuctionInfo?.watch?.condition || 0}
                    name="condition"
                    min="0"
                    max="10"
                    step="0.5"
                  />
                </Col>
              </Row>
            </Container>
          </CardBody>
          <CardBody>
            <Container>
              <CardTitle>드로우 정보</CardTitle>
              <Row style={{ marginTop: "40px" }}>
                <Col>
                  고유Id
                  <Input disabled value={modifyAuctionInfo?.id || "-"} />
                </Col>
                <Col>
                  드로우 제품 가격
                  <Input
                    placeholder="드로우 제품 가격"
                    name="price"
                    type="text"
                    value={
                      modifyAuctionInfo?.price
                        ? Number(modifyAuctionInfo?.price).toLocaleString()
                        : ""
                    }
                    onChange={handleModifyAuction}
                  />
                </Col>
                <Col>
                  드로우 신청 링크
                  <Input
                    placeholder="드로우 신청 링크"
                    type="text"
                    name="description"
                    value={modifyAuctionInfo?.description || "-"}
                    onChange={handleModifyAuction}
                  />
                </Col>
              </Row>
              {/* <Row style={{ marginTop: "40px" }}>
                <Col>
                  최종 입찰 가격
                  <Input
                    value={
                      modifyAuctionInfo?.fixedPrice
                        ? Number(modifyAuctionInfo.fixedPrice).toLocaleString()
                        : "-"
                    }
                    disabled
                  />
                </Col>
                <Col>
                  마지막으로 입찰된 가격
                  <Input
                    value={
                      modifyAuctionInfo?.lastPrice
                        ? Number(modifyAuctionInfo.lastPrice).toLocaleString()
                        : "-"
                    }
                    disabled
                  />
                </Col>
                <Col>
                  입찰 수
                  <Input
                    value={
                      modifyAuctionInfo?.orderCount
                        ? Number(modifyAuctionInfo.orderCount).toLocaleString()
                        : "-"
                    }
                    disabled
                  />
                </Col>
              </Row> */}
              <Row style={{ marginTop: "40px" }}>
                <Col>
                  드로우 상태
                  <Input
                    value={modifyAuctionInfo?.auctionStatus || "-"}
                    disabled
                  />
                </Col>
                <Col>
                  총 조회수
                  <Input value={modifyAuctionInfo?.hitCount || "-"} disabled />
                </Col>
                <Col>
                  한 시간동안 조회한 수
                  <Input value={modifyAuctionInfo?.hourCount || "-"} disabled />
                </Col>
              </Row>
              {/* <Row style={{ marginTop: "40px" }}>
                <Col>
                  옥션 참여 명수
                  <Input
                    value={
                      modifyAuctionInfo?.orderPeopleCount
                        ? Number(
                            modifyAuctionInfo.orderPeopleCount
                          ).toLocaleString()
                        : "-"
                    }
                    disabled
                  />
                </Col>
                <Col></Col>
                <Col></Col>
              </Row> */}

              <Row style={{ marginTop: "40px" }}>
                <Col>
                  드로우 시작 시간 : &nbsp;
                  <input
                    type="datetime-local"
                    className="form=control"
                    name="startDate"
                    value={modifyAuctionInfo?.startDate || null}
                    onChange={handleModifyAuction}
                  />
                </Col>
                <Col>
                  드로우 종료 시간 : &nbsp;
                  <input
                    type="datetime-local"
                    className="form=control"
                    name="endDate"
                    value={modifyAuctionInfo?.endDate || null}
                    onChange={handleModifyAuction}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "40px" }}>
                <Col>
                  <div>보유지점</div>
                  {currentStoreList.map((store, idx) => (
                    <div
                      key={idx}
                      // onClick={() => setAuctionStore(store.id)}
                    >
                      <input
                        type="checkbox"
                        onChange={(e) => handleModifyStoreInfo(e, store.id)}
                        // disabled
                        name="storeId"
                        checked={
                          modifyAuctionInfo?.storeIds?.filter(
                            (el) => el === store.id
                          ).length > 0
                        }
                      />
                      <label htmlFor="supplement">{store.name}</label>
                    </div>
                  ))}
                </Col>
              </Row>

              <Row style={{ marginTop: "40px" }}>
                <Col>
                  사진첨부
                  {/* <div onClick={onClickUpdateNewAuctionInput}> */}
                  {/* <CardImg style={{ width: "50%" }} src={DETAULT_IMAGE} /> */}
                  <input
                    type="file"
                    accept="image/*"
                    ref={modifyAuctionInputRef}
                    key={modifyAuctionInfo?.attachedFiles}
                    onChange={onChangeModifyAuctionInput}
                    // hidden
                  />
                  {/* </div> */}
                </Col>
                <Col>
                  {modifyAuctionInfo?.attachedFiles?.map((photo) => (
                    <>
                      <CardImg style={{ width: "40%" }} src={photo} />
                      <button
                        onClick={() => deleteOnePhotoUrlModifyAuction(photo)}>
                        X
                      </button>
                    </>
                  ))}
                </Col>
              </Row>
              {/* <Row>
                <AuctionPeopleList
                  auctionPeopleData={auctionPeopleData}
                  oneDetailId={oneDetailId}
                  setAuctionPeopleData={setAuctionPeopleData}
                />
              </Row> */}
              <Row style={{ marginTop: "40px" }}>
                <div style={{ marginBottom: "10px", width: "100%" }}>
                  상세 정보
                </div>
                <Wrapper>
                  <Editor
                    ref={modifyAuctionDescription}
                    initialValue={replaceNewlineWithBrTag(
                      decodingHtmlTags(modifyAuctionInfo?.additionalInfo)
                    )}
                    previewStyle="vertical"
                    height="600px"
                    initialEditType="wysiwyg"
                    useCommandShortcut={true}
                  />
                </Wrapper>
              </Row>
              <Row>
                <Col
                  style={{
                    marginTop: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <Button onClick={sendModifyAuction}>드로우 수정하기</Button>
                  <Button onClick={deleteOneAuction}>드로우 삭제하기</Button>
                </Col>
              </Row>
            </Container>
          </CardBody>
        </Card>
      )}
      <Card>
        <CardTitle className="bg-light border-bottom p-3 mb-0 space-between">
          드로우 리스트
          <div>
            <select
              style={{ marginRight: "20px" }}
              value={searchAuctionStatus}
              onChange={(e) => setSearchAuctionStatus(e.target.value)}>
              <option></option>
              <option>WAIT</option>
              <option>RUN</option>
              <option>DONE</option>
            </select>
            <Button style={{ marginRight: "20px" }} onClick={getAuctionList}>
              조회하기
            </Button>
            <Button onClick={openRegisterAuctionForm}>드로우 등록하기</Button>
          </div>
        </CardTitle>
        <CardBody>
          <Table className="no-wrap v-middle" responsive>
            <thead>
              <tr className="border-0">
                <th className="border-0">고유 id</th>
                <th className="border-0">브랜드</th>
                <th className="border-0">모델</th>
                {/* <th className="border-0">입찰 수</th> */}
                {/* <th className="border-0">입찰 참여 명수</th> */}
                <th className="border-0">시작시간</th>
                <th className="border-0">종료시간</th>
                <th className="border-0">상태</th>
              </tr>
            </thead>
            <tbody>
              {currentAuctionInfo?.content?.length > 0 &&
                currentAuctionInfo.content.map((auction, idx) => (
                  <tr
                    key={idx}
                    // onClick={() => openModifyAuction(auction)}
                    // style={{ cursor: "pointer" }}
                  >
                    <td>{auction?.id || "-"}</td>
                    <td>{auction?.watch?.brand || "-"}</td>
                    <td>{auction?.watch?.model || "-"}</td>
                    {/* <td>
                      {auction?.orderCount
                        ? Number(auction.orderCount).toLocaleString()
                        : "-"}
                    </td> */}
                    {/* <td>
                      {auction?.orderPeopleCount
                        ? Number(auction.orderPeopleCount).toLocaleString()
                        : "-"}
                    </td> */}
                    <td>
                      {auction?.startDate ? handleTime(auction.startDate) : "-"}
                    </td>
                    <td>
                      {auction?.endDate ? handleTime(auction.endDate) : "-"}
                    </td>
                    {statusModifyId !== auction.id ? (
                      <>
                        <td>{auction?.auctionStatus || "-"}</td>
                        <td>
                          <button onClick={() => changeStatusModifyId(auction)}>
                            변경하기
                          </button>
                        </td>
                      </>
                    ) : (
                      <>
                        <td>
                          <select
                            name="드로우 상태"
                            value={modifyStatus}
                            onChange={changeOneStatus}>
                            <option>WAIT</option>
                            <option>RUN</option>
                            <option>DONE</option>
                          </select>
                        </td>
                        <td>
                          <button onClick={() => changeState(auction.id)}>
                            저장하기
                          </button>
                        </td>
                      </>
                    )}
                    <td>
                      <button
                        onClick={() => openModifyAuction(auction)}
                        style={{ marginRight: "10px" }}>
                        상세보기
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <PaginationComponent
            prevPage={prevPage}
            paginate={paginate}
            nextPage={nextPage}
            newPageNumbers={newPageNumbers}
            page={page}
          />
        </CardBody>
      </Card>
    </>
  );

  // 파일 업로드 후 URL 반환
  async function uploadFileAndGetUrl(file) {
    if (file) {
      const { type: fileType, name: fileName, size: fileSize } = file;
      // 파일 데이터 만들기
      const formData = new FormData();
      formData.append("file", file);

      // 파일 업로드
      const uploadResult = await uploadFile(formData);
      const {
        status,
        data: { url },
      } = uploadResult;
      if (status === 200 && !!url) {
        return url;
      } else {
        alert("파일 업로드 실패");
        return null;
      }
    } else {
      return null;
    }
  }

  function getSiteInfo() {
    getSiteInfoApi().then(({ data: { data, detail, status } }) => {
      if (status === "SUCCESS") {
        setDialColorList(data?.dialColors || []);
        setDialTypeList(data?.dialTypes || []);
        setCaseDiametersList(data?.caseDiameters || []);
        setCaseMaterialsList(data?.caseMaterials || []);
      } else {
        alert(detail);
      }
    });
  }

  function getBrandList() {
    getBrandListApi().then(({ data: { data, status, detail } }) => {
      if (status === "SUCCESS") {
        setSelectedBrand(data[0]?.brand || null);
        setBrandList(data || []);
        // console.log(data);
      }
    });
  }

  function getStoreList() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      getStoreListApi(authToken).then(({ data: { status, error, data } }) => {
        if (status === "SUCCESS") {
          setCurrentStoreList(data.content || []);
        } else {
          alert(error);
        }
      });
    }
  }

  function searchWatchModel() {
    searchWatchModelApi(selectedBrand, modelInput, page).then(
      ({ data: { data, detail, status } }) => {
        if (status === "SUCCESS") {
          setResultModelArray(data || []);
        } else {
          alert(detail);
        }
      }
    );
  }

  function registerNewAuction() {
    const authToken = CommonHelper.getAuthToken();
    let content = newAuctionDescription.current.getInstance().getMarkdown();
    content = encodingHtmlTags(replaceNewlineWith(content, `<br/>`));
    if (!!authToken) {
      registerNewAuctionApi(
        authToken,
        oneModel,
        startDate,
        endDate,
        auctionStartPrice,
        auctionDescription,
        auctionStore,
        watchPhotos,
        content
      ).then(({ data: { data, detail, status } }) => {
        if (status === "SUCCESS") {
          alert("성공적으로 드로우가 생성되었습니다.");
          setOneModel(null);
          setModelInput("");
          setResultModelArray([]);
          setShowModifyOneAuction(false);
          setOneDetailId(null);
          getAuctionList();
        } else {
          alert(detail);
        }
      });
    } else {
      alert("로그인을 다시 해주세요.");
    }
  }

  function sendModifyAuction() {
    const authToken = CommonHelper.getAuthToken();
    let content = modifyAuctionDescription.current.getInstance().getMarkdown();
    content = encodingHtmlTags(replaceNewlineWith(content, `<br/>`));
    let copyOneAuction = { ...modifyAuctionInfo, additionalInfo: content };
    if (!!authToken) {
      sendModifyAuctionApi(authToken, copyOneAuction).then(
        ({ data: { data, detail, status } }) => {
          if (status === "SUCCESS") {
            alert("성공적으로 수정되었습니다.");
            // setShowModifyOneAuction(false);
            // setModifyAuctionInfo(null)
            setShowModifyOneAuction(false);
            setOneDetailId(null);
            getAuctionList();
          } else {
            alert(detail);
          }
        }
      );
    }
  }

  function getOneAuction() {
    getOneAuctionApi(oneDetailId).then(({ data: { data, detail, status } }) => {
      if (status === "SUCCESS") {
        let copyOneAuction = { ...data };
        copyOneAuction.store = null;
        copyOneAuction.storeIds = data?.stores?.map((el) => {
          return el.id;
        });
        setModifyAuctionInfo(copyOneAuction);
        setShowModifyOneAuction(true);
      } else {
        setShowModifyOneAuction(false);
        setOneDetailId(null);
      }
    });
  }

  function deleteOneAuction() {
    const authToken = CommonHelper.getAuthToken();
    deleteOneAuctionApi(oneDetailId, authToken).then(
      ({ data: { data, detail, status } }) => {
        if (status === "SUCCESS") {
          alert("성공적으로 삭제되었습니다.");
          setOneDetailId(null);
          setShowModifyOneAuction(false);
          getAuctionList();
        } else {
          alert(detail);
        }
      }
    );
  }

  function changeState(id) {
    const authToken = CommonHelper.getAuthToken();
    changeAuctionStateApi(authToken, id, modifyStatus).then(
      ({ data: { status, detail, data } }) => {
        if (status === "SUCCESS") {
          alert("성공적으로 변경되었습니다.");
          setStatusModifyId(null);
          getAuctionList();
        } else {
          alert(detail);
        }
      }
    );
  }
};
export default AuctionTotalList;

const Wrapper = styled.div`
  padding-bottom: 2.8rem;
  font-size: 1.3rem;
  font-family: "Lato";
  width: 100%;
  label {
    display: block;
    margin-bottom: 1rem;
  }
  &.bordered {
    .editorWrapper {
      border-color: red;
    }
  }
  > div + p {
    width: 100%;
    padding-top: 12px;
    font-size: 1.2rem;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: -9px;
  }
`;
