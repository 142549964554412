import React, { useState, useEffect } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";
import { sendNewMaterialListApi } from "../../../apis/modelDetail";
import CommonHelper from "../../../helpers/CommonHelper";

const MaterialsComponent = ({ materialList, setMaterialList, getSiteInfo }) => {
  const deleteOneMaterial = (material) => {
    const filterMaterial = materialList.filter((el) => el !== material);
    setMaterialList(filterMaterial);
  };

  const handleMaterialsChange = (e) => {
    const fakeBox = [...materialList];
    const { name, value } = e.target;
    fakeBox[name] = value;
    setMaterialList(fakeBox);
  };

  const addMaterial= () => {
    const copyMaterialList = [...materialList];
    copyMaterialList.push("");
    setMaterialList(copyMaterialList);
  };
  return (
    <>
      <Card>
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          케이스 소재 리스트 관리
        </CardTitle>
        <CardBody>
          <Container>
            <ul>
              {materialList.map((material, idx) => (
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px",
                  }}>
                  <label style={{ margin: "10px" }}>소재 {idx + 1}</label>
                  <input
                    style={{ margin: "0 10px" }}
                    placeholder={`소재 ${idx + 1}`}
                    name={idx}
                    value={material || null}
                    onChange={handleMaterialsChange}
                  />
                  <Button onClick={() => deleteOneMaterial(material)}>x</Button>
                </Row>
              ))}
            </ul>
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "10px",
              }}>
              <Button style={{ margin: "0 10px" }} onClick={addMaterial}>
                추가
              </Button>
            </Row>
            <Button onClick={sendNewMaterialList}>저장</Button>
          </Container>
        </CardBody>
      </Card>
    </>
  );
  function sendNewMaterialList() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      sendNewMaterialListApi(materialList, authToken).then(
        ({ data: { status, detail } }) => {
          if (status === "SUCCESS") {
            alert("수정되었습니다.");
            getSiteInfo();
          } else {
            alert(detail);
          }
        }
      );
    }
  }
};

export default MaterialsComponent;
