import React, { useEffect, useState } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Table,
  CardImg,
  Button,
} from "reactstrap";
import {
  getPurchaseReservationListApi,
  changeBuyStateApi,
  deleteOneBuyReservationApi,
  getOneReservationBuyListApi,
} from "../../../apis/reservation";
import { handleVisitTime } from "../../../common/common";
import PaginationComponent from "../../PaginationComponent";
import { RESERVATION_STATUS, FILTER_STATUS } from "../../../common/constants";
import CommonHelper from "../../../helpers/CommonHelper";

// TODO : 사진파일 들어오면 img로 보여주는거 추가하기
const ReservationBuy = () => {
  const [oneDetailId, setOneDetailId] = useState("");
  const [modifyId, setModifyId] = useState(null);
  const [buyReservationStatus, setBuyReservationStatus] = useState();
  const [oneReservation, setOneReservation] = useState(null);
  const [showOneDetail, setShowOneDetail] = useState(false);
  const [registeredBuyList, setRegisteredBuyList] = useState([]);
  const [registerBuyInfo, setRegisterBuyInfo] = useState({});
  const [oneStatusFilter, setOneStatusFilter] = useState(
    FILTER_STATUS[0].value
  );

  /////페이지네이션
  const [page, setPage] = useState(0);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(4);
  const LIMIT = 10;

  const { totalPage } = registerBuyInfo;

  const pageNumbers = [];
  for (let i = 0; i < totalPage; i++) {
    pageNumbers.push(i + 1);
  }
  const newPageNumbers = pageNumbers.slice(start, end);

  const paginate = (number) => {
    const num = number === 0 ? number : number - 1;
    setPage(num);
  };

  const prevPage = () => {
    if (page + 1 === 1) {
      return alert("첫페이지입니다.");
    }
    if ((page + 1) % 4 === 1) {
      const s = start > 0 ? start - 4 : 0;
      const e = end > 0 ? end - 4 : end;

      setStart(s);
      setEnd(e);
    }
    setPage(page - 1);
  };

  const nextPage = () => {
    if (page + 2 > pageNumbers.length) {
      return alert("마지막페이지입니다.");
    } else {
      setPage(page + 1);
      if ((page + 2) % 4 === 1) {
        const s = start + 4;
        const e = end + 4;
        setStart(s);
        setEnd(e);
      }
    }
  };

  useEffect(() => {
    getPurchaseReservationList();
  }, [page]);

  const handleChange = (e) => {
    if (e.target.name === "구매 예약 상태") {
      setBuyReservationStatus(e.target.value);
    } else if (e.target.name === "구매예약 상태별 조회") {
      if (e.target.value === "전체") {
        setOneStatusFilter(null);
      } else {
        setOneStatusFilter(e.target.value);
      }
    }
  };

  useEffect(() => {
    oneReservation !== null && setBuyReservationStatus(oneReservation.state);
  }, [oneReservation]);

  useEffect(() => {
    oneDetailId && getOneReservationBuyList();
  }, [oneDetailId]);

  const openOneDetail = (list) => {
    // setShowOneDetail(true);
    // setOneReservation(list);
    setOneDetailId(list.id);
  };

  const closeDetail = () => {
    setShowOneDetail(false);
    setOneDetailId(null);
  };

  const handleChangeOneStatus = (el) => {
    setModifyId(el.id || null);
    setBuyReservationStatus(el.state || "");
  };

  return (
    <>
      {showOneDetail && (
        <Card>
          <CardBody>
            <CardTitle>
              <div className="space-between">
                예약상세
                <button onClick={closeDetail}>닫기</button>
              </div>
            </CardTitle>
            <Container>
              <Row>
                <div className="half-width">description</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneReservation.description ? oneReservation.description : ""}
                </div>
              </Row>
              <Row>
                <div className="half-width">desiredPrice</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneReservation.price
                    ? Number(oneReservation.price).toLocaleString()
                    : ""}
                </div>
              </Row>
              <Row>
                <div className="half-width">state</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneReservation.state ? oneReservation.state : ""}
                </div>
              </Row>
              <Row>
                <div className="half-width">방문지점</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneReservation.store?.name ? oneReservation.store.name : ""}
                </div>
              </Row>
              <Row>
                <div className="half-width">방문시간</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneReservation.visitDateTime
                    ? handleVisitTime(oneReservation.visitDateTime)
                    : null}
                </div>
              </Row>
            </Container>
          </CardBody>
          <CardBody>
            <CardTitle>시계상세</CardTitle>
            <Container>
              <Row>
                <div className="half-width">사진파일들</div>
                <Row
                  style={{
                    marginTop: "40px",
                  }}>
                  <Col style={{ display: "flex", justifyContent: "center" }}>
                    {oneReservation?.attachedFiles?.map((photo) => (
                      <>
                        <CardImg
                          style={{ width: "10%", margin: "10px" }}
                          src={photo}
                        />
                      </>
                    ))}
                  </Col>
                </Row>
              </Row>
              <Row>
                <div className="half-width">브랜드</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneReservation.watch?.brand
                    ? oneReservation.watch.brand
                    : ""}
                </div>
              </Row>
              <Row>
                <div className="half-width">모델</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneReservation.watch?.model
                    ? oneReservation.watch.model
                    : ""}
                </div>
              </Row>
              <Row>
                <div className="half-width">지름</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneReservation.watch?.caseDiameter
                    ? oneReservation.watch.caseDiameter
                    : ""}
                </div>
              </Row>
              <Row>
                <div className="half-width">소재</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneReservation.watch?.caseMaterial
                    ? oneReservation.watch.caseMaterial
                    : ""}
                </div>
              </Row>
              <Row>
                <div className="half-width">컨디션</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneReservation.watch?.condition
                    ? oneReservation.watch.condition
                    : ""}
                </div>
              </Row>
              <Row>
                <div className="half-width">dialColor</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneReservation.watch?.dialColor
                    ? oneReservation.watch.dialColor
                    : ""}
                </div>
              </Row>
              <Row>
                <div className="half-width">gender</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneReservation.watch?.gender
                    ? oneReservation.watch.gender
                    : ""}
                </div>
              </Row>
              <Row>
                <div className="half-width">movement</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneReservation.watch?.movement
                    ? oneReservation.watch.movement
                    : ""}
                </div>
              </Row>
              <Row>
                <div className="half-width">producedYear</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneReservation.watch?.producedYear
                    ? oneReservation.watch.producedYear
                    : ""}
                </div>
              </Row>
              <Row>
                <div className="half-width">serialNo</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneReservation.watch?.serialNo
                    ? oneReservation.watch.serialNo
                    : ""}
                </div>
              </Row>
              <Row>
                <div className="half-width">구성품</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneReservation.watch?.supplements?.map((supplement) => (
                    <div>{supplement}</div>
                  ))}
                </div>
              </Row>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: "20px",
                }}>
                <Button
                  disabled={oneReservation?.state === "APPROVED"}
                  style={{ margin: "10px 0" }}
                  onClick={deleteOneBuyReservation}>
                  삭제하기
                </Button>
                <p>
                  *state가 REGISTERED(대기) 혹은 VISITED(방문함)일 때만 삭제가
                  가능합니다.
                </p>
              </Row>
            </Container>
          </CardBody>
        </Card>
      )}
      <Card>
        <CardBody>
          <CardTitle className="bg-light border-bottom p-3 mb-0 space-between">
            <div>구매예약</div>
            <div>
              <select
                name="구매예약 상태별 조회"
                id={`check-구매상태조회`}
                value={oneStatusFilter}
                onChange={(e) => handleChange(e)}>
                {/* {FILTER_STATUS.map(({ label, value }, idx) => (
                  <option key={idx} value={value}>
                    {label}
                  </option>
                ))} */}
                <option value="">선택</option>
                <option value="REGISTERED">REGISTERED</option>
                <option value="APPROVED">APPROVED</option>
                <option value="VISITED">VISITED</option>
              </select>
              <button onClick={getPurchaseReservationList}>상태조회</button>
            </div>
          </CardTitle>
          <CardTitle>
            <p>
              사용자에 의해 등록됨: REGISTERED, 관리자에 의해 승인됨: APPROVED,
              매장 방문함: VISITED
            </p>
          </CardTitle>
          <Table className="no-wrap v-middle" responsive>
            <thead>
              <tr className="border-0">
                <th className="border-0">신청자 loginId</th>
                <th className="border-0">방문 예정 지점</th>
                <th className="border-0">날짜</th>
                <th className="border-0">구매예약 id</th>
                <th className="border-0">시계 브랜드</th>
                <th className="border-0">레퍼런스</th>
                <th className="border-0">판매예약 id</th>
                <th className="border-0">상태</th>
              </tr>
            </thead>
            <tbody>
              {registeredBuyList.map((el, idx) => (
                <tr key={idx}>
                  <td>{el.createdBy ? el.createdBy : ""}</td>
                  <td>{el.store?.name ? el.store.name : ""}</td>
                  <td>
                    {el.visitDateTime ? handleVisitTime(el.visitDateTime) : ""}
                  </td>
                  <td>{el.id ? el.id : ""}</td>
                  <td>{el.watch?.brand ? el.watch?.brand : ""}</td>
                  <td>{el.watch?.serialNo ? el.watch?.serialNo : ""}</td>
                  <td>{el.sale?.id ? el.sale?.id : ""}</td>
                  {modifyId !== el.id ? (
                    <>
                      <td>{el.state ? el.state : ""}</td>
                      <td>
                        <button onClick={() => handleChangeOneStatus(el)}>
                          변경하기
                        </button>
                      </td>
                    </>
                  ) : (
                    <>
                      <td>
                        <select
                          name="구매 예약 상태"
                          id={`check-구매예약상태`}
                          value={buyReservationStatus}
                          onChange={(e) => handleChange(e)}>
                          <option>REGISTERED</option>
                          <option>APPROVED</option>
                          <option>VISITED</option>
                          {/* {RESERVATION_STATUS.map(({ label, value }, idx) => (
                            <option key={idx} value={value}>
                              {label}
                            </option>
                          ))} */}
                        </select>
                      </td>
                      <td>
                        <button onClick={() => changeState(el.id)}>
                          저장하기
                        </button>
                        {/* <button onClick={() => setModifyId(null)}>
                          취소하기
                        </button> */}
                      </td>
                    </>
                  )}
                  <td>
                    <button onClick={() => openOneDetail(el)}>상세보기</button>
                  </td>
                </tr>
              ))}
              <PaginationComponent
                prevPage={prevPage}
                paginate={paginate}
                nextPage={nextPage}
                newPageNumbers={newPageNumbers}
                page={page}
                lastPage={registerBuyInfo.lastPage}
              />
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  );

  async function getPurchaseReservationList() {
    const authToken = CommonHelper.getAuthToken();
    if (authToken !== null && authToken !== undefined) {
      getPurchaseReservationListApi(authToken, oneStatusFilter, page).then(
        ({ data: { status, detail, data } }) => {
          if (status === "SUCCESS") {
            setRegisteredBuyList(data?.content || []);
            setRegisterBuyInfo(data);
          } else {
            alert(detail);
          }
        }
      );
    }
  }

  function getOneReservationBuyList() {
    getOneReservationBuyListApi(oneDetailId).then(
      ({ data: { data, status, detail } }) => {
        if (status === "SUCCESS") {
          setOneReservation(data);
          setShowOneDetail(true);
        } else {
          alert(detail);
          setShowOneDetail(false);
        }
      }
    );
  }

  function changeState(id) {
    const authToken = CommonHelper.getAuthToken();
    if (authToken !== null && authToken !== undefined) {
      changeBuyStateApi(authToken, id, buyReservationStatus).then(
        ({ data: { status, detail, data } }) => {
          if (status === "SUCCESS") {
            alert("성공적으로 변경되었습니다.");
            setModifyId(null);
            setShowOneDetail(false);
            setOneDetailId(null);
            getPurchaseReservationList();
          } else {
            alert(detail);
          }
        }
      );
    }
  }

  function deleteOneBuyReservation() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      deleteOneBuyReservationApi(authToken, oneReservation).then(
        ({ data: { status, detail, data } }) => {
          if (status === "SUCCESS") {
            alert("성공적으로 삭제되었습니다.");
            setShowOneDetail(false);
            setOneReservation(null);
            setModifyId(null);
            setOneDetailId(null);
            getPurchaseReservationList();
          } else {
            alert(detail);
          }
        }
      );
    }
  }
};

export default ReservationBuy;
