import { apiCall } from "./apiCall";
import { CLIENT_URL, SERVER_API_HOST } from "../common/environment";

export const getBrandListApi = () => {
  return apiCall(`${SERVER_API_HOST}/api/watch/brand/list`, "get", {}, {});
};

export const searchWatchModelApi = (selectedBrand, modelInput,page) => {
  return apiCall(
    `${SERVER_API_HOST}/api/watch/list?page=${page}`,
    "post",
    {
      filters: [
        {
          type: "BRAND",
          keyword: selectedBrand,
        },
        {
          type: "MODEL",
          keyword: modelInput,
        },
      ],
    },
    {}
  );
};

export const sendModifiedProductInfoApi = (oneModel, authToken) => {
  return apiCall(
    `${SERVER_API_HOST}/api/watch/update/${oneModel.id}`,
    "put",
    oneModel,
    { authorization: authToken }
  );
};

export const registerNewProductApi = (authToken, newProductInfo) => {
  return apiCall(
    `${SERVER_API_HOST}/api/watch/create`,
    "post",
    newProductInfo,
    { authorization: authToken }
  );
};

export const getModelListApi = (modelInput) => {
  return apiCall(
    `${SERVER_API_HOST}/api/filter/standard`,
    "post",
    {
      filters: [
        {
          type: "MODEL",
          keyword: modelInput,
        },
      ],
    },
    {}
  );
};

export const deleteOneModelApi = (authToken, oneModel) => {
  return apiCall(
    `${SERVER_API_HOST}/api/watch/delete/${oneModel.id}`,
    "delete",
    {},
    {
      authorization: authToken,
    }
  );
};
