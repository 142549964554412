import { apiCall } from "./apiCall";
import { CLIENT_URL, SERVER_API_HOST } from "../common/environment";

export const modifyPriceApi = (authToken, price, oneModel, dayInfo) => {
  return apiCall(
    `${SERVER_API_HOST}/api/watch/update/sale/price/${oneModel.id}`,
    "put",
    { recentlyPrice: price, day: dayInfo },
    { authorization: authToken }
  );
};

export const findOneWatchApi = (oneProduct, authToken) => {
  return apiCall(
    `${SERVER_API_HOST}/api/watch/get/${oneProduct.id}`,
    "get",
    {},
    { authorization: authToken }
  );
};

export const modifyOnePriceApi = (
  modifyProductInfo,
  newPrice,
  onePriceDay,
  authToken
) => {
  return apiCall(
    `${SERVER_API_HOST}/api/watch/update/sale/price/${modifyProductInfo.id}`,
    "put",
    {
      day: onePriceDay,
      recentlyPrice: newPrice,
    },
    {
      authorization: authToken,
    }
  );
};

export const deleteOnePriceApi = (
  modifyProductInfo,
  onePriceDay,
  authToken
) => {
  return apiCall(
    `${SERVER_API_HOST}/api/watch/update/sale/price/${modifyProductInfo.id}`,
    "put",
    {
      day: onePriceDay,
      recentlyPrice: 0,
    },
    {
      authorization: authToken,
    }
  );
};

export const searchWatchModelApi = (selectedBrand, modelInput) => {
  return apiCall(
    `${SERVER_API_HOST}/api/filter/standard`,
    "post",
    {
      filters: [
        {
          type: "BRAND",
          keyword: selectedBrand,
        },
        {
          type: "INTEGRATED",
          keyword: modelInput,
        },
      ],
    },
    {}
  );
};

export const getPriceExcelApi = (id) => {
  return apiCall(
    `${SERVER_API_HOST}/api/watch/download/sale/price/${id}`,
    "get",
    {},
    {
      withCredentials: false,
      responseType: "blob",
    }
  );
};
