import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Card,
  CardBody,
  CardTitle,
  Button,
  Input,
  CardImg,
} from "reactstrap";
import {
  getReviewListApi,
  createNewReviewApi,
  modifyOneReviewApi,
  deleteOneReviewApi,
  getReviewDetailApi,
} from "../../../apis/customer";
import PaginationComponent from "../../PaginationComponent";
import NewReview from "./components/NewReview";
import CommonHelper from "../../../helpers/CommonHelper";
import ReviewView from "./components/ReviewView";
import ReviewModifyEditor from "./components/ReviewModifyEditor";
import DEFAULT_IMAGE from "../../../assets/images/big/default-image.jpg";

const ReviewPick = () => {
  const [noticeList, setNoticeList] = useState([]);
  const [noticeInfo, setNoticeInfo] = useState({});
  const [showNewNoticeForm, setShowNewNoticeForm] = useState(false);
  const [newNoticeTitle, setNewNoticeTitle] = useState("");
  const [newNoticeContent, setNewNoticeContent] = useState("");
  const [modifyNoticeTitle, setModifyNoticeTitle] = useState("");
  const [modifyNoticeContent, setModifyNoticeContent] = useState("");
  const [oneNotice, setOneNotice] = useState({});
  const [showNoticeDetail, setShowNoticeDetail] = useState(false);
  const [text, setText] = useState({ editorHtml: "", theme: "snow" });
  const [showModifyEditor, setShowModifyEditor] = useState(false);
  const [oneId, setOneId] = useState("");

  /////페이지네이션
  const [page, setPage] = useState(0);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(4);
  const LIMIT = 10;

  const { totalPage } = noticeInfo;

  const pageNumbers = [];
  for (let i = 0; i < totalPage; i++) {
    pageNumbers.push(i + 1);
  }
  const newPageNumbers = pageNumbers.slice(start, end);

  const paginate = (number) => {
    const num = number === 0 ? number : number - 1;
    setPage(num);
  };

  const prevPage = () => {
    if (page + 1 === 1) {
      return alert("첫페이지입니다.");
    }
    if ((page + 1) % 4 === 1) {
      const s = start > 0 ? start - 4 : 0;
      const e = end > 0 ? end - 4 : end;

      setStart(s);
      setEnd(e);
    }
    setPage(page - 1);
  };

  const nextPage = () => {
    if (page + 2 > pageNumbers.length) {
      return alert("마지막페이지입니다.");
    } else {
      setPage(page + 1);
      if ((page + 2) % 4 === 1) {
        const s = start + 4;
        const e = end + 4;
        setStart(s);
        setEnd(e);
      }
    }
  };

  useEffect(() => {
    getNoticeList(); // 첫 렌더에서 공지사항 리스트 호출
  }, [page]);

  /**
   * 새로운 공지사항 생성 input 비우는 함수
   */
  const resetNewNotice = () => {
    setNewNoticeTitle("");
    setNewNoticeContent("");
  };

  /**
   * 새 공지사항 card 열기
   */
  const openNewNoticeForm = () => {
    setShowNewNoticeForm(true);
  };

  /**
   * 새 공지사항 card 닫고 input 비우기
   */
  const closeNewNoticeForm = () => {
    setShowNewNoticeForm(false);
    resetNewNotice();
  };

  /**
   * 공지사항 생성 input과 수정 input 핸들링
   */
  const handleChange = (e) => {
    if (e.target.name === "새 공지사항 제목") {
      setNewNoticeTitle(e.target.value);
    } else if (e.target.name === "새 공지사항 내용") {
      setNewNoticeContent(e.target.value);
    } else if (e.target.name === "공지사항 제목 수정") {
      setModifyNoticeTitle(e.target.value);
    } else if (e.target.name === "공지사항 내용 수정") {
      setModifyNoticeContent(e.target.value);
    }
  };

  /**
   * 하나의 공지사항 선택하면 state에 저장 및 상세모달 state true
   */
  const storeOneNotice = (oneNotice) => {
    // setShowNoticeDetail(true);
    // setOneNotice(oneNotice || {});
    // setModifyNoticeTitle(oneNotice?.title || "");
    // setModifyNoticeContent(oneNotice?.content || "");
    getReviewDetail(oneNotice);
  };

  const handleModifyOrNot = () => {
    setShowModifyEditor(true);
    setShowNoticeDetail(false);
  };
  return (
    <>
      {showNewNoticeForm && (
        <>
          <NewReview
            setShowNewNoticeForm={setShowNewNoticeForm}
            getNoticeList={getNoticeList}
          />
          {/* <NewNoticeEditor text={text} setText={setText} />
          <Card>
            <CardTitle className="bg-light border-bottom p-3 mb-0">
              새로운 공지사항 등록하기
            </CardTitle>
            <CardBody>
              <Container>
                <Row style={{ margin: "10px" }}>
                  <label style={{ margin: "3px" }}>제목</label>
                  <input
                    placeholder="제목"
                    style={{ width: "40%", margin: "5px" }}
                    value={newNoticeTitle}
                    onChange={(e) => handleChange(e)}
                    name="새 공지사항 제목"
                  />
                </Row>
                <Row style={{ margin: "10px" }}>
                  <label style={{ margin: "3px" }}>내용</label>
                  <textarea
                    placeholder="내용"
                    style={{ width: "80%", margin: "5px" }}
                    value={newNoticeContent}
                    name="새 공지사항 내용"
                    onChange={(e) => handleChange(e)}
                  />
                </Row>
                <Row
                  style={{ display: "flex", justifyContent: "space-between" }}>
                  <Button onClick={() => createNewNotice()}>등록하기</Button>
                  <Button onClick={() => closeNewNoticeForm()}>닫기</Button>
                </Row>
              </Container>
            </CardBody>
          </Card> */}
        </>
      )}
      {showNoticeDetail && oneNotice ? (
        <Card>
          <CardTitle className="bg-light border-bottom p-3 mb-0">
            상세
          </CardTitle>
          <CardBody>
            <Row style={{ margin: "10px" }}>
              <label style={{ marginTop: 10, color: "black", fontWeight: 600 }}>
                제목 *
              </label>
              <Input
                style={{ width: "40%", margin: "5px" }}
                value={modifyNoticeTitle}
                disabled
                name="리뷰 제목 수정"
                onChange={(e) => handleChange(e)}
              />
            </Row>
            {/* <Row>{modifyNoticeContent}</Row> */}
            <Row style={{ margin: "10px" }}>
              <label
                style={{
                  marginTop: 10,
                  color: "black",
                  fontWeight: 600,
                  width: "100%",
                  marginBottom: "10px",
                }}>
                내용 *
              </label>
              <ReviewView markdown={modifyNoticeContent} />
            </Row>
            <Row>
              <Card>
                <div>
                  <CardImg
                    top
                    width="100%"
                    src={
                      oneNotice.attacheFiles[0]
                        ? oneNotice.attacheFiles[0]
                        : DEFAULT_IMAGE
                    }
                  />
                  <input type="file" accept="image/*" hidden />
                </div>
              </Card>
            </Row>
            <Row style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                {/* <Button onClick={() => modifyOneNotice()}>수정하기</Button> */}
                <Button onClick={handleModifyOrNot}>수정 시작하기</Button>
                <Button onClick={() => deleteOneNotice()}>삭제하기</Button>
              </div>
              <Button onClick={() => setShowNoticeDetail(false)}>닫기</Button>
            </Row>
          </CardBody>
        </Card>
      ) : (
        ""
      )}
      {showModifyEditor ? (
        <ReviewModifyEditor
          modifyNoticeTitle={modifyNoticeTitle}
          modifyNoticeContent={modifyNoticeContent}
          getNoticeList={getNoticeList}
          showModifyEditor={showModifyEditor}
          setShowModifyEditor={setShowModifyEditor}
          id={oneNotice.id}
          setShowNoticeDetail={setShowNoticeDetail}
          setModifyNoticeTitle={setModifyNoticeTitle}
          oneNotice={oneNotice}
        />
      ) : (
        ""
      )}
      <Card>
        <CardBody>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
            }}>
            <div>
              <CardTitle>{`리뷰pick`}</CardTitle>
            </div>
            {!showNewNoticeForm && (
              <Button onClick={() => openNewNoticeForm()}>
                {`리뷰pick`} 등록
              </Button>
            )}
          </div>
          <ul style={{ margin: "0", padding: "0" }}>
            {noticeList.map((notice, idx) => (
              <li
                style={{
                  display: "flex",
                  borderBottom: "1px solid rgba(0,0,0,0.3)",
                  width: "100%",
                }}
                key={idx}>
                <div
                  style={{
                    width: "5%",
                    margin: "3px",
                    alignItems: "center",
                    minWidth: "fit-content",
                  }}>
                  {notice?.id ? notice.id : ""}
                </div>
                <div
                  style={{
                    width: "70%",
                    margin: "3px",
                    alignItems: "center",
                  }}>
                  {notice?.title ? notice.title : ""}
                </div>
                <div
                  style={{
                    width: "10%",
                    margin: "3px",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}>
                  {/* {notice?.content
                    ? notice.content
                        .replaceAll("&lt;", "<")
                        .replaceAll("&gt;", ">")
                        .replaceAll("<br/>", "\r\n")
                    : ""} */}
                </div>
                <div
                  style={{
                    width: "12%",
                    margin: "3px",
                  }}>
                  <Button onClick={() => storeOneNotice(notice)}>
                    상세조회
                  </Button>
                </div>
              </li>
            ))}
          </ul>
          <PaginationComponent
            prevPage={prevPage}
            paginate={paginate}
            nextPage={nextPage}
            newPageNumbers={newPageNumbers}
            page={page}
            lastPage={noticeInfo.lastPage}
          />
        </CardBody>
      </Card>
    </>
  );

  /**
   * 공지사항 리스트 가져오는 함수
   */
  function getNoticeList() {
    const authToken = CommonHelper.getAuthToken();
    getReviewListApi(authToken, page).then(
      ({ data: { status, errorMessage, data } }) => {
        if (status === "SUCCESS") {
          setNoticeList(data?.content || []);
          setNoticeInfo(data);
        } else {
          alert(errorMessage);
        }
      }
    );
  }

  /**
   * 새 공지사항 만드는 함수
   */
  function createNewNotice() {
    const newNoticeContentForBr = newNoticeContent
      .replace(/(?:\r\n|\r|\n)/g, "<br/>")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
    const authToken = CommonHelper.getAuthToken();
    if (authToken !== null && authToken !== undefined) {
      createNewReviewApi(
        authToken,
        newNoticeTitle,
        newNoticeContentForBr,
        text
      ).then(({ data: { status, errorMessage, data } }) => {
        if (status === "SUCCESS") {
          alert("성공적으로 생성되었습니다.");
          resetNewNotice();
          getNoticeList();
        } else {
          alert(errorMessage);
        }
      });
    }
  }

  /**
   * 공지사항 수정하는 함수
   */
  function modifyOneNotice() {
    const modifyNoticeContentForBr = modifyNoticeContent
      .replace(/(?:\r\n|\r|\n)/g, "<br/>")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
    const authToken = CommonHelper.getAuthToken();
    const modifyId = oneNotice?.id || "";
    if (authToken !== null && authToken !== undefined) {
      modifyOneReviewApi(
        authToken,
        modifyId,
        modifyNoticeTitle,
        modifyNoticeContentForBr
      ).then(({ data: { status } }) => {
        if (status === "SUCCESS") {
          alert("성공적으로 수정되었습니다.");
          setShowNoticeDetail(false);
          getNoticeList();
        }
      });
    }
  }

  /**
   * 공지사항 삭제하는 함수
   */
  function deleteOneNotice() {
    const authToken = CommonHelper.getAuthToken();
    const deleteId = oneNotice?.id || "";
    if (authToken !== null && authToken !== undefined) {
      deleteOneReviewApi(authToken, deleteId).then(({ data: { status } }) => {
        if (status === "SUCCESS") {
          alert("성공적으로 삭제되었습니다.");
          setShowNoticeDetail(false);
          setShowModifyEditor(false);
          getNoticeList();
        } else {
          alert("서버 오류로 삭제를 실패했습니다.");
        }
      });
    }
  }

  function getReviewDetail(oneNotice) {
    const authToken = CommonHelper.getAuthToken();
    if (authToken !== null && authToken !== undefined) {
      getReviewDetailApi(authToken, oneNotice.id).then(
        ({ data: { status, errorMessage, data } }) => {
          if (status === "SUCCESS") {
            setOneNotice(data || {});
            setModifyNoticeTitle(data?.title || "");
            setModifyNoticeContent(data?.content || "");
            setShowNoticeDetail(true);
          } else {
            alert(errorMessage);
          }
        }
      );
    }
  }
};
export default ReviewPick;
