import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Table,
  Button,
} from "reactstrap";
import CommonHelper from "../../../helpers/CommonHelper";
import {
  getAuctionWaitListApi,
  agreeAuctionUpgradeApi,
  rejectAuctionUpgradeApi,
} from "../../../apis/userList";
import { AUCTION_STATUS } from "../../../common/constants";
import PaginationComponent from "../../PaginationComponent";
const emptySearchBox = {
  senderName: "",
  applyStatus: "",
};

const WaitAuctionUserList = () => {
  const history = useHistory();
  const [auctionWaitInfo, setAuctionWaitInfo] = useState({});
  const [showWaitDetail, setShowWaitDetail] = useState(false);
  const [oneWaitDetail, setOneWaitDetail] = useState({});
  const [searchBox, setSearchBox] = useState(emptySearchBox);

  /////페이지네이션
  const [page, setPage] = useState(0);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(4);
  const LIMIT = 10;

  const { totalPage } = auctionWaitInfo;

  const pageNumbers = [];
  for (let i = 0; i < totalPage; i++) {
    pageNumbers.push(i + 1);
  }
  const newPageNumbers = pageNumbers.slice(start, end);

  const paginate = (number) => {
    const num = number === 0 ? number : number - 1;
    setPage(num);
  };

  const prevPage = () => {
    if (page + 1 === 1) {
      return alert("첫페이지입니다.");
    }
    if ((page + 1) % 4 === 1) {
      const s = start > 0 ? start - 4 : 0;
      const e = end > 0 ? end - 4 : end;

      setStart(s);
      setEnd(e);
    }
    setPage(page - 1);
  };

  const nextPage = () => {
    if (page + 2 > pageNumbers.length) {
      return alert("마지막페이지입니다.");
    } else {
      setPage(page + 1);
      if ((page + 2) % 4 === 1) {
        const s = start + 4;
        const e = end + 4;
        setStart(s);
        setEnd(e);
      }
    }
  };

  useEffect(() => {
    getAuctionWaitList();
  }, [page]);

  const openOneDetail = (waitInfo) => {
    setShowWaitDetail(true);
    setOneWaitDetail(waitInfo);
  };

  const closeDetail = () => {
    setShowWaitDetail(false);
  };

  const handleSearchInput = (e) => {
    const { name, value } = e.target;
    const nextInputs = {
      ...searchBox,
      [name]: value,
    };
    setSearchBox(nextInputs);
  };

  return (
    <>
      {showWaitDetail && (
        <Card>
          <CardBody>
            <CardTitle>
              <div className="space-between">
                옥션 신청 상세 조회
                <Button onClick={closeDetail}>닫기</Button>
              </div>
            </CardTitle>
            <Container>
              <Row>
                <div className="half-width">고유 id</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneWaitDetail.id || "-"}
                </div>
              </Row>
              <Row>
                <div className="half-width">옥션 신청 상태</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneWaitDetail.applyStatus || "-"}
                </div>
              </Row>
              <Row>
                <div className="half-width">생성된 시간</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneWaitDetail.createdAt || "-"}
                </div>
              </Row>
              <Row>
                <div className="half-width">작성자</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneWaitDetail.createdBy || "-"}
                </div>
              </Row>
              <Row>
                <div className="half-width">입금 시간</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneWaitDetail.depositTime || "-"}
                </div>
              </Row>
              <Row>
                <div className="half-width">예금주</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneWaitDetail.senderName || "-"}
                </div>
              </Row>
            </Container>
          </CardBody>
          <CardBody>
            <CardTitle>
              <div className="space-between">신청자 상세 정보</div>
            </CardTitle>
            <Container>
              <Row>
                <div className="half-width">고유 id</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneWaitDetail.user?.id || "-"}
                </div>
              </Row>
              <Row>
                <div className="half-width">loginId</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneWaitDetail.user?.loginId || "-"}
                </div>
              </Row>
              <Row>
                <div className="half-width">name</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneWaitDetail.user?.name || "-"}
                </div>
              </Row>
              <Row>
                <div className="half-width">nickName</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneWaitDetail.user?.nickName || "-"}
                </div>
              </Row>
              <Row>
                <div className="half-width">phone</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneWaitDetail.user?.phone || ""}
                </div>
              </Row>
              <Row>
                <div className="half-width">email</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneWaitDetail.user?.email || "-"}
                </div>
              </Row>
              <Row>
                <div className="half-width">roles</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneWaitDetail.user?.roles.length > 0
                    ? oneWaitDetail.user?.roles.map((el) => <div>{el}</div>)
                    : null}
                </div>
              </Row>
            </Container>
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "20px",
              }}>
              {oneWaitDetail.applyStatus === "WAIT" && (
                <>
                  <Button onClick={agreeAuctionUpgrade}>승인하기</Button>
                  <Button onClick={rejectAuctionUpgrade}>거절하기</Button>
                </>
              )}
            </Row>
          </CardBody>
        </Card>
      )}
      <Card>
        <CardBody>
          <CardTitle className="bg-light border-bottom p-3 mb-0 space-between">
            회원 리스트
            <div>
              <input
                type="text"
                placeholder="예금주 검색"
                name="senderName"
                onChange={handleSearchInput}
              />
              <select
                name="applyStatus"
                id={`check-구매상태조회`}
                onChange={handleSearchInput}>
                {AUCTION_STATUS.map(({ label, value }, idx) => (
                  <option key={idx} value={value}>
                    {label}
                  </option>
                ))}
              </select>
              <Button onClick={getAuctionWaitList}>조회하기</Button>
            </div>
          </CardTitle>
          <Table className="no-wrap v-middle" responsive>
            <thead>
              <tr className="border-0">
                <th className="border-0">고유 id</th>
                <th className="border-0">신청자 loginId</th>
                <th className="border-0">예금주</th>
                <th className="border-0">입금시간</th>
                <th className="border-0">applyStatus</th>
                <th className="border-0">createdBy</th>
              </tr>
            </thead>
            <tbody>
              {auctionWaitInfo?.content?.map((el, idx) => (
                <tr key={idx}>
                  <td>{el.id || "no id"}</td>
                  <td>{el.user?.loginId || "-"}</td>
                  <td>{el.senderName || "-"}</td>
                  <td>{el.depositTime || "-"}</td>
                  <td>{el.applyStatus || "-"}</td>
                  <td>
                    <Button onClick={() => openOneDetail(el)}>상세보기</Button>
                  </td>
                </tr>
              ))}
              <PaginationComponent
                prevPage={prevPage}
                paginate={paginate}
                nextPage={nextPage}
                newPageNumbers={newPageNumbers}
                page={page}
                lastPage={auctionWaitInfo.lastPage}
              />
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  );
  function getAuctionWaitList() {
    const authToken = CommonHelper.getAuthToken();
    if (authToken !== null && authToken !== undefined) {
      getAuctionWaitListApi(authToken, searchBox, page).then(
        ({ status, data: { status: backStatus, error, data } }) => {
          //  if(status === 403 || status === 401){
          //    CommonHelper.clearAuthToken();
          //    history.push('/sign-in')
          //  } else {
          if (backStatus === "SUCCESS") {
            setAuctionWaitInfo(data || {});
          } else {
            alert(error);
          }
          // }
        }
      );
    }
  }

  function agreeAuctionUpgrade() {
    const authToken = CommonHelper.getAuthToken();
    if (authToken !== null && authToken !== undefined) {
      agreeAuctionUpgradeApi(authToken, oneWaitDetail.id || "").then(
        ({ status, data: { status: backStatus, error, data } }) => {
          //  if(status === 403 || status === 401){
          //    CommonHelper.clearAuthToken();
          //    history.push('/sign-in')
          //  } else {
          if (backStatus === "SUCCESS") {
            alert("성공적으로 승인되었습니다.");
            getAuctionWaitList();
            setShowWaitDetail(false);
          } else {
            alert(error);
          }
          // }
        }
      );
    }
  }

  function rejectAuctionUpgrade() {
    const authToken = CommonHelper.getAuthToken();
    if (authToken !== null && authToken !== undefined) {
      rejectAuctionUpgradeApi(authToken, oneWaitDetail.id || "").then(
        ({ status, data: { status: backStatus, error, data } }) => {
          // if(status === 403 || status === 401){
          //    CommonHelper.clearAuthToken();
          //    history.push('/sign-in')
          //  } else {
          if (backStatus === "SUCCESS") {
            alert("성공적으로 거절되었습니다.");
            getAuctionWaitList();
            setShowWaitDetail(false);
          } else {
            alert(error);
          }
          // }
        }
      );
    }
  }
};
export default WaitAuctionUserList;
