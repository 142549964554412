import React, { useState } from "react";
import {
  CardBody,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";

const PaginationComponent = ({ prevPage, paginate, nextPage, page, newPageNumbers, firstPage, lastPage }) => {
  return (
    <>
      <CardBody className="">
        <Pagination aria-label="Page navigation example">
          <PaginationItem disabled={page === 0}>
            <PaginationLink previous onClick={() => prevPage()} />
          </PaginationItem>
          {newPageNumbers &&
            newPageNumbers.map((number) => (
              <PaginationItem
                key={number}
                active={number === page + 1}
                onClick={() => paginate(number)}>
                <PaginationLink>{number}</PaginationLink>
              </PaginationItem>
            ))}
          <PaginationItem disabled={lastPage}>
            <PaginationLink next onClick={() => nextPage()} />
          </PaginationItem>
        </Pagination>
      </CardBody>
    </>
  );
};

export default PaginationComponent;
