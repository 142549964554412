export const PCRollingSettingOptions = [
  { value: "조회순", label: "조회순" },
  { value: "가격상승순", label: "가격 상승순" },
  { value: "높은가격순", label: "높은가격순" },
  { value: "거래완료순", label: "거래완료순" },
];
export const PCROLLING_EN_KO = {
  HIT_COUNT : '조회순',
  HPI : '가격상승순',
  HIGH_PRICE : '높은가격순',
  SOLD_COUNT : '거래완료순'
}
export const PCROLLING_KO_EN = {
  조회순: "HIT_COUNT",
  가격상승순: "HPI",
  높은가격순: "HIGH_PRICE",
  거래완료순: "SOLD_COUNT"
}

export const AppRollingSettingOptions = [
  { value: "가격변동", label: "가격변동" },
  { value: "조회순", label: "조회순" },
  { value: "거래완료", label: "거래완료" },
];
export const APP_ROLLING_EN_KO = {
  HPI : '가격변동',
  HIT_COUNT : '조회순',
  SOLD_COUNT: '거래완료'
}

export const APP_ROLLING_KO_EN = {
  가격변동 : 'HPI',
  조회순 : 'HIT_COUNT',
  거래완료: 'SOLD_COUNT'
}

export const popupAmountOptions = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
];

export const popupSizeOptions = [
  { value: "30x40", label: "30x40" },
  { value: "40x40", label: "40x40" },
  { value: "20x30", label: "20x30" },
  { value: "30x30", label: "30x30" },
];

export const PCRollingNumOptions = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
];
export const buyExample = [
  {
    place: ["부산", "서울본점"],
    time: "2021-03-25",
    id: "watch513",
    personInfo: { name: "홍길동", id: "userId" },
    status: "신청",
  },
  {
    place: ["부산"],
    time: "2021-03-21",
    id: "watch5",
    personInfo: { name: "이애리", id: "userId2" },
    status: "종료",
  },
  {
    place: ["서울본점"],
    time: "2021-03-27",
    id: "watch346",
    personInfo: { name: "길동동", id: "userId3" },
    status: "진행중",
  },
  {
    place: ["수원점", "서울본점"],
    time: "2021-03-01",
    id: "watch45",
    personInfo: { name: "징징", id: "userId4" },
    status: "신청",
  },
  {
    place: ["부산"],
    time: "2021-05-21",
    id: "watch200",
    personInfo: { name: "건희", id: "userId5" },
    status: "진행중",
  },
  {
    place: ["수원점"],
    time: "2021-01-21",
    id: "watch535",
    personInfo: { name: "재승", id: "userId6" },
    status: "종료",
  },
];

export const sellExample = [
  {
    brand: "",
    model: "",
    authNum: "",
    condition: "",
    exist: "",
    gender: "",
    year: "",
    size: "",
    movement: "",
    description: "",
    hopePrice: "",
    photoUrl: "",
    place: "",
    time: "",
    personInfo: "",
  },
  {
    brand: "",
    model: "",
    authNum: "",
    condition: "",
    exist: "",
    gender: "",
    year: "",
    size: "",
    movement: "",
    description: "",
    hopePrice: "",
    photoUrl: "",
    place: "",
    time: "",
    personInfo: "",
  },
  {
    brand: "",
    model: "",
    authNum: "",
    condition: "",
    exist: "",
    gender: "",
    year: "",
    size: "",
    movement: "",
    description: "",
    hopePrice: "",
    photoUrl: "",
    place: "",
    time: "",
    personInfo: "",
  },
  {
    brand: "",
    model: "",
    authNum: "",
    condition: "",
    exist: "",
    gender: "",
    year: "",
    size: "",
    movement: "",
    description: "",
    hopePrice: "",
    photoUrl: "",
    place: "",
    time: "",
    personInfo: "",
  },
  {
    brand: "",
    model: "",
    authNum: "",
    condition: "",
    exist: "",
    gender: "",
    year: "",
    size: "",
    movement: "",
    description: "",
    hopePrice: "",
    photoUrl: "",
    place: "",
    time: "",
    personInfo: "",
  },
  {
    brand: "",
    model: "",
    authNum: "",
    condition: "",
    exist: "",
    gender: "",
    year: "",
    size: "",
    movement: "",
    description: "",
    hopePrice: "",
    photoUrl: "",
    place: "",
    time: "",
    personInfo: "",
  },
  {
    brand: "",
    model: "",
    authNum: "",
    condition: "",
    exist: "",
    gender: "",
    year: "",
    size: "",
    movement: "",
    description: "",
    hopePrice: "",
    photoUrl: "",
    place: "",
    time: "",
    personInfo: "",
  },
];

export const findExample = [
  { productName: "", price: "", requestDescription: "", personInfo: "" },
];

export const appManageExample = [
  {
    sequence: "1",
    latestVersion: "1.7.6",
    useVersion: "1.7.6",
    platform: "All",
    check: "운영",
    updateType: "반강제",
    description: "설명설명",
    period: "",
    necessary: "",
    select: "",
    goOrNor: "활성",
  },
  {
    sequence: "1",
    latestVersion: "1.7.6",
    useVersion: "1.7.6",
    platform: "All",
    check: "운영",
    updateType: "반강제",
    description: "설명설명",
    period: "",
    necessary: "",
    select: "",
    goOrNor: "활성",
  },
  {
    sequence: "1",
    latestVersion: "1.7.6",
    useVersion: "1.7.6",
    platform: "All",
    check: "운영",
    updateType: "반강제",
    description: "설명설명",
    period: "",
    necessary: "",
    select: "",
    goOrNor: "활성",
  },
  {
    sequence: "1",
    latestVersion: "1.7.6",
    useVersion: "1.7.6",
    platform: "All",
    check: "운영",
    updateType: "반강제",
    description: "설명설명",
    period: "",
    necessary: "",
    select: "",
    goOrNor: "활성",
  },
  {
    sequence: "1",
    latestVersion: "1.7.6",
    useVersion: "1.7.6",
    platform: "All",
    check: "운영",
    updateType: "반강제",
    description: "설명설명",
    period: "",
    necessary: "",
    select: "",
    goOrNor: "활성",
  },
];

export const UPDATE_TYPE_LIST = [
  { value: "선택", label: "선택" },
  { value: "강제", label: "강제" },
  { value: "반강제", label: "반강제" },
];
export const UPDATE_EN_KO = {
  OPTIONAL: "선택",
  SEMI_MANDATORY: "반강제",
  MANDATORY: "강제",
};
export const UPDATE_KO_EN = {
  선택: "OPTIONAL",
  반강제: "SEMI_MANDATORY",
  강제: "MANDATORY",
};
export const OPERATION_TYPE_LIST = [
  { value: "운영", label: "운영" },
  { value: "점검", label: "점검" },
];
export const ACTIVATION_LIST = [
  { value: "활성화", label: "활성화" },
  { value: "비활성화", label: "비활성화" },
];
export const PLATFORM_LIST = [
  // { value: "ALL", label: "ALL" },
  { value: "ANDROID", label: "ANDROID" },
  { value: "IOS", label: "IOS" },
];

export const RESERVATION_STATUS = [
  {
    label: "REGISTERED",
    value: "REGISTERED",
  },
  {
    label: "APPROVED",
    value: "APPROVED",
  },
  {
    label: "VISITED",
    value: "VISITED",
  },
  {
    label: "SALE_REGISTERED",
    value: "SALE_REGISTERED",
  },
  {
    label: "SOLD",
    value: "SOLD",
  },
];

export const FILTER_STATUS = [
  {
    label: "선택",
    value: '',
  },
  {
    label: "REGISTERED",
    value: "REGISTERED",
  },
  {
    label: "APPROVED",
    value: "APPROVED",
  },
  {
    label: "VISITED",
    value: "VISITED",
  },
  {
    label: "SALE_REGISTERED",
    value: "SALE_REGISTERED",
  },
  {
    label: "SOLD",
    value: "SOLD",
  },
];

export const AUCTION_STATUS = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "WAIT",
    value: "WAIT",
  },
  {
    label: "AGREE",
    value: "AGREE",
  },
  {
    label: "REJECT",
    value: "REJECT",
  },
];

export const SHOW_OR_NOT = [
  {
    label: "없음",
    value: "NONE",
  },
  {
    label: "오늘 보지않기",
    value: "NOT_SEEING_TODAY",
  },
  {
    label: "다신 보지않기",
    value: "NOT_SEEING_AGAIN",
  },
];

export const SHOW_OR_NOT_CHANGE = {
  NONE: "없음",
  NOT_SEEING_TODAY: "오늘 보지않기",
  NOT_SEEING_AGAIN: "다신 보지않기",
};

export const DEFAULT_FILE_SIZE_LIMIT = 10;

export const DEVICE_TYPE_OPTION = [
  //  {
  //   label:'선택',
  //   value:''
  // },
  {
    label: "PC",
    value: "PC",
  },
  {
    label: "MOBILE",
    value: "MOBILE",
  },
];

export const POPUP_USE_OPTIONS = [
  {
    label: "YES",
    value: true,
  },
  {
    label: "NO",
    value: false,
  },
];

export const SEARCH_OPERATING = {
  true: "운영",
  false: "점검",
  "": "없음",
};
export const SEARCH_ACTIVE = {
  true: "활성화",
  false: "비활성화",
  "": "없음",
};
