import React, { useState, useEffect,useRef } from "react";
import styled from "styled-components";
import { Container, Button, Row, Card, CardBody, CardTitle,Input } from "reactstrap";
import { Editor } from "@toast-ui/react-editor";
import "@toast-ui/editor/dist/toastui-editor.css";
import {
  decodingHtmlTags,
  encodingHtmlTags,
  replaceNewlineWith,
  replaceNewlineWithBrTag,
} from "../../../helpers/stringHelpers";
import {
  getFAQListApi,
  createNewFAQApi,
  modifyOneFaqApi,
  deleteOneFaqApi,
} from "../../../apis/customer";
import PaginationComponent from "../../PaginationComponent";
import CommonHelper from "../../../helpers/CommonHelper";
import FaqView from './components/FaqView'
const emptyForm = {
  question: "",
  answer: "",
  keywords: [],
  faqType: "SALE",
};

const MobileFaq = () => {
  const [showNewFAQForm, setShowNewFAQForm] = useState(false);
  const [openModifyFaq, setOpenModifyFaq] = useState(false);
  const [faqList, setFaqList] = useState([]);
  const [faqInfo, setFaqInfo] = useState({})
  const [addKeywordInput, setAddKeywordInput] = useState("");
  const [addNewKeywordInput, setAddNewKeywordInput] = useState("");
  const [showFaqDetail, setShowFaqDetail] = useState(false);
  const [oneFaq, setOneFaq] = useState({});
  const [newFaqForm, setNewFaqForm] = useState(emptyForm);
  const [modifyFaqForm, setModifyFaqForm] = useState(emptyForm);
  const faqAppEditor = useRef(null);
  const faqModifyAppRef = useRef(null);

  /////페이지네이션
  const [page, setPage] = useState(0);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(4);
  const LIMIT = 10;

  const { totalPage } = faqInfo;

  const pageNumbers = [];
  for (let i = 0; i < totalPage; i++) {
    pageNumbers.push(i + 1);
  }
  const newPageNumbers = pageNumbers.slice(start, end);

  const paginate = (number) => {
    const num = number === 0 ? number : number - 1;
    setPage(num);
  };

  const prevPage = () => {
    if (page + 1 === 1) {
      return alert("첫페이지입니다.");
    }
    if ((page + 1) % 4 === 1) {
      const s = start > 0 ? start - 4 : 0;
      const e = end > 0 ? end - 4 : end;

      setStart(s);
      setEnd(e);
    }
    setPage(page - 1);
  };

  const nextPage = () => {
    if (page + 2 > pageNumbers.length) {
      return alert("마지막페이지입니다.");
    } else {
      setPage(page + 1);
      if ((page + 2) % 4 === 1) {
        const s = start + 4;
        const e = end + 4;
        setStart(s);
        setEnd(e);
      }
    }
  };

  // const resetModifyInput = () => {
  //   setModifyFaqForm(emptyForm)
  //   setAddKeywordInput('');
  // }

  useEffect(() => {
    getFAQList();
  }, [page]);

  /**
   * FAQ등록 폼 열기
   */
  const openNewFAQForm = () => {
    setShowNewFAQForm(true);
  };

  /**
   * FAQ등록 폼 닫기
   */
  const closeNewFAQForm = () => {
    resetNewFAQInput();
    setShowNewFAQForm(false);
  };

  /**
   * 새 FAQ 등록 input onChange 관리 함수
   */
  const handleChange = (e) => {
    const copyNewFaqForm = { ...newFaqForm };
    if (e.target.name === "새 FAQ 질문") {
      copyNewFaqForm.question = e.target.value;
    } else if (e.target.name === "새 FAQ 답변") {
      copyNewFaqForm.answer = e.target.value;
    }
    setNewFaqForm(copyNewFaqForm);
  };

  /**
   * FAQ 수정 input onChange 관리 함수
   */
  const handleModifyChange = (e) => {
    const copyModifyForm = { ...modifyFaqForm };
    if (e.target.name === "FAQ 질문 수정") {
      copyModifyForm.question = e.target.value;
    } else if (e.target.name === "FAQ 답변 수정") {
      copyModifyForm.answer = e.target.value;
    }
    setModifyFaqForm(copyModifyForm);
  };

  /**
   * 하나의 공지사항 선택하면 state에 저장 및 상세모달 state true
   */
  const storeOneFaq = (oneFaq) => {
    setShowFaqDetail(true);
    setOneFaq(oneFaq || {});
    const fakeForm = { ...modifyFaqForm };
    fakeForm.question = oneFaq?.question ? oneFaq.question : "";
    fakeForm.answer = oneFaq?.answer ? oneFaq.answer : "";
    fakeForm.keywords = oneFaq?.keywords ? oneFaq.keywords : [];
    setModifyFaqForm(fakeForm);
  };

  /**
   * 새로운 FAQ input reset 함수
   */
  const resetNewFAQInput = () => {
    const resetCopyForm = { ...emptyForm };
    resetCopyForm.keywords = [];
    setNewFaqForm(resetCopyForm);
    setAddNewKeywordInput("");
  };

  /**
   * 새 FAQ 키워드 push 함수
   */
  const addNewKeywords = () => {
    const copyNewForm = { ...newFaqForm };
    copyNewForm.keywords.push(addNewKeywordInput);
    setNewFaqForm(copyNewForm);
    setAddNewKeywordInput("");
  };

  /**
   * 수정 FAQ 키워드 push 함수
   */
  const addModifyKeywords = () => {
    const copyModifyForm = { ...modifyFaqForm };
    copyModifyForm.keywords.push(addKeywordInput);
    setModifyFaqForm(copyModifyForm);
    setAddKeywordInput("");
  };

  /**
   * 새 faq 키워드 하나 삭제 함수
   */
  const deleteNewKeywords = (keyword) => {
    const copyNewForm = { ...newFaqForm };
    copyNewForm.keywords = copyNewForm.keywords.filter((el) => el !== keyword);
    setNewFaqForm(copyNewForm);
  };

  /**
   * 수정 faq 키워드 하나 삭제 함수
   * @param {*} keyword
   */
  const deleteModifyKeywords = (keyword) => {
    const copyModifyForm = { ...modifyFaqForm };
    copyModifyForm.keywords = copyModifyForm.keywords.filter(
      (el) => el !== keyword
    );
    setModifyFaqForm(copyModifyForm);
  };

  const handleModifyOrNot = () => {
    setOpenModifyFaq(true)
    setShowFaqDetail(false)
  }
  return (
    <>
      {showNewFAQForm && (
        <Card>
          <CardTitle className="bg-light border-bottom p-3 mb-0">
            새로운 앱 판매 FAQ 등록하기
          </CardTitle>
          <CardBody>
            <Container>
              <Row>
                <label
                  style={{ marginTop: 10, color: "black", fontWeight: 600 }}>
                  질문 *
                </label>
                <Input
                  autoFocus
                  style={{ marginRight: 0 }}
                  fullWidth
                  type="text"
                  placeholder="글 제목"
                  value={newFaqForm.question}
                  onChange={(e) =>
                    setNewFaqForm({ ...newFaqForm, question: e.target.value })
                  }
                />
              </Row>
              <Row style={{ width: "100%" }}>
                <label
                  style={{ marginTop: 10, color: "black", fontWeight: 600 }}>
                  답변 *
                </label>
                <Wrapper>
                  <Editor
                    ref={faqAppEditor}
                    initialValue=""
                    previewStyle="vertical"
                    height="600px"
                    initialEditType="wysiwyg"
                    useCommandShortcut={true}
                  />
                </Wrapper>
              </Row>
              <Row style={{ display: "flex", justifyContent: "space-between" }}>
                <Button onClick={() => createNewFAQ()}>등록하기</Button>
                <Button onClick={() => closeNewFAQForm(false)}>닫기</Button>
              </Row>
              {/* <Row style={{ margin: "10px" }}>
                <label style={{ margin: "3px" }}>키워드</label>
                {newFaqForm.keywords.map((keyword) => (
                  <div
                    style={{
                      border: "1px solid gray",
                      padding: "8px",
                      borderRadius: "15px",
                      display: "flex",
                    }}>
                    {keyword}
                    <div
                      style={{ fontWeight: "bold", paddingLeft: "7px" }}
                      onClick={() => deleteNewKeywords(keyword)}>
                      x
                    </div>
                  </div>
                ))}
                <input
                  type="text"
                  placeholder="추가할 keyword"
                  value={addNewKeywordInput}
                  onChange={(e) => setAddNewKeywordInput(e.target.value)}
                />
                <Button onClick={() => addNewKeywords()}>+</Button>
              </Row> */}
              {/* <Row style={{ margin: "10px" }}>
                <label style={{ margin: "3px" }}>질문</label>
                <input
                  placeholder="질문"
                  style={{ width: "40%", margin: "5px" }}
                  value={newFaqForm.question}
                  onChange={(e) => handleChange(e)}
                  name="새 FAQ 질문"
                />
              </Row>
              <Row style={{ margin: "10px" }}>
                <label style={{ margin: "3px" }}>답변</label>
                <textarea
                  placeholder="답변"
                  style={{ width: "80%", margin: "5px" }}
                  value={newFaqForm.answer}
                  name="새 FAQ 답변"
                  onChange={(e) => handleChange(e)}
                />
              </Row>
              <Row style={{ display: "flex", justifyContent: "space-between" }}>
                <Button onClick={() => createNewFAQ()}>등록하기</Button>
                <Button onClick={() => closeNewFAQForm()}>닫기</Button>
              </Row> */}
            </Container>
          </CardBody>
        </Card>
      )}
      {showFaqDetail && oneFaq && (
        <Card>
          <CardTitle className="bg-light border-bottom p-3 mb-0">
            FAQ 상세
          </CardTitle>
          <CardBody>
            <Container>
              {/* <Row style={{ margin: "10px" }}>
                <label style={{ margin: "3px" }}>키워드</label>
                {modifyFaqForm.keywords.map((keyword) => (
                  <div
                    style={{
                      border: "1px solid gray",
                      padding: "5px",
                      borderRadius: "15px",
                      display:'flex'
                    }}>
                    {keyword}
                    <div
                      style={{ fontWeight: "bold", paddingLeft: "7px" }}
                      onClick={() => deleteModifyKeywords(keyword)}>
                      x
                    </div>
                  </div>
                ))}
                <input
                  type="text"
                  placeholder="추가할 keyword"
                  value={addKeywordInput}
                  onChange={(e) => setAddKeywordInput(e.target.value)}
                />
                <Button onClick={() => addModifyKeywords()}>+</Button>
              </Row> */}
              <Row style={{ margin: "10px" }}>
                <label
                  style={{ marginTop: 10, color: "black", fontWeight: 600 }}>
                  질문 *
                </label>
                <Input
                  style={{ width: "40%", margin: "5px" }}
                  value={modifyFaqForm.question}
                  disabled
                />
              </Row>
              <Row style={{ margin: "10px" }}>
                <label
                  style={{
                    marginTop: 10,
                    color: "black",
                    fontWeight: 600,
                    width: "100%",
                    marginBottom: "10px",
                  }}>
                  답변 *
                </label>
                <FaqView markdown={modifyFaqForm.answer} />
              </Row>
              <Row style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  {/* <Button onClick={() => modifyOneFaq()}>수정하기</Button> */}
                  <Button onClick={handleModifyOrNot}>
                    수정 시작하기
                  </Button>
                  <Button onClick={() => deleteOneFaq()}>삭제하기</Button>
                </div>
                <Button onClick={() => setShowFaqDetail(false)}>닫기</Button>
              </Row>
            </Container>
          </CardBody>
        </Card>
      )}
      {openModifyFaq && (
        <Card>
          <CardTitle className="bg-light border-bottom p-3 mb-0">
            Faq 수정
          </CardTitle>
          <CardBody maxWidth="xl">
            <Row>
              <label style={{ marginTop: 10, color: "black", fontWeight: 600 }}>
                질문 *
              </label>
              <Input
                autoFocus
                style={{ marginRight: 0 }}
                fullWidth
                type="text"
                placeholder="글 제목"
                value={modifyFaqForm.question}
                onChange={(e) =>
                  setModifyFaqForm({
                    ...modifyFaqForm,
                    question: e.target.value,
                  })
                }
              />
            </Row>
            <Row style={{ width: "100%" }}>
              <label style={{ marginTop: 10, color: "black", fontWeight: 600 }}>
                답변 *
              </label>
              <Wrapper>
                <Editor
                  ref={faqModifyAppRef}
                  previewStyle="vertical"
                  height="600px"
                  initialEditType="wysiwyg"
                  useCommandShortcut={true}
                  initialValue={replaceNewlineWithBrTag(
                    decodingHtmlTags(modifyFaqForm.answer)
                  )}
                />
              </Wrapper>
            </Row>
            <Row style={{ display: "flex", justifyContent: "space-between" }}>
              <Button onClick={() => modifyOneFaq()}>수정하기</Button>
              <Button onClick={() => setOpenModifyFaq(false)}>닫기</Button>
            </Row>
          </CardBody>
        </Card>
      )}
      <Card>
        <CardBody>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
            }}>
            <div>
              <CardTitle>App - 판매메뉴 - faq</CardTitle>
            </div>
            {!showNewFAQForm && (
              <Button onClick={() => openNewFAQForm()}>FAQ 등록</Button>
            )}
          </div>
          <ul style={{ margin: "0", padding: "0" }}>
            {faqList.map((faq, idx) => (
              <li
                style={{
                  display: "flex",
                  borderBottom: "1px solid rgba(0,0,0,0.3)",
                  width: "100%",
                }}
                key={idx}>
                <div
                  style={{
                    width: "5%",
                    margin: "3px",
                    alignItems: "center",
                    minWidth: "fit-content",
                  }}>
                  {faq?.id ? faq.id : ""}
                </div>
                <div
                  style={{
                    width: "70%",
                    margin: "3px",
                    alignItems: "center",
                  }}>
                  {faq?.question ? faq.question : ""}
                </div>
                <div
                  style={{
                    width: "10%",
                    margin: "3px",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}>
                  {/* {faq?.answer
                    ? faq.answer
                        .replaceAll("&lt;", "<")
                        .replaceAll("&gt;", ">")
                        .replaceAll("<br/>", "\r\n")
                    : ""} */}
                </div>
                <div
                  style={{
                    width: "12%",
                    margin: "3px",
                  }}>
                  <Button onClick={() => storeOneFaq(faq)}>상세조회</Button>
                </div>
              </li>
            ))}
          </ul>
          <PaginationComponent
            prevPage={prevPage}
            paginate={paginate}
            nextPage={nextPage}
            newPageNumbers={newPageNumbers}
            page={page}
            lastPage={faqInfo.lastPage}
          />
        </CardBody>
      </Card>
    </>
  );

  /**
   * FAQ리스트 부르는 함수
   */
  function getFAQList() {
    const authToken = CommonHelper.getAuthToken();
    if (authToken !== null && authToken !== undefined) {
      getFAQListApi(authToken, "SALE",page).then(
        ({ data: { status, error, data } }) => {
          if (status === "SUCCESS") {
            setFaqList(data.content || []);
            setFaqInfo(data || {})
          } else {
            alert(error);
          }
        }
      );
    }
  }

  /**
   * 새로운 FAQ생성하는 함수
   */
  function createNewFAQ() {
    let content = faqAppEditor.current.getInstance().getMarkdown();
    if (!newFaqForm.question) {
      alert("질문을 입력해주세요.");
      return;
    }

    if (!content) {
      alert("답변을 입력해주세요.");
      return;
    }
    content = encodingHtmlTags(replaceNewlineWith(content, `<br/>`));
    const params = {
      ...newFaqForm,
      answer: content,
    }
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      createNewFAQApi(authToken, params).then(
        ({ data: { status, error, data } }) => {
          if (status === "SUCCESS") {
            alert("성공적으로 생성되었습니다.");
            setShowNewFAQForm(false)
            resetNewFAQInput();
            getFAQList();
          } else {
            alert(error);
          }
        }
      );
    }
  }

  /**
   * FAQ 수정하는 함수
   */
  function modifyOneFaq() {
    let content = faqModifyAppRef.current.getInstance().getMarkdown();
    if (!modifyFaqForm.question) {
      alert("질문을 입력해주세요.");
      return;
    }

    if (!content) {
      alert("답변을 입력해주세요.");
      return;
    }
    content = encodingHtmlTags(replaceNewlineWith(content, `<br/>`));
    const params = {
      ...modifyFaqForm,
      answer: content,
    };
    const authToken = CommonHelper.getAuthToken();
    if (authToken !== null && authToken !== undefined) {
      modifyOneFaqApi(authToken, oneFaq.id, params).then(
        ({ data: { status, detail, data } }) => {
          if (status === "SUCCESS") {
            alert("성공적으로 수정되었습니다.");
            setOpenModifyFaq(false)
            setShowFaqDetail(false)
            //  resetModifyInput()
            getFAQList();
          } else {
            alert(detail);
          }
        }
      );
    }
  }

  /**
   * FAQ 삭제하는 함수
   */
  function deleteOneFaq() {
    const authToken = CommonHelper.getAuthToken();
    if (authToken !== null && authToken !== undefined) {
      deleteOneFaqApi(authToken, oneFaq.id).then(
        ({ data: { status, detail, data } }) => {
          if (status === "SUCCESS") {
            alert("성공적으로 삭제되었습니다.");
            setShowFaqDetail(false);
            getFAQList();
          } else {
            alert(detail);
          }
        }
      );
    }
  }
};
export default MobileFaq;

const Wrapper = styled.div`
  padding-bottom: 2.8rem;
  font-size: 1.3rem;
  font-family: "Lato";
  width: 100%;
  label {
    display: block;
    margin-bottom: 1rem;
  }
  &.bordered {
    .editorWrapper {
      border-color: red;
    }
  }
  > div + p {
    width: 100%;
    padding-top: 12px;
    font-size: 1.2rem;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: -9px;
  }
`;