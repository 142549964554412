import React, { useState } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";
import ServiceChangeMainBG from "./admin-service-components/ServiceChangeMainBG";
import ServiceStoreBanner from "./admin-service-components/ServiceStoreBanner";
import ServiceSplash from "./admin-service-components/ServiceSplash";
import ServiceAppVersion from "./admin-service-components/ServiceAppVersion";

const SERVICE_MENU = [
  "Mobile 시계시세 배너 링크 관리",
  "매장소개 배너 관리",
  // "스플래시",
  "앱 버전 관리",
];

const ServiceSetting = () => {
  // const [currMenu, setCurrMenu] = useState(SERVICE_MENU[0]);
  const [currMenu, setCurrMenu] = useState("앱 버전 관리");
  const changeMenu = (e) => {
    setCurrMenu(e.target.value);
  };
  return (
    <>
      <Card>
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            {SERVICE_MENU.map((menu) => (
              <Button
                // style={
                //   menu === currMenu
                //     ? {
                //         border: "2px solid black",
                //         padding: "6px",
                //         cursor: "pointer",
                //       }
                //     : {
                //         border: "1px solid gray",
                //         padding: "6px",
                //         cursor: "pointer",
                //       }
                // }
                color="info"
                value={menu}
                onClick={(e) => changeMenu(e)}>
                {menu}
              </Button>
            ))}
          </div>
        </CardTitle>
        <CardBody>
          {currMenu === "Mobile 시계시세 배너 링크 관리" && (
            <ServiceChangeMainBG />
          )}
          {currMenu === "매장소개 배너 관리" && <ServiceStoreBanner />}
          {currMenu === "스플래시" && <ServiceSplash />}
          {currMenu === "앱 버전 관리" && <ServiceAppVersion />}
        </CardBody>
      </Card>
    </>
  );
};

export default ServiceSetting;
