import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Table,
  Button,
  Input,
  CardImg,
} from "reactstrap";
import { Editor } from "@toast-ui/react-editor";
import "@toast-ui/editor/dist/toastui-editor.css";
import styled from "styled-components";
import AdminCreateSell from "./SellComponents/AdminCreateSell";
import {
  getReservationSellListApi,
  changeStateApi,
  modifyOneSellReservationApi,
  sendReservationToAuctionApi,
  deleteOneSellReservationApi,
  searchModelForChangeApi,
  getOneReservationSellListApi,
} from "../../../apis/reservation";
import CommonHelper from "../../../helpers/CommonHelper";
import { handleVisitTime } from "../../../common/common";
import { RESERVATION_STATUS, FILTER_STATUS } from "../../../common/constants";
import { getStoreListApi } from "../../../apis/main";
import { getSiteInfoApi } from "../../../apis/modelDetail";
import { uploadFile, uploadSellFile } from "../../../apis/uploadFile";
import { SERVER_API_HOST } from "../../../common/environment";
import PaginationComponent from "../../PaginationComponent";
import useDebounce from "../../../utils/useDebounce";
import {
  decodingHtmlTags,
  encodingHtmlTags,
  replaceNewlineWith,
  replaceNewlineWithBrTag,
} from "../../../helpers/stringHelpers";
const initAuctionChange = {
  startDate: null,
  endDate: null,
  startPrice: "",
};

// TODO : 사진파일 들어오면 img로 보여주는거 추가하기
const ReservationSell = () => {
  const onePhotoInputRef = useRef(null);
  const [sellReservationStatus, setSellReservationStatus] = useState(null);
  const [registeredSellList, setRegisteredSellList] = useState([]);
  const [registeredSellInfo, setRegisteredSellInfo] = useState({});
  const [modifyId, setModifyId] = useState(null);
  const [oneReservation, setOneReservation] = useState(null);
  const [showOneDetail, setShowOneDetail] = useState(false);
  const [oneDetailId, setOneDetailId] = useState("");
  const [oneStatusFilter, setOneStatusFilter] = useState(
    FILTER_STATUS[0].value
  );
  const [seekModel, setSeekModel] = useState("");
  const [currentStoreList, setCurrentStoreList] = useState([]);
  ////모델 교체를 위한 모델 검색
  const [searchResultArray, setSearchResultArray] = useState([]);
  const [searchModelInput, setSearchModelInput] = useState("");
  const [showSearchInput, setShowSearchInput] = useState(false);
  //////옥션 전환 정보
  const [showInfoForAuction, setShowInfoForAuction] = useState(false);
  const [auctionChangeInfo, setAuctionChangeInfo] = useState(initAuctionChange);
  /////어드민에서 등록하는 판매예약
  const [showNewSell, setShowNewSell] = useState(false);
  //////시계 항목
  const [dialColorList, setDialColorList] = useState([]);
  const [dialTypeList, setDialTypeList] = useState([]);
  const [caseMaterialList, setCaseMaterialsList] = useState([]);
  const [caseDiameterList, setCaseDiametersList] = useState([]);
  ////debounce
  const debouncedSearchTerm = useDebounce(searchModelInput, 500);

  const modifyNoticeEditor = useRef(null);
  useEffect(() => {
    showSearchInput && searchModelForChange();
  }, [debouncedSearchTerm, showSearchInput]);
  /////페이지네이션
  const [page, setPage] = useState(0);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(4);
  const LIMIT = 10;

  const { totalPage } = registeredSellInfo;

  const pageNumbers = [];
  for (let i = 0; i < totalPage; i++) {
    pageNumbers.push(i + 1);
  }
  const newPageNumbers = pageNumbers.slice(start, end);

  const paginate = (number) => {
    const num = number === 0 ? number : number - 1;
    setPage(num);
  };

  const prevPage = () => {
    if (page + 1 === 1) {
      return alert("첫페이지입니다.");
    }
    if ((page + 1) % 4 === 1) {
      const s = start > 0 ? start - 4 : 0;
      const e = end > 0 ? end - 4 : end;

      setStart(s);
      setEnd(e);
    }
    setPage(page - 1);
  };

  const nextPage = () => {
    if (page + 2 > pageNumbers.length) {
      return alert("마지막페이지입니다.");
    } else {
      setPage(page + 1);
      if ((page + 2) % 4 === 1) {
        const s = start + 4;
        const e = end + 4;
        setStart(s);
        setEnd(e);
      }
    }
  };

  const onChangeModifyPhotoInput = async (event) => {
    // 파일을 선택 한 경우에만 실행
    let file = event.target.files[0];
    // 여러번 등록되는 버그 수정 (https://stackoverflow.com/questions/39484895/how-to-allow-input-type-file-to-select-the-same-file-in-react-component)
    const authToken = CommonHelper.getAuthToken();
    // 파일 업로드
    let nextPhotoUrl = await uploadFileAndGetUrl(file);
    if (nextPhotoUrl) {
      // nextPhotoUrl = nextPhotoUrl.replace('http://localhost:8080', SERVER_API_HOST); // TODO 임시 코드
      nextPhotoUrl = `${SERVER_API_HOST}${nextPhotoUrl}`;
      let copyOneReservation = { ...oneReservation };
      copyOneReservation.attachedFiles.push(nextPhotoUrl);
      setOneReservation(copyOneReservation);
    }
  };
  /**
   * 첨부파일 하나 삭제
   * @param {*} photoUrl
   */
  const deleteOnePhotoUrl = (photoUrl) => {
    let copyOneReservation = { ...oneReservation };
    copyOneReservation.attachedFiles = copyOneReservation.attachedFiles.filter(
      (url) => url !== photoUrl
    );
    setOneReservation(copyOneReservation);
  };

  useEffect(() => {
    getStoreList();
    getSiteInfo();
  }, []);

  const handleChange = (e) => {
    if (e.target.name === "판매 예약 상태") {
      setSellReservationStatus(e.target.value);
    } else if (e.target.name === "판매예약 상태별 조회") {
      setOneStatusFilter(e.target.value);
    } else if (e.target.name === "모델 검색") {
      setSeekModel(e.target.value);
    }
  };

  useEffect(() => {
    getReservationSellList();
  }, [page]);

  useEffect(() => {
    oneDetailId && getOneReservationSellList(oneDetailId);
  }, [oneDetailId]);

  useEffect(() => {
    if (oneReservation !== null) {
      setSellReservationStatus(oneReservation?.state);
    }
  }, [oneReservation]);

  const openOneDetail = (list) => {
    setOneDetailId(list.id);
  };

  const closeDetail = () => {
    setShowOneDetail(false);
    setOneDetailId(null);
  };

  const handleModifyReservation = (e) => {
    const { value, name } = e.target;
    const copyOneReservation = { ...oneReservation };
    if (name === "useAuction") {
      if (value === "옥션판매") {
        copyOneReservation[name] = true;
      } else {
        copyOneReservation[name] = false;
      }
    } else if (name === "price") {
      let deleteAll = value
        .replaceAll(" ", "")
        .replaceAll(",", "")
        .replaceAll(/[^0-9]/g, "");
      copyOneReservation[name] = deleteAll;
    } else {
      copyOneReservation[name] = value;
    }
    setOneReservation(copyOneReservation);
  };

  const handleModifyReservationWatch = (e) => {
    const { value, name } = e.target;
    const copyOneReservation = { ...oneReservation };
    copyOneReservation.watch[name] = value;
    setOneReservation(copyOneReservation);
  };

  const handleModifyStore = (storeInfo) => {
    const copyOneReservation = { ...oneReservation };
    // copyOneReservation.storeId = storeInfo.id || null;

    if (
      copyOneReservation?.storeIds?.filter((el) => el === storeInfo.id).length >
      0
    ) {
      copyOneReservation.storeIds = copyOneReservation.storeIds.filter(
        (el) => el !== storeInfo.id
      );
    } else {
      copyOneReservation.storeIds = [
        ...copyOneReservation.storeIds,
        storeInfo.id,
      ];
    }
    setOneReservation(copyOneReservation);
  };

  /**
   * 구성품 array에서 추가 혹은 삭제
   * @param {*} e
   */
  const handleOneReservationSupplements = (e) => {
    const { name, checked } = e.target;
    let copyOneReservation = { ...oneReservation };
    if (checked) {
      copyOneReservation.watch.supplements.push(name);
    } else {
      copyOneReservation.watch.supplements =
        copyOneReservation.watch.supplements.filter((el) => el !== name);
    }
    setOneReservation(copyOneReservation);
  };

  const modifyOneOnlyStatus = (el) => {
    setModifyId(el.id || null);
    setSellReservationStatus(el.state || null);
  };

  const handleAuctionChange = (e) => {
    const { value, name } = e.target;
    const copyAuctionInfo = { ...auctionChangeInfo };
    if (name === "startPrice") {
      let deleteAll = value
        .replaceAll(" ", "")
        .replaceAll(",", "")
        .replaceAll(/[^0-9]/g, "");
      copyAuctionInfo[name] = deleteAll;
    } else {
      copyAuctionInfo[name] = value;
    }
    setAuctionChangeInfo(copyAuctionInfo);
  };

  const checkAuctionInputs = () => {
    const { startDate, endDate, startPrice } = auctionChangeInfo;
    if (!startDate) {
      alert("경매 시작 시간을 지정해주세요.");
    } else if (!endDate) {
      alert("경매 마감 시간을 지정해주세요.");
    } else if (!startPrice) {
      alert("경매시작가를 지정해주세요.");
    } else {
      let result = window.confirm(
        `시작시간 : ${startDate}, 마감시간 : ${endDate}, 시작가 : ${Number(
          startPrice
        ).toLocaleString()}로 제품 경매를 생성하시겠습니까?`
      );
      if (result) {
        sendReservationToAuction();
      } else {
        alert("취소되었습니다.");
      }
    }
  };

  const handleChangeBrandModel = (model) => {
    const copyOneReservation = { ...oneReservation };
    copyOneReservation.watch.brand = model.brand;
    copyOneReservation.watch.model = model.model;
    copyOneReservation.watch.serialNo = model.serialNo;
    setOneReservation(copyOneReservation);
  };

  const closeSearchInput = () => {
    setSearchModelInput("");
    setShowSearchInput(false);
  };
  return (
    <>
      {showOneDetail && (
        <Card>
          <CardBody>
            <CardTitle>
              <div className="space-between">
                예약상세/예약수정
                <Button onClick={closeDetail}>닫기</Button>
              </div>
            </CardTitle>
            <Container>
              <Row style={{ marginTop: "40px" }}>
                <Col>
                  고유 id
                  <Input value={oneReservation?.id || "-"} disabled />
                </Col>
                <Col>
                  연결된 예약 건수
                  <Input
                    value={oneReservation?.reservationCount || 0}
                    disabled
                  />
                  <p>
                    *해당 판매예약과 연관된 구매예약의 갯수로, count가 0이면,
                    해당 판매예약을 삭제할 수 있습니다.
                  </p>
                </Col>
                <Col></Col>
              </Row>
              <Row style={{ marginTop: "40px" }}>
                <Col>
                  description (설명)
                  {/* <div className="bg-light border p-2 col-sm-4">
                  {oneReservation.description ? oneReservation.description : ""}
                </div> */}
                  <Input
                    value={
                      oneReservation?.description
                        ? oneReservation?.description
                        : ""
                    }
                    onChange={handleModifyReservation}
                    name="description"
                  />
                </Col>
                <Col>
                  가격
                  {/* <div className="bg-light border p-2 col-sm-4">
                  {oneReservation.price
                    ? Number(oneReservation.price).toLocaleString()
                    : ""}
                </div> */}
                  <Input
                    value={
                      oneReservation?.price
                        ? Number(oneReservation.price).toLocaleString()
                        : ""
                    }
                    name="price"
                    onChange={handleModifyReservation}
                  />
                </Col>
                <Col style={{ display: "flex", flexDirection: "column" }}>
                  상태
                  {/* <div className="bg-light border">
                    {oneReservation.state ? oneReservation.state : ""}
                  </div> */}
                  {!modifyId ? (
                    <div style={{ display: "flex" }}>
                      <select
                        name="판매 예약 상태"
                        disabled
                        value={oneReservation?.state || ""}>
                        <option>{oneReservation?.state}</option>
                      </select>
                      <Button
                        style={{ marginLeft: "3px" }}
                        onClick={() =>
                          modifyOneOnlyStatus(oneReservation || null)
                        }>
                        변경하기
                      </Button>
                    </div>
                  ) : (
                    <div style={{ display: "flex" }}>
                      <select
                        name="판매 예약 상태"
                        id={`check-판매예약상태`}
                        value={sellReservationStatus || ""}
                        onChange={(e) => handleChange(e)}>
                        {RESERVATION_STATUS.map(({ label, value }, idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        ))}
                      </select>
                      <Button
                        style={{ marginLeft: "3px" }}
                        onClick={() => changeState(oneReservation?.id)}>
                        저장하기
                      </Button>
                    </div>
                  )}
                  {/* <select
                    value={oneReservation.state || ""}
                    onChange={handleModifyReservation}
                    className="form-control"
                    name="state">
                    <option></option>
                    <option>REGISTERED</option>
                    <option>APPROVED</option>
                    <option>VISITED</option>
                    <option>SALE_REGISTERED</option>
                    <option>SOLD</option>
                  </select> */}
                </Col>
              </Row>
              <Row style={{ marginTop: "40px" }}>
                <Col style={{ display: "flex", flexDirection: "column" }}>
                  보유지점
                  {currentStoreList?.map((store, idx) => (
                    <div
                      key={idx}
                      // onClick={() => setAuctionStore(store.id)}
                    >
                      <input
                        type="checkbox"
                        onChange={() => handleModifyStore(store)}
                        // disabled
                        name="store"
                        // checked={oneReservation?.storeId === store?.id}
                        checked={
                          oneReservation?.storeIds?.filter(
                            (el) => el === store.id
                          ).length > 0
                        }
                      />
                      <label htmlFor="supplement">{store?.name || "-"}</label>
                    </div>
                  ))}
                </Col>
                <Col style={{ display: "flex", flexDirection: "column" }}>
                  방문시간
                  {/* <div className="bg-light border p-2 col-sm-4">
                  {oneReservation.visitDateTime
                    ? handleVisitTime(oneReservation.visitDateTime)
                    : null}
                </div> */}
                  <input
                    type="datetime-local"
                    className="form=control"
                    name="visitDateTime"
                    onChange={handleModifyReservation}
                    value={oneReservation?.visitDateTime || null}
                  />
                </Col>
                <Col></Col>
                {/* <Col style={{ display: "flex", flexDirection: "column" }}>
                  등록자가 원하는 판매방식 (옥션판매/일반판매) */}
                {/* <div className="bg-light border p-2 col-sm-4">
                  {oneReservation.useAuction ? "옥션 판매" : "일반 판매"}
                </div> */}
                {/* <select
                    value={oneReservation?.useAuction ? "옥션판매" : "일반판매"}
                    onChange={handleModifyReservation}
                    className="form=control"
                    name="useAuction">
                    <option>옥션판매</option>
                    <option>일반판매</option>
                  </select>
                </Col> */}
              </Row>
            </Container>
          </CardBody>
          <CardBody>
            <CardTitle>
              시계상세{" "}
              <Button onClick={() => setShowSearchInput(true)}>
                모델 교체하기
              </Button>{" "}
            </CardTitle>

            <Container>
              <Row
                style={
                  showSearchInput
                    ? {
                        marginTop: "40px",
                        border: "1px solid lightgray",
                        padding: "20px",
                        position: "relative",
                      }
                    : { marginTop: "40px" }
                }>
                <Col>
                  브랜드
                  <Input
                    value={
                      oneReservation?.watch?.brand
                        ? oneReservation?.watch.brand
                        : ""
                    }
                    disabled
                    onChange={handleModifyReservationWatch}
                    name="brand"
                  />
                  {showSearchInput && (
                    <>
                      모델 검색하기
                      <Input
                        value={searchModelInput}
                        onChange={(e) => setSearchModelInput(e.target.value)}
                      />
                    </>
                  )}
                </Col>
                <Col>
                  모델
                  <Input
                    value={
                      oneReservation?.watch?.model
                        ? oneReservation?.watch.model
                        : ""
                    }
                    disabled
                    name="model"
                    onChange={handleModifyReservationWatch}
                  />
                  {showSearchInput && (
                    <>
                      검색 결과
                      <ul style={{ maxHeight: "120px", overflow: "scroll" }}>
                        {searchResultArray.length === 0 ? (
                          <li>검색 결과가 없습니다.</li>
                        ) : (
                          searchResultArray.map((model, idx) => (
                            <li
                              style={{ cursor: "pointer" }}
                              onClick={() => handleChangeBrandModel(model)}
                              key={idx}>
                              {model.brand || "-"} {model.model || "-"} (
                              {model.serialNo || "-"})
                            </li>
                          ))
                        )}
                      </ul>
                    </>
                  )}
                </Col>
                <Col>
                  레퍼런스
                  <Input
                    value={oneReservation?.watch?.serialNo || ""}
                    onChange={handleModifyReservationWatch}
                    disabled
                    name="serialNo"
                  />
                  {showSearchInput && (
                    <Button
                      onClick={closeSearchInput}
                      style={{ position: "absolute", bottom: "0", right: "0" }}>
                      모델 검색 닫기
                    </Button>
                  )}
                </Col>
              </Row>
              <Row style={{ marginTop: "40px" }}>
                <Col>
                  케이스 소재
                  <select
                    value={oneReservation?.watch?.caseMaterial || ""}
                    name="caseMaterial"
                    className="form-control"
                    onChange={handleModifyReservationWatch}>
                    <option></option>
                    {caseMaterialList?.map((material, idx) => (
                      <option key={idx}>{material}</option>
                    ))}
                  </select>
                </Col>
                <Col>
                  다이얼 타입
                  <select
                    className="form-control"
                    onChange={handleModifyReservationWatch}
                    value={oneReservation?.watch?.dialType}
                    name="dialType">
                    <option></option>
                    {dialTypeList?.map((type, idx) => (
                      <option key={idx}>{type}</option>
                    ))}
                  </select>
                </Col>
                <Col>
                  다이얼 색상
                  <select
                    className="form-control"
                    name="dialColor"
                    value={
                      oneReservation?.watch?.dialColor
                        ? oneReservation?.watch.dialColor
                        : ""
                    }
                    onChange={handleModifyReservationWatch}>
                    <option></option>
                    {dialColorList?.map((color, idx) => (
                      <option key={idx}>{color}</option>
                    ))}
                  </select>
                </Col>
              </Row>
              <Row style={{ marginTop: "40px" }}>
                <Col>
                  국내/국외 보유
                  <select
                    className="form-control"
                    name="holdingType"
                    value={
                      oneReservation?.holdingType
                        ? oneReservation?.holdingType
                        : ""
                    }
                    onChange={handleModifyReservation}>
                    <option></option>
                    <option>국내</option>
                    <option>국외</option>
                  </select>
                </Col>
                <Col>
                  영수증 유무
                  <select
                    className="form-control"
                    name="hasReceipt"
                    value={oneReservation?.hasReceipt ? true : false}
                    onChange={handleModifyReservation}>
                    <option value={true}>유</option>
                    <option value={false}>무</option>
                  </select>
                </Col>
                <Col></Col>
              </Row>
              <Row style={{ marginTop: "40px" }}>
                <Col>
                  성별
                  <select
                    className="form-control"
                    name="gender"
                    value={oneReservation?.watch?.gender || ""}
                    onChange={handleModifyReservationWatch}>
                    <option>MALE</option>
                    <option>FEMALE</option>
                    <option>UNISEX</option>
                  </select>
                </Col>
                <Col>
                  구동방식
                  <select
                    value={oneReservation?.watch?.movement || ""}
                    name="movement"
                    className="form-control"
                    onChange={handleModifyReservationWatch}>
                    <option></option>
                    <option>AUTOMATIC</option>
                    <option>PASSIVITY</option>
                    <option>QUARTZ</option>
                  </select>
                </Col>
                <Col>
                  생산 연도
                  <Input
                    value={oneReservation?.watch?.producedYear || ""}
                    onChange={handleModifyReservationWatch}
                    name="producedYear"
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "40px" }}>
                <Col>
                  구성품
                  {/* <div className="bg-light border p-2 col-sm-4">
                    {oneReservation.watch?.supplements?.map((supplement) => (
                      <div>{supplement}</div>
                    ))}
                  </div> */}
                  <div>
                    <input
                      type="checkbox"
                      onChange={handleOneReservationSupplements}
                      name="SIGAN_GUARANTEE"
                      checked={oneReservation?.watch?.supplements?.includes(
                        "SIGAN_GUARANTEE"
                      )}
                    />
                    <label htmlFor="supplement">하이시간보증서</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      onChange={handleOneReservationSupplements}
                      name="GUARANTEE"
                      checked={oneReservation?.watch?.supplements?.includes(
                        "GUARANTEE"
                      )}
                    />
                    <label htmlFor="supplement">보증서</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="BOX"
                      onChange={handleOneReservationSupplements}
                      checked={oneReservation?.watch?.supplements?.includes(
                        "BOX"
                      )}
                    />
                    <label htmlFor="supplement">박스</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="BOOKLET"
                      onChange={handleOneReservationSupplements}
                      checked={oneReservation?.watch?.supplements?.includes(
                        "BOOKLET"
                      )}
                    />
                    <label htmlFor="supplement">북렛</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="MEDAL"
                      onChange={handleOneReservationSupplements}
                      checked={oneReservation?.watch?.supplements?.includes(
                        "MEDAL"
                      )}
                    />
                    <label htmlFor="supplement">Medal</label>
                  </div>
                </Col>
                <Col>
                  지름
                  <select
                    value={oneReservation?.watch?.caseDiameter || ""}
                    name="caseDiameter"
                    className="form-control"
                    onChange={handleModifyReservationWatch}>
                    <option></option>
                    {caseDiameterList?.map((diameter, idx) => (
                      <option key={idx}>{diameter}</option>
                    ))}
                  </select>
                </Col>
                <Col>
                  {/* <div className="bg-light border p-2 col-sm-4">
                  {oneReservation.watch?.condition
                    ? oneReservation.watch.condition
                    : ""}
                </div> */}
                  컨디션 {oneReservation?.watch?.condition || 0}
                  <Input
                    type="range"
                    onChange={handleModifyReservationWatch}
                    value={oneReservation?.watch?.condition}
                    name="condition"
                    min="0"
                    max="10"
                    step="0.5"
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "40px" }}>
                <Col style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ marginBottom: "10px" }}>사진파일 추가</div>
                  <input
                    type="file"
                    accept="image/*"
                    ref={onePhotoInputRef}
                    key={oneReservation?.attachedFiles}
                    onChange={onChangeModifyPhotoInput}
                    // hidden
                  />
                </Col>
                <Col>
                  예약하러 가기 연결 link
                  <Input
                    placeholder="예약하러 가기 연결 link"
                    type="text"
                    value={
                      !!oneReservation.linkUrl ? oneReservation.linkUrl : ""
                    }
                    name="linkUrl"
                    onChange={handleModifyReservation}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "40px" }}>
                <Col>
                  {oneReservation?.attachedFiles?.map((photo) => (
                    <>
                      <CardImg style={{ width: "10%" }} src={photo} />
                      <button onClick={() => deleteOnePhotoUrl(photo)}>
                        X
                      </button>
                    </>
                  ))}
                </Col>
              </Row>
              <Row style={{ marginTop: "40px" }}>
                <div style={{ marginBottom: "10px", width: "100%" }}>
                  상세 정보
                </div>
                <Wrapper>
                  <Editor
                    ref={modifyNoticeEditor}
                    initialValue={replaceNewlineWithBrTag(
                      decodingHtmlTags(oneReservation?.additionalInfo)
                    )}
                    previewStyle="vertical"
                    height="600px"
                    initialEditType="wysiwyg"
                    useCommandShortcut={true}
                  />
                </Wrapper>
                {/* <SellEditorView
                  markdown={oneReservation?.additionalInfo || "없음"}
                /> */}
              </Row>
              <Row>
                <Col
                  style={{
                    marginTop: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <Button
                    onClick={modifyOneSellReservation}
                    style={{ marginRight: "10px" }}>
                    수정하기
                  </Button>
                  {/* <Button
                    onClick={() => setShowInfoForAuction(true)}
                    style={{ marginRight: "20px" }}>
                    드로우로 전환하기
                  </Button> */}
                  <Button
                    disabled={oneReservation?.reservationCount !== 0}
                    onClick={deleteOneSellReservation}>
                    삭제하기
                  </Button>
                </Col>
              </Row>
            </Container>
          </CardBody>
          {showInfoForAuction && (
            <CardBody>
              <CardTitle>드로우 전환를 위한 추가 정보</CardTitle>
              <Container>
                <Row>
                  <Col style={{ display: "flex", flexDirection: "column" }}>
                    드로우 시작 시간
                    <input
                      type="datetime-local"
                      className="form=control"
                      name="startDate"
                      onChange={handleAuctionChange}
                      value={auctionChangeInfo?.startDate || null}
                    />
                  </Col>
                  <Col style={{ display: "flex", flexDirection: "column" }}>
                    드로우 마감 시간
                    <input
                      type="datetime-local"
                      className="form=control"
                      name="endDate"
                      onChange={handleAuctionChange}
                      value={auctionChangeInfo?.endDate || null}
                    />
                  </Col>
                  <Col style={{ display: "flex", flexDirection: "column" }}>
                    드로우 제품 가격
                    <Input
                      placeholder="드로우 제품 가격"
                      value={
                        auctionChangeInfo?.startPrice
                          ? Number(
                              auctionChangeInfo.startPrice
                            ).toLocaleString()
                          : ""
                      }
                      name="startPrice"
                      onChange={handleAuctionChange}
                      type="text"
                    />
                  </Col>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "20px",
                  }}>
                  <Button
                    onClick={() => setShowInfoForAuction(false)}
                    style={{ marginRight: "10px" }}>
                    드로우 전환 취소
                  </Button>
                  <Button onClick={checkAuctionInputs}>드로우 등록하기</Button>
                </Row>
              </Container>
            </CardBody>
          )}
        </Card>
      )}
      {showNewSell && (
        <Card>
          <CardTitle
            className="bg-light border-bottom p-3 mb-0"
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
            }}>
            판매 예약 등록
            {/* (등록 후, 일반 판매 및 옥션 전환이 가능합니다.) */}
            <Button onClick={() => setShowNewSell(false)}>닫기</Button>
          </CardTitle>
          <AdminCreateSell
            getReservationSellList={getReservationSellList}
            dialColorList={dialColorList}
            dialTypeList={dialTypeList}
            caseDiameterList={caseDiameterList}
            caseMaterialList={caseMaterialList}
            setShowNewSell={setShowNewSell}
          />
        </Card>
      )}
      <Card>
        <CardBody>
          <CardTitle className="bg-light border-bottom p-3 mb-0 space-between">
            판매예약
            <div>
              <input
                value={seekModel}
                onChange={handleChange}
                name="모델 검색"
                placeholder="모델 검색"
                style={{ marginRight: "10px" }}
              />
              <select
                name="판매예약 상태별 조회"
                id={`check-판매상태조회`}
                value={oneStatusFilter}
                onChange={handleChange}>
                {FILTER_STATUS.map(({ label, value }, idx) => (
                  <option key={idx} value={value}>
                    {label}
                  </option>
                ))}
              </select>
              <Button
                style={{ margin: "0 10px" }}
                onClick={getReservationSellList}>
                상태조회
              </Button>
              <Button
                style={{ marginRight: "10px" }}
                onClick={() => setShowNewSell(true)}>
                판매예약 등록하기
              </Button>
            </div>
          </CardTitle>

          <CardTitle>
            <p>
              고객 등록: REGISTERED, 어드민 승인: APPROVED, 방문함: VISITED,
              판매 상품으로 등록됨: SALE_REGISTERED, 판매완료: SOLD
            </p>
          </CardTitle>
          {/* <Table className="no-wrap v-middle" responsive>
          <thead>
            <tr className="border-0" 
            >
                <th className="border-0">고유키</th>
                <th className="border-0">신청자</th>
                <th className="border-0">시계브랜드/이름</th>
                <th className="border-0">방문지점</th>
                <th className="border-0">시간</th>
                <th className="border-0">상태</th>
                <th className="border-0">변경 버튼</th>
                <th className="border-0">상세조회</th>
              </tr>
          </thead>
          <tbody>
            {registeredSellList.map((list,idx) => (
              <tr key={idx}>
                <td>{list.id ? list.id : ''}</td>
                <td>{list.createdBy ? list.createdBy : ''}</td>
                <td>{list.watch.brand ? list.watch.brand : ''}/{list.watch.model ? list.watch.model : ''}</td>
                <td>{list.store}</td>
                <td>{handleVisitTime(list.visitDateTime ? list.visitDateTime : null)}</td>
                {modifyId !== list.id ?
                  <>
                    <td>
                     {list.state ? list.state : ''}
                    </td>
                    <td>
                      <Button onClick={() =>setModifyId(list.id ? list.id : null)}>변경하기</Button> 
                    </td>
                  </>
                 : 
                 <>
                    <td>
                      <select
                      name="판매 예약 상태"
                      id={`check-판매예약상태`}
                      value={sellReservationStatus}
                      onChange={(e) => handleChange(e)}
                      >
                        {RESERVATION_STATUS.map(({ label, value }, idx) => (
                          <option key={idx} value={value}>
                            {label}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <Button onClick={() => changeState(list.id)}>저장하기</Button>
                    </td>
                    </>
                    }
                    <td>
                      <Button onClick={() => openOneDetail(list)}>
                        상세보기
                      </Button>
                    </td>
                </tr>
            ))}
          </tbody>
        </Table> */}
          <Table className="no-wrap v-middle" responsive>
            <thead>
              <tr className="border-0">
                <th className="border-0">고유 id</th>
                <th className="border-0">방문 예정 지점</th>
                <th className="border-0">날짜</th>
                <th className="border-0">브랜드</th>
                <th className="border-0">모델명</th>
                <th className="border-0">레퍼런스</th>
                <th className="border-0">관련 예약 건수</th>
                <th className="border-0">신청자</th>
                <th className="border-0">상태</th>
              </tr>
            </thead>
            <tbody>
              {registeredSellList.length > 0 &&
                registeredSellList.map((el, idx) => (
                  <tr key={idx}>
                    <td>{el.id ? el.id : "-"}</td>
                    <td>{el.store?.name ? el.store.name : "-"}</td>
                    <td>
                      {el.visitDateTime
                        ? handleVisitTime(el.visitDateTime)
                        : ""}
                    </td>
                    <td>{el.watch?.brand ? el.watch.brand : "-"}</td>
                    <td>{el.watch?.model ? el.watch.model : "-"}</td>
                    <td>{el.watch?.serialNo ? el.watch.serialNo : "-"}</td>
                    <td>{el.reservationCount ? el.reservationCount : 0}</td>
                    <td>{el.createdBy ? el.createdBy : "-"}</td>
                    <td>{el.state ? el.state : "-"}</td>
                    <td>
                      <Button
                        onClick={() => openOneDetail(el)}
                        style={{ marginRight: "10px" }}>
                        상세보기
                      </Button>
                    </td>
                  </tr>
                ))}
              <PaginationComponent
                prevPage={prevPage}
                paginate={paginate}
                nextPage={nextPage}
                newPageNumbers={newPageNumbers}
                page={page}
                lastPage={registeredSellInfo.lastPage}
              />
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  );

  function getSiteInfo() {
    getSiteInfoApi().then(({ data: { data, detail, status } }) => {
      if (status === "SUCCESS") {
        setDialColorList(data?.dialColors || []);
        setDialTypeList(data?.dialTypes || []);
        setCaseDiametersList(data?.caseDiameters || []);
        setCaseMaterialsList(data?.caseMaterials || []);
      } else {
        alert(detail);
      }
    });
  }

  function getStoreList() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      getStoreListApi(authToken).then(({ data: { status, detail, data } }) => {
        if (status === "SUCCESS") {
          setCurrentStoreList(data.content || []);
        } else {
          alert(detail);
        }
      });
    }
  }

  function getReservationSellList() {
    const authToken = CommonHelper.getAuthToken();
    if (authToken !== null && authToken !== undefined) {
      getReservationSellListApi(
        authToken,
        oneStatusFilter,
        page,
        seekModel
      ).then(({ data: { status, detail, data } }) => {
        if (status === "SUCCESS") {
          setRegisteredSellList(data?.content || []);
          setRegisteredSellInfo(data);
          if (showOneDetail) {
            let list = data.content.filter((el) => el.id === oneReservation.id);
            let oneList = list[0] || {};
            let copyList = { ...oneList };
            setOneReservation(copyList);
          }
        } else {
          console.log(detail);
          alert("판매 리스트를 불러오는데 문제가 생겼습니다.");
        }
      });
    }
  }

  function getOneReservationSellList(oneDetailId) {
    getOneReservationSellListApi(oneDetailId).then(
      ({ data: { status, detail, data } }) => {
        if (status === "SUCCESS") {
          let copyList = { ...data };
          copyList.store = null;
          copyList.storeIds = data?.stores?.map((el) => {
            return el.id;
          });
          setOneReservation(copyList);
          setShowOneDetail(true);
        } else {
          alert(detail);
          setShowOneDetail(false);
        }
      }
    );
  }

  function changeState(id) {
    const authToken = CommonHelper.getAuthToken();
    if (authToken !== null && authToken !== undefined) {
      changeStateApi(authToken, id, sellReservationStatus).then(
        ({ data: { status, detail, data } }) => {
          if (status === "SUCCESS") {
            alert("성공적으로 변경되었습니다.");
            setModifyId(null);
            // setShowOneDetail(false);
            getReservationSellList();
          } else {
            alert("변경에 실패했습니다.");
          }
        }
      );
    }
  }

  // 파일 업로드 후 URL 반환
  async function uploadFileAndGetUrl(file) {
    if (file) {
      const { type: fileType, name: fileName, size: fileSize } = file;
      // 파일 데이터 만들기
      const formData = new FormData();
      formData.append("file", file);

      // 파일 업로드
      const uploadResult = await uploadSellFile(formData);
      const {
        status,
        data: { url },
      } = uploadResult;
      if (status === 200 && !!url) {
        return url;
      } else {
        alert("파일 업로드 실패");
        return null;
      }
    } else {
      return null;
    }
  }

  function modifyOneSellReservation() {
    //판매예약 내용 수정
    const authToken = CommonHelper.getAuthToken();
    let content = modifyNoticeEditor.current.getInstance().getMarkdown();
    content = encodingHtmlTags(replaceNewlineWith(content, `<br/>`));
    let copyOneReservation = { ...oneReservation, additionalInfo: content };
    if (!!authToken) {
      modifyOneSellReservationApi(authToken, copyOneReservation).then(
        ({ data: { data, status, detail } }) => {
          if (status === "SUCCESS") {
            alert("성공적으로 수정되었습니다.");
            setShowOneDetail(false);
            setOneReservation(null);
            setOneDetailId(null);
            // setOne
            getReservationSellList();
          } else {
            alert(detail);
          }
        }
      );
    }
  }

  function deleteOneSellReservation() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      deleteOneSellReservationApi(authToken, oneReservation).then(
        ({ data: { status, detail } }) => {
          if (status === "SUCCESS") {
            alert("성공적으로 삭제되었습니다.");
            setOneReservation(null);
            setShowOneDetail(false);
            setOneDetailId(null);
            getReservationSellList();
          } else {
            alert(detail);
          }
        }
      );
    }
  }

  function sendReservationToAuction() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      sendReservationToAuctionApi(
        authToken,
        auctionChangeInfo,
        oneReservation.id
      ).then(({ data: { data, status, detail } }) => {
        if (status === "SUCCESS") {
          alert(
            "성공적으로 전환되었습니다. 해당 옥션은 옥션관리에서 확인하실 수 있습니다."
          );
          setShowInfoForAuction(false);
          setShowOneDetail(false);
          setOneReservation(null);
          setOneDetailId(null);
          setAuctionChangeInfo(initAuctionChange);
          getReservationSellList();
        }
      });
    }
  }

  function searchModelForChange() {
    searchModelForChangeApi(searchModelInput).then(
      ({ data: { data, detail, status } }) => {
        if (status === "SUCCESS") {
          setSearchResultArray(data || []);
        } else {
          alert(detail);
        }
      }
    );
  }
};

export default ReservationSell;

const Wrapper = styled.div`
  padding-bottom: 2.8rem;
  font-size: 1.3rem;
  font-family: "Lato";
  width: 100%;
  label {
    display: block;
    margin-bottom: 1rem;
  }
  &.bordered {
    .editorWrapper {
      border-color: red;
    }
  }
  > div + p {
    width: 100%;
    padding-top: 12px;
    font-size: 1.2rem;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: -9px;
  }
`;
