import { apiCall } from "./apiCall";
import { CLIENT_URL, SERVER_API_HOST } from "../common/environment";
import CommonHelper from "helpers/CommonHelper";

export const getReservationSellListApi = (
  authToken,
  searchStatus,
  page,
  seekModel
) => {
  return apiCall(
    //   `${SERVER_API_HOST}/api/reservation/sale?page=0&offset=15&direction=desc&property=id&state=REGISTERED`
    `${SERVER_API_HOST}/api/reservation/sale/list?page=${page}&offset=10&direction=desc`,
    "post",
    {
      state: searchStatus || null,
      filters: [
        {
          type: "INTEGRATED",
          keyword: seekModel,
        },
      ],
    },
    {
      authorization: authToken,
    }
  );
};

export const getOneReservationSellListApi = (id) => {
  return apiCall(
    `${SERVER_API_HOST}/api/reservation/sale/get/${id}`,
    "get",
    {},
    {}
  );
};

export const getOneReservationBuyListApi = (id) => {
  return apiCall(
    `${SERVER_API_HOST}/api/reservation/purchase/get/${id}`,
    "get",
    {},
    {}
  );
};

export const changeStateApi = (authToken, id, newState) => {
  return apiCall(
    `${SERVER_API_HOST}/api/reservation/sale/update/state/${id}`,
    "put",
    {
      state: newState,
    },
    {
      authorization: authToken,
    }
  );
};

export const modifyOneSellReservationApi = (authToken, oneReservation) => {
  return apiCall(
    `${SERVER_API_HOST}/api/reservation/sale/update/${oneReservation.id}`,
    "put",
    oneReservation,
    {
      authorization: authToken,
    }
  );
};

export const deleteOneSellReservationApi = (authToken, oneReservation) => {
  return apiCall(
    `${SERVER_API_HOST}/api/reservation/sale/delete/${oneReservation.id}`,
    "delete",
    {},
    {
      authorization: authToken,
    }
  );
};

export const deleteOneBuyReservationApi = (authToken, oneReservation) => {
  return apiCall(
    `${SERVER_API_HOST}/api/reservation/purchase/delete/${oneReservation.id}`,
    "delete",
    {},
    {
      authorization: authToken,
    }
  );
};

export const registerNewSellApi = (
  authToken,
  oneModel,
  sellPrice,
  sellDescription,
  sellStoreId,
  watchPhotos,
  visitTime,
  content,
  holdingType,
  hasReceipt,
  reservationLink
) => {
  return apiCall(
    `${SERVER_API_HOST}/api/reservation/sale/create`,
    "post",
    {
      watch: oneModel,
      price: sellPrice,
      description: sellDescription,
      storeIds: sellStoreId,
      attachedFiles: watchPhotos,
      visitDateTime: visitTime,
      additionalInfo: content,
      holdingType,
      hasReceipt,
      linkUrl: reservationLink,
    },
    {
      authorization: authToken,
    }
  );
};

//////판매 => 옥션으로 전환
export const sendReservationToAuctionApi = (
  authToken,
  infoForAuctionChange,
  id
) => {
  return apiCall(
    `${SERVER_API_HOST}/api/reservation/sale/update/auction/${id}`,
    "put",
    infoForAuctionChange,
    {
      authorization: authToken,
    }
  );
};

//////구매예약////////

/**\
 * 구매예약 목록 조회
 */
export const getPurchaseReservationListApi = (
  authToken,
  searchStatus,
  page
) => {
  return apiCall(
    `${SERVER_API_HOST}/api/reservation/purchase/list?page=${page}&offset=10&direction=desc`,
    "post",
    {
      state: searchStatus || null,
    },
    {
      authorization: authToken,
    }
  );
};

/**
 * 구매예약 상태 업데이트
 * @param {} authToken
 * @param {*} id
 * @param {Object} newState
 */
export const changeBuyStateApi = (authToken, id, newState) => {
  return apiCall(
    `${SERVER_API_HOST}/api/reservation/purchase/update/state/${id}`,
    "put",
    {
      state: newState,
    },
    {
      authorization: authToken,
    }
  );
};

/**
 * 구매예약 삭제
 * @param {*} authToken
 * @param {*} id
 */
export const deleteBuyReservationApi = (authToken, id) => {
  return apiCall(
    `${SERVER_API_HOST}/api/reservation/purchase/delete/${id}`,
    "delete",
    {},
    {
      authorization: authToken,
    }
  );
};

/**
 * 구매예약 업데이트
 * @param {*} authToken
 * @param {Object} newBuyReservation
 */
export const updateOneBuyReservationApi = (authToken, newBuyReservation) => {
  return apiCall(
    `${SERVER_API_HOST}/api/reservation/purchase/update/${newBuyReservation.id}`,
    "put",
    newBuyReservation,
    {
      authorization: authToken,
    }
  );
};

////////////찾아줘 리스트 ///////////////

export const getFindProductListApi = (authToken, listState, page) => {
  return apiCall(
    `${SERVER_API_HOST}/api/seek?page=${page}&offset=10`,
    "get",
    {
      state: listState,
    },
    {
      authorization: authToken,
    }
  );
};

export const changeOneFindStateApi = (authToken, id, changeState) => {
  return apiCall(
    `${SERVER_API_HOST}/api/seek/${id}`,
    "put",
    {
      state: changeState,
    },
    {
      authorization: authToken,
    }
  );
};

export const deleteOneFindListApi = (authToken, id) => {
  return apiCall(
    `${SERVER_API_HOST}/api/seek/${id}`,
    "delete",
    {},
    {
      authorization: authToken,
    }
  );
};
///////////

export const getBrandListApi = () => {
  return apiCall(`${SERVER_API_HOST}/api/watch/brand/list`, "get", {}, {});
};

export const searchWatchModelApi = (selectedBrand, modelInput) => {
  return apiCall(
    `${SERVER_API_HOST}/api/filter/standard`,
    "post",
    {
      filters: [
        {
          type: "BRAND",
          keyword: selectedBrand,
        },
        {
          type: "INTEGRATED",
          keyword: modelInput,
        },
      ],
    },
    {}
  );
};

export const searchModelForChangeApi = (modelInput) => {
  return apiCall(
    `${SERVER_API_HOST}/api/filter/standard`,
    "post",
    {
      filters: [
        {
          type: "MODEL",
          keyword: modelInput,
        },
      ],
    },
    {}
  );
};
