export const handleVisitTime = (date) => {
    if(date !== null && date !== undefined){
      const [day, time] = date.split('T')
      const getTime = time.split(':')[0];
      let timeNumber = Number(getTime);
      let nextTime = timeNumber +1;
    if(timeNumber<10){
      timeNumber = `0${timeNumber}`
    }
    if(nextTime <10){
    nextTime = `0${nextTime}`
    }
      return `${day} / ${timeNumber}~${nextTime}`
    } else {
        return ;
    }
}

export const handleCreatedAt = (date) => {
  if(!!date){
    const deleteMilli = date.split('.')[0];
    const [day, time] = deleteMilli.split('T');
    return `${day} ${time}`
  }
}

export const handleTimeForDatePicker = (time) => {
   if(!!time){
     const zTime = `${time}z`;
     return zTime;
   } else {
     return null;
   }
}

export const handleTime = (time) => {
  if (!!time) {
    const [left, right] = time.split("T");
    const [hour, minute, second] = right.split(":");
    return `${left} ${hour}:${minute}`;
  }
};