import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  CardImg,
  CardText,
  Button,
} from "reactstrap";
import DEFAULT_IMAGE from "../../../assets/images/big/default-image.jpg";
import CommonHelper from "../../../helpers/CommonHelper";
import { SERVER_API_HOST } from "../../../common/environment";
import { uploadFile } from "../../../apis/uploadFile";
import { changeAppMainBgApi } from "../../../apis/service";
import { getAppSiteInfoApi } from "../../../apis/appSite";

const ServiceChangeMainBG = () => {
  const backgroundImgRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [siteInfo, setSiteInfo] = useState({});
  const [bannerLink, setBannerLink] = useState("");

  // const onClickUpdateAppMainBgInput = () => {
  //   // 파일 입력 폼에 변화가 일어나는지 감시하는 이벤트 리스터 등록
  //   backgroundImgRef.current.addEventListener(
  //     "change",
  //     onChangeNewAppMainBgInput,
  //     false
  //   );
  //   // 파일 입력 폼 열기
  //   backgroundImgRef.current.click();
  // };

  const authToken = CommonHelper.getAuthToken();
  useEffect(() => {
    authToken && getRegisteredMainBg();
  }, [authToken]);

  return (
    <>
      {/* <Col xs="12" md="6">
        <Card>
          <div
            onClick={onClickUpdateAppMainBgInput}
            style={{ cursor: "pointer" }}>
            <CardImg top width="100%" src={imgSrc ? imgSrc : DEFAULT_IMAGE} />
            <input type="file" accept="image/*" ref={backgroundImgRef} hidden />
          </div>
          <CardText>모바일의 메인 BG - 텍스트가 포함되어있는 사진</CardText>
        </Card>
      </Col> */}
      <Col>
        <label>모바일 시계시세 배너 링크 : </label>
        <input
          style={{ width: "50%" }}
          value={bannerLink}
          onChange={(e) => setBannerLink(e.target.value)}
        />
      </Col>
      <Button onClick={changeAppMainBg}>저장하기</Button>
    </>
  );

  function getRegisteredMainBg() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      getAppSiteInfoApi(authToken).then(({ data: { status, error, data } }) => {
        if (status === "SUCCESS") {
          setSiteInfo(data);
          // setImgSrc(data.saleBackgroundImg || null);
          setBannerLink(data.saleBackgroundImg || "");
        } else {
          console.log(error);
          // alert("문제가 생겼습니다.");
        }
      });
    }
  }

  function changeAppMainBg() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      changeAppMainBgApi(authToken, bannerLink).then(
        ({ data: { status, error, data } }) => {
          if (status === "SUCCESS") {
            alert("설정이 완료되었습니다.");
            getRegisteredMainBg();
          } else {
            alert("저장하는데 문제가 생겼습니다.");
            getRegisteredMainBg();
          }
        }
      );
    }
  }

  async function onChangeNewAppMainBgInput(event) {
    // 파일을 선택 한 경우에만 실행
    const file = event.target.files[0];
    // 여러번 등록되는 버그 수정 (https://stackoverflow.com/questions/39484895/how-to-allow-input-type-file-to-select-the-same-file-in-react-component)
    event.target.value = null;
    const authToken = CommonHelper.getAuthToken();
    // 파일 업로드
    let nextPhotoUrl = await uploadFileAndGetUrl(file);
    if (nextPhotoUrl) {
      // nextPhotoUrl = nextPhotoUrl.replace('http://localhost:8080', SERVER_API_HOST); // TODO 임시 코드
      nextPhotoUrl = `${SERVER_API_HOST}${nextPhotoUrl}`;
      // const fakeInfo = { ...newBannerInfo };
      // fakeInfo.photoUrl = nextPhotoUrl;
      // setNewBannerInfo(fakeInfo);
      setImgSrc(nextPhotoUrl);
    }
  }

  // 파일 업로드 후 URL 반환
  async function uploadFileAndGetUrl(file) {
    if (file) {
      const { type: fileType, name: fileName, size: fileSize } = file;
      // 파일 데이터 만들기
      const formData = new FormData();
      formData.append("file", file);

      // 파일 업로드
      const uploadResult = await uploadFile(formData);
      const {
        status,
        data: { url },
      } = uploadResult;
      if (status === 200 && !!url) {
        return url;
      } else {
        alert("파일 업로드 실패");
        return null;
      }
    } else {
      return null;
    }
  }
};

export default ServiceChangeMainBG;
