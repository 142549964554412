import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Header from "../header/header";
import CommonHelper from "../../helpers/CommonHelper";
import { signInApi, getUserSessionApi } from "../../apis/auth";

document.body.style.backgroundColor = "rgba(31,136,229,0.4)";
const SignIn = () => {
  const [userId, setUserId] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const history = useHistory();
  const authToken = CommonHelper.getAuthToken();

  /**
   * 로그인 api호출 전에 비어있는 값이 없는지 체크하는 함수
   * @param {*} e
   */
  const validateform = (e) => {
    if (userId == null || userId == "") {
      alert("ID가 비어있습니다.");
    } else if (userPassword == null || userPassword == "") {
      alert("password가 비어있습니다");
    } else {
      sendSignIn();
    }
  };
  /**
   * id, password onChange를 handle하는 함수
   * @param {*} e
   */
  const handleChange = (e) => {
    if (e.target.name === "id") {
      setUserId(e.target.value);
    } else if (e.target.name === "password") {
      setUserPassword(e.target.value);
    }
  };

  useEffect(() => {
    //토큰이 있으면 로그인 화면 에서 메인 화면으로 넘김
    if (authToken) history.replace("/starter/starter");
  }, []);

  /**
   * 로그인 버튼 눌렀을 때 실행될 함수
   */
  const sendSignIn = () => {
    signInApi(userId, userPassword).then(
      ({ data: { data : tokenData, detail, status } }) => {
        if (status === "SUCCESS" && !!tokenData?.token) {
          getUserSessionApi(tokenData?.token).then(
            ({ data: { data, detail, status } }) => {
              if (status === "SUCCESS") {
                if (data?.roles?.includes("ADMIN")) {
                  CommonHelper.setAuthenticateToken(tokenData?.token);
                  history.push("/admin-components/MainSetting");
                } else {
                  alert('어드민이 아닙니다.')
                }
              } else {
                alert(detail)
              }
            }
          );
          // CommonHelper.setAuthenticateToken(data);
          // history.push("/admin-components/MainSetting");
          //   appDispatch({
          //     type: "LOGGED_IN",
          //     loggedIn: true,
          //     data: userData,
          //   });
          //   history.replace("/");
        } else {
          alert(detail);
        }
      }
    );
  };
  return (
    <>
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full">
        <Header />
        <div className="login-inner">
          <div className="login-outer">
            <h3>Log in</h3>
            <div classname="login-form-group">
              <div className="login-form-group">
                <label>ID</label>
                <input
                  type="id"
                  className="login-form-control"
                  placeholder="ID"
                  name="id"
                  value={userId}
                  onChange={(e) => handleChange(e)}
                />
              </div>

              <div className="login-form-group">
                <label>Password</label>
                <input
                  type="password"
                  className="login-form-control"
                  placeholder="Password"
                  name="password"
                  value={userPassword}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="login-form-group">
                <div className="login-custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="login-custom-control-input"
                    id="customCheck1"
                  />
                  <label
                    className="login-custom-control-label"
                    htmlFor="customCheck1">
                    Remember me
                  </label>
                </div>
              </div>
            </div>
            <button
              onClick={() => validateform()}
              className="btn btn-dark btn-lg btn-block">
              Sign in
            </button>
            <p className="forgot-password text-right">
              Forgot <a href="#">password?</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
