// 줄바꿈을 <br/> 태그로 변경
export const replaceNewlineWith = (str, replaceChar) => {
  return str.replace(/(?:\r\n|\r|\n)/g, replaceChar);
};

export const replaceNewlineWithBrTag = (str, replaceChar) => {
  str.replaceAll(`<br/>`, `\r\n`);
  str.replaceAll(`<br>`, `\r\n`);
  return str;
};

// URL에 링크를 추가
export const addUrlLink = (str, className) => {
  return str.replace(
    /^(http|https|ftp|telnet|news|irc):\/\/([-/.a-zA-Z0-9_~#%$?&=:200-377()]+)/gi,
    `<a href='$1://$2' target='_blank' class='${className}'>$1://$2</a>`
  );
};

// Email에 링크를 추가
export const addEmailLink = (str, className) => {
  return str.replace(
    /([xA1-xFEa-z0-9_-]+@[xA1-xFEa-z0-9-]+.[a-z0-9-]+)/gi,
    `<a href='mailto:$1' class='${className}'>$1</a>`
  );
};

// XSS 방지 HTML 특수문자 반환
export const encodingHtmlTags = (html) => {
  return (html && encodingTags(html)) || "";

  function encodingTags(_html) {
    return (
      _html
        // .replace(/&/g, '&amp;')
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
    );
    // .replace(/,/g, '&#x27;')
    // .replace(/"/g, '&quot;')
    // .replace(/\(/g, '&#40;')
    // .replace(/\)/g, '&#41;')
    // .replace(/\//g, '&#x2F;')
  }
};

export const decodingHtmlTags = (encoded) => {
  return (encoded && decodingTags(encoded)) || "";

  function decodingTags(_encoded) {
    return (
      _encoded
        // .replace(/&amp;/g, '&')
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
    );
    // .replace(/&#x27;/g, ',')
    // .replace(/&quot;/g, '"')
    // .replace(/&#40;/g, '(')
    // .replace(/&#41;/g, ')')
    // .replace(/&#x2F;/g, '/')
  }
};
