import React from "react";
import { Route, Redirect } from "react-router-dom";
import CommonHelper from "../helpers/CommonHelper";

const MemberOnlyRoute = ({ component: Component, render, ...rest }) => {
  const authToken = CommonHelper.getAuthToken();
  // const { user: { loggedIn }} = useAppState();

  const isMember = () => !!authToken;
  return (
    <Route
      {...rest}
      render={(props) =>
        isMember() ? (
          render ? (
            render(props)
          ) : (
            <Component {...props} child={rest.child} />
          )
        ) : (
          <Redirect to={{ pathname: "/sign-in" }} />
        )
      }
    />
  );
};

export default MemberOnlyRoute;
