import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Button,
  Input,
  Table,
} from "reactstrap";
import CommonHelper from "../../../helpers/CommonHelper";
import PaginationComponent from "../../PaginationComponent";
import useDebounce from "../../../utils/useDebounce";
import {
  modifyPriceApi,
  findOneWatchApi,
  modifyOnePriceApi,
  deleteOnePriceApi,
  searchWatchModelApi,
  getPriceExcelApi,
} from "../../../apis/price";
import { getBrandListApi } from "../../../apis/reservation";
import { uploadExcel } from "../../../apis/uploadFile";
import ExcelExamplePhoto from "../../../assets/images/price-excel-example.png";

const initNewProduct = {
  brand: "",
  model: "",
  serialNo: "",
  caseDiameter: "",
  caseMaterial: "",
  movement: "",
  dialColor: "",
  dialType: "",
  officialPrice: 0,
};
const ulStyle = {
  height: "100px",
  overflow: "scroll",
  border: "1px solid gainsboro",
  borderRadius: "3px",
};

const PriceManageComponent = () => {
  ////생성
  const [newProductInfo, setNewProductInfo] = useState(initNewProduct);
  ////////조회 및 수정state /////////
  const [showModifyOneProduct, setShowModifyOneProduct] = useState(false);
  const [modifyProductInfo, setModifyProductInfo] = useState({});
  const [currentProductList, setCurrentProductList] = useState({});
  //브랜드 검색 및 선택
  const [brandList, setBrandList] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  ////모델 검색 및 선택
  const [modelInput, setModelInput] = useState("");
  const [resultModelArray, setResultModelArray] = useState([]);
  /////debounce
  const debouncedSearchTerm = useDebounce(modelInput, 500);
  const [modifyPriceInput, setModifyPriceInput] = useState("");
  const [modifyDayInput, setModifyDayInput] = useState("");
  const [onePriceDay, setOnePriceDay] = useState(null);
  const [newPrice, setNewPrice] = useState("");
  const [oneModelId, setOneModelId] = useState("");

  useEffect(() => {
    searchWatchModel();
  }, [debouncedSearchTerm, selectedBrand]);

  const handleModifyProduct = (e) => {
    const { name, value } = e.target;
    let copyProductInfo = { ...modifyProductInfo };
    copyProductInfo[name] = value;
    setModifyProductInfo(copyProductInfo);
  };

  /////////조회 및 수정 함수 시작
  const openModifyOneProduct = (oneProduct) => {
    // setShowModifyOneProduct(true);
    // setModifyProductInfo(oneProduct); //수정할 내용
    findOneWatch(oneProduct);
    setOneModelId(oneProduct);
    // setModifyPriceInput(oneProduct.recentlyPrice || "");
  };

  const closeModifyProduct = () => {
    setShowModifyOneProduct(false);
  };

  const handleModifyPriceInput = (e) => {
    const { value } = e.target;
    let deleteAll = value
      .replaceAll(" ", "")
      .replaceAll(",", "")
      .replaceAll(/[^0-9]/g, "");
    setModifyPriceInput(deleteAll);
  };

  const checkBeforemodifyPrice = () => {
    if (modifyDayInput.length === 0) {
      alert("날짜를 입력해주세요");
    } else if (modifyDayInput.length < 8) {
      alert("날짜 형식은 yyyyMMdd로 8개의 숫자를 입력해주세요.");
    } else if (modifyPriceInput.length === 0) {
      alert("가격을 입력해주세요");
    } else {
      modifyPrice();
    }
  };

  const openInputForModifyPrice = (day) => {
    setOnePriceDay(day);
  };

  const handleOneNewPrice = (e) => {
    let deleteAll = e.target.value
      .replaceAll(" ", "")
      .replaceAll(",", "")
      .replaceAll(/[^0-9]/g, "");
    setNewPrice(deleteAll);
  };

  const closeOneNewPriceModify = () => {
    setNewPrice("");
    setOnePriceDay(null);
  };

  useEffect(() => {
    getBrandList();
  }, []);

  const handleBrandChange = (e) => {
    setSelectedBrand(e.target.value);
  };

  const onChangeUploadExcelFile = async (event) => {
    let file = event.target.files[0];
    let nextExcel = await uploadPriceExcel(file);
    const { data, detail, status } = nextExcel;
    if (status === "SUCCESS") {
      alert("가격 데이터 엑셀 업로드에 성공했습니다.");
      findOneWatch(oneModelId);
    } else {
      alert(detail);
    }
  };

  return (
    <>
      <Card>
        <CardTitle
          className="bg-light border-bottom p-3 mb-0"
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
          }}>
          모델 검색
        </CardTitle>
        <CardBody>
          <Container>
            <CardTitle>시계 찾기</CardTitle>
            <Row>
              <Col>
                브랜드 선택
                <select
                  className="form-control"
                  value={selectedBrand}
                  onChange={handleBrandChange}>
                  {brandList.map((brandObject, idx) => (
                    <option value={brandObject.brand} key={idx}>
                      {brandObject.brand || "-"} ({brandObject.count || 0})
                    </option>
                  ))}
                </select>
              </Col>
              <Col>
                모델 검색하기
                <Input
                  value={modelInput}
                  onChange={(e) => setModelInput(e.target.value)}
                  placeholder="모델명을 입력해주세요."
                />
              </Col>
              <Col>
                검색 결과
                <ul style={ulStyle}>
                  {resultModelArray.length === 0 ? (
                    <li>검색 결과가 없습니다.</li>
                  ) : (
                    resultModelArray.map((model, idx) => (
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => openModifyOneProduct(model)}
                        key={idx}>
                        {model.brand || "-"} {model.model || "-"}(
                        {model.serialNo || "-"})
                      </li>
                    ))
                  )}
                </ul>
              </Col>
            </Row>
          </Container>
        </CardBody>
      </Card>
      {showModifyOneProduct && (
        <Card>
          <CardTitle
            className="bg-light border-bottom p-3 mb-0"
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
            }}>
            가격 수정 및 정보
            <Button onClick={closeModifyProduct}>닫기</Button>
          </CardTitle>
          <Container>
            <CardTitle
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px",
                marginTop: "40px",
              }}>
              {/* 가격 관리 */}
            </CardTitle>
            <Row style={{ marginTop: "40px", marginLeft: "20px" }}>
              <Col>
                <label style={{ fontSize: "16px", fontWeight: "bold" }}>
                  가격 엑셀파일 예시
                </label>
                <img src={ExcelExamplePhoto} style={{ width: "100%" }} />
              </Col>
              <Col>
                <label style={{ fontSize: "16px", fontWeight: "bold" }}>
                  가격 엑셀파일 선택
                </label>
                <Input type="file" onChange={onChangeUploadExcelFile} />
                <label style={{ marginTop: "10px" }}>
                  *파일 선택완료 시 즉시 업로드 됩니다.
                </label>
              </Col>
            </Row>{" "}
            <Row style={{ marginTop: "40px", marginLeft: "20px" }}>
              <Col>
                가격{" "}
                <Input
                  value={
                    modifyPriceInput
                      ? Number(modifyPriceInput).toLocaleString()
                      : ""
                  }
                  onChange={handleModifyPriceInput}
                  name="recentlyPrice"
                />
              </Col>
              <Col>
                날짜
                <Input
                  placeholder="yyyyMMdd"
                  value={modifyDayInput}
                  onChange={(e) => setModifyDayInput(e.target.value)}
                  name="day"
                />
              </Col>
              <Col>
                추가하기 버튼
                <div>
                  <Button onClick={checkBeforemodifyPrice}>
                    가격/날짜 추가하기
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
          <CardBody style={{ color: "black", margin: "40px 20px 0 20px" }}>
            <CardTitle
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px",
              }}>
              가격 리스트/수정/삭제
              <Button onClick={onClickDownPriceExcel}>
                가격 엑셀 다운받기
              </Button>
            </CardTitle>
            <Container>
              <Card>
                {/* <CardTitle className="bg-light border-bottom p-3 mb-0 space-between">
                  옥션 리스트
                  <div>
                    <select
                      style={{ marginRight: "20px" }}
                      value={searchAuctionStatus}
                      onChange={(e) => setSearchAuctionStatus(e.target.value)}>
                      <option></option>
                      <option>WAIT</option>
                      <option>RUN</option>
                      <option>DONE</option>
                    </select>
                    <Button
                      style={{ marginRight: "20px" }}
                      onClick={getAuctionList}>
                      조회하기
                    </Button>
                    <Button onClick={openRegisterAuctionForm}>
                      옥션 상품 등록하기
                    </Button>
                  </div>
                </CardTitle> */}
                <CardBody
                  style={{
                    height: "400px",
                    maxHeight: "400px",
                    overflow: "scroll",
                  }}>
                  <Table className="no-wrap v-middle" responsive>
                    <thead>
                      <tr className="border-0">
                        <th className="border-0">오늘의 hpi</th>
                        <th className="border-0">날짜</th>
                        <th className="border-0">가격</th>
                        <th className="border-0"></th>
                        <th className="border-0"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {modifyProductInfo?.priceHistories?.length > 0 ? (
                        modifyProductInfo?.priceHistories?.map((price, idx) => (
                          <tr
                            key={idx}
                            // onClick={() => openModifyAuction(price)}
                          >
                            <td>{price.hpi || "-"}</td>
                            <td>{price.day || "-"}</td>
                            {onePriceDay === price.day ? (
                              <>
                                <td>
                                  <input
                                    value={
                                      newPrice
                                        ? Number(newPrice).toLocaleString()
                                        : ""
                                    }
                                    onChange={handleOneNewPrice}
                                  />
                                </td>
                                <td>
                                  <button onClick={modifyOnePrice}>
                                    저장하기
                                  </button>
                                  <button onClick={closeOneNewPriceModify}>
                                    취소하기
                                  </button>
                                </td>
                              </>
                            ) : (
                              <>
                                <td>
                                  {price?.price
                                    ? Number(price.price).toLocaleString()
                                    : "-"}
                                </td>
                                <td>
                                  <button
                                    onClick={() =>
                                      openInputForModifyPrice(price.day)
                                    }>
                                    수정하기
                                  </button>
                                </td>
                              </>
                            )}
                            <td>
                              <button onClick={() => deleteOnePrice(price.day)}>
                                삭제하기
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <div>존재하는 가격 리스트가 없습니다.</div>
                      )}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Container>
          </CardBody>
          <CardBody>
            <CardTitle
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px",
              }}>
              모델 정보
            </CardTitle>
            <Container>
              <Row style={{ marginTop: "40px" }}>
                <Col>
                  해당 모델 사용중 Count
                  <Input
                    disabled
                    value={modifyProductInfo?.relationCount || "0"}
                  />
                  <p>
                    *count가 0이면, 브랜드/모델 추가 메뉴에서 해당 모델을
                    삭제하거나 브랜드, 모델을 수정할 수 있습니다.
                  </p>
                </Col>
                <Col>
                  id
                  <Input disabled value={modifyProductInfo?.id || "-"} />
                </Col>
                <Col>
                  RetailPrice
                  <Input
                    value={modifyProductInfo?.officialPrice || 0}
                    onChange={handleModifyProduct}
                    name="officialPrice"
                    disabled
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "40px" }}>
                <Col>
                  브랜드
                  <Input
                    onChange={handleModifyProduct}
                    value={modifyProductInfo?.brand || "-"}
                    disabled
                    name="brand"
                    // disabled={modifyProductInfo?.relationCount > 0}
                  />
                </Col>
                <Col>
                  모델
                  <Input
                    onChange={handleModifyProduct}
                    name="model"
                    value={modifyProductInfo?.model || "-"}
                    disabled
                    // disabled={modifyProductInfo?.relationCount > 0}
                  />
                </Col>
                <Col>
                  레퍼런스/serialNo
                  <Input
                    onChange={handleModifyProduct}
                    value={modifyProductInfo?.serialNo || "-"}
                    disabled
                    name="serialNo"
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "40px" }}>
                <Col>
                  케이스 사이즈
                  <Input
                    onChange={handleModifyProduct}
                    value={modifyProductInfo?.caseDiameter || "-"}
                    disabled
                    name="caseDiameter"
                  />
                </Col>
                <Col>
                  구동방식
                  <Input
                    onChange={handleModifyProduct}
                    value={modifyProductInfo.movement || ""}
                    name="movement"
                    disabled
                  />
                </Col>
                <Col>
                  케이스 소재
                  <Input
                    onChange={handleModifyProduct}
                    value={modifyProductInfo.caseMaterial || ""}
                    name="caseMaterial"
                    disabled
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "40px" }}>
                <Col>
                  다이얼 색상
                  <Input
                    onChange={handleModifyProduct}
                    value={modifyProductInfo.dialColor || ""}
                    name="dialColor"
                    disabled
                  />
                </Col>
                <Col>
                  다이얼 타입
                  <Input
                    onChange={handleModifyProduct}
                    value={modifyProductInfo.dialType || ""}
                    name="dialType"
                    disabled
                  />
                </Col>
                <Col>
                  gender
                  <Input
                    onChange={handleModifyProduct}
                    value={modifyProductInfo.gender || ""}
                    name="gender"
                    disabled
                  />
                </Col>
              </Row>
            </Container>
          </CardBody>
          <CardBody></CardBody>
        </Card>
      )}
    </>
  );

  function onClickDownPriceExcel() {
    getPriceExcelApi(oneModelId?.id).then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: response.headers["content-type"] })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${oneModelId.brand}_${oneModelId.model}_price`
      );
      document.body.appendChild(link);
      link.click();
    });
  }

  function getBrandList() {
    getBrandListApi().then(({ data: { data, status, detail } }) => {
      if (status === "SUCCESS") {
        setSelectedBrand(data[0]?.brand || null);
        setBrandList(data || []);
        // console.log(data);
      }
    });
  }

  function searchWatchModel() {
    searchWatchModelApi(selectedBrand, modelInput).then(
      ({ data: { data, detail, status } }) => {
        if (status === "SUCCESS") {
          setResultModelArray(data || []);
        } else {
          alert(detail);
        }
      }
    );
  }

  function modifyPrice() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      modifyPriceApi(
        authToken,
        modifyPriceInput,
        modifyProductInfo,
        modifyDayInput
      ).then(({ data: { detail, status } }) => {
        if (status === "SUCCESS") {
          alert("가격이 추가되었습니다.");
          // setShowModifyOneProduct(false);
          searchWatchModel();
          findOneWatch(modifyProductInfo);
          setModifyDayInput("");
          setModifyPriceInput("");
        } else {
          alert(detail);
        }
      });
    }
  }

  function findOneWatch(oneProduct) {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      findOneWatchApi(oneProduct, authToken).then(
        ({ data: { data, detail, status } }) => {
          if (status === "SUCCESS") {
            setShowModifyOneProduct(true);
            setModifyProductInfo(data);
          } else {
            alert(detail);
          }
        }
      );
    }
  }

  function modifyOnePrice() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      modifyOnePriceApi(
        modifyProductInfo,
        newPrice,
        onePriceDay,
        authToken
      ).then(({ data: { data, detail, status } }) => {
        if (status === "SUCCESS") {
          alert("성공적으로 수정되었습니다.");
          setNewPrice("");
          setOnePriceDay(null);
          findOneWatch(modifyProductInfo);
        } else {
          alert(detail);
        }
      });
    }
  }

  function deleteOnePrice(deleteDay) {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      deleteOnePriceApi(modifyProductInfo, deleteDay, authToken).then(
        ({ data: { data, detail, status } }) => {
          if (status === "SUCCESS") {
            alert("해당 날짜의 값이 비워졌습니다.");
            findOneWatch(modifyProductInfo);
          }
        }
      );
    }
  }

  async function uploadPriceExcel(file) {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      const uploadResult = await uploadExcel(formData);
      const { status, detail, data } = uploadResult;
      if (status === 200) {
        return data;
      } else {
        alert("엑셀 업로드 실패");
        return null;
      }
    } else {
      return null;
    }
  }
};
export default PriceManageComponent;
