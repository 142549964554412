import { apiCall } from "./apiCall";
import { CLIENT_URL, SERVER_API_HOST } from "../common/environment";
import CommonHelper from "helpers/CommonHelper";

export const getNoticeListApi = (authToken, page) => {
  return apiCall(
    `${SERVER_API_HOST}/api/notice/list?page=${page}`,
    "get",
    {},
    {
      authorization: authToken,
    }
  );
};

export const createNewNoticeApi = (authToken, newtitle, newcontent, text) => {
  return apiCall(
    `${SERVER_API_HOST}/api/notice`,
    "post",
    {
      title: newtitle,
      content: newcontent,
      // content: text,
    },
    {
      authorization: authToken,
    }
  );
};

export const modifyOneNoticeApi = (
  authToken,
  id,
  modifyTitle,
  modifyContent
) => {
  return apiCall(
    `${SERVER_API_HOST}/api/notice/${id}`,
    "put",
    {
      title: modifyTitle,
      content: modifyContent,
    },
    {
      authorization: authToken,
    }
  );
};

export const deleteOneNoticeApi = (authToken, id) => {
  return apiCall(
    `${SERVER_API_HOST}/api/notice/${id}`,
    "delete",
    {},
    {
      authorization: authToken,
    }
  );
};

export const getFAQListApi = (authToken, type, page) => {
  return apiCall(
    `${SERVER_API_HOST}/api/faq?page=${page}`,
    "get",
    { faqType: type },
    {
      authorization: authToken,
    }
  );
};

export const createNewFAQApi = (authToken, newFaqForm) => {
  return apiCall(`${SERVER_API_HOST}/api/faq`, "post", newFaqForm, {
    authorization: authToken,
  });
};

export const modifyOneFaqApi = (authToken, id, modifyFaqForm) => {
  return apiCall(`${SERVER_API_HOST}/api/faq/${id}`, "put", modifyFaqForm, {
    authorization: authToken,
  });
};

export const deleteOneFaqApi = (authToken, id) => {
  return apiCall(
    `${SERVER_API_HOST}/api/faq/${id}`,
    "delete",
    {},
    {
      authorization: authToken,
    }
  );
};

export const getReviewListApi = (authToken, page) => {
  return apiCall(
    `${SERVER_API_HOST}/api/board/list?group=review&page=${page}&direction=desc&viewContent=false`,
    "get",
    {},
    {
      authorization: authToken,
    }
  );
};

export const createNewReviewApi = (
  authToken,
  newtitle,
  newcontent,
  group,
  attacheFiles
) => {
  return apiCall(
    `${SERVER_API_HOST}/api/board`,
    "post",
    {
      title: newtitle,
      content: newcontent,
      group: "review",
      attacheFiles,
      // content: text,
    },
    {
      authorization: authToken,
    }
  );
};

export const modifyOneReviewApi = (
  authToken,
  id,
  modifyTitle,
  modifyContent,
  attacheFiles
) => {
  return apiCall(
    `${SERVER_API_HOST}/api/board/${id}`,
    "put",
    {
      title: modifyTitle,
      content: modifyContent,
      attacheFiles,
    },
    {
      authorization: authToken,
    }
  );
};

export const deleteOneReviewApi = (authToken, id) => {
  return apiCall(
    `${SERVER_API_HOST}/api/board/${id}`,
    "delete",
    {},
    {
      authorization: authToken,
    }
  );
};

export const getReviewDetailApi = (authToken, id) => {
  return apiCall(
    `${SERVER_API_HOST}/api/board/get/${id}`,
    "get",
    {},
    {
      authorization: authToken,
    }
  );
};
