import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Button,
  Table,
} from "reactstrap";
import CommonHelper from "../../helpers/CommonHelper";
import { getUserListApi } from "../../apis/userList";
import PaginationComponent from "../PaginationComponent";
import {
  getHaplMainListApi,
  getStoreListApi,
  cancelHaplApi,
  approveHaplApi,
  deleteHaplApi,
} from "../../apis/hapl";
const emptySearchBox = {
  name: "",
  nickName: "",
  loginId: "",
};
export const HAPLE_STATUS = {
  WAIT: "예약중",
  CONFIRM: "예약 승인",
  CANCEL: "예약 취소",
};

const PAID_SERVICE = {
  OVER_HAUL: "오버홀",
  POLISHING: "폴리싱",
  FILM_SERVICE: "필름시공",
};

const APPRAISALS_TYPE = {
  DETAIL: "정밀감정",
  SIMPLE: "단순감정",
  NOT: "감정안함",
};

const MAIN_MENU = ["공간대여 관리"];

const HaplManage = () => {
  const [currMenu, setCurrMenu] = useState(MAIN_MENU[0]);

  const changeMenu = (e) => {
    setCurrMenu(e.target.value);
  };

  const history = useHistory();
  const [siganUserInfo, setSiganUserInfo] = useState({});
  const [showUserDetail, setShowUserDetail] = useState(false);
  const [oneUserDetail, setOneUserDetail] = useState({});
  const [searchBox, setSearchBox] = useState(emptySearchBox);
  const [storeList, setStoreList] = useState([]);

  const [mainReservedInfo, setMainReservedInfo] = useState({});

  const [seekModel, setSeekModel] = useState("");
  const [seekStore, setSeekStore] = useState("");
  const [seekStatus, setSeekStatus] = useState("");

  ////페이지네이션 추기
  const [page, setPage] = useState(0);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(4);
  const LIMIT = 10;

  const { totalPage } = mainReservedInfo;

  const pageNumbers = [];
  for (let i = 0; i < totalPage; i++) {
    pageNumbers.push(i + 1);
  }
  const newPageNumbers = pageNumbers.slice(start, end);

  const paginate = (number) => {
    const num = number === 0 ? number : number - 1;
    setPage(num);
  };

  const prevPage = () => {
    if (page + 1 === 1) {
      return alert("첫페이지입니다.");
    }
    if ((page + 1) % 4 === 1) {
      const s = start > 0 ? start - 4 : 0;
      const e = end > 0 ? end - 4 : end;

      setStart(s);
      setEnd(e);
    }
    setPage(page - 1);
  };

  const nextPage = () => {
    if (page + 2 > pageNumbers.length) {
      return alert("마지막페이지입니다.");
    } else {
      setPage(page + 1);
      if ((page + 2) % 4 === 1) {
        const s = start + 4;
        const e = end + 4;
        setStart(s);
        setEnd(e);
      }
    }
  };
  /////페이지네이션 끝

  const openOneDetail = (haplInfo) => {
    setShowUserDetail(true);
    setOneUserDetail(haplInfo);
  };

  const closeDetail = () => {
    setShowUserDetail(false);
  };

  const handleSearchInput = (e) => {
    const { name, value } = e.target;
    const nextInputs = {
      ...searchBox,
      [name]: value,
    };
    setSearchBox(nextInputs);
  };

  useEffect(() => {
    getMainList();
  }, [page]);

  useEffect(() => {
    getStoreList();
  }, []);

  const handleChange = (e) => {
    if (e.target.name === "status") {
      setSeekStatus(e.target.value);
    } else if (e.target.name === "store") {
      setSeekStore(e.target.value);
    } else if (e.target.name === "search") {
      setSeekModel(e.target.value);
    }
  };

  return (
    <>
      <Card>
        <CardTitle className="bg-light border-bottom p-3 mb-0 space-between">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {MAIN_MENU.map((menu) => (
              <Button value={menu} color="info" onClick={(e) => changeMenu(e)}>
                {menu}
              </Button>
            ))}
          </div>
        </CardTitle>
        <CardBody>
          {showUserDetail && (
            <Card>
              <CardBody>
                <CardTitle>
                  <div className="space-between">
                    공간대여 신청 상세
                    <Button onClick={closeDetail}>닫기</Button>
                  </div>
                </CardTitle>
                <Container>
                  <Row>
                    <div className="half-width">신청자/핸드폰번호</div>
                    <div className="bg-light border p-2 col-sm-4">
                      {oneUserDetail.name || ""} / {oneUserDetail.email || ""}
                    </div>
                  </Row>
                  <Row>
                    <div className="half-width">예약 현황</div>
                    <div className="bg-light border p-2 col-sm-4">
                      {HAPLE_STATUS[oneUserDetail.status] || "-"}
                    </div>
                  </Row>
                  <Row>
                    <div className="half-width">이용일자</div>
                    <div className="bg-light border p-2 col-sm-4">
                      {oneUserDetail.day || "-"}
                    </div>
                  </Row>
                  <Row>
                    <div className="half-width">이용시간</div>
                    <div className="bg-light border p-2 col-sm-4">
                      {oneUserDetail.timeStr || "-"}
                    </div>
                  </Row>
                  <Row>
                    <div className="half-width">이용인원</div>
                    <div className="bg-light border p-2 col-sm-4">
                      {oneUserDetail.userCount || "-"}
                    </div>
                  </Row>
                  <Row>
                    <div className="half-width">직영점</div>
                    <div className="bg-light border p-2 col-sm-4">
                      {oneUserDetail.store.name || "-"}
                    </div>
                  </Row>
                  <Row>
                    <div className="half-width">주소</div>
                    <div className="bg-light border p-2 col-sm-4">
                      {oneUserDetail.store.address || "-"}
                    </div>
                  </Row>
                  <Row>
                    <div className="half-width">문의처</div>
                    <div className="bg-light border p-2 col-sm-4">
                      {oneUserDetail.store.contactNumber || "-"}
                    </div>
                  </Row>
                  <Row>
                    <div className="half-width">구매자/판매자</div>
                    <div className="bg-light border p-2 col-sm-4">
                      {oneUserDetail.userType === "SELLER"
                        ? "판매자"
                        : "구매자"}
                    </div>
                  </Row>
                  <Row>
                    <div className="half-width">감정여부</div>
                    <div className="bg-light border p-2 col-sm-4">
                      {oneUserDetail.appraisalsType
                        ? APPRAISALS_TYPE[oneUserDetail.appraisalsType]
                        : ""}
                    </div>
                  </Row>
                  <Row>
                    <div className="half-width">유료서비스</div>
                    <div className="bg-light border p-2 col-sm-4">
                      {/* {oneUserDetail.etcQuestion || "-"} */}
                      {oneUserDetail.paidServices.length > 0
                        ? oneUserDetail.paidServices.map((el) => (
                            <span>{PAID_SERVICE[el]} </span>
                          ))
                        : ""}
                    </div>
                  </Row>
                  <Row>
                    <div className="half-width">기타내용</div>
                    <div className="bg-light border p-2 col-sm-4">
                      {oneUserDetail.etcQuestion || "-"}
                    </div>
                  </Row>
                </Container>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <Button onClick={approveHapl}>승인하기</Button>
                    <Button onClick={cancelHapl}>취소하기</Button>
                  </div>
                  <Button onClick={deleteHapl}>삭제하기</Button>
                </div>
              </CardBody>
            </Card>
          )}
          <Card>
            <CardBody>
              <CardTitle className="bg-light border-bottom p-3 mb-0 space-between">
                공간대여 신청 현황
                <div>
                  <input
                    value={seekModel}
                    onChange={handleChange}
                    name="search"
                    placeholder="이름/번호 검색"
                    style={{ marginRight: "10px" }}
                  />
                  <select onChange={handleChange} name="status">
                    <option value={null} onClick={() => setSeekStatus(null)}>
                      전체
                    </option>
                    <option value="WAIT" onClick={() => setSeekStatus("WAIT")}>
                      WAIT
                    </option>
                    <option
                      value="CONFIRM"
                      onClick={() => setSeekStatus("CONFIRM")}>
                      CONFIRM
                    </option>
                    <option
                      value="CANCEL"
                      onClick={() => setSeekStatus("CANCEL")}>
                      CANCEL
                    </option>
                  </select>
                  <select onChange={handleChange} name="store">
                    <option value={null} onClick={() => setSeekStore(null)}>
                      전체
                    </option>
                    {storeList.map((el) => (
                      <option value={el.id} onClick={() => setSeekStore(el.id)}>
                        {el.name}
                      </option>
                    ))}
                  </select>

                  <Button onClick={getMainList}>조회하기</Button>
                </div>
              </CardTitle>
              <Table className="no-wrap v-middle" responsive>
                <thead>
                  <tr className="border-0">
                    <th className="border-0">신청자</th>
                    <th className="border-0">직영점</th>
                    <th className="border-0">고객연락처</th>
                    <th className="border-0">이용일자</th>
                    <th className="border-0">이용시간</th>
                    <th className="border-0">예약상태</th>
                  </tr>
                </thead>
                <tbody>
                  {mainReservedInfo?.content?.map((el, idx) => (
                    <tr key={idx}>
                      <td>{el.name || "-"}</td>
                      <td>{el.store.name || "-"}</td>
                      <td>{el.email || "-"}</td>
                      <td>{el.day || "-"}</td>
                      <td>{el.timeStr || "-"}</td>
                      <td>{HAPLE_STATUS[el.status] || "-"}</td>
                      <td>
                        <Button onClick={() => openOneDetail(el)}>
                          상세보기
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <PaginationComponent
                prevPage={prevPage}
                paginate={paginate}
                nextPage={nextPage}
                newPageNumbers={newPageNumbers}
                page={page}
              />
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </>
  );

  // 메인화면 예약 현황 리스트
  function getMainList() {
    const authToken = CommonHelper.getAuthToken()
      ? CommonHelper.getAuthToken()
      : CommonHelper.getSessionAuthToken();
    getHaplMainListApi(seekStore, authToken, page, seekModel, seekStatus).then(
      ({ data: { data, detail, status } }) => {
        if (status === "SUCCESS") {
          setMainReservedInfo(data || {});
        } else {
          alert(detail);
        }
      }
    );
  }

  function getStoreList() {
    getStoreListApi().then(({ data: { data, detail, status } }) => {
      if (status === "SUCCESS") {
        setStoreList(data.content || []);
      } else {
        alert(detail);
      }
    });
  }

  function approveHapl() {
    const authToken = CommonHelper.getAuthToken()
      ? CommonHelper.getAuthToken()
      : CommonHelper.getSessionAuthToken();
    if (authToken) {
      approveHaplApi(oneUserDetail.id, authToken).then(
        ({ data: { data, detail, status } }) => {
          if (status === "SUCCESS") {
            alert("승인되었습니다.");
            setShowUserDetail(false);
            getMainList();
          } else {
            alert(detail);
          }
        }
      );
    }
  }

  function cancelHapl() {
    const authToken = CommonHelper.getAuthToken()
      ? CommonHelper.getAuthToken()
      : CommonHelper.getSessionAuthToken();
    if (authToken) {
      cancelHaplApi(oneUserDetail.id, authToken).then(
        ({ data: { data, detail, status } }) => {
          if (status === "SUCCESS") {
            alert("취소되었습니다.");
            setShowUserDetail(false);
            getMainList();
          } else {
            alert(detail);
          }
        }
      );
    }
  }

  function deleteHapl() {
    const authToken = CommonHelper.getAuthToken()
      ? CommonHelper.getAuthToken()
      : CommonHelper.getSessionAuthToken();
    if (authToken) {
      deleteHaplApi(oneUserDetail.id, authToken).then(
        ({ data: { data, detail, status } }) => {
          if (status === "SUCCESS") {
            alert("공간대여가 삭제되었습니다.");
            setShowUserDetail(false);
            getMainList();
          } else {
            alert(detail);
          }
        }
      );
    }
  }
};

export default HaplManage;
