import { apiCall, apiCallByFormData } from "./apiCall";
import { CLIENT_URL, SERVER_API_HOST } from "../common/environment";
import CommonHelper from "helpers/CommonHelper";

export const getAppSiteInfoApi = (authToken) => {
  return apiCall(
    `${SERVER_API_HOST}/api/site/info`,
    "get",
    {},
    {
      authorization: authToken,
    }
  );
};

export const sendChangeKeywordsApi = (authToken, newKeywordsBox) => {
  return apiCall(
    `${SERVER_API_HOST}/api/site/search/keywords`,
    "put",
    {
      searchKeywords: newKeywordsBox,
    },
    {
      authorization: authToken,
    }
  );
};

///////////app-매장소개 배너 api///
export const sendChangeBrandApi = (authToken, newBrandsBox) => {
  return apiCall(
    `${SERVER_API_HOST}/api/site/search/brands`,
    "put",
    {
      searchBrands: newBrandsBox,
    },
    {
      authorization: authToken,
    }
  );
};

export const changeAppSiteBanner = (authToken, newAppSiteBanners) => {
  return apiCall(
    `${SERVER_API_HOST}/api/site/banner`,
    "put",
    {
      bannerUrls: newAppSiteBanners,
    },
    {
      authorization: authToken,
    }
  );
};

////////////////앱 버전관리 api//////////////////

export const getAppVersionApi = (authToken) => {
  return apiCall(
    `${SERVER_API_HOST}/api/app/version?`,
    "get",
    {},
    {
      authorization: authToken,
    }
  );
};

export const deleteOneVersionApi = (authToken, id) => {
  return apiCall(
    `${SERVER_API_HOST}/api/app/version/${id}`,
    "delete",
    {},
    { authorization: authToken }
  );
};

export const modifyOneVersionApi = (authToken, modifyForm) => {
  return apiCall(
    `${SERVER_API_HOST}/api/app/version/${modifyForm.id}`,
    "put",
    modifyForm,
    {
      authorization: authToken,
    }
  );
};

export const createNewVersionApi = (authToken, newVersion) => {
  return apiCall(`${SERVER_API_HOST}/api/app/version`, "post", newVersion, {
    authorization: authToken,
  });
};

export const searchAppVersionApi = (
  authToken,
  searchVersion,
  searchUpdateType,
  searchIsOperating,
  searchIsActive,
  searchPlatformType
) => {
  return apiCall(
    `${SERVER_API_HOST}/api/app/version`,
    "get",
    {
      version: searchVersion || null,
      updateType: searchUpdateType || null,
      isOperating: searchIsOperating || null,
      isActive: searchIsActive || null,
      platformType: searchPlatformType || null,
    },
    {
      authorization: authToken,
    }
  );
};

//////////////faq keywords////////

export const changeFaqKeywordsApi = (authToken, keywords) => {
  return apiCall(
    `${SERVER_API_HOST}/api/site/search/faq`,
    "put",
    {
      searchFaqKeywords: keywords,
    },
    { authorization: authToken }
  );
};


///////////점검화면 설정//////
export const changeInspectionSettingApi = (authToken, checkInspection, startDate, endDate) => {
  return apiCall(
    `${SERVER_API_HOST}/api/site/server/check`,
    "put",
    {
      siteCheck: checkInspection,
      siteCheckStartDate: startDate,
      siteCheckEndDate: endDate,
    },
    {
      authorization: authToken,
    }
  );
}