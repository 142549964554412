import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Col,
  Row,
  CardBody,
  CardTitle,
  Button,
  Input,
  CardImg,
} from "reactstrap";
import { Editor } from "@toast-ui/react-editor";
import "@toast-ui/editor/dist/toastui-editor.css";
import styled, { css } from "styled-components";
import CommonHelper from "../../../../helpers/CommonHelper";
import {
  decodingHtmlTags,
  encodingHtmlTags,
  replaceNewlineWith,
  replaceNewlineWithBrTag,
} from "../../../../helpers/stringHelpers";
// import {
//   getAuctionListApi,
// } from "../../../../apis/auction";
import {
  registerNewSellApi,
  getBrandListApi,
  searchWatchModelApi,
} from "../../../../apis/reservation";
import PaginationComponent from "../../../PaginationComponent";
import { getStoreListApi } from "../../../../apis/main";
import useDebounce from "../../../../utils/useDebounce";
import DETAULT_IMAGE from "../../../../assets/images/big/default-image.jpg";
import { uploadFile, uploadSellFile } from "../../../../apis/uploadFile";
import { SERVER_API_HOST } from "../../../../common/environment";
const ulStyle = {
  height: "100px",
  overflow: "scroll",
  border: "1px solid gainsboro",
  borderRadius: "3px",
};

const AdminCreateSell = ({
  getReservationSellList,
  dialColorList,
  dialTypeList,
  caseMaterialList,
  caseDiameterList,
  setShowNewSell,
}) => {
  const newAuctionPhotoInputRef = useRef();
  const [oneModel, setOneModel] = useState(null);
  const [currentStoreList, setCurrentStoreList] = useState([]);
  const [searchAuctionStatus, setSearchAuctionStatus] = useState("");
  ////////조회 및 수정state /////////
  const [showModifyOneAuction, setShowModifyOneAuction] = useState(false);
  const [modifyAuctionInfo, setModifyAuctionInfo] = useState({});
  ///////추가 정보 state//////////
  const [auctionStartPrice, setAuctionStartPrice] = useState("");
  const [auctionDescription, setAuctionDescription] = useState("");
  const [reservationLink, setReservationLink] = useState(""); // 예약하러가기 링크. 필수는 아님. 없으면 클라 disabled
  const [auctionStore, setAuctionStore] = useState([]);
  const [watchPhotos, setWatchPhotos] = useState([]);
  //브랜드 검색 및 선택
  const [brandList, setBrandList] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  ////모델 검색 및
  const [modelInput, setModelInput] = useState("");
  const [resultModelArray, setResultModelArray] = useState([]);
  const [visitTime, setVisitTime] = useState(null);
  //국내 국외
  const [holdingType, setHoldingType] = useState("");
  //영수증 유무
  const [hasReceipt, setHasReceipt] = useState(false);

  const [currentAuctionInfo, setCurrentAuctionInfo] = useState({});

  /////debounce
  const debouncedSearchTerm = useDebounce(modelInput, 500);

  const noticeEditor = useRef(null);

  useEffect(() => {
    searchWatchModel();
  }, [debouncedSearchTerm, selectedBrand]);
  useEffect(() => {
    getStoreList();
  }, []);
  //////date picker 옥션 시작/옥션 종료
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  ////페이지네이션 시작
  const [page, setPage] = useState(0);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(4);
  const LIMIT = 10;

  const { totalPage } = currentAuctionInfo;

  const pageNumbers = [];
  for (let i = 0; i < totalPage; i++) {
    pageNumbers.push(i + 1);
  }
  const newPageNumbers = pageNumbers.slice(start, end);

  const paginate = (number) => {
    const num = number === 0 ? number : number - 1;
    setPage(num);
  };

  const prevPage = () => {
    if (page + 1 === 1) {
      return alert("첫페이지입니다.");
    }
    if ((page + 1) % 4 === 1) {
      const s = start > 0 ? start - 4 : 0;
      const e = end > 0 ? end - 4 : end;

      setStart(s);
      setEnd(e);
    }
    setPage(page - 1);
  };

  const nextPage = () => {
    if (page + 2 > pageNumbers.length) {
      return alert("마지막페이지입니다.");
    } else {
      setPage(page + 1);
      if ((page + 2) % 4 === 1) {
        const s = start + 4;
        const e = end + 4;
        setStart(s);
        setEnd(e);
      }
    }
  };

  //   const getAuctionList = () => {
  //     const authToken = CommonHelper.getAuthToken();
  //     if (!!authToken) {
  //       getAuctionListApi(authToken, page, searchAuctionStatus).then(
  //         ({ data: { data } }) => {
  //           setCurrentAuctionInfo(data || {});
  //         }
  //       );
  //     }
  //   };
  useEffect(() => {
    // getAuctionList();
  }, [page]);

  /////페이지 네이션 관련 끝

  useEffect(() => {
    getBrandList();
  }, []);

  const handleBrandChange = (e) => {
    setSelectedBrand(e.target.value);
  };

  const handleModelInput = (e) => {
    setModelInput(e.target.value);
  };

  // const onClickUpdateNewAuctionInput = () => {
  //   // 파일 입력 폼에 변화가 일어나는지 감시하는 이벤트 리스터 등록
  //   newAuctionPhotoInputRef.current.addEventListener(
  //     "change",
  //     onChangeNewAuctionInput,
  //     false
  //   );
  //   // 파일 입력 폼 열기
  //   newAuctionPhotoInputRef.current.click();
  // };
  // console.log(oneModel);

  /**
   * handleChange
   * @param {*} e
   */
  const handleNewAuction = (e) => {
    const { name, value } = e.target;
    let copyOneModel = { ...oneModel };
    copyOneModel[name] = value;
    setOneModel(copyOneModel);
  };

  /**
   * 옥션생성-구성품 array에서 추가 혹은 삭제
   * @param {*} e
   */
  const handleNewAuctionSupplements = (e) => {
    const { name, checked } = e.target;
    let copyOneModel = { ...oneModel };
    if (checked) {
      copyOneModel.supplements.push(name);
    } else {
      copyOneModel.supplements = copyOneModel.supplements.filter(
        (el) => el !== name
      );
    }
    setOneModel(copyOneModel);
  };

  /**
   * 옥션생성 - 첨부파일 하나 삭제
   * @param {*} photoUrl
   */
  const deleteOnePhotoUrl = (photoUrl) => {
    let copyPhotos = [...watchPhotos];
    copyPhotos = copyPhotos.filter((url) => url !== photoUrl);
    setWatchPhotos(copyPhotos);
  };

  const onChangeNewAuctionInput = async (event) => {
    // 파일을 선택 한 경우에만 실행
    let file = event.target.files[0];
    // 여러번 등록되는 버그 수정 (https://stackoverflow.com/questions/39484895/how-to-allow-input-type-file-to-select-the-same-file-in-react-component)
    const authToken = CommonHelper.getAuthToken();
    // 파일 업로드
    let nextPhotoUrl = await uploadFileAndGetUrl(file);
    if (nextPhotoUrl) {
      // nextPhotoUrl = nextPhotoUrl.replace('http://localhost:8080', SERVER_API_HOST); // TODO 임시 코드
      nextPhotoUrl = `${SERVER_API_HOST}${nextPhotoUrl}`;
      let fakeArray = [...watchPhotos];
      fakeArray.push(nextPhotoUrl);
      setWatchPhotos(fakeArray);
    }
  };

  const handleAuctionStartPriceInput = (e) => {
    const { value } = e.target;
    let deleteAll = value
      .replaceAll(" ", "")
      .replaceAll(",", "")
      .replaceAll(/[^0-9]/g, "");
    setAuctionStartPrice(deleteAll);
  };

  const handleStoreChange = (id) => {
    let copyAuctionStore = [...auctionStore];
    if (auctionStore.filter((el) => el === id).length > 0) {
      copyAuctionStore = auctionStore.filter((el) => el !== id);
    } else {
      copyAuctionStore.push(id);
    }
    setAuctionStore(copyAuctionStore);
  };

  return (
    <>
      <CardBody>
        <Container>
          <CardTitle>시계 찾기</CardTitle>
          <Row>
            <Col>
              브랜드 선택
              <select
                className="form-control"
                value={selectedBrand}
                onChange={handleBrandChange}>
                {brandList.map((brandObject, idx) => (
                  <option value={brandObject.brand} key={idx}>
                    {brandObject.brand || "-"} ({brandObject.count || 0})
                  </option>
                ))}
              </select>
            </Col>
            <Col>
              모델 검색하기
              <Input value={modelInput} onChange={handleModelInput} />
            </Col>
            <Col>
              검색 결과
              <ul style={ulStyle}>
                {resultModelArray.length === 0 ? (
                  <li>검색 결과가 없습니다.</li>
                ) : (
                  resultModelArray.map((model, idx) => (
                    <li
                      style={{ cursor: "pointer" }}
                      onClick={() => setOneModel(model)}
                      key={idx}>
                      {model.model || "-"}({model.serialNo || "-"})
                    </li>
                  ))
                )}
              </ul>
            </Col>
          </Row>
        </Container>
      </CardBody>
      {oneModel && (
        <>
          <CardBody>
            <Container>
              <CardTitle>판매예약 등록 시계 정보</CardTitle>
              <Row style={{ marginTop: "40px" }}>
                <Col>
                  브랜드
                  <Input disabled value={oneModel.brand || ""} />
                </Col>
                <Col>
                  모델
                  <Input disabled value={oneModel.model || ""} />
                </Col>
                <Col>
                  레퍼런스/serialNo
                  <Input
                    onChange={handleNewAuction}
                    value={oneModel.serialNo || ""}
                    name="serialNo"
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "40px" }}>
                <Col>
                  지름 (mm)
                  <select
                    value={oneModel.caseDiameter || ""}
                    name="caseDiameter"
                    className="form-control"
                    onChange={handleNewAuction}>
                    <option></option>
                    {caseDiameterList?.map((diameter, idx) => (
                      <option key={idx}>{diameter}</option>
                    ))}
                  </select>
                </Col>
                <Col>
                  구동방식 (오토/수동/쿼츠)
                  <select
                    value={oneModel.movement || ""}
                    name="movement"
                    className="form-control"
                    onChange={handleNewAuction}>
                    <option></option>
                    <option>AUTOMATIC</option>
                    <option>PASSIVITY</option>
                    <option>QUARTZ</option>
                  </select>
                </Col>
                <Col>
                  생산 연도
                  <Input
                    onChange={handleNewAuction}
                    type="number"
                    value={oneModel.producedYear || ""}
                    name="producedYear"
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "40px" }}>
                <Col>
                  구성품
                  <div>
                    <div>
                      <input
                        type="checkbox"
                        onChange={handleNewAuctionSupplements}
                        name="SIGAN_GUARANTEE"
                        checked={oneModel.supplements.includes(
                          "SIGAN_GUARANTEE"
                        )}
                      />
                      <label htmlFor="supplement">하이시간보증서</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        onChange={handleNewAuctionSupplements}
                        name="GUARANTEE"
                        checked={oneModel.supplements.includes("GUARANTEE")}
                      />
                      <label htmlFor="supplement">보증서</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        name="BOX"
                        onChange={handleNewAuctionSupplements}
                        checked={oneModel.supplements.includes("BOX")}
                      />
                      <label htmlFor="supplement">박스</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        name="BOOKLET"
                        onChange={handleNewAuctionSupplements}
                        checked={oneModel.supplements.includes("BOOKLET")}
                      />
                      <label htmlFor="supplement">북렛</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        name="MEDAL"
                        onChange={handleNewAuctionSupplements}
                        checked={oneModel.supplements.includes("MEDAL")}
                      />
                      <label htmlFor="supplement">Medal</label>
                    </div>
                  </div>
                </Col>
                <Col>
                  성별
                  <select
                    className="form-control"
                    name="gender"
                    value={oneModel.gender || ""}
                    onChange={handleNewAuction}>
                    <option>MALE</option>
                    <option>FEMALE</option>
                    <option>UNISEX</option>
                  </select>
                </Col>
                <Col>
                  케이스 소재
                  <select
                    className="form-control"
                    name="caseMaterial"
                    value={oneModel.caseMaterial || ""}
                    onChange={handleNewAuction}>
                    <option></option>
                    {caseMaterialList?.map((material, idx) => (
                      <option key={idx}>{material}</option>
                    ))}
                  </select>
                  {/* <Input
                    onChange={handleNewAuction}
                    value={oneModel.caseMaterial || ""}
                    name="caseMaterial"
                  /> */}
                </Col>
              </Row>
              <Row style={{ marginTop: "40px" }}>
                <Col>
                  다이얼 색상
                  <select
                    className="form-control"
                    name="dialColor"
                    value={oneModel.dialColor || ""}
                    onChange={handleNewAuction}>
                    <option></option>
                    {dialColorList?.map((color, idx) => (
                      <option key={idx}>{color}</option>
                    ))}
                  </select>
                </Col>
                <Col>
                  다이얼 타입
                  <select
                    className="form-control"
                    name="dialType"
                    value={oneModel.dialType || ""}
                    onChange={handleNewAuction}>
                    <option></option>
                    {dialTypeList?.map((type, idx) => (
                      <option key={idx}>{type}</option>
                    ))}
                  </select>
                </Col>
                <Col>
                  컨디션 {oneModel.condition || 0}
                  <Input
                    type="range"
                    onChange={handleNewAuction}
                    value={oneModel.condition || 0}
                    name="condition"
                    min="0"
                    max="10"
                    step="0.5"
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "40px" }}>
                <Col>
                  국내/국외 보유
                  <select
                    className="form-control"
                    name="holdingType"
                    value={holdingType ? holdingType : ""}
                    onChange={(e) => setHoldingType(e.target.value)}>
                    <option></option>
                    <option>국내</option>
                    <option>국외</option>
                  </select>
                </Col>
                <Col>
                  영수증 유무
                  <select
                    className="form-control"
                    name="hasReceipt"
                    value={hasReceipt ? true : false}
                    onChange={(e) => setHasReceipt(e.target.value)}>
                    <option value={true}>유</option>
                    <option value={false}>무</option>
                  </select>
                </Col>
                <Col></Col>
              </Row>
            </Container>
          </CardBody>
          <CardBody>
            <Container>
              <Row style={{ marginTop: "40px" }}>
                <Col>
                  가격
                  <Input
                    placeholder="가격"
                    type="text"
                    value={
                      auctionStartPrice
                        ? Number(auctionStartPrice).toLocaleString()
                        : ""
                    }
                    onChange={handleAuctionStartPriceInput}
                  />
                </Col>
                <Col>
                  설명
                  <Input
                    placeholder="설명"
                    type="text"
                    value={auctionDescription}
                    onChange={(e) => setAuctionDescription(e.target.value)}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "40px" }}>
                <Col style={{ display: "flex", flexDirection: "column" }}>
                  매장 방문시간 : &nbsp;
                  <input
                    type="datetime-local"
                    className="form=control"
                    value={visitTime}
                    onChange={(e) => setVisitTime(e.target.value)}
                  />
                  <span className="text-danger">
                    없으시면 아무시간으로 설정해주세요.
                  </span>
                </Col>
                <Col>
                  <div>보유지점</div>
                  {currentStoreList.map((store, idx) => (
                    <div
                      key={idx}
                      // onClick={() => setAuctionStore(store.id)}
                    >
                      <input
                        type="checkbox"
                        onChange={() => handleStoreChange(store.id)}
                        // disabled
                        name={store.name}
                        checked={
                          auctionStore.filter((el) => el === store.id).length >
                          0
                        }
                      />
                      <label htmlFor="supplement">{store.name}</label>
                    </div>
                  ))}
                </Col>
              </Row>

              <Row style={{ marginTop: "40px" }}>
                <Col>
                  사진첨부
                  {/* <div onClick={onClickUpdateNewAuctionInput}> */}
                  {/* <CardImg style={{ width: "50%" }} src={DETAULT_IMAGE} /> */}
                  <input
                    type="file"
                    accept="image/*"
                    ref={newAuctionPhotoInputRef}
                    key={watchPhotos}
                    onChange={onChangeNewAuctionInput}
                    // hidden
                  />
                  {/* </div> */}
                </Col>
                <Col>
                  예약하러 가기 연결 link
                  <Input
                    placeholder="예약하러 가기 연결 link"
                    type="text"
                    value={reservationLink}
                    onChange={(e) => setReservationLink(e.target.value)}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "40px" }}>
                <Col>
                  {watchPhotos.map((photo) => (
                    <>
                      <CardImg style={{ width: "10%" }} src={photo} />
                      <button onClick={() => deleteOnePhotoUrl(photo)}>
                        X
                      </button>
                    </>
                  ))}
                </Col>
              </Row>
              <Row style={{ marginTop: "40px" }}>
                <label>상세 정보(사진 및 추가 설명)</label>
                <Wrapper>
                  <Editor
                    ref={noticeEditor}
                    initialValue=""
                    previewStyle="vertical"
                    height="600px"
                    initialEditType="wysiwyg"
                    useCommandShortcut={true}
                  />
                </Wrapper>
              </Row>
              <Row>
                <Col
                  style={{
                    marginTop: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <Button
                    onClick={() =>
                      registerNewSell(
                        oneModel,
                        auctionStartPrice,
                        auctionDescription,
                        auctionStore,
                        watchPhotos,
                        visitTime,
                        reservationLink
                      )
                    }>
                    판매예약 등록하기
                  </Button>
                </Col>
              </Row>
            </Container>
          </CardBody>
        </>
      )}
    </>
  );
  // 파일 업로드 후 URL 반환
  async function uploadFileAndGetUrl(file) {
    if (file) {
      const { type: fileType, name: fileName, size: fileSize } = file;
      // 파일 데이터 만들기
      const formData = new FormData();
      formData.append("file", file);

      // 파일 업로드
      const uploadResult = await uploadSellFile(formData);
      const {
        status,
        data: { url },
      } = uploadResult;
      if (status === 200 && !!url) {
        return url;
      } else {
        alert("파일 업로드 실패");
        return null;
      }
    } else {
      return null;
    }
  }

  function getBrandList() {
    getBrandListApi().then(({ data: { data, status, detail } }) => {
      if (status === "SUCCESS") {
        setSelectedBrand(data[0]?.brand || null);
        setBrandList(data || []);
        // console.log(data);
      }
    });
  }

  function getStoreList() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      getStoreListApi(authToken).then(({ data: { status, error, data } }) => {
        if (status === "SUCCESS") {
          setCurrentStoreList(data.content || []);
        } else {
          alert(error);
        }
      });
    }
  }

  function searchWatchModel() {
    searchWatchModelApi(selectedBrand, modelInput).then(
      ({ data: { data, detail, status } }) => {
        if (status === "SUCCESS") {
          setResultModelArray(data || []);
        } else {
          alert(detail);
        }
      }
    );
  }

  function registerNewSell(
    oneModel,
    auctionStartPrice,
    auctionDescription,
    auctionStore,
    watchPhotos,
    visitTime,
    reservationLink
  ) {
    const authToken = CommonHelper.getAuthToken();
    let content = noticeEditor.current.getInstance().getMarkdown();
    content = encodingHtmlTags(replaceNewlineWith(content, `<br/>`));
    if (
      auctionStartPrice &&
      auctionDescription &&
      auctionStore &&
      watchPhotos &&
      visitTime
    ) {
      if (!!authToken) {
        registerNewSellApi(
          authToken,
          oneModel,
          auctionStartPrice,
          auctionDescription,
          auctionStore,
          watchPhotos,
          visitTime,
          content,
          holdingType,
          hasReceipt,
          reservationLink
        ).then(({ data: { data, detail, status } }) => {
          if (status === "SUCCESS") {
            alert("성공적으로 판매예약이 생성되었습니다.");
            setOneModel(null);
            setModelInput("");
            setResultModelArray([]);
            getReservationSellList();
            setShowNewSell(false);
          } else {
            //   alert("판매예약 생성하는데에 문제가 생겼습니다.");
            //   console.log(detail);
            alert(detail);
          }
        });
      } else {
        alert("로그인을 다시 해주세요.");
      }
    } else {
      alert("비어있는 값을 모두 채워주세요.");
    }
  }
};
export default AdminCreateSell;

const Wrapper = styled.div`
  padding-bottom: 2.8rem;
  font-size: 1.3rem;
  font-family: "Lato";
  width: 100%;
  label {
    display: block;
    margin-bottom: 1rem;
  }
  &.bordered {
    .editorWrapper {
      border-color: red;
    }
  }
  > div + p {
    width: 100%;
    padding-top: 12px;
    font-size: 1.2rem;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: -9px;
  }
`;
