import React, { useState, useEffect } from "react";
import img1 from "assets/images/users/1.jpg";
import img2 from "assets/images/users/2.jpg";
import img3 from "assets/images/users/3.jpg";
import img4 from "assets/images/users/4.jpg";
import {
  Card,
  CardBody,
  CardTitle,
  Button,
  Input,
  Table,
  Row,
  Alert,
  Col,
} from "reactstrap";
import {
  appManageExample,
  UPDATE_TYPE_LIST,
  OPERATION_TYPE_LIST,
  ACTIVATION_LIST,
  PLATFORM_LIST,
  UPDATE_EN_KO,
  UPDATE_KO_EN,
  SEARCH_OPERATING,
  SEARCH_ACTIVE,
} from "../../../common/constants";
import {
  getAppVersionApi,
  deleteOneVersionApi,
  modifyOneVersionApi,
  createNewVersionApi,
  searchAppVersionApi,
} from "../../../apis/appSite";
import CommonHelper from "../../../helpers/CommonHelper";
const initNewVersion = {
  version: null,
  updateType: "MANDATORY",
  isOperating: true,
  isActive: false,
  platformType: "IOS",
  description: "",
  maintenanceNotice: "",
  updateNotice: "",
};

const ServiceAppVersion = () => {
  const [showResister, setShowResister] = useState(false); //등록 폼 보여주는 state
  const [showModify, setShowModify] = useState(false);

  const [searchVersion, setSearchVersion] = useState("");
  const [searchUpdateType, setSearchUpdateType] = useState("");
  const [searchIsOperating, setSearchIsOperating] = useState("");
  const [searchIsActive, setSearchIsActive] = useState("");
  const [searchPlatformType, setSearchPlatformType] = useState("");

  const [currentAppVersions, setCurrentAppVersions] = useState([]);
  const [newAppVersion, setNewAppVersion] = useState(initNewVersion);
  const [oneVersion, setOneVersion] = useState({});

  const openResister = () => {
    setShowResister(true);
    setNewAppVersion(initNewVersion);
  };

  useEffect(() => {
    getAppVersion();
  }, []);

  //  "version": "1.0.0",
  //   "updateType": "MANDATORY",
  //   "isOperating": true,
  //   "isActive": true,
  //   "platformType": "IOS",
  //   "description": "",
  //   "maintenanceNotice": "",
  //   "updateNotice": "",
  //   "id": 2,
  //   "createdBy": "admin",
  //   "updatedBy": "admin",
  //   "createdAt": "2021-02-15T01:59:02.688726",
  //   "updatedAt": "2021-02-15T01:59:02.688726"

  /**
   * 리스트에서 수정하기 누르면 조회 보여주기
   * @param {*} version
   */
  const openModifyOneVersion = (version) => {
    setShowModify(true);
    setOneVersion(version);
  };

  const handleNewChange = (e) => {
    const { name, value } = e.target;
    const copyNewAppVersion = {
      ...newAppVersion,
    };
    switch (name) {
      case "isActive":
        if (value === "활성화") {
          copyNewAppVersion[name] = true;
        } else if (value === "비활성화") {
          copyNewAppVersion[name] = false;
        }
        break;
      case "isOperating":
        if (value === "운영") {
          copyNewAppVersion[name] = true;
        } else if (value === "점검") {
          copyNewAppVersion[name] = false;
        }
        break;
      case "updateType":
        copyNewAppVersion[name] = UPDATE_KO_EN[value];
        break;

      default:
        copyNewAppVersion[name] = value;
        break;
    }

    setNewAppVersion(copyNewAppVersion);
  };

  const handleModifyChange = (e) => {
    const { value, name } = e.target;
    const copyOneAppVersion = { ...oneVersion };
    switch (name) {
      case "isActive":
        if (value === "활성화") {
          copyOneAppVersion[name] = true;
        } else if (value === "비활성화") {
          copyOneAppVersion[name] = false;
        }
        break;
      case "isOperating":
        if (value === "운영") {
          copyOneAppVersion[name] = true;
        } else if (value === "점검") {
          copyOneAppVersion[name] = false;
        }
        break;
      case "updateType":
        copyOneAppVersion[name] = UPDATE_KO_EN[value];
        break;

      default:
        copyOneAppVersion[name] = value;
        break;
    }

    setOneVersion(copyOneAppVersion);
  };

  /**
   * handle 운영 search select
   * @param {*} e
   */
  const handleSearchOperating = (e) => {
    const { value, name } = e.target;
    if (value === "운영") {
      setSearchIsOperating(true);
    } else if (value === "점검") {
      setSearchIsOperating(false);
    } else if (value === "") {
      setSearchIsOperating("");
    }
  };

  /**
   * handle 활성화 search select
   */
  const handleSearchActive = (e) => {
    const { value } = e.target;
    if (value === "활성화") {
      setSearchIsActive(true);
    } else if (value === "비활성화") {
      setSearchIsActive(false);
    } else if (value === "") {
      setSearchIsActive(null);
    }
  };

  /**
   * 버전 삭제 버튼 클릭 시
   */
  const openAlertBeforeDelete = () => {
    const result = window.confirm(
      "해당 버전을 삭제하시겠습니까? 삭제 하시면 해당 버전 앱의 접속이 원활하지 않을 수 있습니다."
    );
    if (!!result) {
      deleteOneVersion();
    } else {
      alert("취소되었습니다.");
    }
  };

  const openAlertBeforeCreate = () => {
    if (!newAppVersion.version) {
      alert("사용버전을 채워주세요.");
    } else {
      createNewVersion();
    }
  };

  const closeRegister = () => {
    setShowResister(false);
  };

  const closeModify = () => {
    setShowModify(false);
  };
  return (
    <>
      {showResister && (
        <Card>
          <CardTitle className="bg-light border-bottom p-3 mb-0">
            <div className="space-between">
              앱 버전관리 등록
              <Button onClick={closeRegister}>닫기</Button>
            </div>
          </CardTitle>
          <CardBody>
            <Row style={{ margin: "10px" }}>
              <Col>업데이트타입</Col>
              <Col>
                <select
                  className="form-control"
                  name="updateType"
                  id={`check-업데이트타입`}
                  value={UPDATE_EN_KO[newAppVersion.updateType]} // selected로 변동될 수 있게 만들기
                  autoblur="true"
                  onChange={handleNewChange}
                  // disabled={selectDisabled}>
                >
                  {UPDATE_TYPE_LIST.map(({ label, value }, index) => (
                    <option key={index} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </Col>
              <Col>운영/점검</Col>
              <Col>
                <select
                  className="form-control"
                  name="isOperating"
                  id={`check-운영점검`}
                  value={newAppVersion.isOperating ? "운영" : "점검"} // selected로 변동될 수 있게 만들기
                  autoblur="true"
                  onChange={handleNewChange}
                  // disabled={selectDisabled}>
                >
                  {OPERATION_TYPE_LIST.map(({ label, value }, index) => (
                    <option key={index} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </Col>
              <Col>활성화</Col>
              <Col>
                <select
                  className="form-control"
                  name="isActive"
                  id={`check-활성화`}
                  value={newAppVersion.isActive ? "활성화" : "비활성화"} // selected로 변동될 수 있게 만들기
                  autoblur="true"
                  onChange={handleNewChange}
                  // disabled={selectDisabled}>
                >
                  {ACTIVATION_LIST.map(({ label, value }, index) => (
                    <option key={index} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
            <Row style={{ margin: "10px" }}>
              {/* <Col>최신버전</Col>
              <Col>
                <input
                  placeholder="최신버전"
                  onChange={(e) => setLatestInput(e.target.value)}
                  value={latestInput}
                />
              </Col> */}

              <Col>사용버전</Col>
              <Col>
                <Input
                  placeholder="사용버전"
                  onChange={handleNewChange}
                  value={newAppVersion.version}
                  name="version"
                />
              </Col>
              <Col>플랫폼</Col>
              <Col>
                <select
                  className="form-control"
                  name="platformType"
                  id={`check-플랫폼`}
                  value={newAppVersion.platformType} // selected로 변동될 수 있게 만들기
                  autoblur="true"
                  onChange={handleNewChange}
                  // disabled={selectDisabled}>
                >
                  {PLATFORM_LIST.map(({ label, value }, index) => (
                    <option key={index} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </Col>
              <Col></Col>
              <Col></Col>
            </Row>
            <Row style={{ margin: "10px" }}>
              <div style={{ width: "20%", padding: "15px" }}>
                <div>설명</div>
              </div>
              <div style={{ width: "75%" }}>
                <textarea
                  name="description"
                  value={newAppVersion.description}
                  className="form-control"
                  onChange={handleNewChange}
                  placeholder="관리자만 보게될 설명입니다."
                  style={{ width: "100%" }}></textarea>
              </div>
            </Row>
            <Row style={{ margin: "10px" }}>
              <div style={{ width: "20%", padding: "15px" }}>
                <div>정기점검 문구</div>
              </div>
              <div style={{ width: "75%" }}>
                <textarea
                  name="maintenanceNotice"
                  value={newAppVersion.maintenanceNotice}
                  className="form-control"
                  onChange={handleNewChange}
                  placeholder="점검일 경우 app에서 alert 띄울 문구입니다."
                  style={{ width: "100%" }}></textarea>
              </div>
            </Row>
            <Row style={{ margin: "10px" }}>
              <div style={{ width: "20%", padding: "15px" }}>
                <div>업데이트 문구</div>
              </div>
              <div style={{ width: "75%" }}>
                <textarea
                  name="updateNotice"
                  value={newAppVersion.updateNotice}
                  className="form-control"
                  placeholder="업데이트 관련 app에서 alert 띄울 문구입니다."
                  onChange={handleNewChange}
                  style={{ width: "100%" }}></textarea>
              </div>
            </Row>
            {/* <Row style={{ margin: "10px" }}>
              <div style={{ width: "20%", padding: "15px" }}>
                <div>선택 업데이트 문구</div>
              </div>
              <div style={{ width: "75%" }}>
                <textarea
                  className="form-control"
                  style={{ width: "100%" }}></textarea>
              </div>
            </Row> */}
            <Row style={{ display: "flex", justifyContent: "center" }}>
              <Button onClick={openAlertBeforeCreate}>등록하기</Button>
            </Row>
          </CardBody>
        </Card>
      )}
      {showModify && (
        <Card>
          <CardTitle className="bg-light border-bottom p-3 mb-0">
            <div className="space-between">
              앱 버전관리 수정
              <Button onClick={closeModify}>닫기</Button>
            </div>
          </CardTitle>
          <CardBody>
            <Row style={{ margin: "10px" }}>
              <Col>업데이트타입</Col>
              <Col>
                <select
                  className="form-control"
                  name="updateType"
                  // id={`check-업데이트타입`}
                  value={UPDATE_EN_KO[oneVersion.updateType]} // selected로 변동될 수 있게 만들기
                  autoblur="true"
                  onChange={handleModifyChange}
                  // disabled={selectDisabled}>
                >
                  {UPDATE_TYPE_LIST.map(({ label, value }, index) => (
                    <option key={index} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </Col>
              <Col>운영/점검</Col>
              <Col>
                <select
                  className="form-control"
                  name="isOperating"
                  id={`check-운영점검`}
                  value={oneVersion.isOperating ? "운영" : "점검"} // selected로 변동될 수 있게 만들기
                  autoblur="true"
                  onChange={handleModifyChange}
                  // disabled={selectDisabled}>
                >
                  {OPERATION_TYPE_LIST.map(({ label, value }, index) => (
                    <option key={index} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </Col>
              <Col>활성화</Col>
              <Col>
                <select
                  className="form-control"
                  name="isActive"
                  id={`check-활성화`}
                  value={oneVersion.isActive ? "활성화" : "비활성화"} // selected로 변동될 수 있게 만들기
                  autoblur="true"
                  onChange={handleModifyChange}
                  // disabled={selectDisabled}>
                >
                  {ACTIVATION_LIST.map(({ label, value }, index) => (
                    <option key={index} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
            <Row style={{ margin: "10px" }}>
              {/* <Col>최신버전</Col>
              <Col>
                <Input
                  placeholder="최신버전"
                  onChange={(e) => setLatestInput(e.target.value)}
                  value={latestInput}
                />
              </Col> */}
              <Col>사용버전</Col>
              <Col>
                <Input
                  placeholder="사용버전"
                  name="version"
                  onChange={handleModifyChange}
                  value={oneVersion.version}
                />
              </Col>
              <Col>플랫폼</Col>
              <Col>
                <select
                  className="form-control"
                  name="platformType"
                  id={`check-플랫폼`}
                  autoblur="true"
                  value={oneVersion.platformType}
                  onChange={handleModifyChange}
                  // disabled={selectDisabled}>
                >
                  {PLATFORM_LIST.map(({ label, value }, index) => (
                    <option key={index} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </Col>
              <Col></Col>
              <Col></Col>
            </Row>
            <Row style={{ margin: "10px" }}>
              <div style={{ width: "20%", padding: "15px" }}>
                <div>설명</div>
              </div>
              <div style={{ width: "75%" }}>
                <textarea
                  name="description"
                  className="form-control"
                  value={oneVersion.description}
                  onChange={handleModifyChange}
                  style={{ width: "100%" }}></textarea>
              </div>
            </Row>
            <Row style={{ margin: "10px" }}>
              <div style={{ width: "20%", padding: "15px" }}>
                <div>정기점검 문구</div>
              </div>
              <div style={{ width: "75%" }}>
                <textarea
                  name="maintenanceNotice"
                  className="form-control"
                  value={oneVersion.maintenanceNotice}
                  onChange={handleModifyChange}
                  style={{ width: "100%" }}></textarea>
              </div>
            </Row>
            <Row style={{ margin: "10px" }}>
              <div style={{ width: "20%", padding: "15px" }}>
                <div>업데이트 문구</div>
              </div>
              <div style={{ width: "75%" }}>
                <textarea
                  name="updateNotice"
                  className="form-control"
                  value={oneVersion.updateNotice}
                  onChange={handleModifyChange}
                  style={{ width: "100%" }}></textarea>
              </div>
            </Row>
            {/* <Row style={{ margin: "10px" }}>
              <div style={{ width: "20%", padding: "15px" }}>
                <div>선택 업데이트 문구</div>
              </div>
              <div style={{ width: "75%" }}>
                <textarea style={{ width: "100%" }}></textarea>
              </div>
            </Row> */}
            <Row style={{ display: "flex", justifyContent: "center" }}>
              <Button onClick={modifyOneVersion}>수정하기</Button>
              <Button onClick={openAlertBeforeDelete}>삭제하기</Button>
            </Row>
          </CardBody>
        </Card>
      )}
      <Card>
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          <div className="space-between">
            앱 버전 관리
            <Button onClick={() => openResister()}>등록</Button>
          </div>
        </CardTitle>
        <CardBody>
          <Row
            style={{
              display: "flex",
              margin: "10px auto",
              justifyContent: "space-around",
            }}>
            <label style={{ display: "flex", alignItems: "center" }}>
              version
            </label>
            <Input
              value={searchVersion}
              style={{ width: "10%" }}
              onChange={(e) => setSearchVersion(e.target.value)}
              // placeholder=""
            />
            <label style={{ display: "flex", alignItems: "center" }}>
              updateType
            </label>
            <select
              className="form-control"
              style={{ width: "10%" }}
              value={searchUpdateType}
              onChange={(e) => setSearchUpdateType(e.target.value)}>
              <option value=""></option>
              <option value="MANDATORY">강제</option>
              <option value="SEMI_MANDATORY">반강제</option>
              <option value="OPTIONAL">선택</option>
            </select>
            <label style={{ display: "flex", alignItems: "center" }}>
              운영
            </label>
            <select
              className="form-control"
              style={{ width: "10%" }}
              value={SEARCH_OPERATING[searchIsOperating]}
              onChange={(e) => handleSearchOperating(e)}>
              <option value="">없음</option>
              <option value="운영">운영</option>
              <option value="점검">점검</option>
            </select>
            <label style={{ display: "flex", alignItems: "center" }}>
              활성화
            </label>
            <select
              className="form-control"
              style={{ width: "10%" }}
              value={SEARCH_ACTIVE[searchIsActive]}
              onChange={(e) => handleSearchActive(e)}>
              <option value="">없음</option>
              <option value="활성화">활성화</option>
              <option value="비활성화">비활성화</option>
            </select>
            <label style={{ display: "flex", alignItems: "center" }}>
              플랫폼
            </label>
            <select
              className="form-control"
              style={{ width: "10%" }}
              value={searchPlatformType}
              onChange={(e) => setSearchPlatformType(e.target.value)}>
              <option value=""></option>
              <option value="IOS">IOS</option>
              <option value="ANDROID">ANDROID</option>
            </select>
            <Button onClick={searchAppVersion}>검색</Button>
          </Row>
          <Table className="no-wrap v-middle" responsive>
            <thead>
              <tr className="border-0">
                <th className="border-0">고유키</th>
                <th className="border-0">버전</th>

                <th className="border-0">플랫폼</th>
                <th className="border-0">활성화여부</th>
                <th className="border-0">운영/점검</th>
                <th className="border-0">업데이트타입</th>
                <th className="border-0"></th>
              </tr>
            </thead>
            <tbody>
              {currentAppVersions.map((version) => (
                <tr>
                  <td>
                    <div className="d-flex no-block align-items-center">
                      <div className="">
                        <h5 className="mb-0 font-16 font-medium">
                          {version.id || "-"}
                        </h5>
                        {/* <span>hgover@gmail.com</span> */}
                      </div>
                    </div>
                  </td>
                  <td>{version.version || "-"}</td>
                  <td>{version.platformType || "-"}</td>
                  <td className="blue-grey-text  text-darken-4 font-medium">
                    {version.isActive ? "활성화" : "비활성화"}
                  </td>
                  <td>{version.isOperating ? "운영" : "점검"}</td>
                  <td className="blue-grey-text  text-darken-4 font-medium">
                    {version.updateType || "-"}
                  </td>
                  <td>
                    <Button onClick={() => openModifyOneVersion(version)}>
                      수정/삭제
                    </Button>
                    {/* <Button onClick={openAlertBeforeDelete}>삭제하기</Button> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  );

  function getAppVersion() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      getAppVersionApi(authToken).then(({ data }) => {
        setCurrentAppVersions(data || []);
      });
    }
  }

  function deleteOneVersion() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      deleteOneVersionApi(authToken, oneVersion.id).then(
        ({ data: { status } }) => {
          if (status === "SUCCESS") {
            alert("성공적으로 삭제되었습니다.");
            setShowModify(false);
            getAppVersion();
          }
        }
      );
    }
  }

  function modifyOneVersion() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      modifyOneVersionApi(authToken, oneVersion).then(({ data }) => {
        if (data?.updatedAt) {
          alert("성공적으로 수정되었습니다.");
          // setShowModify(false);
          getAppVersion();
        } else {
          alert("수정하는데에 문제가 생겼습니다.");
        }
      });
    }
  }

  function createNewVersion() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      createNewVersionApi(authToken, newAppVersion).then(({ data }) => {
        if (data?.createdAt) {
          alert("성공적으로 생성되었습니다.");
          getAppVersion();
        } else {
          alert("생성하는데에 문제가 생겼습니다.");
        }
      });
    }
  }

  function searchAppVersion() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      searchAppVersionApi(
        authToken,
        searchVersion,
        searchUpdateType,
        searchIsOperating,
        searchIsActive,
        searchPlatformType
      ).then(({ data }) => {
        setCurrentAppVersions(data || []);
      });
    }
  }
};

export default ServiceAppVersion;
