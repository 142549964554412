import { apiCall, apiCallByFormData } from "./apiCall";
import { CLIENT_URL, SERVER_API_HOST } from "../common/environment";
import CommonHelper from "helpers/CommonHelper";

export const postSignUpApi = (
  id,
  password,
  email,
  nickName,
  phoneNumber,
  name,
  agreeMail,
  agreeSms,
  authToken
) => {
  return apiCall(
    `${SERVER_API_HOST}/api/user/create/admin`,
    "post",
    {
      loginId: id,
      password: password,
      name: name,
      email: email,
      phone: phoneNumber,
      nickName: nickName,
      agreeMail: agreeMail,
      agreeSms: agreeSms,
    },
    {
      authorization: authToken,
    }
  );
};

export const getAdminListApi = (authToken, searchBox, page) => {
  return apiCall(
    // `${SERVER_API_HOST}/api/user/list?role=ADMIN&page=${page}&name=${
    //   searchBox.name || null
    // }&nickName=${searchBox.nickName || null}&loginId=${
    //   searchBox.loginId || null
    // }`,
    `${SERVER_API_HOST}/api/user/list?role=ADMIN&page=${page}`,
    "get",
    searchBox,
    { authorization: authToken }
  );
};

export const putUserDataUpdateApi = (userId, newPassword,newName,newEmail,newPhone,newNickName,newAgreeMail,newAgreeSms,authToken) => {
  return apiCall(
    `${SERVER_API_HOST}/api/user/update/${userId}`,
    "put",
    {
      password: newPassword,
      name: newName,
      email: newEmail,
      phone: newPhone,
      nickName: newNickName,
      agreeMail: newAgreeMail,
      agreeSms: newAgreeSms,
    },
    {
      authorization: authToken,
    }
  );
};
