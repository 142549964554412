import { apiCall, apiCallByFormData } from "./apiCall";
import { CLIENT_URL, SERVER_API_HOST } from "../common/environment";
import CommonHelper from "helpers/CommonHelper";

export const getPopupListApi = (authToken) => {
  return apiCall(
    `${SERVER_API_HOST}/api/popup/list?page=0&offset=10`,
    "get",
    {},
    {
      authorization: authToken,
    }
  );
};

export const uploadFile = async (formData) => {
  // const formDataResult = await apiCallByFormData(
  //         `${SERVER_API_HOST}/api/public/attache/file/upload`,
  //         formData,
  //         {}
  //     ).data;
  // return formDataResult;
  return apiCallByFormData(
    `${SERVER_API_HOST}/api/public/attache/file/upload`,
    formData,
    {}
  );
};

export const sendCreatePopupApi = (authToken, newPopupInfo) => {
  return apiCall(`${SERVER_API_HOST}/api/popup`, "post", newPopupInfo, {
    authorization: authToken,
  });
};

export const sendModifyOnePopupApi = (authToken, modifyPopupDetail) => {
  const getId = modifyPopupDetail?.id ? modifyPopupDetail.id : null;
  if (getId) {
    return apiCall(
      `${SERVER_API_HOST}/api/popup/${getId}`,
      "put",
      modifyPopupDetail,
      {
        authorization: authToken,
      }
    );
  } else {
    return {
      status: "error",
    };
  }
};

export const deleteModifyOnePopupApi = (authToken, deletePopupDetail) => {
  const getId = deletePopupDetail?.id ? deletePopupDetail.id : null;
  if (getId) {
    return apiCall(
      `${SERVER_API_HOST}/api/popup/${getId}`,
      "delete",
      {},
      {
        authorization: authToken,
      }
    );
  } else {
    return {
      status: "error",
    };
  }
};

////////// 배너 ////////////////

export const createBannerApi = (authToken, newBannerInfo) => {
  return apiCall(`${SERVER_API_HOST}/api/banner`, "post", newBannerInfo, {
    authorization: authToken,
  });
};

export const getBannerListApi = (authToken) => {
  return apiCall(
    `${SERVER_API_HOST}/api/banner/list`,
    "get",
    {},
    {
      authorization: authToken,
    }
  );
};

export const modifyOneBannerApi = (authToken, modifyBannerDetail) => {
  if (modifyBannerDetail?.id) {
    return apiCall(
      `${SERVER_API_HOST}/api/banner/${modifyBannerDetail.id}`,
      "put",
      modifyBannerDetail,
      {
        authorization: authToken,
      }
    );
  } else
    return {
      data: {
        status: "FAIL",
        error: "수정 실패",
      },
    };
};

export const deleteOneBannerApi = (authToken, oneBannerDetail) => {
  if (oneBannerDetail?.id) {
    return apiCall(
      `${SERVER_API_HOST}/api/banner/${oneBannerDetail.id}`,
      "delete",
      {},
      {
        authorization: authToken,
      }
    );
  } else
    return {
      data: {
        status: "FAIL",
        error: "삭제 실패",
      },
    };
};

///////매장정보////////

export const getStoreListApi = (authToken) => {
  return apiCall(
    `${SERVER_API_HOST}/api/store/list?page=0&offset=1000`,
    "get",
    {},
    {
      authorization: authToken,
    }
  );
};

export const createNewStoreApi = (authToken, newStoreInfo) => {
  return apiCall(`${SERVER_API_HOST}/api/store`, "post", newStoreInfo, {
    authorization: authToken,
  });
};

export const modifyOneStoreApi = (authToken, id, modifyOneStore) => {
  return apiCall(`${SERVER_API_HOST}/api/store/${id}`, "put", modifyOneStore, {
    authorization: authToken,
  });
};

export const deleteOneStoreApi = (authToken, oneStoreDetail) => {
  return apiCall(
    `${SERVER_API_HOST}/api/store/${oneStoreDetail.id}`,
    "delete",
    {},
    {
      authorization: authToken,
    }
  );
};

/////////////PC롤링
export const changePcRollingApi = (authToken, pcStandard, pcLimit) => {
  return apiCall(
    `${SERVER_API_HOST}/api/site/rolling/pc`,
    "put",
    {
      standard: pcStandard,
      limit: pcLimit,
    },
    {
      authorization: authToken,
    }
  );
};
/////////////app 롤링
export const changeAppRollingApi = (authToken, appStandard) => {
  return apiCall(
    `${SERVER_API_HOST}/api/site/rolling/app`,
    "put",
    {
      standard: appStandard,
      limit: 10,
    },
    {
      authorization: authToken,
    }
  );
};

export const putMobileBannerApi = (authToken, bannerList) => {
  return apiCall(
    `${SERVER_API_HOST}/api/site/watch/background/images`,
    "put",
    bannerList,
    { authorization: authToken }
  );
};
