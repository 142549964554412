import React, { useState } from "react";
import { Card, CardBody, CardTitle, Button } from "reactstrap";
import AdminCreateAcount from "./admin-admin-components/AdminCreateAcount";
const ADMIN_MENU = ["관리자 계정 관리"];

const AdminManage = () => {
  const [currMenu, setCurrMenu] = useState(ADMIN_MENU[0]);
  const changeMenu = (e) => {
    setCurrMenu(e.target.value);
  };
  return (
    <>
      <Card>
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            {ADMIN_MENU.map((menu) => (
              <Button
                // style={
                //   menu === currMenu
                //     ? {
                //         border: "2px solid black",
                //         padding: "6px",
                //         cursor: "pointer",
                //       }
                //     : {
                //         border: "1px solid gray",
                //         padding: "6px",
                //         cursor: "pointer",
                //       }
                // }
                value={menu}
                color="info"
                onClick={(e) => changeMenu(e)}>
                {menu}
              </Button>
            ))}
          </div>
        </CardTitle>
        <CardBody>
          {currMenu === "관리자 계정 관리" && <AdminCreateAcount />}
        </CardBody>
      </Card>
    </>
  );
};

export default AdminManage;
