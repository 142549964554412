import { apiCall, apiCallByFormData } from "./apiCall";
import { CLIENT_URL, SERVER_API_HOST } from "../common/environment";

export const getStoreListApi = () => {
  return apiCall(
    `${SERVER_API_HOST}/api/store/list?page=0&offset=5`,
    "get",
    {},
    {}
  );
};

// 메인에 예약 리스트
export const getHaplMainListApi = (
  store,
  authToken,
  page,
  seekModel,
  seekStatus
) => {
  const id = store === "전체" ? null : store ? store : null;
  const token = !!authToken ? authToken : null;
  const model = seekModel || null;
  const status = seekStatus === "전체" ? null : seekStatus ? seekStatus : null;
  return apiCall(
    `${SERVER_API_HOST}/api/visit/reservation/list?page=${page}&offset=10${
      id ? `&storeId=${id}` : ""
    }${model ? `&search=${model}` : ""}${status ? `&status=${status}` : ""}`,
    "get",
    {},
    {
      authorization: token,
    }
  );
};

export const cancelHaplApi = (id, authToken) => {
  return apiCall(
    `${SERVER_API_HOST}/api/visit/reservation/cancel/${id}`,
    "delete",
    {},
    {
      authorization: authToken,
    }
  );
};

export const deleteHaplApi = (id, authToken) => {
  return apiCall(
    `${SERVER_API_HOST}/api/visit/reservation/delete/${id}`,
    "delete",
    {},
    {
      authorization: authToken,
    }
  );
};

export const approveHaplApi = (id, token) => {
  return apiCall(
    `${SERVER_API_HOST}/api/visit/reservation/confirm/${id}`,
    "put",
    {},
    {
      authorization: token,
    }
  );
};
