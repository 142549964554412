import { apiCall } from "./apiCall";
import { CLIENT_URL, SERVER_API_HOST } from "../common/environment";
import CommonHelper from "helpers/CommonHelper";

/**
 * 로그인
 * @param userId
 * @param userPassword
 * @returns {AxiosPromise}
 */
export const signInApi = (loginId, password) => {
  return apiCall(`${SERVER_API_HOST}/api/public/login`, "post", {
    loginId,
    password,
  });
};

export const getUserSessionApi = (authToken) => {
  return apiCall(
    `${SERVER_API_HOST}/api/user/session`,
    "get",
    {},
    {
      authorization: authToken,
    }
  );
};
