/*--------------------------------------------------------------------------------*/
/*                                  starter                                    */
/*--------------------------------------------------------------------------------*/
import FirstDashboard from "../views/starter/starter.jsx";
/*--------------------------------------------------------------------------------*/
/*                           Ui-components Dropdown                               */
/*--------------------------------------------------------------------------------*/
import Alerts from "../views/ui-components/alert.jsx";
import Badges from "../views/ui-components/badge.jsx";
import Buttons from "../views/ui-components/button.jsx";
import Cards from "../views/ui-components/cards.jsx";
import LayoutComponent from "../views/ui-components/layout.jsx";
import PaginationComponent from "../views/ui-components/pagination.jsx";
import PopoverComponent from "../views/ui-components/popover.jsx";
import TooltipComponent from "../views/ui-components/tooltip.jsx";
import UserListManage from "../components/admin-components/UserListManage.jsx";
import ReservationManage from "../components/admin-components/ReservationManage.jsx";
import AuctionManage from "../components/admin-components/AuctionManage.jsx";
import CustomerCenter from "../components/admin-components/CustomerCenter.jsx";
import AdminManage from "../components/admin-components/AdminManage.jsx";
import MainSetting from "../components/admin-components/MainSetting.jsx";
import ServiceSetting from "../components/admin-components/ServiceSetting.jsx";
import RecommendSetting from "../components/admin-components/RecommendSetting.jsx";
import AddProduct from "../components/admin-components/AddProduct";
import PriceManage from "../components/admin-components/PriceManage";
import ModelDetailManage from "../components/admin-components/ModelDetailManage";
import HaplManage from "../components/admin-components/HaplManage.jsx";

var ThemeRoutes = [
  // {
  //   path: "/starter/starter",
  //   name: "Dashboard",
  //   icon: "mdi mdi-adjust",
  //   component: FirstDashboard,
  // },
  // {
  //   path: "/ui-components/alert",
  //   name: "Alerts",
  //   icon: "mdi mdi-comment-processing-outline",
  //   component: Alerts,
  // },
  // {
  //   path: "/ui-components/badge",
  //   name: "Badges",
  //   icon: "mdi mdi-arrange-send-backward",
  //   component: Badges,
  // },
  // {
  //   path: "/ui-components/button",
  //   name: "Buttons",
  //   icon: "mdi mdi-toggle-switch",
  //   component: Buttons,
  // },
  // {
  //   path: "/ui-components/card",
  //   name: "Cards",
  //   icon: "mdi mdi-credit-card-multiple",
  //   component: Cards,
  // },
  // {
  //   path: "/ui-components/layout",
  //   name: "Layout",
  //   icon: "mdi mdi-apps",
  //   component: LayoutComponent,
  // },
  // {
  //   path: "/ui-components/pagination",
  //   name: "Pagination",

  //   icon: "mdi mdi-priority-high",
  //   component: PaginationComponent,
  // },
  // {
  //   path: "/ui-components/popover",
  //   name: "Popover",

  //   icon: "mdi mdi-pencil-circle",
  //   component: PopoverComponent,
  // },
  // {
  //   path: "/ui-components/tooltip",
  //   name: "Toltips",

  //   icon: "mdi mdi-image-filter-vintage",
  //   component: TooltipComponent,
  // },
  {
    path: "/admin-components/MainSetting",
    name: "메인 설정(PC/App)",
    icon: "mdi mdi-image-filter-vintage",
    component: MainSetting,
  },
  {
    path: "/admin-components/HaplManage",
    icon: "mdi mdi-image-filter-vintage",
    name: "공간대여 관리",
    component: HaplManage,
  },
  {
    path: "/admin-components/RecommendSetting",
    name: "추천 설정(App)",
    icon: "mdi mdi-image-filter-vintage",
    component: RecommendSetting,
  },
  {
    path: "/admin-components/ServiceSetting",
    name: "서비스 설정(App)",
    icon: "mdi mdi-image-filter-vintage",
    component: ServiceSetting,
  },
  {
    path: "/admin-components/UserListManage",
    name: "회원관리",
    icon: "mdi mdi-image-filter-vintage",
    component: UserListManage,
  },
  {
    path: "/admin-components/ReservationManage",
    name: "예약관리/판매관리",
    icon: "mdi mdi-image-filter-vintage",
    component: ReservationManage,
  },
  {
    path: "/admin-components/AuctionManage",
    name: "하이드로우 관리",
    icon: "mdi mdi-image-filter-vintage",
    component: AuctionManage,
  },
  {
    path: "/admin-components/CustomerCenter",
    name: "고객센터",
    icon: "mdi mdi-image-filter-vintage",
    component: CustomerCenter,
  },
  {
    path: "/admin-components/AddProduct",
    name: "브랜드/모델 추가",
    icon: "mdi mdi-image-filter-vintage",
    component: AddProduct,
  },
  {
    path: "/admin-components/PriceManage",
    name: "가격관리",
    icon: "mdi mdi-image-filter-vintage",
    component: PriceManage,
  },
  {
    path: "/admin-components/ModelDetailManage",
    name: "모델 항목 관리",
    icon: "mdi mdi-image-filter-vintage",
    component: ModelDetailManage,
  },
  {
    path: "/admin-components/AdminManage",
    name: "관리자 설정",
    icon: "mdi mdi-image-filter-vintage",
    component: AdminManage,
  },
  {
    path: "/",
    pathTo: "/admin-components/MainSetting",
    name: "메인 설정(PC/App)",
    redirect: true,
  },
];
export default ThemeRoutes;
