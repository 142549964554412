import React, { useState, useEffect } from "react";
import {
  Card,
  CardTitle,
  Table,
  Row,
  CardBody,
  Container,
  Button,
} from "reactstrap";
import {
  getFindProductListApi,
  changeOneFindStateApi,
  deleteOneFindListApi,
} from "../../../apis/reservation";
import PaginationComponent from "../../PaginationComponent";
import CommonHelper from "../../../helpers/CommonHelper";
import { handleCreatedAt } from "../../../common/common";

const ReservationFind = () => {
  const [listState, setListState] = useState(null);
  const [findServiceList, setFindServiceList] = useState([]);
  const [findServiceData, setFindServiceData] = useState({});
  const [error, setError] = useState(false);
  const [showOpenDetail, setShowOpenDetail] = useState(false);
  const [oneFindDetail, setOneFindDetail] = useState({});

  const authToken = CommonHelper.getAuthToken();

  /////페이지네이션
  const [page, setPage] = useState(0);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(4);
  const LIMIT = 10;

  const { totalPage } = findServiceData;

  const pageNumbers = [];
  for (let i = 0; i < totalPage; i++) {
    pageNumbers.push(i + 1);
  }
  const newPageNumbers = pageNumbers.slice(start, end);

  const paginate = (number) => {
    const num = number === 0 ? number : number - 1;
    setPage(num);
  };

  const prevPage = () => {
    if (page + 1 === 1) {
      return alert("첫페이지입니다.");
    }
    if ((page + 1) % 4 === 1) {
      const s = start > 0 ? start - 4 : 0;
      const e = end > 0 ? end - 4 : end;

      setStart(s);
      setEnd(e);
    }
    setPage(page - 1);
  };

  const nextPage = () => {
    if (page + 2 > pageNumbers.length) {
      return alert("마지막페이지입니다.");
    } else {
      setPage(page + 1);
      if ((page + 2) % 4 === 1) {
        const s = start + 4;
        const e = end + 4;
        setStart(s);
        setEnd(e);
      }
    }
  };
  useEffect(() => {
    authToken && getFindProductList();
  }, [authToken, page]);

  const openOneDetail = (oneFind) => {
    setShowOpenDetail(true);
    setOneFindDetail(oneFind);
  };
  const closeDetail = () => {
    setShowOpenDetail(false);
  };
  const handleFindStateSelect = (e) => {
    const copyOneFind = { ...oneFindDetail };
    copyOneFind.state = e.target.value;
    setOneFindDetail(copyOneFind);
  };

  const changeListState = (e) => {
    setListState(e.target.value);
  };
  return (
    <>
      {showOpenDetail && (
        <Card>
          <CardBody>
            <CardTitle>
              <div className="space-between">
                회원상세
                <Button onClick={closeDetail}>닫기</Button>
              </div>
            </CardTitle>
            <Container>
              <Row style={{ marginTop: "20px" }}>
                <div className="half-width">고유 id</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneFindDetail.id || "-"}
                </div>
              </Row>
              <Row style={{ marginTop: "20px" }}>
                <div className="half-width">model</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneFindDetail.model || "-"}
                </div>
              </Row>
              <Row style={{ marginTop: "20px" }}>
                <div className="half-width">가격</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneFindDetail.price
                    ? Number(oneFindDetail.price).toLocaleString()
                    : "-"}
                </div>
              </Row>
              <Row style={{ marginTop: "20px" }}>
                <div className="half-width">전화번호</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneFindDetail.phone || "-"}
                </div>
              </Row>
              <Row style={{ marginTop: "20px" }}>
                <div className="half-width">설명</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneFindDetail.description || "-"}
                </div>
              </Row>
              <Row style={{ marginTop: "20px" }}>
                <div className="half-width">신청자 loginId</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneFindDetail?.createdBy || "-"}
                </div>
              </Row>
              <Row style={{ marginTop: "20px" }}>
                <div className="half-width">서비스 상태</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneFindDetail.state || "-"}
                </div>
              </Row>
              <Row style={{ marginTop: "20px" }}>
                <div className="half-width">신청된 시간</div>
                <div className="bg-light border p-2 col-sm-4">
                  {oneFindDetail.createdAt
                    ? handleCreatedAt(oneFindDetail.createdAt)
                    : "-"}
                </div>
              </Row>
              <Row style={{ marginTop: "20px" }}>
                <div className="half-width">상태 변경</div>
                <select
                  value={oneFindDetail.state}
                  onChange={handleFindStateSelect}>
                  <option value="REGISTERED">REGISTERED</option>
                  <option value="SEEKING">SEEKING</option>
                  <option value="STOCKED">STOCKED</option>
                </select>
                <Button
                  onClick={changeOneFindState}
                  style={{ marginLeft: "20px" }}>
                  상태변경
                </Button>
              </Row>
              <Row style={{ marginTop: "20px" }}>
                <div className="half-width">찾아줘 리스트 삭제</div>
                <Button onClick={deleteOneFindList}>삭제하기</Button>
              </Row>
            </Container>
          </CardBody>
        </Card>
      )}
      <label style={{ margin: "5px" }}>상태에 따른 찾아줘 리스트 검색</label>
      <select
        style={{ margin: "5px", height: "30px" }}
        value={listState}
        onChange={changeListState}>
        <option value="">선택</option>
        <option value="REGISTERED">REGISTERED</option>
        <option value="SEEKING">SEEKING</option>
        <option value="STOCKED">STOCKED</option>
      </select>
      <Button onClick={getFindProductList}>검색</Button>
      <Card>
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          찾아줘 리스트
        </CardTitle>
        <CardTitle>
          <p>등록됨: REGISTERED, 찾는중: SEEKING, 입고됨: STOCKED</p>
        </CardTitle>
        <Table className="no-wrap v-middle" responsive>
          <thead>
            <tr className="border-0">
              <th className="border-0">고유 id</th>
              <th className="border-0">model</th>
              <th className="border-0">price</th>
              {/* <th className="border-0">description</th> */}
              <th className="border-0">신청자 loginId</th>
              <th className="border-0">서비스 상태</th>
              <th className="border-0">신청된 시간</th>
            </tr>
          </thead>
          <tbody>
            {findServiceList?.map((el, idx) => (
              <tr key={idx}>
                <td>{el.id || "-"}</td>
                <td>{el.model || "-"}</td>
                <td>{el.price ? Number(el.price).toLocaleString() : "-"}</td>
                {/* <td>{el.description || "-"}</td> */}
                <td>{el.createdBy || "-"}</td>
                <td>{el.state || "-"}</td>
                <td>{el.createdAt ? handleCreatedAt(el.createdAt) : "-"}</td>
                <td>
                  <Button onClick={() => openOneDetail(el)}>상세보기</Button>
                </td>
              </tr>
            ))}
            <PaginationComponent
              prevPage={prevPage}
              paginate={paginate}
              nextPage={nextPage}
              newPageNumbers={newPageNumbers}
              page={page}
              lastPage={findServiceData.lastPage}
            />
          </tbody>
        </Table>
      </Card>
    </>
  );

  function getFindProductList() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      getFindProductListApi(authToken, listState, page).then(
        ({ data: { data, status, detail } }) => {
          // console.log(res);
          if (status === "SUCCESS") {
            setFindServiceList(data.content || []);
            setFindServiceData(data);
          } else {
            setError(true);
            alert(detail);
          }
        }
      );
    }
  }

  function changeOneFindState() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      changeOneFindStateApi(
        authToken,
        oneFindDetail.id,
        oneFindDetail.state
      ).then(({ data: { data, status } }) => {
        // console.log(res);
        if (status === "SUCCESS") {
          alert("상태 수정이 완료되었습니다.");
          getFindProductList();
        } else {
          alert("수정하는데 문제가 있습니다.");
          getFindProductList();
        }
      });
    }
  }

  function deleteOneFindList() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      deleteOneFindListApi(authToken, oneFindDetail.id).then(
        ({ data: { data, status } }) => {
          if (status === "SUCCESS") {
            alert("성공적으로 삭제되었습니다.");
            setShowOpenDetail(false);
            getFindProductList();
          } else {
            alert("삭제하는데 문제가 있습니다.");
          }
        }
      );
    }
  }
};
export default ReservationFind;
