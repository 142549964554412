import React, { createRef, useCallback, useRef, useState } from "react";
import { Button, Input, Card, CardBody, CardTitle, Row, CardImg } from "reactstrap";
import styled, { css } from "styled-components";
// import { commonStyle } from "../../../../assets/js/commonStyle";
import { useHistory } from "react-router-dom";
// import green from "@material-ui/core/colors/green";
// import { addNotice, updateSymbols } from "../../../../helpers/apiList";
import { Editor } from "@toast-ui/react-editor";
import "@toast-ui/editor/dist/toastui-editor.css";
import {
  decodingHtmlTags,
  encodingHtmlTags,
  replaceNewlineWith,
  replaceNewlineWithBrTag,
} from "../../../../helpers/stringHelpers";
import { createNewReviewApi } from "../../../../apis/customer";
import CommonHelper from "../../../../helpers/CommonHelper";
import DEFAULT_IMAGE from '../../../../assets/images/big/default-image.jpg';
import { SERVER_API_HOST } from "../../../../common/environment";
import { uploadFile } from "../../../../apis/main";

const NewReview = ({ setShowNewNoticeForm, getNoticeList }) => {
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("공지");
  const [attachFiles, setAttachFiles] = useState([]);
  //   const classes = commonStyle();
  const history = useHistory();
  const noticeEditor = useRef(null);
  const newStoreInputRef = useRef(null);

  const onClickUpdateNewStoreInput = () => {
    // 파일 입력 폼에 변화가 일어나는지 감시하는 이벤트 리스터 등록
    newStoreInputRef.current.addEventListener(
      "change",
      onChangeNewStoreInput,
      false
    );
    // 파일 입력 폼 열기
    newStoreInputRef.current.click();
  };
  return (
    <Card>
      <CardTitle className="bg-light border-bottom p-3 mb-0">
        리뷰pick 작성
      </CardTitle>
      <CardBody maxWidth="xl">
        <Row>
          <label style={{ marginTop: 10, color: "black", fontWeight: 600 }}>
            제목 *
          </label>
          <Input
            autoFocus
            style={{ marginRight: 0 }}
            type="text"
            placeholder="글 제목"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Row>
        <Row style={{ width: "100%" }}>
          <label style={{ marginTop: 10, color: "black", fontWeight: 600 }}>
            내용 *
          </label>
          <Wrapper>
            <Editor
              ref={noticeEditor}
              initialValue=""
              previewStyle="vertical"
              height="600px"
              initialEditType="wysiwyg"
              useCommandShortcut={true}
            />
          </Wrapper>
        </Row>
        <Row>
          <Card>
            <div onClick={onClickUpdateNewStoreInput}>
              <CardImg
                top
                width="100%"
                src={attachFiles[0] ? attachFiles[0] : DEFAULT_IMAGE}
              />
              <input
                type="file"
                accept="image/*"
                ref={newStoreInputRef}
                hidden
              />
            </div>
          </Card>
        </Row>
        <Row style={{ display: "flex", justifyContent: "space-between" }}>
          <Button onClick={() => onSave()}>등록하기</Button>
          <Button onClick={() => setShowNewNoticeForm(false)}>닫기</Button>
        </Row>
      </CardBody>
    </Card>
  );

  async function onChangeNewStoreInput(event) {
    // 파일을 선택 한 경우에만 실행
    const file = event.target.files[0];
    // 여러번 등록되는 버그 수정 (https://stackoverflow.com/questions/39484895/how-to-allow-input-type-file-to-select-the-same-file-in-react-component)
    event.target.value = null;
    const authToken = CommonHelper.getAuthToken();
    // 파일 업로드
    let nextPhotoUrl = await uploadFileAndGetUrl(file);
    if (nextPhotoUrl) {
      // nextPhotoUrl = nextPhotoUrl.replace('http://localhost:8080', SERVER_API_HOST); // TODO 임시 코드
      nextPhotoUrl = `${SERVER_API_HOST}${nextPhotoUrl}`;
      const fakeInfo = [...attachFiles];
      //   fakeInfo.profiles.push(nextPhotoUrl);
      fakeInfo[0] = nextPhotoUrl;
      setAttachFiles(fakeInfo);
    }
  }

  // 파일 업로드 후 URL 반환
  async function uploadFileAndGetUrl(file) {
    if (file) {
      const { type: fileType, name: fileName, size: fileSize } = file;
      // 파일 데이터 만들기
      const formData = new FormData();
      formData.append("file", file);

      // 파일 업로드
      const uploadResult = await uploadFile(formData);
      const {
        status,
        data: { url },
      } = uploadResult;
      if (status === 200 && !!url) {
        return url;
      } else {
        alert("파일 업로드 실패");
        return null;
      }
    } else {
      return null;
    }
  }

  async function onSave() {
    const authToken = CommonHelper.getAuthToken();
    // const content = editorStateToHtml(noticeEditor.current.getEditorState());
    let content = noticeEditor.current.getInstance().getMarkdown();
    if (!title) {
      alert("제목을 입력해주세요.");
      return;
    }

    if (!content) {
      alert("내용을 입력해주세요.");
      return;
    }
    content = encodingHtmlTags(replaceNewlineWith(content, `<br/>`));

    const params = {
      title,
      content,
      group: 'review',
      attachFiles,
      //   category,
      //   attachFiles: [], // 일단 첨부파일은 공지사항에서 없는 것으로 함...
    };
    const response = await createNewReviewApi(
      authToken,
      params.title,
      params.content,
      params.group,
      params.attachFiles
    );
    if (response.data.status === "SUCCESS") {
      alert(`성공적으로 생성되었습니다.`);
      getNoticeList();
      setShowNewNoticeForm(false);
    } else {
      alert(`서버 오류로 글 작성에 실패하였습니다.`);
    }
  }
};

export default NewReview;

const Wrapper = styled.div`
  padding-bottom: 2.8rem;
  font-size: 1.3rem;
  font-family: "Lato";
  width: 100%;
  label {
    display: block;
    margin-bottom: 1rem;
  }
  &.bordered {
    .editorWrapper {
      border-color: red;
    }
  }
  > div + p {
    width: 100%;
    padding-top: 12px;
    font-size: 1.2rem;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: -9px;
  }
`;
