import React, { useEffect, useState } from "react";
import { Container, Button, Input, Card, CardTitle, Row } from "reactstrap";
import {
  decodingHtmlTags,
  replaceNewlineWithBrTag,
} from "../../../../helpers/stringHelpers";
import { Viewer } from "@toast-ui/react-editor";

const ReviewView = ({ markdown }) => {
  const marked = replaceNewlineWithBrTag(decodingHtmlTags(markdown));
  return (
    <>
      <Viewer initialValue={marked} initialEditType="markdown" />
    </>
  );
};

export default ReviewView;
