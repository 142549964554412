import React, { useState, useEffect } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";
import { sendNewColorListApi } from "../../../apis/modelDetail";
import CommonHelper from "../../../helpers/CommonHelper";

const ColorsComponent = ({ colorList, setColorList, getSiteInfo }) => {
  const deleteOneColor = (color) => {
    const filterColor = colorList.filter((el) => el !== color);
    setColorList(filterColor);
  };

  const handleColorsChange = (e) => {
    const fakeBox = [...colorList];
    const { name, value } = e.target;
    fakeBox[name] = value;
    setColorList(fakeBox);
  };

  const addColor = () => {
    const copyColorList = [...colorList];
    copyColorList.push("");
    setColorList(copyColorList);
  };
  return (
    <>
      <Card>
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          다이얼 컬러 리스트 관리
        </CardTitle>
        <CardBody>
          <Container>
            <ul>
              {colorList.map((color, idx) => (
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px",
                  }}>
                  <label style={{ margin: "10px" }}>컬러 {idx + 1}</label>
                  <input
                    style={{ margin: "0 10px" }}
                    placeholder={`컬러 ${idx + 1}`}
                    name={idx}
                    value={color || null}
                    onChange={handleColorsChange}
                  />
                  <Button onClick={() => deleteOneColor(color)}>x</Button>
                </Row>
              ))}
            </ul>
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "10px",
              }}>
              <Button style={{ margin: "0 10px" }} onClick={addColor}>
                추가
              </Button>
            </Row>
            <Button onClick={sendNewColorList}>저장</Button>
          </Container>
        </CardBody>
      </Card>
    </>
  );
  function sendNewColorList() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      sendNewColorListApi(colorList, authToken).then(
        ({ data: { status, detail } }) => {
          if (status === "SUCCESS") {
            alert("수정되었습니다.");
            getSiteInfo();
          } else {
            alert(detail);
          }
        }
      );
    }
  }
};

export default ColorsComponent;
