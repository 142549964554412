import React, { useState } from "react";
import { Card, CardBody, CardTitle, Button } from "reactstrap";
import CustomerFAQ from "./admin-customer-components/CustomerFAQ";
import CustomerNotice from "./admin-customer-components/CustomerNotice";
import MobileFaq from "./admin-customer-components/MobileFaq";
import FaqKeywords from "./admin-customer-components/FaqKeywords";
import ReviewPick from "./admin-customer-components/ReviewPick";

const CUSTOMER_MENU = [
  "뉴스&이벤트",
  "고객센터",
  "리뷰pick",
  "app 판매메뉴 - FAQ",
  "faq keywords 설정",
];

const CustomerCenter = () => {
  //TODO : currMenu 주석된걸로 바꾸기. 다른곳도
  const [currMenu, setCurrMenu] = useState(CUSTOMER_MENU[2]);
  // const [currMenu, setCurrMenu] = useState("공지사항");
  const changeMenu = (e) => {
    setCurrMenu(e.target.value);
  };
  //TODO : 선택되었을 때 컬러 style.css에서 .card-current-active 컬러 바꾸기
  return (
    <>
      <Card>
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            {CUSTOMER_MENU.map((menu) => (
              <Button
                // className={
                //   menu === currMenu ? "card-current-active" : "card-current"
                // }
                value={menu}
                color="info"
                onClick={(e) => changeMenu(e)}>
                {menu}
              </Button>
            ))}
          </div>
        </CardTitle>
        <CardBody>
          {currMenu === "뉴스&이벤트" && <CustomerNotice />}
          {currMenu === "고객센터" && <CustomerFAQ />}
          {currMenu === "리뷰pick" && <ReviewPick />}
          {currMenu === "app 판매메뉴 - FAQ" && <MobileFaq />}
          {currMenu === "faq keywords 설정" && <FaqKeywords />}
        </CardBody>
      </Card>
    </>
  );
};

export default CustomerCenter;
