import { apiCall, apiCallByFormData } from "./apiCall";
import { CLIENT_URL, SERVER_API_HOST } from "../common/environment";

export const getAuctionListApi = (authToken, nowPage, status) => {
  return apiCall(
    `${SERVER_API_HOST}/api/auction/list?page=${nowPage}`,
    "post",
    {
      auctionStatus: status || null,
    },
    {
      authorization: authToken,
    }
  );
};
export const getOneAuctionApi = (id) => {
  return apiCall(`${SERVER_API_HOST}/api/auction/get/${id}`, "get", {}, {});
};

export const deleteOneAuctionApi = (id, authToken) => {
  return apiCall(
    `${SERVER_API_HOST}/api/auction/delete/${id}`,
    "delete",
    {},
    { authorization: authToken }
  );
};
export const getBrandListApi = () => {
  return apiCall(`${SERVER_API_HOST}/api/watch/brand/list`, "get", {}, {});
};
export const searchWatchModelApi = (selectedBrand, modelInput) => {
  return apiCall(
    `${SERVER_API_HOST}/api/filter/standard`,
    "post",
    {
      filters: [
        {
          type: "BRAND",
          keyword: selectedBrand,
        },
        {
          type: "MODEL",
          keyword: modelInput,
        },
      ],
    },
    {}
  );
};

export const registerNewAuctionApi = (
  authToken,
  oneModel,
  startDate,
  endDate,
  auctionStartPrice,
  auctionDescription,
  auctionStore,
  watchPhotos,
  content
) => {
  return apiCall(
    `${SERVER_API_HOST}/api/auction/create`,
    "post",
    {
      watch: oneModel,
      attachedFiles: watchPhotos,
      storeIds: auctionStore,
      startDate,
      endDate,
      price: auctionStartPrice,
      description: auctionDescription,
      additionalInfo: content,
    },
    {
      authorization: authToken,
    }
  );
};

export const sendModifyAuctionApi = (authToken, modifyAuctionInfo) => {
  return apiCall(
    `${SERVER_API_HOST}/api/auction/update/${modifyAuctionInfo.id || null}`,
    "put",
    modifyAuctionInfo,
    {
      authorization: authToken,
    }
  );
};

export const getOneAuctionPeopleListApi = (oneAuctionId, page) => {
  return apiCall(
    `${SERVER_API_HOST}/api/auction/order/list?auctionId=${oneAuctionId}&page=${page}`,
    "get",
    {},
    {}
  );
};

export const changeAuctionStateApi = (authToken, id, modifyStatus) => {
  return apiCall(
    `${SERVER_API_HOST}/api/auction/update/state/${id}`,
    "put",
    {
      auctionStatus: modifyStatus,
    },
    {
      authorization: authToken,
    }
  );
};
