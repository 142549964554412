import { apiCall, apiCallByFormData } from "./apiCall";
import { CLIENT_URL, SERVER_API_HOST } from "../common/environment";

export const changeAppMainBgApi = (authToken, url) => {
  return apiCall(
    `${SERVER_API_HOST}/api/site/sale/background`,
    "put",
    { saleBackgroundImg: url },
    {
      authorization: authToken,
    }
  );
};
