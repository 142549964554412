import { apiCall, apiCallByFormData } from "./apiCall";
import { CLIENT_URL, SERVER_API_HOST } from "../common/environment";

export const uploadFile = async (formData) => {
  // const formDataResult = await apiCallByFormData(
  //         `${SERVER_API_HOST}/api/public/attache/file/upload`,
  //         formData,
  //         {}
  //     ).data;
  // return formDataResult;
  return apiCallByFormData(
    `${SERVER_API_HOST}/api/public/attache/file/upload`,
    formData,
    {}
  );
};

export const uploadSellFile = async (formData) => {
  return apiCallByFormData(
    `${SERVER_API_HOST}/api/public/attache/file/upload?width=800&height=1067`,
    formData,
    {}
  );
};

export const uploadExcel = async (formData) => {
  return apiCallByFormData(
    `${SERVER_API_HOST}/api/watch/update/sale/price`,
    formData,
    { method: "PUT" }
  );
};
