import React, { useState, useEffect } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";

const RecommendPopularBrand = ({ siteInfo, sendChangeBrand }) => {
  const [popularBrands, setPopularBrands] = useState([]);

  useEffect(() => {
    setPopularBrands(siteInfo.searchBrands || []);
  }, [siteInfo]);

  const deleteOneBrand = (brand) => {
    const filterBrands = popularBrands.filter((el) => el !== brand);
    setPopularBrands(filterBrands);
  };

  const addBrand = () => {
    if (popularBrands.length < 5) {
      const copyAddBrands = [...popularBrands];
      copyAddBrands.push("");
      setPopularBrands(copyAddBrands);
    } else {
      alert("추천 인기 브랜드는 5개까지 설정 가능합니다.");
    }
  };

  const handleOnChangePopularBrands = (e) => {
    const { name } = e.target;
    const copyBrandsForInputChange = [...popularBrands];
    copyBrandsForInputChange[name] = e.target.value;
    setPopularBrands(copyBrandsForInputChange);
  };

  return (
    <>
      <Card>
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          app 추천 인기 브랜드
        </CardTitle>
        <CardBody>
          <Container>
            {popularBrands.map((brand, idx) => (
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "10px",
                }}>
                <label style={{ margin: "10px 0" }}>브랜드 {idx + 1}</label>
                <input
                  style={{ margin: "0 10px" }}
                  placeholder={`브랜드 ${idx + 1}`}
                  value={brand}
                  name={idx}
                  onChange={handleOnChangePopularBrands}
                />
                <Button onClick={() => deleteOneBrand(brand)}>x</Button>
              </Row>
            ))}
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "10px",
              }}
              onClick={() => console.log("hi")}>
              {/* <label style={{ margin: "10px" }}>추가하기</label> */}
              {/* <input placeholder="브랜드" value={newBrandInput} /> */}
              <Button style={{ margin: "0 10px" }} onClick={() => addBrand()}>
                추가
              </Button>
            </Row>
            <Button onClick={() => sendChangeBrand(popularBrands)}>저장</Button>
          </Container>
        </CardBody>
      </Card>
    </>
  );
};

export default RecommendPopularBrand;
