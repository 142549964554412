import React, { useState } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";
import AuctionTotalList from "./admin-auction-components/AuctionTotalList";

const AUCTION_MENU = [
  // "판매 관리", // 조회, 생성, 수정, 삭제
  "드로우 관리", // 조회 생성 수정 삭제
];

const AuctionManage = () => {
  const [currMenu, setCurrMenu] = useState(AUCTION_MENU[0]);

  const changeMenu = (e) => {
    setCurrMenu(e.target.value);
  };

  const handleInput = (e) => {
    console.log(e.target.value);
  };

  return (
    <>
      <Card>
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            {AUCTION_MENU.map((menu) => (
              <Button
                // style={
                //   menu === currMenu
                //     ? {
                //         border: "2px solid black",
                //         padding: "6px",
                //         cursor: "pointer",
                //       }
                //     : {
                //         border: "1px solid gray",
                //         padding: "6px",
                //         cursor: "pointer",
                //       }
                // }
                value={menu}
                color="info"
                onClick={(e) => changeMenu(e)}>
                {menu}
              </Button>
            ))}
          </div>
        </CardTitle>
        <CardBody>
          {currMenu === "드로우 관리" && <AuctionTotalList />}
        </CardBody>
      </Card>
    </>
  );
};

export default AuctionManage;
