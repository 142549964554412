import React, { useState, useEffect, Fragment, useRef } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";
import DEFAULT_IMAGE from "../../../assets/images/big/default-image.jpg";
import {
  getPopupListApi,
  uploadFile,
  sendCreatePopupApi,
  sendModifyOnePopupApi,
  deleteModifyOnePopupApi,
} from "../../../apis/main.js";
// import { handleVisitTime } from "../../../common/common";
import {
  SHOW_OR_NOT,
  DEFAULT_FILE_SIZE_LIMIT,
  DEVICE_TYPE_OPTION,
  POPUP_USE_OPTIONS,
  SHOW_OR_NOT_CHANGE,
} from "../../../common/constants";
import { CLIENT_URL, SERVER_API_HOST } from "../../../common/environment";
import CommonHelper from "../../../helpers/CommonHelper";
const initNewPopup = {
  photoUrl: null,
  link: null,
  width: null,
  height: null,
  left: null,
  right: null,
  opacityRate: null,
  deviceType: "PC",
  hiddenOption: "NONE",
  use: false,
};

const PCPopup = () => {
  const [imgSrc, setImgSrc] = useState(null); //백엔드에 연결하기 전에 테스트src 둘 state
  const [currentPopupList, setCurrentPopupList] = useState([]);
  const [showOnePopup, setShowOnePopup] = useState(false); //팝업수정 show or not
  const [onePopupDetail, setOnePopupDetail] = useState({});
  const [showCreatePopup, setShowCreatePopup] = useState(false); //팝업생성 show or not
  const [newPopupInfo, setNewPopupInfo] = useState(initNewPopup);
  const newPopupInputRef = useRef(null);
  const modifyPopupInputRef = useRef(null);
  //새로 생성 - 팝업위치 설정
  const [popupPlace, setPopupPlace] = useState("왼쪽");
  const [leftPosition, setLeftPosition] = useState("");
  const [rightPosition, setRightPosition] = useState("");
  //팝업 수정 - 팝업위치 설정
  const [modifyPopupPlace, setModifyPopupPlace] = useState("왼쪽");
  const [modifyLeftPosition, setModifyLeftPosition] = useState("");
  const [modifyRightPosition, setModifyRightPosition] = useState("");

  const onClickUpdateNewPopupInput = () => {
    // 파일 입력 폼에 변화가 일어나는지 감시하는 이벤트 리스터 등록
    newPopupInputRef.current.addEventListener(
      "change",
      onChangeNewPopupInput,
      false
    );
    // 파일 입력 폼 열기
    newPopupInputRef.current.click();
  };

  const onClickUpdateModifyPopupInput = () => {
    // 파일 입력 폼에 변화가 일어나는지 감시하는 이벤트 리스터 등록
    modifyPopupInputRef.current.addEventListener(
      "change",
      onChangeModifyPopupInput,
      false
    );
    // 파일 입력 폼 열기
    modifyPopupInputRef.current.click();
  };

  useEffect(() => {
    getPopupList();
  }, []);

  const openModifyOnePopup = (popup) => {
    setShowOnePopup(true);
    setOnePopupDetail(popup);
  };

  const handleNewChange = (e) => {
    const { name, value } = e.target;
    const nextPopupInfo = {
      ...newPopupInfo,
      [name]: value,
    };
    setNewPopupInfo(nextPopupInfo);
  };

  const closeCreatePopup = () => {
    setShowCreatePopup(false);
    setNewPopupInfo(initNewPopup);
  };

  const closeModifyOnePopup = () => {
    setShowOnePopup(false);
  };

  const openNewPopup = () => {
    setShowCreatePopup(true);
  };

  const handleModifyChange = (e) => {
    const { name, value } = e.target;
    const nextPopupInfo = {
      ...onePopupDetail,
      [name]: value,
    };
    setOnePopupDetail(nextPopupInfo);
  };

  const checkBeforeSendCreatePopup = () => {
    const { photoUrl, link, width, height, opacityRate } = newPopupInfo;
    if (photoUrl && link && width && height && opacityRate) {
      sendCreatePopup();
    } else {
      alert("비어 있는 값을 채운 뒤 다시 시도해주세요.");
    }
  };

  return (
    <>
      {showCreatePopup && (
        <Card>
          <CardTitle className="bg-light border-bottom p-3 mb-0">
            <div className="space-between">
              팝업 생성하기
              <button onClick={closeCreatePopup}>닫기</button>
            </div>
          </CardTitle>
          <CardBody>
            <Container>
              <Row>
                <Col sm="6">
                  <Card>
                    <div onClick={onClickUpdateNewPopupInput}>
                      <CardImg
                        top
                        width="100%"
                        src={
                          newPopupInfo.photoUrl
                            ? newPopupInfo.photoUrl
                            : DEFAULT_IMAGE
                        }
                      />
                      <input
                        type="file"
                        accept="image/*"
                        ref={newPopupInputRef}
                        hidden
                      />
                    </div>
                    <CardBody>
                      <CardText>
                        <label>링크</label>
                        <input
                          name="link"
                          onChange={handleNewChange}
                          value={newPopupInfo.link || ""}
                          type="text"
                          placeholder="클릭 시 이동할 링크"
                        />
                      </CardText>
                      <CardText>
                        <p
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}>
                          <label>팝업 사이즈 가로</label>
                          <input
                            name="width"
                            value={newPopupInfo.width || ""}
                            placeholder="가로 사이즈"
                            onChange={handleNewChange}
                            type="number"
                          />
                          <label>팝업 사이즈 세로</label>
                          <input
                            name="height"
                            value={newPopupInfo.height || ""}
                            placeholder="세로 사이즈"
                            onChange={handleNewChange}
                            type="number"
                          />
                        </p>
                      </CardText>
                      <CardText>
                        <p
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}>
                          <label>팝업 위치</label>
                          <select
                            value={popupPlace}
                            onChange={(e) => setPopupPlace(e.target.value)}>
                            <option>왼쪽</option>
                            <option>오른쪽</option>
                          </select>
                        </p>
                      </CardText>
                      <CardText>
                        <p
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}>
                          <label>
                            위치 설정
                            <div>
                              *위쪽에서 설정한 팝업 위치가 왼쪽일 경우
                              왼쪽으로부터 몇 px 설정. 위치가 오른쪽일 경우
                              오른쪽으로부터 몇 px 설정.
                            </div>
                          </label>
                          <div style={{ display: "flex" }}>
                            <label>왼쪽으로부터 &nbsp;</label>{" "}
                            <input
                              value={leftPosition}
                              onChange={(e) => setLeftPosition(e.target.value)}
                              type="text"
                              disabled={popupPlace === "오른쪽" ? true : false}
                            />
                            <label>px</label>
                          </div>
                          <div style={{ marginTop: "10px" }}>
                            <label>오른쪽으로부터&nbsp;</label>{" "}
                            <input
                              value={rightPosition}
                              onChange={(e) => setRightPosition(e.target.value)}
                              type="text"
                              disabled={popupPlace === "왼쪽" ? true : false}
                            />
                            <label>px</label>
                          </div>
                        </p>
                      </CardText>
                      <CardText>
                        <label>opacityRate</label>
                        <input
                          name="opacityRate"
                          value={newPopupInfo.opacityRate || ""}
                          type="number"
                          placeholder="0~100"
                          onChange={handleNewChange}
                        />
                      </CardText>
                      <CardText>
                        <label>기기타입</label>
                        <select
                          name="deviceType"
                          value={newPopupInfo.deviceType || "PC"}
                          autoblur="true"
                          onChange={handleNewChange}>
                          {DEVICE_TYPE_OPTION.map(({ label, value }, index) => (
                            <option key={index} value={value}>
                              {label}
                            </option>
                          ))}
                        </select>
                      </CardText>
                      <CardText>
                        <label>hidden option</label>
                        <select
                          name="hiddenOption"
                          value={newPopupInfo.hiddenOption || ""} // selected로 변동될 수 있게 만들기
                          autoblur="true"
                          onChange={handleNewChange}>
                          {SHOW_OR_NOT.map(({ label, value }, index) => (
                            <option key={index} value={value}>
                              {label}
                            </option>
                          ))}
                        </select>
                      </CardText>
                      <CardText>
                        <label>사용여부</label>
                        <select
                          name="use"
                          onChange={handleNewChange}
                          value={!!newPopupInfo.use}>
                          {POPUP_USE_OPTIONS.map(({ label, value }, index) => (
                            <option key={index} value={value}>
                              {label}
                            </option>
                          ))}
                        </select>
                      </CardText>
                      <Button onClick={checkBeforeSendCreatePopup}>
                        팝업 추가하기
                      </Button>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </CardBody>
        </Card>
      )}
      {showOnePopup && (
        <Card>
          <CardTitle className="bg-light border-bottom p-3 mb-0">
            <div className="space-between">
              팝업 조회 및 수정
              <button onClick={closeModifyOnePopup}>닫기</button>
            </div>
          </CardTitle>
          <CardBody>
            <Container>
              <Row>팝업 조회 및 수정</Row>
              <Row>
                <Col sm="6">
                  <Card>
                    <div onClick={onClickUpdateModifyPopupInput}>
                      <CardImg
                        top
                        width="100%"
                        src={
                          onePopupDetail.photoUrl
                            ? onePopupDetail.photoUrl
                            : DEFAULT_IMAGE
                        }
                      />
                      <input
                        type="file"
                        accept="image/*"
                        ref={modifyPopupInputRef}
                        hidden
                      />
                    </div>
                    <CardBody>
                      <CardText>
                        <label>링크</label>
                        <input
                          name="link"
                          onChange={handleModifyChange}
                          value={onePopupDetail.link || ""}
                          type="text"
                        />
                      </CardText>
                      <CardText>
                        <p
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}>
                          <label>팝업 사이즈 가로</label>
                          <input
                            name="width"
                            onChange={handleModifyChange}
                            value={onePopupDetail.width || ""}
                            type="number"
                            placeholder="가로 사이즈"
                          />
                          <label>팝업 사이즈 세로</label>
                          <input
                            name="height"
                            onChange={handleModifyChange}
                            value={onePopupDetail.height || ""}
                            type="number"
                            placeholder="세로 사이즈"
                          />
                        </p>
                      </CardText>
                      <CardText>
                        <p
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}>
                          <label>팝업 위치</label>
                          <select
                            value={modifyPopupPlace}
                            onChange={(e) =>
                              setModifyPopupPlace(e.target.value)
                            }>
                            <option>왼쪽</option>
                            <option>오른쪽</option>
                          </select>
                        </p>
                      </CardText>
                      <CardText>
                        <p
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}>
                          <label>
                            위치 설정
                            <div>
                              *위쪽에서 설정한 팝업 위치가 왼쪽일 경우
                              왼쪽으로부터 몇 px 설정. 위치가 오른쪽일 경우
                              오른쪽으로부터 몇 px 설정.
                            </div>
                          </label>
                          <div style={{ display: "flex" }}>
                            <label>왼쪽으로부터 &nbsp;</label>{" "}
                            <input
                              value={modifyLeftPosition}
                              onChange={(e) =>
                                setModifyLeftPosition(e.target.value)
                              }
                              type="text"
                              disabled={
                                modifyPopupPlace === "오른쪽" ? true : false
                              }
                            />
                            <label>px</label>
                          </div>
                          <div style={{ marginTop: "10px" }}>
                            <label>오른쪽으로부터&nbsp;</label>{" "}
                            <input
                              value={modifyRightPosition}
                              onChange={(e) =>
                                setModifyRightPosition(e.target.value)
                              }
                              type="text"
                              disabled={
                                modifyPopupPlace === "왼쪽" ? true : false
                              }
                            />
                            <label>px</label>
                          </div>
                        </p>
                      </CardText>
                      <CardText>
                        <label>opacityRate</label>
                        <input
                          name="opacityRate"
                          onChange={handleModifyChange}
                          value={onePopupDetail.opacityRate || ""}
                          type="number"
                          placeholder="0~100"
                        />
                      </CardText>
                      <CardText>
                        <label>기기타입</label>
                        <select
                          name="deviceType"
                          value={onePopupDetail.deviceType || "PC"}
                          autoblur="true"
                          onChange={handleModifyChange}>
                          {DEVICE_TYPE_OPTION.map(({ label, value }, index) => (
                            <option key={index} value={value}>
                              {label}
                            </option>
                          ))}
                        </select>
                      </CardText>
                      <CardText>
                        <label>hidden option</label>
                        <select
                          name="hiddenOption"
                          value={onePopupDetail.hiddenOption || "NONE"} // selected로 변동될 수 있게 만들기
                          autoblur="true"
                          onChange={handleModifyChange}>
                          {SHOW_OR_NOT.map(({ label, value }, index) => (
                            <option key={index} value={value}>
                              {label}
                            </option>
                          ))}
                        </select>
                      </CardText>
                      <CardText>
                        <label>사용여부</label>
                        <select
                          name="use"
                          onChange={handleModifyChange}
                          value={onePopupDetail.use}>
                          {POPUP_USE_OPTIONS.map(({ label, value }, index) => (
                            <option key={index} value={value}>
                              {label}
                            </option>
                          ))}
                        </select>
                      </CardText>
                      <CardText>
                        <label>고유 id</label>
                        <p>{onePopupDetail.id || "no id"}</p>
                      </CardText>
                      <Button onClick={sendModifyOnePopup}>수정하기</Button>
                      <Button onClick={deleteModifyOnePopup}>삭제하기</Button>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </CardBody>
        </Card>
      )}
      <Card>
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          <div className="space-between">
            PC/MOBILE 팝업 리스트
            <button onClick={openNewPopup}>팝업 생성하기</button>
          </div>
        </CardTitle>
        <CardBody>
          <Container>
            <Row>리스트 목록</Row>
            <Row>
              {currentPopupList.map((el, i) => (
                <React.Fragment key={i}>
                  <Col sm="6">
                    <Card>
                      <div>
                        <CardImg
                          top
                          width="100%"
                          src={el.photoUrl ? el.photoUrl : DEFAULT_IMAGE}
                        />
                      </div>
                      <CardBody>
                        <CardText>
                          <label>링크</label>
                          <p>{el.link || "no link"}</p>
                        </CardText>
                        <CardText>
                          <label>가로사이즈</label>
                          <p>{el.width || "no width"}</p>
                        </CardText>
                        <CardText>
                          <label>세로사이즈</label>
                          <p>{el.height || "no height"}</p>
                        </CardText>
                        <CardText>
                          <label>왼쪽으로부터</label>
                          <p>{el.left || "not left"}</p>
                        </CardText>
                        <CardText>
                          <label>오른쪽으로부터</label>
                          <p>{el.right || "not right"}</p>
                        </CardText>
                        <CardText>
                          <label>opacityRate</label>
                          <p>{el.opacityRate || "opacityRate"}</p>
                        </CardText>
                        <CardText>
                          <label>기기타입</label>
                          <p>{el.deviceType || "no deviceType"}</p>
                        </CardText>
                        <CardText>
                          <label>hidden option</label>
                          <p>
                            {el.hiddenOption
                              ? SHOW_OR_NOT_CHANGE[el.hiddenOption]
                              : "no hidden option"}
                          </p>
                        </CardText>
                        <CardText>
                          <label>사용여부</label>
                          <p>{el.use ? "YES" : "NO"}</p>
                        </CardText>
                        <CardText>
                          <label>고유 id</label>
                          <p>{el.id || "no id"}</p>
                        </CardText>
                        <Button onClick={() => openModifyOnePopup(el)}>
                          수정하기
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                </React.Fragment>
              ))}
            </Row>
            <Row></Row>
          </Container>
        </CardBody>
      </Card>
    </>
  );

  function getPopupList() {
    const authToken = CommonHelper.getAuthToken();
    if (authToken !== null && authToken !== undefined) {
      getPopupListApi(authToken).then(({ data: { status, error, data } }) => {
        if (status === "SUCCESS") {
          setCurrentPopupList(data.content || []);
        } else {
          alert(error);
        }
      });
    }
  }

  async function onChangeNewPopupInput(event) {
    // 파일을 선택 한 경우에만 실행
    const file = event.target.files[0];
    // 여러번 등록되는 버그 수정 (https://stackoverflow.com/questions/39484895/how-to-allow-input-type-file-to-select-the-same-file-in-react-component)
    event.target.value = null;
    const authToken = CommonHelper.getAuthToken();
    // 파일 업로드
    let nextPhotoUrl = await uploadFileAndGetUrl(file);
    if (nextPhotoUrl) {
      // nextPhotoUrl = nextPhotoUrl.replace('http://localhost:8080', SERVER_API_HOST); // TODO 임시 코드
      nextPhotoUrl = `${SERVER_API_HOST}${nextPhotoUrl}`;
      const fakeInfo = { ...newPopupInfo };
      fakeInfo.photoUrl = nextPhotoUrl;
      setNewPopupInfo(fakeInfo);
    }
  }

  async function onChangeModifyPopupInput(event) {
    // 파일을 선택 한 경우에만 실행
    const file = event.target.files[0];
    // 여러번 등록되는 버그 수정 (https://stackoverflow.com/questions/39484895/how-to-allow-input-type-file-to-select-the-same-file-in-react-component)
    event.target.value = null;
    const authToken = CommonHelper.getAuthToken();
    // 파일 업로드
    let nextPhotoUrl = await uploadFileAndGetUrl(file);
    if (nextPhotoUrl) {
      // nextPhotoUrl = nextPhotoUrl.replace('http://localhost:8080', SERVER_API_HOST); // TODO 임시 코드
      nextPhotoUrl = `${SERVER_API_HOST}${nextPhotoUrl}`;
      const fakeInfo = { ...onePopupDetail };
      fakeInfo.photoUrl = nextPhotoUrl;
      setOnePopupDetail(fakeInfo);
    }
  }

  // 파일 업로드 후 URL 반환
  async function uploadFileAndGetUrl(file) {
    if (file) {
      const { type: fileType, name: fileName, size: fileSize } = file;
      // 파일 데이터 만들기
      const formData = new FormData();
      formData.append("file", file);

      // 파일 업로드
      const uploadResult = await uploadFile(formData);
      const {
        status,
        data: { url },
      } = uploadResult;
      if (status === 200 && !!url) {
        return url;
      } else {
        alert("파일 업로드 실패");
        return null;
      }
    } else {
      return null;
    }
  }

  function sendCreatePopup() {
    const authToken = CommonHelper.getAuthToken();
    if (authToken !== null && authToken !== undefined) {
      let copyPopupInfo = { ...newPopupInfo };
      if (popupPlace === "왼쪽") {
        copyPopupInfo.left = leftPosition;
        copyPopupInfo.right = null;
      } else if (popupPlace === "오른쪽") {
        copyPopupInfo.right = rightPosition;
        copyPopupInfo.left = null;
      }
      sendCreatePopupApi(authToken, copyPopupInfo).then(
        ({ data: { status, error, data } }) => {
          if (status === "SUCCESS") {
            alert("성공적으로 생성되었습니다.");
            getPopupList();
            setShowCreatePopup(false);
          } else {
            console.log(error);
            alert("문제가 생겼습니다.");
          }
        }
      );
    }
  }

  function sendModifyOnePopup() {
    const authToken = CommonHelper.getAuthToken();
    if (authToken !== null && authToken !== undefined) {
      let copyPopupInfo = { ...onePopupDetail };
      if (modifyPopupPlace === "왼쪽") {
        copyPopupInfo.left = modifyLeftPosition;
        copyPopupInfo.right = null;
      } else if (modifyPopupPlace === "오른쪽") {
        copyPopupInfo.right = modifyRightPosition;
        copyPopupInfo.left = null;
      }
      sendModifyOnePopupApi(authToken, copyPopupInfo).then(
        ({ data: { status, error, data } }) => {
          if (status === "SUCCESS") {
            alert("성공적으로 수정되었습니다.");
            getPopupList();
            setShowOnePopup(false);
          } else {
            console.log(error);
            alert("문제가 생겼습니다.");
          }
        }
      );
    }
  }

  function deleteModifyOnePopup() {
    const authToken = CommonHelper.getAuthToken();
    if (authToken !== null && authToken !== undefined) {
      deleteModifyOnePopupApi(authToken, onePopupDetail).then(
        ({ data: { status, error, data } }) => {
          if (status === "SUCCESS") {
            alert("성공적으로 삭제되었습니다.");
            getPopupList();
            setShowOnePopup(false);
          } else {
            console.log(error);
            alert("문제가 생겼습니다.");
          }
        }
      );
    }
  }
};

export default PCPopup;
