import React, { useState } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  CardTitleCard,
  CardImg,
  CardImgOverlay,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardColumns,
  CardGroup,
  CardDeck,
  CardLink,
  CardHeader,
  CardFooter,
  Button,
} from "reactstrap";
import DEFAULT_IMAGE from "../../../assets/images/big/default-image.jpg";

const ServiceSplash = () => {
  const [imgSrc, setImgSrc] = useState(null);
  const addFile = () => {};
  return (
    <>
      <Col xs="12" md="6">
        <Card>
          <div onClick={() => console.log("hi")}>
            <CardImg top width="100%" src={imgSrc ? imgSrc : DEFAULT_IMAGE} />
            <input type="file" onChange={(e) => addFile(e)} />
          </div>
        </Card>
      </Col>
      <button>저장하기</button>
    </>
  );
};

export default ServiceSplash;
