import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";
import DEFAULT_IMAGE from "../../../assets/images/big/default-image.jpg";
import CommonHelper from "../../../helpers/CommonHelper";
import { SERVER_API_HOST } from "../../../common/environment";
import { uploadFile, putMobileBannerApi } from "../../../apis/main.js";
import { getSiteInfoApi } from "../../../apis/modelDetail.js";
const bannerKeys = ["first", "second", "third", "fourth", "fifth"];
const ColStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "2vw",
  fontWeight: "bold",
};

const MobileBanner = () => {
  const [newBannerInfo, setNewBannerInfo] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
    fifth: "",
  });
  const [newBannerLink, setNewBannerLink] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
    fifth: "",
  });
  const banner1InputRef = useRef();
  const banner2InputRef = useRef();
  const banner3InputRef = useRef();
  const banner4InputRef = useRef();
  const banner5InputRef = useRef();

  useEffect(() => {
    getSiteInfo();
  }, []);

  const onClickUpdateNewBannerInput = (e) => {
    // 파일 입력 폼에 변화가 일어나는지 감시하는 이벤트 리스터 등록
    const { id } = e.target;
    if (id === "banner1InputRef") {
      banner1InputRef.current.addEventListener(
        "change",
        onChangeNewBannerInput,
        false
      );
      // 파일 입력 폼 열기
      banner1InputRef.current.click();
    } else if (id === "banner2InputRef") {
      banner2InputRef.current.addEventListener(
        "change",
        onChangeNewBannerInput,
        false
      );
      // 파일 입력 폼 열기
      banner2InputRef.current.click();
    } else if (id === "banner3InputRef") {
      banner3InputRef.current.addEventListener(
        "change",
        onChangeNewBannerInput,
        false
      );
      // 파일 입력 폼 열기
      banner3InputRef.current.click();
    } else if (id === "banner4InputRef") {
      banner4InputRef.current.addEventListener(
        "change",
        onChangeNewBannerInput,
        false
      );
      // 파일 입력 폼 열기
      banner4InputRef.current.click();
    } else if (id === "banner5InputRef") {
      banner5InputRef.current.addEventListener(
        "change",
        onChangeNewBannerInput,
        false
      );
      // 파일 입력 폼 열기
      banner5InputRef.current.click();
    }
  };

  const onChangeNewBannerInput = async (event) => {
    // 파일을 선택 한 경우에만 실행
    const file = event.target.files[0];
    const where = event.target.name;
    // 여러번 등록되는 버그 수정 (https://stackoverflow.com/questions/39484895/how-to-allow-input-type-file-to-select-the-same-file-in-react-component)
    event.target.value = null;
    const authToken = CommonHelper.getAuthToken();
    // 파일 업로드
    let nextPhotoUrl = await uploadFileAndGetUrl(file);
    if (nextPhotoUrl) {
      // nextPhotoUrl = nextPhotoUrl.replace('http://localhost:8080', SERVER_API_HOST); // TODO 임시 코드
      nextPhotoUrl = `${SERVER_API_HOST}${nextPhotoUrl}`;
      let fakeInfo = {
        ...newBannerInfo,
      };
      fakeInfo[where] = nextPhotoUrl;
      setNewBannerInfo(fakeInfo);
    }
  };

  const onChangeLinkInputs = (e) => {
    const { name, value } = e.target;
    let fakeLink = { ...newBannerLink };
    fakeLink[name] = value;
    setNewBannerLink(fakeLink);
  };
  const makeListBeforePut = () => {
    let array = [
      {
        url: newBannerInfo.first || null,
        link: newBannerLink.first || null,
      },
      {
        url: newBannerInfo.second || null,
        link: newBannerLink.second || null,
      },
      {
        url: newBannerInfo.third || null,
        link: newBannerLink.third || null,
      },
      {
        url: newBannerInfo.fourth || null,
        link: newBannerLink.fourth || null,
      },
      {
        url: newBannerInfo.fifth || null,
        link: newBannerLink.fifth || null,
      },
    ];
    putMobileBanner(array);
  };

  const deleteOneBanner = (name) => {
    let fakeList = { ...newBannerInfo };
    let fakeLink = { ...newBannerLink };
    fakeList[name] = "";
    fakeLink[name] = "";

    setNewBannerInfo(fakeList);
    setNewBannerLink(fakeLink);
  };
  return (
    <>
      <Card>
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          Mobile 배너 설정
        </CardTitle>
        <CardBody>
          <Container>
            <div>
              * 1번 사진이 없을 시 초기 모바일 배너 사진으로 나오게 됩니다.
            </div>
            <div>
              * x 버튼은 사진과 링크를 다 비워주는 역할을 하고 수정하기 버튼을
              눌러 저장해주셔야 합니다.
            </div>
            <Row style={{ marginTop: "40px" }}>
              <Col style={ColStyle}>1</Col>
              <Col>
                <div
                  onClick={onClickUpdateNewBannerInput}
                  style={{ width: "70%" }}>
                  <CardImg
                    top
                    src={
                      newBannerInfo["first"]
                        ? newBannerInfo["first"]
                        : DEFAULT_IMAGE
                    }
                    id="banner1InputRef"
                  />
                  <input
                    type="file"
                    accept="image/*"
                    ref={banner1InputRef}
                    key={newBannerInfo.first}
                    name="first"
                    hidden
                  />
                </div>
                <div>
                  <label>클릭 시 이동할 링크 :</label>
                  <input
                    onChange={onChangeLinkInputs}
                    value={newBannerLink["first"] || ""}
                    name="first"
                  />
                </div>
              </Col>
              <Col style={ColStyle}>
                <Button onClick={() => deleteOneBanner("first")}>x</Button>
              </Col>
            </Row>
            <Row style={{ marginTop: "40px" }}>
              <Col style={ColStyle}>2</Col>
              <Col>
                <div
                  onClick={onClickUpdateNewBannerInput}
                  style={{ width: "70%" }}>
                  <CardImg
                    top
                    src={
                      newBannerInfo["second"]
                        ? newBannerInfo["second"]
                        : DEFAULT_IMAGE
                    }
                    id="banner2InputRef"
                  />
                  <input
                    type="file"
                    accept="image/*"
                    ref={banner2InputRef}
                    key={newBannerInfo.second}
                    name="second"
                    hidden
                  />
                </div>
                <div>
                  <label>클릭 시 이동할 링크 : </label>
                  <input
                    onChange={onChangeLinkInputs}
                    value={newBannerLink["second"] || ""}
                    name="second"
                  />
                </div>
              </Col>
              <Col style={ColStyle}>
                <Button onClick={() => deleteOneBanner("second")}>x</Button>
              </Col>
            </Row>
            <Row style={{ marginTop: "40px" }}>
              <Col style={ColStyle}>3</Col>
              <Col>
                <div
                  onClick={onClickUpdateNewBannerInput}
                  style={{ width: "70%" }}>
                  <CardImg
                    top
                    src={
                      newBannerInfo["third"]
                        ? newBannerInfo["third"]
                        : DEFAULT_IMAGE
                    }
                    id="banner3InputRef"
                  />
                  <input
                    type="file"
                    accept="image/*"
                    ref={banner3InputRef}
                    key={newBannerInfo.third}
                    name="third"
                    hidden
                  />
                </div>
                <div>
                  <label>클릭 시 이동할 링크 : </label>
                  <input
                    onChange={onChangeLinkInputs}
                    value={newBannerLink["third"] || ""}
                    name="third"
                  />
                </div>
              </Col>
              <Col style={ColStyle}>
                <Button onClick={() => deleteOneBanner("third")}>x</Button>
              </Col>
            </Row>
            <Row style={{ marginTop: "40px" }}>
              <Col style={ColStyle}>4</Col>
              <Col>
                <div
                  onClick={onClickUpdateNewBannerInput}
                  style={{ width: "70%" }}>
                  <CardImg
                    top
                    src={
                      newBannerInfo["fourth"]
                        ? newBannerInfo["fourth"]
                        : DEFAULT_IMAGE
                    }
                    id="banner4InputRef"
                  />
                  <input
                    type="file"
                    accept="image/*"
                    ref={banner4InputRef}
                    name="fourth"
                    key={newBannerInfo.fourth}
                    hidden
                  />
                </div>
                <div>
                  <label>클릭 시 이동할 링크 : </label>
                  <input
                    onChange={onChangeLinkInputs}
                    value={newBannerLink["fourth"] || ""}
                    name="fourth"
                  />
                </div>
              </Col>
              <Col style={ColStyle}>
                <Button onClick={() => deleteOneBanner("fourth")}>x</Button>
              </Col>
            </Row>
            <Row style={{ marginTop: "40px" }}>
              <Col style={ColStyle}>5</Col>
              <Col>
                <div
                  onClick={onClickUpdateNewBannerInput}
                  style={{ width: "70%" }}>
                  <CardImg
                    top
                    src={
                      newBannerInfo["fifth"]
                        ? newBannerInfo["fifth"]
                        : DEFAULT_IMAGE
                    }
                    id="banner5InputRef"
                  />
                  <input
                    type="file"
                    accept="image/*"
                    ref={banner5InputRef}
                    key={newBannerInfo.fifth}
                    name="fifth"
                    hidden
                  />
                </div>
                <div>
                  <label>클릭 시 이동할 링크 : </label>
                  <input
                    onChange={onChangeLinkInputs}
                    value={newBannerLink["fifth"] || ""}
                    name="fifth"
                  />
                </div>
              </Col>
              <Col style={ColStyle}>
                <Button onClick={() => deleteOneBanner("fifth")}>x</Button>
              </Col>
            </Row>
            <Row
              style={{
                marginTop: "40px",
                display: "flex",
                justifyContent: "center",
              }}>
              <Button onClick={makeListBeforePut}>수정하기</Button>
            </Row>
          </Container>
        </CardBody>
      </Card>
    </>
  );

  // 파일 업로드 후 URL 반환
  async function uploadFileAndGetUrl(file) {
    if (file) {
      const { type: fileType, name: fileName, size: fileSize } = file;
      // 파일 데이터 만들기
      const formData = new FormData();
      formData.append("file", file);

      // 파일 업로드
      const uploadResult = await uploadFile(formData);
      const {
        status,
        data: { url },
      } = uploadResult;
      if (status === 200 && !!url) {
        return url;
      } else {
        alert("파일 업로드 실패");
        return null;
      }
    } else {
      return null;
    }
  }

  function putMobileBanner(bannerList) {
    const authToken = CommonHelper.getAuthToken();
    putMobileBannerApi(authToken, bannerList).then(
      ({ data: { data, detail, status } }) => {
        if (status === "SUCCESS") {
          alert("성공적으로 수정되었습니다.");
        } else {
          alert(detail);
        }
      }
    );
  }

  function getSiteInfo() {
    getSiteInfoApi().then(({ data: { data, detail, status } }) => {
      if (status === "SUCCESS") {
        const { backGroundImages } = data;
        let fakeBanner = { ...newBannerInfo };
        for (let i = 0; i < backGroundImages.length; i++) {
          fakeBanner[bannerKeys[i]] = backGroundImages[i].url || "";
        }
        setNewBannerInfo(fakeBanner);
        let fakeLink = { ...newBannerLink };
        for (let k = 0; k < backGroundImages.length; k++) {
          fakeLink[bannerKeys[k]] = backGroundImages[k].link || "";
        }
        setNewBannerLink(fakeLink);
      } else {
        alert(detail);
      }
    });
  }
};

export default MobileBanner;
