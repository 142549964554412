import { apiCall, apiCallByFormData } from "./apiCall";
import { CLIENT_URL, SERVER_API_HOST } from "../common/environment";

export const getSiteInfoApi = () => {
  return apiCall(`${SERVER_API_HOST}/api/site/info`, "get", {}, {});
};

export const sendNewDiameterListApi = (newDiameterList, authToken) => {
  return apiCall(
    `${SERVER_API_HOST}/api/site/watch/casediameter`,
    "put",
    newDiameterList,
    { authorization: authToken }
  );
};

export const sendNewColorListApi = (newColorList, authToken) => {
  return apiCall(
    `${SERVER_API_HOST}/api/site/watch/dialcolor`,
    "put",
    newColorList,
    {
      authorization: authToken,
    }
  );
};

export const sendNewMaterialListApi = (newMaterialList, authToken) => {
  return apiCall(
    `${SERVER_API_HOST}/api/site/watch/casematerial`,
    "put",
    newMaterialList,
    {
      authorization: authToken,
    }
  );
};

export const sendNewTypeListApi = (newTypeList, authToken) => {
  return apiCall(
    `${SERVER_API_HOST}/api/site/watch/dialtype`,
    "put",
    newTypeList,
    { authorization: authToken }
  );
};
