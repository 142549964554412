import React, { useState, useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ko from "date-fns/locale/ko";
import {
  Card,
  CardTitle,
  Col,
  Row,
  CardBody,
  Container,
  Button,
} from "reactstrap";
import CommonHelper from "../../../helpers/CommonHelper";
import {
  getAppSiteInfoApi,
  changeInspectionSettingApi,
} from "../../../apis/appSite";
import { handleTimeForDatePicker} from '../../../common/common'

const InspectionSetting = () => {
  const [onOffInspection, setOnOffInspection] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  // select된 시간을 서버에 보내는 형식으로 바꾸기
  const offset = new Date().getTimezoneOffset() * 60000;
  const convertStartDate = new Date(startDate - offset)
    .toISOString()
    .split(".")[0];
    const offsetEnd = new Date().getTimezoneOffset() * 60000;
    const convertEndDate = new Date(endDate - offset)
      .toISOString()
      .split(".")[0];

  useEffect(() => {
    getAppSiteInfo();
  }, []);

  const handleSiteCheck = (e) => {
    setOnOffInspection(e.target.value);
  };
  return (
    <>
      <Card>
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          점검 화면 설정
        </CardTitle>
        <CardBody>
          <Row style={{ display: "flex", justifyContent: "space-around" }}>
            <label>시작 시간 : &nbsp;</label>
            <ReactDatePicker
              locale={ko}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={60}
              timeCaption="time"
              dateFormat="yyyy, MMMM dd, h:mm aa"
            />
            <label>종료 시간 :&nbsp;</label>
            <ReactDatePicker
              locale={ko}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={60}
              timeCaption="time"
              dateFormat="yyyy, MMMM dd, h:mm aa"
            />
            <label>점검 화면 동작 여부 :&nbsp;</label>
            <select value={onOffInspection} onChange={handleSiteCheck}>
              <option value={true}>true</option>
              <option value={false}>false</option>
            </select>
          </Row>
          <div style={{ marginRight: "20px" }}>
            <Button onClick={changeInspectionSetting}>저장하기</Button>
          </div>
        </CardBody>
      </Card>
    </>
  );

  function getAppSiteInfo() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      getAppSiteInfoApi(authToken).then(({ data: { status, error, data } }) => {
        if (status === "SUCCESS") {
          setOnOffInspection(data.siteCheck || false);
          setStartDate(
            data.siteCheckStartDate
              ? new Date(data.siteCheckStartDate)
              : new Date()
          );
            setEndDate(
              data.siteCheckEndDate
                ? new Date(data.siteCheckEndDate)
                : null
            );
        } else {
          console.log(error);
          alert("문제가 생겼습니다.");
        }
      });
    }
  }

  function changeInspectionSetting() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      changeInspectionSettingApi(
        authToken,
        onOffInspection,
        convertStartDate,
        convertEndDate
      ).then(({ data: { status } }) => {
        if (status === "SUCCESS") {
          alert("수정되었습니다.");
          getAppSiteInfo()
        } else {
          alert("문제가 생겼습니다.");
        }
      });
    }
  }
};

export default InspectionSetting;
