import React, { createRef, useCallback, useRef, useState } from "react";
import {
  Container,
  Button,
  Input,
  Card,
  CardBody,
  CardTitle,
  Row,
} from "reactstrap";
import styled, { css } from "styled-components";
// import { commonStyle } from "../../../../assets/js/commonStyle";
import { useHistory } from "react-router-dom";
// import green from "@material-ui/core/colors/green";
// import { addNotice, updateSymbols } from "../../../../helpers/apiList";
import { Editor } from "@toast-ui/react-editor";
import "@toast-ui/editor/dist/toastui-editor.css";
import {
  decodingHtmlTags,
  encodingHtmlTags,
  replaceNewlineWith,
  replaceNewlineWithBrTag,
} from "../../../../helpers/stringHelpers";
import { modifyOneFaqApi } from "../../../../apis/customer";
import CommonHelper from "../../../../helpers/CommonHelper";

const FaqModifyEditor = ({
  setOpenModifyEditor,
  modifyFaqForm,
  setModifyFaqForm,
  id,
  getFAQList,
  setShowFaqDetail,
}) => {
  const history = useHistory();
  const faqModifyRef = useRef(null);

  return (
    <Card>
      <CardTitle className="bg-light border-bottom p-3 mb-0">
        Faq 수정
      </CardTitle>
      <CardBody maxWidth="xl">
        <Row>
          <label style={{ marginTop: 10, color: "black", fontWeight: 600 }}>
            질문 *
          </label>
          <Input
            autoFocus
            style={{ marginRight: 0 }}
            fullWidth
            type="text"
            placeholder="글 제목"
            value={modifyFaqForm.question}
            onChange={(e) =>
              setModifyFaqForm({ ...modifyFaqForm, question: e.target.value })
            }
          />
        </Row>
        <Row style={{ width: "100%" }}>
          <label style={{ marginTop: 10, color: "black", fontWeight: 600 }}>
            답변 *
          </label>
          <Wrapper>
            <Editor
              ref={faqModifyRef}
              previewStyle="vertical"
              height="600px"
              initialEditType="wysiwyg"
              useCommandShortcut={true}
              initialValue={replaceNewlineWithBrTag(
                decodingHtmlTags(modifyFaqForm.answer)
              )}
            />
          </Wrapper>
        </Row>
        <Row style={{ display: "flex", justifyContent: "space-between" }}>
          <Button onClick={() => onModify()}>수정하기</Button>
          <Button onClick={() => setOpenModifyEditor(false)}>닫기</Button>
        </Row>
      </CardBody>
    </Card>
  );

  async function onModify() {
    const authToken = CommonHelper.getAuthToken();
    // const content = editorStateToHtml(faqModifyRef.current.getEditorState());
    let content = faqModifyRef.current.getInstance().getMarkdown();
    if (!modifyFaqForm.question) {
      alert("질문을 입력해주세요.");
      return;
    }

    if (!content) {
      alert("답변을 입력해주세요.");
      return;
    }
    content = encodingHtmlTags(replaceNewlineWith(content, `<br/>`));

    const params = {
      ...modifyFaqForm,
      answer: content,
      //   category,
      //   attachFiles: [], // 일단 첨부파일은 공지사항에서 없는 것으로 함...
    };
    const response = await modifyOneFaqApi(
      authToken,
      id,
      params
    );
    if (response.data.status === "SUCCESS") {
      alert(`성공적으로 수정되었습니다.`);
      getFAQList();
      setOpenModifyEditor(false);
      setShowFaqDetail(false);
      //   history.push("/board/notice/list");
    } else {
      alert(`서버 오류로 글 작성에 실패하였습니다.`);
    }
  }
};

export default FaqModifyEditor;

const Wrapper = styled.div`
  padding-bottom: 2.8rem;
  font-size: 1.3rem;
  font-family: "Lato";
  width: 100%;
  label {
    display: block;
    margin-bottom: 1rem;
  }
  &.bordered {
    .editorWrapper {
      border-color: red;
    }
  }
  > div + p {
    width: 100%;
    padding-top: 12px;
    font-size: 1.2rem;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: -9px;
  }
`;
