import React, { useState, useEffect, Fragment, useRef } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";
import DEFAULT_IMAGE from "../../../assets/images/big/default-image.jpg";
import { CLIENT_URL, SERVER_API_HOST } from "../../../common/environment";
import CommonHelper from "../../../helpers/CommonHelper";
import {
  getStoreListApi,
  uploadFile,
  deleteOneStoreApi,
  modifyOneStoreApi,
  createNewStoreApi,
} from "../../../apis/main";

const initNewStore = {
  //   bannerUrl: null,
  contactNumber: null,
  description: null,
  email: null,
  fax: null,
  profiles: [],
  latitude: 0,
  longitude: 0,
  name: null,
  siteUrl: null,
  subDescription: null,
  placeId: null,
};

const StoreInfo = () => {
  const newStoreInputRef = useRef(null);
  const modifyStoreInputRef = useRef(null);
  const [showCreateStore, setShowCreateStore] = useState(false);
  const [currentStoreList, setCurrentStoreList] = useState([]);
  const [newStoreInfo, setNewStoreInfo] = useState(initNewStore); //initial state만들어주기
  const [oneStoreDetail, setOneStoreDetail] = useState({});
  const [showModifyStore, setShowModifyStore] = useState(false);

  const onClickUpdateNewStoreInput = () => {
    // 파일 입력 폼에 변화가 일어나는지 감시하는 이벤트 리스터 등록
    newStoreInputRef.current.addEventListener(
      "change",
      onChangeNewStoreInput,
      false
    );
    // 파일 입력 폼 열기
    newStoreInputRef.current.click();
  };

  const onClickUpdateModifyStoreInput = () => {
    modifyStoreInputRef.current.addEventListener(
      "change",
      onChangeModifyStoreInput,
      false
    );
    // 파일 입력 폼 열기
    modifyStoreInputRef.current.click();
  };

  const closeCreateStore = () => {
    setShowCreateStore(false);
    setNewStoreInfo(initNewStore); //생성팝업 닫으면서 input 초기화
  };

  const openCreateStore = () => {
    setShowCreateStore(true);
  };

  const openModifyOneStore = (store) => {
    setShowModifyStore(true);
    setOneStoreDetail(store);
  };

  const closeModifyOneStore = () => {
    setShowModifyStore(false);
  };

  const handleModifyChange = (e) => {
    const { name, value } = e.target;
    const copyOneStore = { ...oneStoreDetail };
    copyOneStore[name] = value;
    setOneStoreDetail(copyOneStore);
  };

  const handleNewChange = (e) => {
    const { name, value } = e.target;
    const copyNewStoreInfo = { ...newStoreInfo };
    copyNewStoreInfo[name] = value;
    setNewStoreInfo(copyNewStoreInfo);
  };

  const checkBeforeSendCreateStore = () => {
    //비어있는 값 없는지 확인하고 create하기
    const {
      //   bannerUrl,
      contactNumber,
      profiles,
      description,
      email,
      fax,
      name,
      siteUrl,
      placeId,
      subDescription,
    } = newStoreInfo;

    if (
      profiles.length > 0 &&
      contactNumber &&
      description &&
      email &&
      fax &&
      name &&
      siteUrl &&
      subDescription &&
      placeId
    ) {
      createNewStore();
    } else {
      alert("비어있는 값을 채운 후 다시 생성해주세요");
    }
  };

  useEffect(() => {
    getStoreList();
  }, []);
  return (
    <>
      {showCreateStore && (
        <Card>
          <CardTitle className="bg-light border-bottom p-3 mb-0">
            <div className="space-between">
              매장 추가하기
              <button onClick={closeCreateStore}>닫기</button>
            </div>
          </CardTitle>
          <CardBody>
            <Container>
              <Row>
                <Col sm="6">
                  <Card>
                    <div onClick={onClickUpdateNewStoreInput}>
                      <CardImg
                        top
                        width="100%"
                        src={
                          newStoreInfo.profiles[0]
                            ? newStoreInfo.profiles[0]
                            : DEFAULT_IMAGE
                        }
                      />
                      <input
                        type="file"
                        accept="image/*"
                        ref={newStoreInputRef}
                        hidden
                      />
                    </div>
                    <CardBody>
                      <CardText>
                        <label>지점 이름</label>
                        <input
                          style={{ width: "100%" }}
                          name="name"
                          onChange={handleNewChange}
                          value={newStoreInfo.name || ""}
                          type="text"
                          placeholder="ex) 부산 본사"
                        />
                      </CardText>
                      <CardText>
                        <label>주소</label>
                        <input
                          style={{ width: "100%" }}
                          name="address"
                          onChange={handleNewChange}
                          value={newStoreInfo.address || ""}
                          type="text"
                          placeholder="ex) 부산 광역시 해운대구 센텀중앙로 97 A동 2710호"
                        />
                      </CardText>
                      <CardText>
                        <label>대표 전화</label>
                        <input
                          style={{ width: "100%" }}
                          name="contactNumber"
                          onChange={handleNewChange}
                          value={newStoreInfo.contactNumber || ""}
                          type="text"
                          placeholder="ex) 1644-5751"
                        />
                      </CardText>
                      <CardText>
                        <label>설명</label>
                        <input
                          style={{ width: "100%" }}
                          name="description"
                          onChange={handleNewChange}
                          value={newStoreInfo.description || ""}
                          type="text"
                          placeholder="메인 설명"
                        />
                      </CardText>
                      <CardText>
                        <label>부가설명</label>
                        <input
                          style={{ width: "100%" }}
                          name="subDescription"
                          onChange={handleNewChange}
                          value={newStoreInfo.subDescription || ""}
                          type="text"
                          placeholder="메인 부가설명"
                        />
                      </CardText>
                      <CardText>
                        <label>이메일</label>
                        <input
                          style={{ width: "100%" }}
                          name="email"
                          onChange={handleNewChange}
                          value={newStoreInfo.email || ""}
                          type="text"
                          placeholder="ex) master@hisigan.com"
                        />
                      </CardText>
                      <CardText>
                        <label>팩스</label>
                        <input
                          style={{ width: "100%" }}
                          name="fax"
                          onChange={handleNewChange}
                          value={newStoreInfo.fax || ""}
                          type="text"
                          placeholder="ex) 051-711-3049"
                        />
                      </CardText>
                      <CardText>
                        <label>누르면 이동될 사이트</label>
                        <input
                          style={{ width: "100%" }}
                          name="siteUrl"
                          onChange={handleNewChange}
                          value={newStoreInfo.siteUrl || ""}
                          type="text"
                          placeholder="ex) http://www.hisigan.com"
                        />
                      </CardText>

                      <CardText>
                        <label>place Id</label>
                        <input
                          style={{ width: "100%" }}
                          name="placeId"
                          value={newStoreInfo.placeId || ""}
                          placeholder="ex) place_id:ChIJZ-5J96GTaDURpb-XTZEHjaQ"
                          onChange={handleNewChange}
                          type="text"
                        />
                        <a
                          href="https://developers.google.com/places/web-service/place-id"
                          target="_blank">
                          place Id 찾기
                        </a>
                      </CardText>
                      <Button onClick={checkBeforeSendCreateStore}>
                        팝업 추가하기
                      </Button>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </CardBody>
        </Card>
      )}
      {showModifyStore && (
        <Card>
          <CardTitle className="bg-light border-bottom p-3 mb-0">
            <div className="space-between">
              매장 조회 및 수정
              <button onClick={closeModifyOneStore}>닫기</button>
            </div>
          </CardTitle>
          <CardBody>
            <Container>
              <Row>매장 조회 및 수정</Row>
              <Row>
                <Col sm="6">
                  <Card>
                    <div onClick={onClickUpdateModifyStoreInput}>
                      <CardImg
                        top
                        width="100%"
                        src={
                          oneStoreDetail.profiles[0]
                            ? oneStoreDetail.profiles[0]
                            : DEFAULT_IMAGE
                        }
                      />
                      <input
                        type="file"
                        accept="image/*"
                        ref={modifyStoreInputRef}
                        hidden
                      />
                    </div>
                    <CardBody>
                      <CardText>
                        <label>지점 이름</label>
                        <input
                          style={{ width: "100%" }}
                          name="name"
                          onChange={handleModifyChange}
                          value={oneStoreDetail.name || ""}
                          type="text"
                          placeholder="ex) 부산 본사"
                        />
                      </CardText>
                      <CardText>
                        <label>주소</label>
                        <input
                          style={{ width: "100%" }}
                          name="address"
                          onChange={handleModifyChange}
                          value={oneStoreDetail.address || ""}
                          type="text"
                          placeholder="ex) 부산 광역시 해운대구 센텀중앙로 97 A동 2710호"
                        />
                      </CardText>
                      <CardText>
                        <label>대표 전화</label>
                        <input
                          style={{ width: "100%" }}
                          name="contactNumber"
                          onChange={handleModifyChange}
                          value={oneStoreDetail.contactNumber || ""}
                          type="text"
                          placeholder="ex) 1644-5751"
                        />
                      </CardText>
                      <CardText>
                        <label>설명</label>
                        <input
                          style={{ width: "100%" }}
                          name="description"
                          onChange={handleModifyChange}
                          value={oneStoreDetail.description || ""}
                          type="text"
                          placeholder="메인 설명"
                        />
                      </CardText>
                      <CardText>
                        <label>부가설명</label>
                        <input
                          style={{ width: "100%" }}
                          name="subDescription"
                          onChange={handleModifyChange}
                          value={oneStoreDetail.subDescription || ""}
                          type="text"
                          placeholder="메인 부가설명"
                        />
                      </CardText>
                      <CardText>
                        <label>이메일</label>
                        <input
                          style={{ width: "100%" }}
                          name="email"
                          onChange={handleModifyChange}
                          value={oneStoreDetail.email || ""}
                          type="text"
                          placeholder="ex) master@hisigan.com"
                        />
                      </CardText>
                      <CardText>
                        <label>팩스</label>
                        <input
                          style={{ width: "100%" }}
                          name="fax"
                          onChange={handleModifyChange}
                          value={oneStoreDetail.fax || ""}
                          type="text"
                          placeholder="ex) 051-711-3049"
                        />
                      </CardText>
                      <CardText>
                        <label>누르면 이동될 사이트</label>
                        <input
                          style={{ width: "100%" }}
                          name="siteUrl"
                          onChange={handleModifyChange}
                          value={oneStoreDetail.siteUrl || ""}
                          type="text"
                          placeholder="ex) http://www.hisigan.com"
                        />
                      </CardText>

                      <CardText>
                        <p
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}>
                          <label>place Id</label>
                          <input
                            style={{ width: "100%" }}
                            name="placeId"
                            value={oneStoreDetail.placeId || ""}
                            placeholder="ex) place_id:ChIJZ-5J96GTaDURpb-XTZEHjaQ"
                            onChange={handleModifyChange}
                            type="text"
                          />
                        </p>
                        <a
                          href="https://developers.google.com/places/web-service/place-id"
                          target="_blank">
                          place Id 찾기
                        </a>
                      </CardText>
                      <Button onClick={modifyOneStore}>수정하기</Button>
                      <Button onClick={deleteOneStore}>삭제하기</Button>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </CardBody>
        </Card>
      )}
      <Card>
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          <div className="space-between">
            매장 리스트
            <Button onClick={openCreateStore}>매장 추가하기</Button>
          </div>
        </CardTitle>
        <CardBody>
          <Container>
            <Row>리스트 목록</Row>
            <Row>
              {currentStoreList.map((el, i) => (
                <React.Fragment key={i}>
                  <Col sm="6">
                    <Card>
                      <div>
                        <CardImg
                          top
                          width="100%"
                          src={el.profiles[0] ? el.profiles[0] : DEFAULT_IMAGE}
                        />
                      </div>
                      <CardBody>
                        <CardText>
                          <label>지점 이름</label>
                          <input
                            style={{ width: "100%" }}
                            value={el.name || "-"}
                            disabled
                          />
                        </CardText>
                        <CardText>
                          <label>주소</label>
                          <input
                            style={{ width: "100%" }}
                            value={el.address || "-"}
                            disabled
                          />
                        </CardText>
                        <CardText>
                          <label>대표 전화</label>
                          <input
                            style={{ width: "100%" }}
                            value={el.contactNumber || "-"}
                            disabled
                          />
                        </CardText>
                        <CardText>
                          <label>설명</label>
                          <textarea
                            style={{ width: "100%" }}
                            value={el.description || "-"}
                            disabled
                          />
                        </CardText>
                        <CardText>
                          <label>부가설명</label>
                          <input
                            style={{ width: "100%" }}
                            value={el.subDescription || "-"}
                            disabled
                          />
                        </CardText>
                        <CardText>
                          <label>이메일</label>
                          <input
                            style={{ width: "100%" }}
                            value={el.email || "-"}
                            disabled
                          />
                        </CardText>
                        <CardText>
                          <label>팩스</label>
                          <input
                            style={{ width: "100%" }}
                            value={el.fax || "-"}
                            disabled
                          />
                        </CardText>
                        <CardText>
                          <label>누르면 이동될 사이트</label>
                          <input
                            style={{ width: "100%" }}
                            value={el.siteUrl || "-"}
                            disabled
                          />
                        </CardText>
                        <CardText>
                          <label>google map place id</label>
                          <input
                            style={{ width: "100%" }}
                            value={el.placeId || "-"}
                            disabled
                          />
                        </CardText>
                        {/* <Row>
                          <div className="half-width">지점 이름</div>
                          <div className="half-width">{el.name || "-"}</div>
                        </Row>
                        <Row>
                          <div className="half-width">주소</div>
                          <div className="half-width">{el.address || "-"}</div>
                        </Row>
                        <Row>
                          <div className="half-width">대표 전화</div>
                          <div className="half-width">
                            {el.contactNumber || "-"}
                          </div>
                        </Row>
                        <Row>
                          <div className="half-width">설명</div>
                          <div className="half-width">
                            {el.description || "-"}
                          </div>
                        </Row>
                        <Row>
                          <div className="half-width">부가설명</div>
                          <div className="half-width">
                            {el.subDescription || "-"}
                          </div>
                        </Row>
                        <Row>
                          <div className="half-width">이메일</div>
                          <div className="half-width">{el.email || "-"}</div>
                        </Row>
                        <Row>
                          <div className="half-width">팩스</div>
                          <div className="half-width">{el.fax || "-"}</div>
                        </Row>
                        <Row>
                          <div className="half-width">누르면 이동될 사이트</div>
                          <div className="half-width">{el.siteUrl || "-"}</div>
                        </Row>
                        <Row>
                          <div className="half-width">google map place id</div>
                          <div className="half-width">{el.placeId || "-"}</div>
                        </Row> */}
                        {/* <Row>
                          <div className="half-width">위도</div>
                          <p>{el.latitude || "-"}</p>
                        </Row>
                        <Row>
                          <div className="half-width">경도</div>
                          <p>{el.longitude || "-"}</p>
                        </Row> */}
                        <Button onClick={() => openModifyOneStore(el)}>
                          수정하기
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                </React.Fragment>
              ))}
            </Row>
            <Row></Row>
          </Container>
        </CardBody>
      </Card>
    </>
  );

  function getStoreList() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      getStoreListApi(authToken).then(({ data: { status, error, data } }) => {
        if (status === "SUCCESS") {
          setCurrentStoreList(data.content || []);
        } else {
          alert(error);
        }
      });
    }
  }

  async function onChangeModifyStoreInput(event) {
    // 파일을 선택 한 경우에만 실행
    const file = event.target.files[0];
    // 여러번 등록되는 버그 수정 (https://stackoverflow.com/questions/39484895/how-to-allow-input-type-file-to-select-the-same-file-in-react-component)
    event.target.value = null;
    const authToken = CommonHelper.getAuthToken();
    // 파일 업로드
    let nextPhotoUrl = await uploadFileAndGetUrl(file);
    if (nextPhotoUrl) {
      // nextPhotoUrl = nextPhotoUrl.replace('http://localhost:8080', SERVER_API_HOST); // TODO 임시 코드
      nextPhotoUrl = `${SERVER_API_HOST}${nextPhotoUrl}`;
      const fakeInfo = { ...oneStoreDetail };
      //   fakeInfo.profiles.push(nextPhotoUrl);
      fakeInfo.profiles[0] = nextPhotoUrl;
      setOneStoreDetail(fakeInfo);
    }
  }

  async function onChangeNewStoreInput(event) {
    // 파일을 선택 한 경우에만 실행
    const file = event.target.files[0];
    // 여러번 등록되는 버그 수정 (https://stackoverflow.com/questions/39484895/how-to-allow-input-type-file-to-select-the-same-file-in-react-component)
    event.target.value = null;
    const authToken = CommonHelper.getAuthToken();
    // 파일 업로드
    let nextPhotoUrl = await uploadFileAndGetUrl(file);
    if (nextPhotoUrl) {
      // nextPhotoUrl = nextPhotoUrl.replace('http://localhost:8080', SERVER_API_HOST); // TODO 임시 코드
      nextPhotoUrl = `${SERVER_API_HOST}${nextPhotoUrl}`;
      const fakeInfo = { ...newStoreInfo };
      //   fakeInfo.profiles.push(nextPhotoUrl);
      fakeInfo.profiles[0] = nextPhotoUrl;
      setNewStoreInfo(fakeInfo);
    }
  }

  // 파일 업로드 후 URL 반환
  async function uploadFileAndGetUrl(file) {
    if (file) {
      const { type: fileType, name: fileName, size: fileSize } = file;
      // 파일 데이터 만들기
      const formData = new FormData();
      formData.append("file", file);

      // 파일 업로드
      const uploadResult = await uploadFile(formData);
      const {
        status,
        data: { url },
      } = uploadResult;
      if (status === 200 && !!url) {
        return url;
      } else {
        alert("파일 업로드 실패");
        return null;
      }
    } else {
      return null;
    }
  }

  function createNewStore() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      createNewStoreApi(authToken, newStoreInfo).then(
        ({ data: { status, detail, data } }) => {
          if (status === "SUCCESS") {
            alert("성공적으로 생성되었습니다.");
            getStoreList();
            // setShowCreateStore(false);
            closeCreateStore();
          } else {
            alert(detail);
          }
        }
      );
    }
  }

  function modifyOneStore() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      modifyOneStoreApi(authToken, oneStoreDetail.id, oneStoreDetail).then(
        ({ data: { status, detail, data } }) => {
          if (status === "SUCCESS") {
            alert("성공적으로 수정되었습니다.");
            getStoreList();
            setShowModifyStore(false);
          } else {
            alert(detail);
          }
        }
      );
    }
  }

  function deleteOneStore() {
    const authToken = CommonHelper.getAuthToken();
    if (!!authToken) {
      deleteOneStoreApi(authToken, oneStoreDetail).then(
        ({ data: { status, error, data } }) => {
          if (status === "SUCCESS") {
            alert("성공적으로 삭제되었습니다.");
            getStoreList();
            setShowModifyStore(false);
          } else {
            console.log(error);
            alert("삭제하는데 문제가 생겼습니다.");
          }
        }
      );
    }
  }
};

export default StoreInfo;
